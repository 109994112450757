import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        <Header />
        <section className="privacy-policy-banner">
          <div className="privacy-policy-banner-img">
            <figure>
              <img src="img/privacy_policy_banner.jpg" alt="Privacy Policy" />
            </figure>
          </div>
          <div className="privacy-policy-banner-content">
            <div className="container">
              <div className="privacy-policy-heading">
                <h1>MARKETPLACE TERMS OF SERVICE</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="privacy-policy-wr">
          <div className="container">
            <div className="privacy-policy-content">
              <div className="privacy-policy-text-block">
                <ol>
                  <li>
                    <h3>About the Website</h3>
                    <ul className="alpha">
                      <li>
                        This website <b>( Website )</b> is operated by Ozgigs Global Pty
                        Ltd (ACN 659 327 849) <b>( we, our or us )</b> and it operates as
                        an online marketplace to facilitate interactions
                        between: <br/><br/> 
                        (i) parties providing services <b>( Provider )</b> and<br />
                        (ii) parties receiving services <b>( Receiver )</b>,<br />
                        making it easier for the Receiver and the Provider to locate,
                        communicate, arrange payment and deliver the services in
                        a fast and secure manner <b>( Services )</b>.
                      </li>
                      <li>
                        Please read these terms and conditions <b>( Terms )</b> carefully
                        as they govern your rights and responsibilities when
                        using our Services.
                      </li>
                      <li>
                        We reserve the right to review and change any of these
                        Terms by updating them at our sole discretion. When we
                        update these Terms, we will use reasonable endeavours to
                        provide you with notice of updates of these Terms. Any
                        changes to these Terms take immediate effect from the
                        date of their publication. Before you continue, we
                        recommend you keep a copy of these Terms for your
                        records.
                      </li>
                      <li>
                        If the Services’ features change such that they are
                        governed by separate terms, Members will be informed,
                        and must accept those terms before given access to the
                        changes.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3>Acceptance of the Terms</h3>
                    <p className="pt-1">
                      You accept these Terms by creating a subscription, by
                      registering for or using the Services and/or by making or
                      receiving any payment when using our Services as required
                      under these Terms. You may also accept these Terms by
                      clicking to accept or agree to these Terms where and if
                      this option is made available to you by us in the user
                      interface.
                    </p>
                  </li>

                  <li>
                    <h3>The Services</h3>
                    <ul className="alpha">
                      <li>
                        In order to access the Services, both the Receiver and
                        the Provider are required to register for an account
                        through the Website <b>( Account )</b>.
                      </li>
                      <li>
                        As part of the registration process, or as part of
                        your continued use of the Services, you may be
                        required to provide personal information about
                        yourself (such as identification or contact details),
                        including but not limited to:   <br /><br />(i) Email address  <br />(ii)
                        Preferred username  <br />(iii) Mailing address  <br />(iv)
                        Telephone number  <br />(v) Password  <br />(vi) description of the
                        event  <br />(vii) the Service offered or required.
                      </li>
                      <li>
                        You warrant that any information you give to us in the
                        course of completing the registration process will
                        always be accurate, correct and up to date.
                      </li>
                      <li>
                        Once you have completed the registration process, you
                        will be a registered member of the Website <b>( Member ) </b>
                        and agree to be bound by these Terms.
                      </li>
                      <li>
                        You must not apply to become a Member if:  <br/><br/>(i) you are
                        not of legal age to form a binding contract with us or
                        <br />(ii) you are a person barred from receiving the
                        Services under the laws of Australia or other
                        countries including the country in which you are
                        resident or from which you use the Services.
                      </li>
                      <li>
                        We will use reasonable efforts consistent with
                        prevailing industry standards to maintain the Services
                        in a manner which minimises errors and interruptions.
                        From time to time the Services may be temporarily
                        unavailable for scheduled maintenance or for
                        unscheduled emergency maintenance. We will use
                        reasonable efforts to provide advance notice of any
                        scheduled service disruption. However, we do not
                        warrant that the Services will be uninterrupted or
                        error free, nor do we make any warranty as to the
                        results that may be obtained from use of the Services.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Your obligations as a Member</h3><br />
                    <p>4.1 &nbsp;&nbsp;&nbsp;As a Member, you agree to comply with the following:</p>
                    <ul className="alpha">
                      <li>
                        you will not share your profile with any other person
                      </li>
                      <li>
                        you will use the Services only for purposes that are
                        permitted by:  <br/><br/>
                        (i) these Terms and  <br />
                        (ii) any applicable law,
                        regulation or generally accepted practices or guidelines in
                        the relevant jurisdictions
                      </li>
                      <li>
                        you have sole responsibility
                        for protecting the confidentiality of your password and/or
                        email address
                      </li>
                      <li>
                        use of your password by any other person may result in the immediate cancellation of
                        the Services and/or your Membership
                      </li>
                      <li>
                        any use of your registration information by any other person, or third parties, is strictly
                        prohibited. You agree to immediately notify us of any unauthorised use of your password
                        or email address or any breach of security of which you have become aware
                      </li>
                      <li>
                        you must not expressly or impliedly impersonate another Member or use the profile or
                        password of another Member at any time
                      </li>
                      <li>
                        any content that you broadcast, publish, upload, transmit, post or distribute on the
                        Website <b>( Your Content )</b> will always be accurate, correct and up to date and you will
                        maintain reasonable records of Your Content and own all rights in relation to Your
                        Content (including but not limited to copyright and all other forms of intellectual property)
                      </li>
                      <li>
                        you are responsible for Your Content and we don’t own that data, but you grant us an
                        irrevocable licence to use, remove, copy, transmit, store, share, analyse, and back up all
                        data (including images and photographs) you submit to us through the Services,
                        including personal data and images of yourself and others, to enable you to use our
                        Services, allow us to improve, develop and protect our services, create new services,
                        communicate with you about your subscription and send you information we think may
                        be of interest to you, and to enable us to market and promote our Services to third
                        parties
                      </li>
                      <li>
                        you agree not to harass, impersonate, stalk, threaten another Member of the Website
                        (where interaction with other Members is made available to you)
                      </li>
                      <li>
                        access and use of the Website is limited, non-transferable and allows for the sole use of
                        the Website by you for the purposes of providing or accessing the Services
                      </li>
                      <li>
                        you will not use the Services or the Website in connection with any commercial
                        endeavours except those that are specifically endorsed or approved by us
                      </li>
                      <li>
                        you will not use the Services or Website for any illegal and/or unauthorised use which
                        includes collecting email addresses of Members by electronic or other means for thepurpose of sending unsolicited email or unauthorised framing of or linking to the
                        Website
                      </li>
                      <li>
                        you agree that commercial advertisements, affiliate links and other forms of solicitation
                        may be removed by us without notice and may result in termination of the Services
                        and/or your Membership. Appropriate legal action will be taken by us for any illegal or
                        unauthorised use of the Website and
                      </li>
                      <li>
                        you acknowledge and agree that any automated use of the Website or its Services is
                        prohibited.
                      </li>

                    </ul>
                    <p>4.2 &nbsp;&nbsp;&nbsp;As a Member, you represent and warrant that:</p>
                    <ul className="alpha">
                      <li>
                        You have obtained all necessary rights, releases, privacy consents and permissions to
                        provide data to us and grant the rights to us in these Terms.
                      </li>
                      <li>
                        Your data and its transfer to and use by us, as authorised by you under these Terms,
                        does not and will not violate any laws (including without limitation those relating to
                        export control and electronic communications) or the rights of any third party, including
                        without limitation any intellectual property rights, rights of privacy, or rights of publicity,
                        and any use, collection and disclosure authorised is not inconsistent with the terms of
                        any applicable privacy policies.
                      </li>
                      <li>
                        You will not reverse compile, disassemble, remove, release, disclose, reveal, copy,
                        extract, modify or otherwise reverse engineer all or any part of the Services.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Payment</h3>
                    <ul className="alpha">
                      <li>
                        Depending on the type of Membership that you subscribe for, you may be liable to pay us fees,
                        other than on fee free accounts. Fees will be calculated, due and payable in accordance with
                        our Pricing Schedule, as published by us from time to time on our Website <b>( Pricing
                        Schedule )</b>.
                      </li>
                      <li>
                        We reserve the right to negotiate our fees on a case-by-case basis, including but not limited to
                        providing additional functionalities at our discretion (such as bidding for premium positioning on
                        our Website).
                      </li>
                      <li>
                        Unless otherwise agreed, all fees are charged in Australian dollars and are expressed
                        exclusive of GST (which applies in addition where so required by law).
                      </li>
                      <li>
                        Membership periods may in some instances be automatically renewed and charged on a
                        monthly or annual basis (as applicable) until you elect to cancel your subscription, or it is
                        terminated in accordance with these Terms. We may offer you a free or pilot trial but if you
                        continue to use our Services after the trial you will then become liable for and must pay the
                        fees in accordance with these Terms.
                      </li>
                      <li>
                        Other than on fee free membership Accounts, your subscription continues for the period
                        covered by the fees paid or payable by you. At the end of each billing period, these terms
                        automatically continue for a further period of the same duration as the previous one, provided
                        you continue to pay the subscription fees.
                      </li>
                      <li>
                        Payments may be made using third-party applications and services not owned, operated, or
                        otherwise controlled by us. You acknowledge and agree that we will not be liable for any losses
                        or damage arising from the operation of third-party payment applications and services. You
                        further acknowledge and warrant that you have read, understood and agree to be bound by
                        the terms and conditions of the applicable third-party payment applications and services you
                        choose to use as a payment method.
                      </li>
                      <li>
                        If your usage exceeds your subscription or otherwise requires the payment of additional fees,
                        You will be billed for such usage and you agree to pay the additional fees in the manner
                        provided in these Terms and the relevant tax invoice.
                      </li>
                      <li>
                        We reserve the right to change the Pricing Schedule, our fees, any other applicable chargesand to institute new charges at the end of your subscription, or at any other time upon no less
                        than thirty (30) days prior written notice to you (which may be sent by email).
                      </li>
                      <li>
                        If you believe that we have billed you incorrectly, you must contact us in writing no later than 60
                        days after the expense is raised in order to receive an adjustment or credit.
                      </li>
                      <li>
                        Any invoices issued by us must be paid in accordance with the terms stated therein.
                      </li>
                      <li>
                        Any unpaid amounts or defaults in payment (e.g. credit card expiry) will be subject to an
                        interest charge of 1.5% per month on the outstanding balance, or the maximum permitted by
                        law, whichever is lower, plus all expenses of defaults and collection and may result in
                        immediate termination of your subscription.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Cancelling or Changing Your Subscription</h3>
                    <ul className="alpha">
                      <li>
                        To cancel or change your subscription, please contact
                        us. If you cancel or change your subscription, we will cease
                        to automatically renew the subscription or apply the new
                        charges (as the case may be) from the end of the term in
                        which the subscription was cancelled or changed. You will
                        still need to pay all relevant fees up to and including the
                        day of cancellation.
                      </li>
                      <li>
                        We reserve the right to cancel fee free accounts at any time and without notice or warning.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Refund Policy</h3>
                    <ul className="alpha">
                      <li>
                        Since we are only a facilitator in introducing the
                        Receiver to the Provider (and/or vice versa) and providing a
                        system to make payment, we do not hold any liability to
                        either party directly and we will not personally refund or
                        pay any payments related to the use of Services.
                      </li>
                      <li>
                        If a
                        Receiver is unsatisfied with the services provided by the
                        Provider or believes that they may be entitled to a refund,
                        the Receiver must and agrees to only ever: <br/><br/>(i) contact the
                        Provider directly to request a refund <br/>(ii) if
                        contacting the Provider is not successful after fourteen
                        (14) days, contact us through the 'Contact Us' section of
                        the Website outlining why you believe you are entitled to a
                        refund.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Copyright and Intellectual Property</h3>
                    <ul className="alpha">
                      <li>
                        The Website, the Services and all of our related
                        products are subject to copyright. The material on the
                        Website is protected by copyright under the laws of
                        Australia and through international treaties. Unless
                        otherwise indicated, all rights (including copyright) in the
                        Services and compilation of the Website (including but not
                        limited to text, graphics, logos, button icons, video
                        images, audio clips, Website code, scripts, design elements
                        and interactive features) or the Services are owned or
                        controlled for these purposes and are reserved by us and our
                        contributors.
                      </li>
                      <li>
                        All trademarks, service marks and trade
                        names are owned, registered and/or licensed by us and we
                        grant Members a worldwide, non-exclusive, royalty-free,
                        revocable, non-transferable license whilst they are a Member
                        to: <br/><br/>(i) use the Website pursuant to these Terms <br/>(ii) copy
                        and store the Website and the material contained in the
                        Website in their device's cache memory and <br/>(iii) print pages
                        from the Website for their own personal and non-commercial
                        use. We do not grant you any other rights whatsoever in
                        relation to the Website or the Services. All other rights
                        are expressly reserved by us.
                      </li>
                      <li>
                        We retain all rights, title
                        and interest in and to the Website and all related Services.
                        Nothing you do on or in relation to the Website will
                        transfer any: <br/><br/>(i) business name, trading name, domain name,
                        trade mark, industrial design, patent, registered design or
                        copyright or <br/>(ii) a right to use or exploit a business name,
                        trading name, domain name, trade mark or industrial design
                        or <br/>(iii) a thing, system or process that is the subject of a
                        patent, registered design or copyright (or an adaptation or
                        modification of such a thing, system or process), to you.
                      </li>
                      <li>
                        You may not, without our prior written permission and
                        the permission of any other relevant rights owners,
                        broadcast, republish, up-load to a third party, transmit,
                        post, distribute, show or play in public, adapt or change in
                        any way the Services or third-party Services for any
                        purpose, unless otherwise provided by these Terms. This
                        prohibition does not extend to materials on the Website,
                        which are freely available for re-use or are in the public
                        domain.
                      </li>
                      <li>
                        Where you broadcast, publish, upload, transmit,
                        post or distribute Your Content on the Website, then you
                        grant to us a non-exclusive, transferrable, perpetual,
                        royalty- free, irrevocable, worldwide licence to broadcast,
                        republish, up-load to a third party, transmit, post,
                        distribute, show or play in public, adapt or change Your
                        Content.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Privacy</h3>
                    We take your privacy seriously and any information provided
                    through your use of the Website and/or Services are subject
                    to our Privacy Policy, which is available on the Website.
                  </li>

                  <li>
                    <h3>General Disclaimer</h3>
                    <ul className="alpha">
                      <li>
                        Nothing in these Terms limits or excludes any
                        guarantees, warranties, representations or conditions
                        implied or imposed by law, including the Australian Consumer
                        Law (or any liability under them) which by law may not be
                        limited or excluded.
                      </li>
                      <li>Subject to this clause, and to the
                        extent permitted by law:<br/><br/>
                        (i) all terms, guarantees, warranties, representations or conditions which are not
                        expressly stated in the Terms are excluded <br/>
                        (ii) we will not be liable for any special, indirect or consequential loss or damage (unless
                        such loss or damage is reasonably foreseeable resulting from our failure to meet an
                        applicable consumer guarantee), loss of profit or opportunity, or damage to goodwill
                        arising out of or in connection with the Services or these Terms (including as a result of
                        not being able to use the Services or the late supply of the Services), whether at
                        common law, under contract, tort (including negligence), in equity, pursuant to statute
                        or otherwise.
                      </li>
                      <li>
                        Use of the Website and the Services is at your own risk. Everything on the Website and the
                        Services is provided to you "as is" and "as available" without warranty or condition of any
                        kind. None of our affiliates, directors, officers, employees, agents, contributors and licensors
                        make any express or implied representation or warranty about the Services or any products
                        or Services (including our own products or Services) referred to on the Website. This includes
                        (but is not restricted to) loss or damage you might suffer as a result of any of the following:<br/><br/>
                        (i) failure of performance, error, omission, interruption, deletion, defect, failure to correct
                        defects, delay in operation or transmission, computer virus or other harmful component,
                        loss of data, communication line failure, unlawful third-party conduct, or theft,
                        destruction, alteration or unauthorised access to records <br/>
                        (ii) the accuracy, suitability or currency of any information on the Website, the Services,
                        or any of its Services related products (including third party material and
                        advertisements on the Website) <br/>
                        (iii) costs incurred as a result of you using the Website, the Services or any of our
                        products and <br/>
                        (iv) the Services or operation in respect to links which are provided for your
                        convenience.
                      </li>
                      <li>
                        You acknowledge that our Website and the Services are only intended to facilitate the
                        interactions between the Receiver and the Provider, and we do not offer any services other
                        than the introduction and we holds no liability to you as a result of any conduct of other
                        Members or the misuse of Your Content by any party (including other Members).
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Limitation of Liability</h3>
                    <ul className="alpha">
                      <li>
                        Our total liability arising out of or in connection with
                        the Services or these Terms, however arising, including
                        under contract, tort (including negligence), in equity,
                        under statute or otherwise, will not exceed the resupply of
                        the Services to you.
                      </li>
                      <li>
                        You expressly understand and agree
                        that we, our affiliates, employees, agents, contributors and
                        licensors shall not be liable to you for any direct,
                        indirect, incidental, special consequential or exemplary
                        damages which may be incurred by you, however caused and
                        under any theory of liability. This shall include, but is
                        not limited to, any loss of profit (whether incurred
                        directly or indirectly), any loss of goodwill or business
                        reputation and any other intangible loss.
                      </li>
                      <li>
                        You
                        acknowledge and agree that we hold no liability for any
                        direct, indirect, incidental, special consequential or
                        exemplary damages which may be incurred by you as a result
                        of providing Your Content to the Website.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Termination of Contract</h3>
                    <ul className="alpha">
                      <li>
                        If you want to terminate these Terms, you may do so by
                        providing us with 30 days' notice of your intention to
                        terminate by sending notice of your intention to terminate
                        to CWB Pty Ltd via the 'Contact Us' link on our homepage.
                      </li>
                      <li>
                        We may at any time, terminate these Terms with you if:
                        <br/><br/>(i) you have breached any provision of these Terms or intend
                        to breach any provision <br/>(ii) we are required to do so by law
                        <br/>(iii) we are transitioning to no longer providing the
                        Services to Members in the country in which you are resident
                        or from which you use the service or <br/>(iv) the provision of
                        the Services to you by us is, in our opinion, no longer
                        commercially viable <br/>(v) we do not wish to offer you access
                        to the Services for any reason whatsoever.
                      </li>
                      <li>
                        Subject to
                        local applicable laws, we reserve the right to discontinue
                        or cancel your membership at any time and may suspend or
                        deny, in our sole discretion, your access to all or any
                        portion of the Website or the Services without notice if you
                        breach any provision of these Terms or any applicable law or
                        if your conduct impacts our name or reputation or violates
                        the rights of those of another party.
                      </li>
                      <li>
                        When these Terms
                        come to an end, all of the legal rights, obligations and
                        liabilities that you and we have benefited from, been
                        subject to (or which have accrued over time whilst these
                        Terms have been in force) or which are expressed to continue
                        indefinitely, shall be unaffected by this cessation, and the
                        provisions of this clause shall continue to apply to such
                        rights, obligations and liabilities indefinitely.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Indemnity</h3>
                    <p className="pt-1">
                      You agree to indemnify us, our affiliates, employees, agents, contributors, third party contentproviders and licensors from and against:
                    </p>
                    <ul className="alpha">
                      <li>
                        all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including
                        legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with
                        Your Content
                      </li>
                      <li>
                        any direct or indirect consequences of you
                        accessing, using or transacting on the Website or attempts
                        to do so and/or
                      </li>
                      <li>
                        any breach of these Terms by you.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Dispute Resolution</h3>
                    <ul className="alpha">
                      <li>
                        <b>Compulsory</b> <br />
                        If a dispute arises out of or relates to
                        these Terms, either party may not commence any Tribunal or
                        Court proceedings in relation to the dispute, unless the
                        following clauses have been complied with (except where
                        urgent interlocutory relief is sought).
                      </li>
                      <li>
                        <b>Notice</b><br />
                        A party
                        to these Terms claiming a dispute (Dispute) has arisen under
                        these Terms, must give written notice to the other party
                        detailing the nature of the dispute, the desired outcome and
                        the action required to settle the Dispute.
                      </li>
                      <li>
                        <b>Resolution</b><br />
                        On receipt of that notice <b>(Notice)</b> by that other party, the
                        parties to these Terms (Parties) must:<br/><br/>
                        (i) within 30 days of the Notice endeavour in good faith to resolve the Dispute
                        expeditiously by negotiation or such other means upon which they may mutually
                        agree<br/>
                        (ii) If for any reason whatsoever, 30 days after the date of the Notice, the Dispute has not
                        been resolved, the Parties must request that an appropriate mediator be appointed by
                        the Resolution Institute<br/>
                        (iii) The Parties are equally liable for the fees and reasonable expenses of a mediator and
                        the cost of the venue of the mediation and without limiting the foregoing undertake to
                        pay any amounts requested by the mediator as a pre-condition to the mediation
                        commencing. The Parties must each pay their own costs associated with the mediation.<br/>
                        (iv) The mediation will be held in Sydney, Australia.<br/>
                      </li>
                      <li>
                        <b>Confidential</b><br />
                        All communications concerning negotiations made by the Parties arising out of and in
                        connection with this dispute resolution clause are confidential and to the extent possible, must
                        be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.
                      </li>
                      <li>
                        <b>Termination of Mediation</b><br />
                        If 4 weeks have elapsed after the start of a mediation of the Dispute and the Dispute has not
                        been resolved, either Party may ask the mediator to terminate the mediation and the
                        mediator must do so.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3>Venue and Jurisdiction</h3>
                    <p className="pt-1">
                      The Services offered by us are intended to be viewed by
                      residents of Australia. In the event of any dispute arising
                      out of or in relation to the Website, you agree that the
                      exclusive venue for resolving any dispute shall be in the
                      courts of New South Wales, Australia.
                    </p>
                  </li>

                  <li>
                    <h3>Governing Law</h3>
                    <p className="pt-1">
                      The Terms are governed by the laws of New South Wales,
                      Australia. Any dispute, controversy, proceeding or claim of
                      whatever nature arising out of or in any way relating to
                      these Terms and the rights created hereby shall be governed,
                      interpreted and construed by, under and pursuant to the laws
                      of New South Wales, Australia, without reference to conflict
                      of law principles, notwithstanding mandatory rules. The
                      validity of this governing law clause is not contested.
                      These Terms shall be binding to the benefit of the parties
                      hereto and their successors and assigns.
                    </p>
                  </li>
                  <li>
                    <h3>Independent Legal Advice</h3>
                    <p className="pt-1">
                      Both parties confirm and declare that the provisions of
                      these Terms are fair and reasonable and both parties have
                      had the opportunity to obtain independent legal advice and
                      declare these Terms are not against public policy on the
                      grounds of inequality or bargaining power or general grounds
                      of restraint of trade.
                    </p>
                  </li>
                  <li>
                    <h3>Severance</h3>
                    <p className="pt-1">
                      If any part of these Terms is found to be void or
                      unenforceable by a Court of competent jurisdiction, that
                      part shall be severed and the rest of these Terms shall
                      remain in force.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default TermsOfUse;