import { commonService } from "../services/common.service";
import { UploadProfileConst } from "../constants/user.constants";

const uploadProfileAction = (data) => async (dispatch) => {
    try{
        const uploadProfile = await commonService.withToken('/upload-profile',data);
        if (uploadProfile) {
            dispatch({ type: UploadProfileConst.GET_UPLOADPROFILE_SUCCESS, payload: { datasss: uploadProfile.data } });
        }
        else {
            dispatch({ type: UploadProfileConst.GET_UPLOADPROFILE_FAILURE, payload: { data: uploadProfile.data } });
        }
    }
    catch (error) {
        dispatch({ type: UploadProfileConst.GET_UPLOADPROFILE_FAILURE, payload: error.message })
    }
}

export default uploadProfileAction;