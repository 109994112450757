export const userConstants = {
  GET_LOGIN_REQUEST: 'GET_LOGIN_REQUEST',
  GET_LOGIN_SUCCESS: 'GET_LOGIN_SUCCESS',
  GET_LOGIN_FAILURE: 'GET_LOGIN_FAILURE'
}
/************************ Constants For Authenctication **********************/

export const logoutData = {
  GET_LOGOUT_SUCCESS: 'GET_LOGOUT_SUCCESS',
  GET_LOGOUT_FAILURE: 'GET_LOGOUT_FAILURE'
}

export const signupConstants = {
  GET_SIGNUP_SUCCESS: 'GET_SIGNUP_SUCCESS',
  GET_SIGNUP_FAILURE: 'GET_SIGNUP_FAILURE'
}

export const forgetPassword = {
  GET_FORGET_SUCCESS: 'GET_FORGET_SUCCESS',
  GET_FORGET_FAILURE: 'GET_FORGET_FAILURE',
}

export const resetPassword = {
  GET_RESET_REQUEST: 'GET_RESET_REQUEST',
  GET_RESET_SUCCESS: 'GET_RESET_SUCCESS',
  GET_RESET_FAILURE: 'GET_RESET_FAILURE',
}

export const googleConstants = {
  GET_GOOGLE_SUCCESS: 'GET_GOOGLE_SUCCESS',
  GET_GOOGLE_FAILURE: 'GET_GOOGLE_FAILURE',
  CLEAR_GOOGLE_LOGIN: 'CLEAR_GOOGLE_LOGIN'
}

export const varificationConstants = {
  GET_VARIFICATION_SUCCESS: 'GET_VARIFICATION_SUCCESS',
  GET_VARIFICATION_FAILURE: 'GET_VARIFICATION_FAILURE'
}

export const resendEmailVerificationConstants = {
  GET_RESENDVERIFICATION_SUCCESS: 'GET_RESENDVERIFICATION_SUCCESS',
  GET_RESENDVERIFICATION_FAILURE: 'GET_RESENDVERIFICATION_FAILURE'
}

export const clearResendEmailVerificationData = {
  CLEAR_RESENDEMAILVERIFICATION_MESSAGE : "CLEAR_RESENDEMAILVERIFICATION_MESSAGE"
}

export const clearLoginData = {
  CLEAR_LOGIN_MESSAGE : "CLEAR_LOGIN_MESSAGE"
}

export const clearForgetData = {
  CLEAR_FORGET_MESSAGE : "CLEAR_FORGET_MESSAGE"
}

export const clearVarificationData = {
  CLEAR_VARIFICATION_MESSAGE : "CLEAR_VARIFICATION_MESSAGE"
}

export const clearSignupData = {
  CLEAR_SIGNUP_MESSAGE : "CLEAR_SIGNUP_MESSAGE"
}

export const clearResetPasswordData = {
  CLEAR_RESET_PASSWORD_MESSAGE : "CLEAR_RESET_PASSWORD_MESSAGE"
}

export const clearLogoutData = {
  CLEAR_LOGOUT_MESSAGE : "CLEAR_LOGOUT_MESSAGE"
}

export const dashboardCount = {
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE'
}

export const consumerData = {
  GET_CONSUMER_SUCCESS: 'GET_CONSUMER_SUCCESS',
  GET_CONSUMER_FAILURE: 'GET_CONSUMER_FAILURE'
}

export const vendorData = {
  GET_VENDOR_SUCCESS: 'GET_VENDOR_SUCCESS',
  GET_VENDOR_FAILURE: 'GET_VENDOR_FAILURE',
  GET_VENDOR_REQUEST: 'GET_VENDOR_REQUEST'
}

export const paymentData = {
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE: 'GET_PAYMENT_FAILURE'
}

export const reportData = {
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE'
}

export const feedbackData = {
  GET_FEEDBACK_SUCCESS: 'GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_FAILURE: 'GET_FEEDBACK_FAILURE'
}

export const subscriptionHistoryConst = {
  GET_SUBSCRIPTIONHISTORY_SUCCESS: 'GET_SUBSCRIPTIONHISTORY_SUCCESS',
  GET_SUBSCRIPTIONHISTORY_FAILURE: 'GET_SUBSCRIPTIONHISTORY_FAILURE'
}

export const userblockData = {
  GET_USERBLOCK_SUCCESS: 'GET_USERBLOCK_SUCCESS',
  GET_USERBLOCK_FAILURE: 'GET_USERBLOCK_FAILURE'
}

export const detailsData = {
  GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE: 'GET_DETAILS_FAILURE'
}

export const extraConstants = {
  GET_EXTRA_SUCCESS: 'GET_EXTRA_SUCCESS',
  GET_EXTRA_FAILURE: 'GET_EXTRA_FAILURE'
}

export const categoryData = {
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAILURE: 'GET_CATEGORY_FAILURE'
}

export const RegionData = {
  GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',
  GET_REGION_FAILURE: 'GET_REGION_FAILURE'
}

export const vendorFavUnfavResponse = {
  GET_FAVUNFAV_SUCCESS: 'GET_FAVUNFAV_SUCCESS',
  GET_FAVUNFAV_FAILURE: 'GET_FAVUNFAV_FAILURE',
  GET_FAVUNFAV_RESTORE: 'GET_FAVUNFAV_RESTORE',
}

export const vendorSlugId = {
  GET_SLUG_ID_SUCCESS: 'GET_SLUG_ID_SUCCESS',
  GET_SLUG_ID_FAILURE: 'GET_SLUG_ID_FAILURE'
}

export const vendorDetailsData = {
  GET_VENDORDETAIL_SUCCESS: 'GET_VENDORDETAIL_SUCCESS',
  GET_VENDORDETAIL_FAILURE: 'GET_VENDORDETAIL_FAILURE'
}


export const vendorReelsData = {
  GET_VENDORREELS_SUCCESS: 'GET_VENDORREELS_SUCCESS',
  GET_VENDORREELS_FAILURE: 'GET_VENDORREELS_FAILURE'
}

export const searchVendorReelsData = {
  GET_SEARCHVENDORREELS_SUCCESS: 'GET_SEARCHVENDORREELS_SUCCESS',
  GET_SEARCHVENDORREELS_FAILURE: 'GET_SEARCHVENDORREELS_FAILURE'
}

export const ChatListConst = {
  GET_CHATLIST_SUCCESS: 'GET_CHATLIST_SUCCESS',
  GET_CHATLIST_FAILURE: 'GET_CHATLIST_FAILURE'
}

export const CreateChatConst = {
  GET_CREATECHAT_SUCCESS: 'GET_CREATECHAT_SUCCESS',
  GET_CREATECHAT_FAILURE: 'GET_CREATECHAT_FAILURE'
}

export const SendChatNotification = {
  GET_SENDCHATNOTIFICATION_SUCCESS: 'GET_SENDCHATNOTIFICATION_SUCCESS',
  GET_SENDCHATNOTIFICATION_FAILURE: 'GET_SENDCHATNOTIFICATION_FAILURE'
}

export const VendorReviewsConst = {
  GET_VENDORREVIEWS_SUCCESS: "GET_VENDORREVIEWS_SUCCESS",
  GET_VENDORREVIEWS_FAILURE: "GET_VENDORREVIEWS_FAILURE"
}

/************************ Constants For Vendor Side Module **********************/

export const CategoryRegionListConst = {
  CATEGORY_REGIONS_LIST_SUCCESS: 'CATEGORY_REGIONS_LIST_SUCCESS',
  CATEGORY_REGIONS_LIST_FAILURE: 'CATEGORY_REGIONS_LIST_FAILURE',
}

export const VendorHomeConst = {
  GET_VENDORHOME_SUCCESS: 'GET_VENDORHOME_SUCCESS',
  GET_VENDORHOME_FAILURE: 'GET_VENDORHOME_FAILURE',
}

export const eventTypeConst = {
  GET_EVENTTYPE_SUCCESS: 'GET_EVENTTYPE_SUCCESS',
  GET_EVENTTYPE_FAILURE: 'GET_EVENTTYPE_FAILURE'
}

export const vendorEventsConst = {
  GET_VENDOREVENTS_SUCCESS: 'GET_VENDOREVENTS_SUCCESS',
  GET_VENDOREVENTS_FAILURE: 'GET_VENDOREVENTS_FAILURE'
}

export const EventDetailsConst = {
  GET_EVENTDETAILS_SUCCESS: 'GET_EVENTDETAILS_SUCCESS',
  GET_EVENTDETAILS_FAILURE: 'GET_EVENTDETAILS_FAILURE',
}

export const VendorDashboardData = {
  GET_VENDORDASHBOARD_SUCCESS: 'GET_VENDORDASHBOARD_SUCCESS',
  GET_VENDORDASHBOARD_FAILURE: 'GET_VENDORDASHBOARD_FAILURE',
}

export const VendorProfileConstant = {
  GET_VENDORPROFILE_SUCCESS: 'GET_VENDORPROFILE_SUCCESS',
  GET_VENDORPROFILE_FAILURE: 'GET_VENDORPROFILE_FAILURE',
  GET_VENDORPROFILE_REQUEST: 'GET_VENDORPROFILE_REQUEST'
}

export const clearPasswordData = {
  CLEAR_PASSWORD_MESSAGE: 'CLEAR_PASSWORD_MESSAGE',
}

export const clearUpdateVendorData = {
  CLEAR_UPDATEVENDOR_MESSAGE: 'CLEAR_UPDATEVENDOR_MESSAGE'
}

export const updateVendorConstants = {
  GET_UPDATEVENDOR_SUCCESS: 'GET_UPDATEVENDOR_SUCCESS',
  UPDATEVENDOR_CLEAR: 'UPDATEVENDOR_CLEAR',
  GET_UPDATEVENDOR_FAILURE: 'GET_UPDATEVENDOR_FAILURE',
}

export const vendorSubscriptionConst = {
  GET_VENDORSUBSCRIPTION_SUCCESS: 'GET_VENDORSUBSCRIPTION_SUCCESS',
  GET_VENDORSUBSCRIPTION_FAILURE: 'GET_VENDORSUBSCRIPTION_FAILURE',
}

export const productConst = {
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',
}

export const checkoutSessionConst = {
  GET_CHECKOUTSESSION_SUCCESS: 'GET_CHECKOUTSESSION_SUCCESS',
  GET_CHECKOUTSESSION_FAILURE: 'GET_CHECKOUTSESSION_FAILURE',
}

export const catCheckoutSessionConst = {
  GET_CATCHECKOUTSESSION_SUCCESS: 'GET_CATCHECKOUTSESSION_SUCCESS',
  GET_CATCHECKOUTSESSION_FAILURE: 'GET_CATCHECKOUTSESSION_FAILURE',
}

export const sessionRetriveConst = {
  GET_SESSIONRETRIVE_SUCCESS: 'GET_SESSIONRETRIVE_SUCCESS',
  GET_SESSIONRETRIVE_FAILURE: 'GET_SESSIONRETRIVE_FAILURE',
}

export const clearSessionData = {
  CLEAR_SESSION_MESSAGE : "CLEAR_SESSSION_MESSAGE"
}

export const vendorSubscriptionDetailsConst = {
  GET_VENDORSUBSCRIPTIONDETAILS_SUCCESS: 'GET_VENDORSUBSCRIPTIONDETAILS_SUCCESS',
  GET_VENDORSUBSCRIPTIONDETAILS_FAILURE: 'GET_VENDORSUBSCRIPTIONDETAILS_FAILURE',
}

/************************ Constants For Consumer Side Module **********************/

export const ConsumerDashboardConst = {
  GET_CONSUMERDASHBOARD_SUCCESS: 'GET_CONSUMERDASHBOARD_SUCCESS',
  GET_CONSUMERDASHBOARD_FAILURE: 'GET_CONSUMERDASHBOARD_FAILURE',
}

export const ConsumerProfileConst = {
  GET_CONSUMERPROFILE_SUCCESS: 'GET_CONSUMERPROFILE_SUCCESS',
  GET_CONSUMERPROFILE_FAILURE: 'GET_CONSUMERPROFILE_FAILURE',
}

export const clearProfileData = {
  CLEAR_PROFILE_MESSAGE : "CLEAR_PROFILE_MESSAGE"
}

export const UploadProfileConst = {
  GET_UPLOADPROFILE_SUCCESS: 'GET_UPLOADPROFILE_SUCCESS',
  GET_UPLOADPROFILE_FAILURE: 'GET_UPLOADPROFILE_FAILURE',
}

export const FavouriteVendorConst = {
  GET_FAVOURITEVENDOR_SUCCESS: 'GET_FAVOURITEVENDOR_SUCCESS',
  GET_FAVOURITEVENDOR_FAILURE: 'GET_FAVOURITEVENDOR_FAILURE',
}

export const ChangePasswordConst = {
  GET_CHANGEPASSWORD_SUCCESS: 'GET_CHANGEPASSWORD_SUCCESS',
  GET_CHANGEPASSWORD_FAILURE: 'GET_CHANGEPASSWORD_FAILURE',
}

export const ConsumerReviewsConst = {
  GET_CONSUMERREVIEWS_SUCCESS: 'GET_CONSUMERREVIEWS_SUCCESS',
  GET_CONSUMERREVIEWS_FAILURE: 'GET_CONSUMERREVIEWS_FAILURE',
}

export const DeleteProfileConst = {
  GET_DELETEPROFILE_SUCCESS: 'GET_DELETEPROFILE_SUCCESS',
  GET_DELETEPROFILE_FAILURE: 'GET_DELETEPROFILE_FAILURE',
  CLEAR_DELETEPROFILE_MESSAGE: 'CLEAR_DELETEPROFILE_MESSAGE',
}

/************************ Constants For Event Module **********************/

export const RecentEventsConst = {
  GET_RECENTEVENTS_SUCCESS: 'GET_RECENTEVENTS_SUCCESS',
  GET_RECENTEVENTS_FAILURE: 'GET_RECENTEVENTS_FAILURE',
}

export const PastEventsConst = {
  GET_PASTEVENTS_SUCCESS: 'GET_PASTEVENTS_SUCCESS',
  GET_PASTEVENTS_FAILURE: 'GET_PASTEVENTS_FAILURE',
}

export const CreateEventConst = {
  GET_CREATEEVENT_SUCCESS: 'GET_CREATEEVENT_SUCCESS',
  GET_CREATEEVENT_FAILURE: 'GET_CREATEEVENT_FAILURE',
}

export const clearCreateEventData = {
  CLEAR_CREATEEVENT_MESSAGE : "CLEAR_CREATEEVENT_MESSAGE"
}

export const ViewEventConst = {
  GET_VIEWEVENT_SUCCESS: 'GET_VIEWEVENT_SUCCESS',
  GET_VIEWEVENT_FAILURE: 'GET_VIEWEVENT_FAILURE',
}

export const EditEventConst = {
  GET_EDITEVENT_SUCCESS: 'GET_EDITEVENT_SUCCESS',
  GET_EDITEVENT_FAILURE: 'GET_EDITEVENT_FAILURE',
}

export const clearUpdateEventData = {
  CLEAR_UPDATEEVENT_MESSAGE : "CLEAR_UPDATEEVENT_MESSAGE"
}

export const UpdateEventConst = {
  GET_UPDATEEVENT_SUCCESS: 'GET_UPDATEEVENT_SUCCESS',
  UPDATE_EVENT_CLEAR: 'UPDATE_EVENT_CLEAR',
  GET_UPDATEEVENT_FAILURE: 'GET_UPDATEEVENT_FAILURE',
}

export const DeleteEventConst = {
  GET_DELETEEVENT_SUCCESS: 'GET_DELETEEVENT_SUCCESS',
  GET_DELETEEVENT_FAILURE: 'GET_DELETEEVENT_FAILURE',
  DELETEEVENT_CLEAR : 'DELETEEVENT_CLEAR'
}

export const VisitCountConstant = {
  VISIT_COUNT_DELETE: 'VISIT_COUNT_DELETE',
  VISIT_COUNT_SUCCESS: 'VISIT_COUNT_SUCCESS',
  VISIT_COUNT_FAILURE: 'VISIT_COUNT_FAILURE',
}

export const WebsiteVisitCountConst = {
  WEBSITE_VISIT_COUNT_DELETE: 'WEBSITE_VISIT_COUNT_DELETE',
  WEBSITE_VISIT_COUNT_SUCCESS: 'WEBSITE_VISIT_COUNT_SUCCESS',
  WEBSITE_VISIT_COUNT_FAILURE: 'WEBSITE_VISIT_COUNT_FAILURE',
}

// Notification list constant

export const NotificationListConst = {
  GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_FAILURE: 'GET_NOTIFICATION_LIST_FAILURE'
}

export const StaticDataListConst = {
  GET_STATIC_DATA_LIST_SUCCESS: 'GET_STATIC_DATA_LIST_SUCCESS',
  GET_STATIC_DATA_LIST_FAILURE: 'GET_STATIC_DATA_LIST_FAILURE'
}

export const HeaderCategoryConst = {
  REMOVE_CATEGORY_SUCCESS: 'REMOVE_CATEGORY_SUCCESS',
  REMOVE_CATEGORY_FAILURE: 'REMOVE_CATEGORY_FAILURE'
}
