import { commonService } from "../services/common.service";
import { ConsumerReviewsConst } from "../constants/user.constants";

const consumerReviewsAction = () => async(dispatch) => {
    try{
        const consumerReviews = await commonService.withLogin('/review-list');
        if (consumerReviews) {
            dispatch({ type: ConsumerReviewsConst.GET_CONSUMERREVIEWS_SUCCESS, payload: { datasss: consumerReviews.data } });
        }
        else {
            dispatch({ type: ConsumerReviewsConst.GET_CONSUMERREVIEWS_SUCCESS, payload: { data: consumerReviews.data } });
        }
    }catch(error){
        dispatch({ type: ConsumerReviewsConst.GET_CONSUMERREVIEWS_FAILURE, payload: error.message })
    }
}
export default consumerReviewsAction;
