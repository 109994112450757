import { ConsumerDashboardConst } from '../constants/user.constants';
import { commonService } from '../services/common.service';

const consumerDashboardAction = () => async (dispatch) =>  {
    try {
        const consumerDashboard = await commonService.withLogin(`/consumer-dashboard`);
        if (consumerDashboard) {
            dispatch({ type: ConsumerDashboardConst.GET_CONSUMERDASHBOARD_SUCCESS, payload: { datasss: consumerDashboard.data } });
        }
        else {
            dispatch({ type: ConsumerDashboardConst.GET_CONSUMERDASHBOARD_FAILURE, payload: { data: consumerDashboard.data } });
        }
    }
    catch(error) {
        dispatch({ type: ConsumerDashboardConst.GET_CONSUMERDASHBOARD_FAILURE, payload: error.message })
    }
}

export default consumerDashboardAction;