import { VisitCountConstant,WebsiteVisitCountConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

export const VendorProfileVisitCount = (obj) => async (dispatch) => {
    try {
        const profileVisit = await commonService.withOutToken('/profile-views',obj);
        if(profileVisit) {
            dispatch({type: VisitCountConstant.VISIT_COUNT_SUCCESS, payload: {datasss: profileVisit.data} });
        }
        else {
            dispatch({ type: VisitCountConstant.VISIT_COUNT_FAILURE, payload: { data: profileVisit.data } });
        }
    }
    catch (error) {
        dispatch({ type: VisitCountConstant.VISIT_COUNT_FAILURE, payload: error.message });
    }
}

export const WebsiteVisitCount = (obj) => async (dispatch) => {
    try {
        const websiteVisit = await commonService.withOutToken('/store-website-view-count',obj);
        if(websiteVisit) {
            dispatch({type: WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_SUCCESS, payload: {datasss: websiteVisit.data} });
        }
        else {
            dispatch({ type: WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_FAILURE, payload: { data: websiteVisit.data } });
        }
    }
    catch (error) {
        dispatch({ type: WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_FAILURE, payload: error.message });
    }
}
