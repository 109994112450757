import { vendorEventsConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const vendorEventsAction = () => async (dispatch) => {
    try {
        const vendorEvents = await commonService.withLogin(`/vendor-events`);
        if (vendorEvents) {
            dispatch({ type: vendorEventsConst.GET_VENDOREVENTS_SUCCESS, payload: { datasss: vendorEvents.data } });
        }
        else {
            dispatch({ type: vendorEventsConst.GET_VENDOREVENTS_FAILURE, payload: { data: vendorEvents.data } });
        }
    }
    catch (error) {
        dispatch({ type: vendorEventsConst.GET_VENDOREVENTS_FAILURE, payload: error.message })
    }
}

export default vendorEventsAction;