import React, { useState, useEffect } from "react";
import authenticationLogin, { clearLoginMsg } from "../../../actions/login";
import authenticationGoogle, {
    clearGoogleLogin,
} from "../../../actions/googleAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import Header from "../../includes/header";
import Footer from "../../includes/footer";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";
import {  createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup    } from 'firebase/auth';
import { auth } from '../../../firebase'


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = require("is-mobile");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(eyeOff);
    const [loading, setLoading] = useState(false);

    // for google login
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const onLoginSuccess = async (e) => {
        await dispatch(authenticationGoogle(e.profileObj));
        let tokenObj = JSON.parse(localStorage.getItem("googleLogin"));
        if (tokenObj) {
            navigate("/signup");
        }
    };
    const onLoginFailure = (res) => {
        console.log("login failure", res);
    };

    // const messaging = getMessaging();
    // getToken(messaging, { vapidKey: "BANghCQTMaIr2NmU3E18C8RSS90Q4guDunUpz6Xp7eG6jmmXCR_SZnEfaNBCHRnVT_byApG4_0IFW0rFcfL0J7c" }).then((currentToken) => {
    //     if (currentToken) {
    //         console.log('currentToken',currentToken);
    //         // Send the token to your server and update the UI if necessary
    //     } else {
    //         // Show permission request UI
    //         console.log('No registration token available. Request permission to generate one.');
    //     }
    // }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    // });

    // onMessage(messaging, (payload) => {
    //     console.log("Message received. ", payload);
    // });

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (!email || !password) {
            setError(true);
            setLoading(false);
            return false;
        }
        await dispatch(authenticationLogin({ email: email, password: password, device_type : isMobile() ? 'mobile' : 'desktop' }));
    };

    useEffect(() => {
        let googleLogin = JSON.parse(localStorage.getItem("googleLogin"));
        if (googleLogin) {
            localStorage.removeItem("googleLogin");
        }
    }, []);

    const authuser = useSelector((state) => state.user);
    useEffect(() => {
        const registerDeviceToken = async () => {
            const messaging = getMessaging();

            if (authuser && authuser?.authuser && authuser?.authuser?.datasss && authuser?.authuser?.datasss?.status === true) {
                let tokenObj = {
                    email: authuser?.authuser.datasss.data.user.email,
                    api_token: authuser?.authuser.datasss.data.api_token,
                };
                localStorage.setItem("token", JSON.stringify(tokenObj));
                try {
                    const currentToken = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
                    });

                    if (currentToken) {
                        localStorage.setItem("FCM_TOKEN", currentToken);

                        const headers = {
                            "content-type": "application/json",
                            Authorization: `Bearer ${tokenObj.api_token}`,
                        };

                        let obj = {
                            user_id: authuser.authuser.datasss.data.user.id,
                            device_token: currentToken,
                        };

                        const response = await axios.post(
                            `${process.env.REACT_APP_API_ENDPOINT}/api/store-device-token`,
                            obj,
                            { headers }
                        );

                        // Send token to your server to associate with user ID.
                    } else {
                        console.log("No FCM token available.");
                    }
                } catch (error) {
                    console.log("Error getting FCM token:", error);
                }
                if (authuser.authuser.datasss.data.user.role === 1) {
                    localStorage.removeItem("userData");
                    localStorage.removeItem("token");
                    toast.warn("Admin cannot login here");
                } else if (authuser.authuser.datasss.data.user.role === 2) {
                    if (authuser.authuser.datasss.data.user.step === 1) {
                        navigate("/vendor-profile");
                    }
                    if (authuser.authuser.datasss.data.user.step === 0) {
                        navigate("/vendor-dashboard");
                    }
                    toast.success(authuser?.authuser && authuser?.authuser.datasss && authuser?.authuser.datasss.message
                    );
                } else if (authuser.authuser.datasss.data.user.role === 3) {
                    toast.success(authuser?.authuser && authuser?.authuser.datasss && authuser?.authuser.datasss.message);
                    navigate("/consumer-dashboard");
                } else {
                    navigate("/home");
                }
                dispatch(clearLoginMsg());
                setLoading(false);
                signInWithEmailAndPassword(auth, email, password) // generateing fcm token if user exst in firebase
                .then((userCredential) => {
                        const access_token = userCredential.user.getIdToken()
                        access_token.then(data=>{
                            localStorage.setItem('FIREBASE_ACCESS_TOKEN',data)
                        })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === "auth/user-not-found") {
                        createUserWithEmailAndPassword(auth, email, password).then((userRegistered) => { // creating user user noy exst in firebase
                            const access_token = userRegistered.user.getIdToken()
                            access_token.then(data=>{
                                localStorage.setItem('FIREBASE_ACCESS_TOKEN',data);
                            })
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                });
            }

            if (authuser && authuser?.authuser && authuser?.authuser?.datasss && authuser?.authuser?.datasss?.status === false) {
                toast.error(
                    authuser?.authuser &&
                    authuser?.authuser.datasss &&
                    authuser?.authuser.datasss.message
                );
                dispatch(clearLoginMsg());
                setLoading(false);
            }
            if (authuser && authuser?.authuser && authuser?.authuser?.datasss && authuser?.authuser?.datasss?.status === 1) {
                toast.error(
                    authuser?.authuser &&
                    authuser?.authuser.datasss &&
                    authuser?.authuser.datasss.message
                );
                setLoading(false);
                dispatch(clearLoginMsg());
                navigate('/resend-email-verification',{state:{
                    email : email,
                }});
            }
            if (
                authuser &&
                authuser?.authuser &&
                authuser?.authuser?.datasss &&
                authuser?.authuser?.datasss?.status === 2
            ) {
                toast.warn(
                    authuser?.authuser &&
                    authuser?.authuser.datasss &&
                    authuser?.authuser.datasss.message
                );
                dispatch(clearLoginMsg());
                setLoading(false);
            }
        };

        registerDeviceToken();
    }, [authuser]);

    // Google Login
    const google = useSelector((state) => state.google);
    useEffect(() => {
        const provider = new GoogleAuthProvider();

        const user = JSON.parse(localStorage.getItem("userData"));
        const initClient = async () => {    
            gapi.client.init({ CLIENT_ID: CLIENT_ID, scope: "" });
            
            if (google?.google?.datasss?.status === true && user) {
                
                // signInWithPopup(auth, provider)
                //     .then((result) => {
                //     // Signed-in user info
                //     const user = result.user;
                //     const uid = user.uid;
                //     const accessToken = user.getIdToken();
                //     accessToken.then(data=>{
                //         localStorage.setItem('FIREBASE_ACCESS_TOKEN',data)
                //     })
                // })
                // .catch((error) => {
                //   // Handle Google sign-in error
                //   console.log("Google sign-in error:", error);
                // });
                
                let tokenObj = {
                    email: google?.google?.datasss?.data?.user.email,
                    api_token: google?.google?.datasss?.data?.api_token,
                };

                signInWithEmailAndPassword(auth, tokenObj.email, "Password@123") // generateing fcm token if user exst in firebase
                .then((userCredential) => {
                        const access_token = userCredential.user.getIdToken()
                        access_token.then(data=>{
                            localStorage.setItem('FIREBASE_ACCESS_TOKEN',data)
                        })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === "auth/user-not-found") {
                        createUserWithEmailAndPassword(auth, tokenObj.email, "Password@123").then((userRegistered) => { // creating user user noy exst in firebase
                            const access_token = userRegistered.user.getIdToken()
                            access_token.then(data=>{
                                localStorage.setItem('FIREBASE_ACCESS_TOKEN',data);
                            })
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                });

                // store device token of user
                localStorage.setItem("token", JSON.stringify(tokenObj));
                try {
                    const messaging = getMessaging(); // Assuming it returns a promise
                    const currentToken = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
                    });
                    if (currentToken) {
                        localStorage.setItem("FCM_TOKEN", currentToken);
                        const headers = {
                            "content-type": "application/json",
                            Authorization: `Bearer ${tokenObj.api_token}`,
                        };
                        let obj = {
                            user_id: google?.google?.datasss?.data?.user.id,
                            device_token: currentToken,
                        };
                        try {
                            const response = await axios.post(
                                `${process.env.REACT_APP_API_ENDPOINT}/api/store-device-token`,
                                obj,
                                { headers }
                            );
                        } catch (error) {
                            console.log("Error storing device token:", error);
                            // Handle the error appropriately.
                        }
                    } else {
                        console.log("No FCM token available.");
                    }
                } catch (error) {
                    console.log("Error getting FCM token:", error);
                }
                
                toast.success(google?.google.datasss.message);
                dispatch(clearGoogleLogin());
                if (google?.google?.datasss?.data?.user?.role === 2) {
                    if (google?.google?.datasss?.data?.user?.step === 1) {
                        navigate("/vendor-profile");
                    }
                    if (google?.google?.datasss?.data?.user?.step === 0) {
                        navigate("/vendor-dashboard");
                    }
                } else if (google?.google?.datasss?.data?.user?.role === 3) {
                    navigate("/consumer-dashboard");
                } else {
                    navigate("/home");
                }
            }

            if ( google && google?.google && google?.google?.datasss && google?.google?.datasss?.status === false
            ) {
                toast.error(
                    google?.google &&
                    google?.google.datasss &&
                    google?.google.datasss.message
                );
                dispatch(clearGoogleLogin());
            }

            if ( google && google?.error && google?.error?.datasss && google?.error?.datasss?.status === 2
            ) {
                toast.warn(
                    google?.error &&
                    google?.error.datasss &&
                    google?.error.datasss.message
                );
                dispatch(clearGoogleLogin());
            }
        };

        gapi.load("client:auth2", initClient);
    }, [google]);


    const handelToggle = () => {
        if (type === "password") {
            setIcon(eye);
            setType("text");
        } else {
            setIcon(eyeOff);
            setType("password");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <Header />
                <section className=" middle-section">
                    <div className="signup-page">
                        <div className="container">
                            <div className="custom-form login_screen common-inner-container  fadeIn wow">
                                <div className="row g-0 custom-form-inner">
                                    <div className="col-md-5 col-12 cust-remove-padding">
                                        <div className="login-left-sec">
                                            <img src="img/stockSignUp.jpeg" alt="login-bg" />
                                            {/* <div className="bg-gradients">
                                                    </div> */}
                                            <div className="login-tagline ">
                                                <h2 className=" fadeInUp wow">WELCOME!</h2>
                                                {/* <p className=" fadeInUp wow">
                                                    It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when
                                                    looking at its layout.
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7 col-12 cust-remove-padding login-right">
                                        <div className="form-container">
                                            <div className="text-center fadeInUp wow">
                                                <h1>Login</h1>
                                                <h6>Sign in to our account</h6>
                                            </div>
                                            <form
                                                action="#"
                                                onSubmit={handleSubmit}
                                                className="loginForm"
                                            >
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-name">USER EMAIL</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        value={email}
                                                        placeholder="Enter email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        id="u-name"
                                                        name="username"
                                                    />
                                                    {error && !email && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-password">PASSWORD</label>
                                                    <div className="input-iconParent ">
                                                        <input
                                                            type={type}
                                                            value={password}
                                                            placeholder="Password"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            id="u-password"
                                                            className="form-control"
                                                            name="password"
                                                        />
                                                        <span
                                                            onClick={handelToggle}
                                                            className="input-icon "
                                                        >
                                                            <Icon icon={icon} size={25} />
                                                        </span>
                                                    </div>
                                                    {error && !password && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12 ">
                                                            <div className="checkboxbtn">
                                                                <input type="checkbox" id="checkbox2" />
                                                                <label htmlFor="checkbox2">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12 text-lg-right forgot-password">
                                                            <Link to="/forgot-password">
                                                                Forgot Password?
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group form-footer ">
                                                    {loading ? (
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn fadeInUp wow"
                                                                disabled
                                                            >
                                                                <div className={"btn-loader"}>
                                                                    <img
                                                                        src="/img/loader.svg"
                                                                        className="loader-svg-img"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn fadeInUp wow"
                                                            >
                                                                {" "}
                                                                Sign In{" "}
                                                            </button>
                                                        </>
                                                    )}
                                                    <div className="or fadeInUp wow">
                                                        <hr />
                                                        <p>Or</p>
                                                        <hr />
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="other-account fadeInUp wow">
                                                            <GoogleLogin
                                                                clientId={CLIENT_ID}
                                                                buttonText="Sign In with Google"
                                                                onSuccess={onLoginSuccess}
                                                                onFailure={onLoginFailure}
                                                                cookiePolicy={"single_host_origin"}
                                                                render={(renderProps) => (
                                                                    <a
                                                                        onClick={renderProps.onClick}
                                                                        disabled={renderProps.disabled}
                                                                        href="#"
                                                                    >
                                                                        <img src="img/gmail.svg" alt="" />
                                                                    </a>
                                                                )}
                                                            />
                                                        </div>
                                                        <h6 className="mt-1 google-text">Sign in with</h6>
                                                        <h6>Google Account</h6>
                                                    </div>
                                                    <p className="footerlink fadeInUp wow">
                                                        Don’t have an account?
                                                        <Link to="/signup">Sign Up</Link>
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default Login;
