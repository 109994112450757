import { commonService } from "../services/common.service";
import { ViewEventConst } from "../constants/user.constants";

const viewEventAction = (eventId) => async (dispatch) => {
    try {
        const viewEvent = await commonService.withLogin(`/view-event/${eventId}`);
        if (viewEvent) {
            dispatch({ type: ViewEventConst.GET_VIEWEVENT_SUCCESS, payload: { datasss: viewEvent.data } });
        }
        else {
            dispatch({ type: ViewEventConst.GET_VIEWEVENT_FAILURE, payload: { data: viewEvent.data } });
        }
    }
    catch (error) {
        dispatch({ type: ViewEventConst.GET_VIEWEVENT_FAILURE, payload: error.message })
    }
}

export default viewEventAction;