import { commonService } from "../services/common.service";
import { StaticDataListConst } from "../constants/user.constants";

const staticPagesAction = () => async (dispatch) => {

    try {
        const staticData = await commonService.withOutLogin(`/static-page-list`);
        console.log('');
        if (staticData) {
            dispatch({ type: StaticDataListConst.GET_STATIC_DATA_LIST_SUCCESS, payload: { datasss: staticData.data } });
        }
        else {
            dispatch({ type: StaticDataListConst.GET_STATIC_DATA_LIST_FAILURE, payload: { data: staticData.data } });
        }
    }
    catch (error) {
        dispatch({ type: StaticDataListConst.GET_STATIC_DATA_LIST_FAILURE, payload: error.message })
    }
}

export default staticPagesAction;