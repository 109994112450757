import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, Routes, useParams } from "react-router-dom";
import varificationLogin,{clearVarificationMsg} from "../../../actions/varificationAction";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const EmailVerification = () => {

  const { varification } = useSelector((state) => state.varification);
  const navigate = useNavigate();
  const { id } = useParams()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(varificationLogin(id));
    dispatch(clearVarificationMsg());
    
  }, []);

  useEffect(() => {
    if (varification.status !==false) {
      toast("Your Account Verified successfully, Now you can login")
      navigate("/login")
    }
    else{
      navigate("/login")
    }
  }, []);

  return <div></div>;
};

export default EmailVerification;
