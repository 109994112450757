import { productConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const productData = () => async (dispatch) =>{
    try{
      const  productData  = await commonService.withLogin(`/product-list`);
      if(productData){
        dispatch({type: productConst.GET_PRODUCT_SUCCESS , payload: {datasss: productData.data}})
      }
      else{
        dispatch({type: productConst.GET_PRODUCT_SUCCESS , payload: {data: productData.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: productConst.GET_PRODUCT_SUCCESS , payload: error.message})
  }
}

export default productData;