import axios from "axios";
import { Navigate } from "react-router-dom";

export const commonService = {
  withToken,
  withLogin,
  withOutToken,
  withOutLogin,
};

// const API_URL = "https://staging-api.ozgigs.com.au/api";
// const API_URL = "http://localhost:8000/api";
const API_URL = process.env.REACT_APP_API_ENDPOINT + '/api';

//-- It's common function for using the token
function withToken(apiName, data) {
  let tokenObj = JSON.parse(localStorage.getItem("token"));
  if (tokenObj) {
    return axios({
      method: "POST",
      url: `${API_URL + apiName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.api_token}`,
      },
      data: data,
    })
      .then((handleResponse) => {
        return handleResponse;
      })
      .catch((error) => {
        return error.response;
      });
  } else {
    window.location.href = "/login";
  }
}

//-- It's common function for using the token
function withOutToken(apiName, data) {
  return axios({
    method: "POST",
    url: `${API_URL + apiName}`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((handleResponse) => {
      return handleResponse;
    })
    .catch((error) => {
      return error.response;
    });
}

function withLogin(apiName, data) {
  let tokenObj = JSON.parse(localStorage.getItem("token"));
  if (tokenObj) {
    return axios({
      method: "GET",
      url: `${API_URL + apiName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.api_token}`,
      },
      data: data,
    })
      .then((handleResponse) => {
        return handleResponse;
      })
      .catch((error) => {
        return error.response;
      });
  } else {
    return <Navigate to="/login" replace />;
  }
}

function withOutLogin(apiName) {
  return axios({
    method: "GET",
    url: `${API_URL + apiName}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((handleResponse) => {
      return handleResponse;
    })
    .catch((error) => {
      return error.response;
    });
}
