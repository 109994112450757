import { HeaderCategoryConst } from "../constants/user.constants";

const headerAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: HeaderCategoryConst.REMOVE_CATEGORY_SUCCESS, payload: data });
    } 
    catch (error) {
        dispatch({ type: HeaderCategoryConst.REMOVE_CATEGORY_FAILURE, payload: error.message })
    }
}

export default headerAction;