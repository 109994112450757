import { CreateChatConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const createChatAction = (data) => async (dispatch) => {
    try {
        const createChat = await commonService.withToken(`/create-chat`, data);
        if (createChat) {
            dispatch({ type: CreateChatConst.GET_CREATECHAT_SUCCESS, payload: { datasss: createChat.data } });
        }
        else {
            dispatch({ type: CreateChatConst.GET_CREATECHAT_FAILURE, payload: { data: createChat.data } });
        }
    }
    catch (error) {
        dispatch({ type: CreateChatConst.GET_CREATECHAT_FAILURE, payload: error.message })
    }
}

export default createChatAction;