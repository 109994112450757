import { vendorFavUnfavResponse } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const vendorLikeDislikeAction = (Id) => async (dispatch) => {
    try {
        const vendorFavUnfav = await commonService.withToken(`/get-vendor-favourite`, Id);
        if (vendorFavUnfav) {
            dispatch({ type: vendorFavUnfavResponse.GET_FAVUNFAV_SUCCESS, payload: { datasss: vendorFavUnfav.data } });
        }
        else {
            dispatch({ type: vendorFavUnfavResponse.GET_FAVUNFAV_FAILURE, payload: { data: vendorFavUnfav.data } });
        }
    }
    catch (error) {
        dispatch({ type: vendorFavUnfavResponse.GET_FAVUNFAV_FAILURE, payload: error.message })
    }
}

export default vendorLikeDislikeAction;