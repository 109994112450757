import React, { useEffect } from "react";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        <Header />
        <section className="privacy-policy-banner">
          <div className="privacy-policy-banner-img">
            <figure>
              <img src="img/privacy_policy_banner.jpg" alt="Privacy Policy" />
            </figure>
          </div>
          <div className="privacy-policy-banner-content">
            <div className="container">
              <div className="privacy-policy-heading">
                <h1>PRIVACY POLICY</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="privacy-policy-wr">
          <div className="container">
            <div className="privacy-policy-content">
              <div className="privacy-policy-text-block">
                <ol>
                  <li>
                    <h3>PRIVACY POLICY</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Ozgigs Global Pty Ltd (ACN 659 327 849) is a business
                          registered in Australia (the <b>Company</b>, <b>us</b>{" "}
                          or <b>we</b>) and is committed to keeping your
                          personal data safe and secure. In doing so, we follow
                          the spirit of the Australian Privacy Principles in the
                          Privacy Act 1988 (Cth) (the <b>Privacy Act</b>). However, until such time (if at
                          all) as the Company is subject to the Act, we have
                          chosen not to opt in to the Act.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          If you are a citizen of a European Union (“EU”)
                          country or we collect personal data about you from a
                          source in the EU, we aim to provide the standards of
                          privacy protection required by the EU’s General Data
                          Protection Regulation (<b>GDPR</b>). Additionally, EU
                          based organisations that share data with us may
                          require that we meet GDPR standards. Our commitments
                          in this policy are generally consistent with the GDPR
                          requirements applying to a Controller of subject data,
                          but if you have a query about your rights or our
                          obligations for GDPR purposes, please contact us.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          By engaging the Company’s services or using the
                          Company’s website (together, the, or our,{" "}
                          <b>Services</b>), you are giving consent to the
                          Company for the use of your personal information in
                          the manner set out in this Privacy Policy.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>GIVING CONSENT</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          You are not obliged to disclose personal information
                          about yourself when you visit our website. Where
                          practicable, we will allow you to deal with us on an
                          anonymous or pseudonymous basis.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          If you do not wish to provide personal information to
                          us, then you do not have to do so, however it may
                          affect your ability to use our Services.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          By providing us with any personal information, you
                          consent to us using this information to assist you and
                          to offer our Services in accordance with this Policy.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>COLLECTION OF PERSONAL INFORMATION</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Personal information is information or an opinion that
                          identifies an individual. Examples of personal
                          information we collect includes names, user names,
                          gender, email addresses, phone and facsimile numbers,
                          address, social media information, age, date of birth,
                          and information about your personal and business
                          circumstances.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Personal information may be collected from you in a
                          variety of ways, including but not limited to: <br/><br/>(a)
                          when you register for our Services <br/>(b) when you
                          interact with us electronically or in person <br/>(c) when
                          you access our Services and <br/>(d) when we provides
                          products and/or Services to you.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Where reasonable and practicable to do so, we will
                          collect your personal information only from you.
                          However, in some circumstances we may be provided with
                          information by third parties. In such cases we will
                          take reasonable steps to ensure that you are made
                          aware of the information provided to us by a third
                          party.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The personal information that we collect and hold
                          about you depends on your interaction with us. We
                          collect your personal information for the primary
                          purpose of providing our Services and to communicate
                          with you. We may also use your personal information
                          for secondary purposes closely related to the primary
                          purpose, in circumstances where you would reasonably
                          expect such use or disclosure. You may unsubscribe
                          from our mailing/ marketing/SMS/social media lists and
                          platforms including those from business
                          intelligenceplatforms (if any) at any time by
                          contacting us in writing or using the “opt out” or
                          “unfollow” functions that we include in our
                          communications or are available on said platforms.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Some personal information (e.g. race, ethnicity,
                          health, criminal record etc.) is sensitive and
                          requires a higher level of protection. If we hold any
                          sensitive personal information, we will not use or
                          disclose your sensitive personal information other
                          than as permitted by law or with your consent.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          If you are an individual residing in the EU, we
                          collect and process information about you only where
                          we have legal bases for doing so under applicable EU
                          laws. The legal bases depend on the Services being
                          used. We collect and use your information only where:
                          <br/><br/>(i) we need it to identify you to enable us to provide
                          our Services and it satisfies a legitimate interest
                          (which is not overridden by your data protection
                          interests), such as for research and development
                          purposes, to maintain and improve our Services, to
                          promote and market our Services, and to protect our
                          legal rights and interests <br/>(ii) you give us consent to
                          do so for a specific purpose e.g. where you subscribe
                          to our newsletters or request or download our
                          publications or data <br/>(iii) we need to process your
                          data to comply with a legal obligation such as
                          performing client due diligence/”know your client”,
                          anti-money laundering, anti-bribery, sanctions or
                          reputational risk screening, identifying conflicts of
                          interests, for the prevention of fraud and/or other
                          relevant background checks as may be required by
                          applicable law and regulation and best practice at any
                          given time (and if false or inaccurate information is
                          provided and fraud is identified or suspected, details
                          may be passed to fraud prevention agencies and may be
                          recorded by us or by them), to enforce our legal
                          rights, to comply with our legal or regulatory
                          reporting obligations and/or to protect the rights of
                          third parties, to ensure that we can recover any
                          payments due to us and where necessary to enforce such
                          recovery through the engagement of debt collection
                          agencies or taking other legal action (including the
                          commencement and carrying out of legal and court
                          proceedings) or <br/>(iv) to reorganise or make changes to
                          our business. In the event that we undergo a re-
                          organisation (e.g. if we merge, combine, list or
                          divest a part of our business), we may need to
                          transfer some or all of your personal information to
                          the relevant third party (or its advisors) as part of
                          any due diligence process or transfer to that
                          re-organised entity or third party for the same
                          purposes as set out in this Policy or for the purpose
                          of analysing any proposed transaction.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          We also collect website activity statistics such as
                          number of visitors, pages visited, time and date of
                          visit and where you accessed our website from, so that
                          we can make informed decisions relating to improving
                          our website and the Services we deliver. This
                          information is anonymous and doesn’t identify a
                          person.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          We may also collect personal information from you when
                          you use or access our website or our social media
                          pages. This may be done through the collection of your
                          IP address and the use of web analytics tools (such as
                          Google Analytics), ‘cookies’ or other similar tracking
                          technologies that allow us to track and analyse your
                          website usage. Cookies are small files that store
                          information on your computer, mobile phone or other
                          device and enable and allow the creator of the cookie
                          to identify when you visit different websites. If you
                          do not wish information to be stored as a cookie, you
                          can disable cookies in your web browser.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          If you are an individual residing in the EU or any
                          other jurisdiction that requires us to obtain your
                          consent to use cookies, then you will have an
                          opportunity to manage your consent preference on our
                          website, except that certain cookies are required to
                          enable core site functionality, and you cannot opt-out
                          or choose to disable those cookies. We may use cookies
                          and other tags to display advertisements to EU users
                          based on non-personally indefinable information,
                          anonymous data, or aggregated data that we have
                          collected in connection with an EU users’ interaction
                          with our website and via our services.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>USE AND DISCLOSURE OF PERSONAL INFORMATION</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company may use and/or disclose your personal
                          information to: <br/><br/>(a) enable us to provide our Services
                          to you and our clients <br/>(b) for record keeping and
                          administrative purposes <br/>(c) provide information about
                          you to our contractors, employees, consultants, agents
                          or other third parties for the purpose of providing
                          our Services <br/>(d) improve and optimise our Service
                          offering and client experience <br/>(e) enable us to use
                          outsourcing services, such as data storage, debt
                          collection, bulk distribution and mailing, direct
                          marketing, and technology support <br/>(f) enable you to
                          access to the website <br/>(g) track usage and measure
                          traffic statistics of the website <br/>(h) supply to you
                          the products and/or Services that you purchase or
                          request from us <br/>(i) receive and/or access information
                          from third party providers for the provision of our
                          products and/or Services <br/>(j) contact you regarding
                          your use of the products and/or Services offered by
                          the Company <br/>(k) contact you in relation to comments,
                          complaints, enquiries or dispute resolution <br/>(l)
                          collect payments from you <br/>(m) communicate with third
                          party providers or associate parties with respect to
                          the provision of the Services <br/>(n) send you marketing
                          communications <br/>(o) comply with laws.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company will not disclose personal information
                          about you, unless it is required, incidental or
                          otherwise related to the primary purpose of providing
                          Services to you or a third party for which you have
                          consented to by engaging the Company’ Services or by
                          using the Company’s Services. However, the Company may
                          disclose your personal information to its business
                          partners and suppliers to assist them to provide
                          Services to you as a customer.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company, in its sole discretion, may disclose your
                          personal information if it is required to do so by law
                          or legal process, including:<br/><br/>(a) in order to establish,
                          exercise or defend its legal rights <br/>(b) as required or
                          authorised by law or <br/>(c) to the extent required to
                          permit the Company to investigate suspected fraud,
                          harassment or other violations of any law, rule or
                          regulation, our policies, or the rights of third
                          parties or any other suspected conduct the Company
                          deem improper.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>SECURITY AND ACCURACY</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          It is important that you advise the Company of changes
                          to your personal information that you have provided to
                          the Company as it is essential that your personal
                          information is accurate, complete, and up to date in
                          order for the Company to provide Services.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company will take commercially reasonable steps to
                          protect your personal information from misuse, loss,
                          unauthorised access, modification and disclosure.
                          However, the Company is not liable for any
                          unauthorised access to this information.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company will take commercially reasonable steps to
                          destroy or permanently de-identify personal
                          information if it is no longer needed for the purposes
                          of providing our Services to you and where the Company
                          is not required to retain that information by law.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Our website contains links to other websites. Those
                          links are provided for your convenience and may not
                          remain current or be maintained. We are not
                          responsible for the privacy practices of those linked
                          websites and we suggest you review the privacy
                          policies of those websites before using them.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>ACCESS AND CORRECTION</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          You are responsible to ensure that the information you
                          provide to the Company is accurate, complete and up to
                          date. If you find that the information we have is not
                          up to date or is inaccurate, please advise us as soon
                          as practicable so we can update our records and ensure
                          we can continue to provide our Services. We will deal
                          with all requests for access to personal information
                          within a reasonable timeframe.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          By written request, you may contact the Company and
                          ask to know what sort of personal information is held
                          about you, for what purposes, how it is collected,
                          held, used and disclosed and/or to access your
                          personal information. In order to protect your
                          personalinformation we may require identification from
                          you before releasing the requested information. The
                          Company reserves the right to charge a reasonable
                          administration fee for this access.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          If you are protected under the GDPR, you may have the
                          following rights: <br/><br/>(i) right of access – the right to
                          access the personal information that we hold or
                          process about you <br/>(ii) right to rectification – the
                          right to update, correct or amend the personal
                          information that we hold or process about you <br/>(iii)
                          right to erasure – the right to request remove
                          personal information that we hold about you <br/>(iv) right
                          to restrict – the right to stop us processing all or
                          some of your personal data <br/>(v) right to object – the
                          right to object to your personal information being
                          used for direct marketing purposes <br/>(vi) right to data
                          portability – the right to request a copy of your
                          personal data in electronic format <br/>(vii) right not to
                          be subject to automated decision making – the right to
                          not be subject to a decision based solely on automated
                          decision making, including profiling, where the
                          decision would have a legal effect on you or produce a
                          similarly significant effect <br/>(viii) right to restrict
                          – the right to restrict how we use your personal
                          information whilst a complaint is being investigated
                          <br/>(ix) the right to lodge a complaint with a supervisory
                          authority.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>IDENTIFIERS</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          You acknowledge that the Company retains, collects and
                          maintains your personal information for statistical
                          analysis purposes (whether for commercial or
                          non-commercial purposes). You agree that the Company
                          may share, distribute and/or transfer informationwhich
                          you have provided to the Company to third parties,
                          after that information has been de- identified.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>TRANS-BORDER DATA FLOWS</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company may use cloud services, and as such, its
                          servers may be located in Australia or overseas and
                          you acknowledge, agree and understand that your
                          personal information may be transferred and stored in
                          Australia and other foreign jurisdictions which the
                          cloud services may direct the data to be stored. You
                          agree for us to transfer your information into foreign
                          jurisdictions for the purposes of providing you with
                          the Services. If we do so, we will take reasonable
                          steps to ensure that any overseas recipient deals with
                          such personal information in a manner consistent with
                          this policy. If you are based in the European Economic
                          Area (<b>EEA</b>), this will mean that your personal
                          information may be transferred to, be accessible from,
                          and/or stored at, a destination outside the EEA in
                          which data protection laws may not be as comprehensive
                          as in the EEA.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          Regardless of the location of our processing, we will
                          impose the same data protection safeguards that we
                          deploy inside the EEA and implement appropriate
                          measures to ensure that your personal information is
                          protected in accordance with applicable data
                          protection laws. Where a third party service provider
                          processes the personal information of EEA residents on
                          our behalf, we will ensure that appropriate measures
                          are in place to ensure an adequate level of protection
                          for your personal information, usually by including EU
                          standard contractual clauses in our agreements with
                          such third party service providers.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>COMPLAINTS</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          As a valuable customer, if you have any complaints in
                          relation to this Privacy Policy or privacy practice,
                          please feel free to contact the Company via the email
                          address listed below.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company values your opinions and takes complaints
                          very seriously. Upon receiving written notice of your
                          complaint about privacy, the Company will respond in a
                          timely manner to advise you of the outcome following
                          its enquiring into the same. You will also be invited
                          to respond to its outcome. If a response is received,
                          the Company will assess your response and advise if it
                          has changed its view.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>MISCELLANEOUS</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          <b>Waivers</b>
                          <br />
                          In limited circumstances, the Company may allow you to
                          waive application of this Privacy Policy. A waiver of
                          this Privacy Policy must be in writing and signed by
                          the Company. The Company anticipates that the waiver
                          of this Privacy Policy would only be in
                          exceptionalcircumstances, and the Company encourages
                          that you consider the circumstances leading to the
                          waiver request prior to submitting such a request.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          <b>Governing Law & Jurisdiction</b>
                          <br />
                          This Privacy Policy is governed by the laws of New
                          South Wales, Australia. In the event of any dispute
                          arising out of or in connection with this Privacy
                          Policy, you agree that the exclusive venue for
                          resolving any dispute shall be Sydney, Australia.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>VARIATION TO THIS PRIVACY POLICY</h3>
                    <ol>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          This Privacy Policy is available online via{" "}
                          <a>ozgigs.com.au/terms-and-conditions</a>
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>
                          The Company reserves the right to modify or amend this
                          Privacy Policy at any time, for any reason, and
                          without any notice, by posting a revised version on
                          its website. Any changes to this Privacy Policy will
                          become effective when the Company posts the revised
                          Privacy Policy on the website. Your use of the website
                          following these changes means that you accept the
                          revised Privacy Policy.
                        </p>
                      </li>
                      <li style={{ fontWeight: "400" }}>
                        <p>This policy was last modified on 5 June 2023.</p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>CONTACT</h3>
                    <ol>
                    <li style={{ fontWeight: "400" }}>
                      <p>
                        Please contact the Company if you have any questions
                        regarding this Privacy Policy.
                        <br />
                        Email: <a>support@ozgigs.com.au</a>
                      </p>
                    </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
