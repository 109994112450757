import { checkoutSessionConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const checkoutSession = (data) => async (dispatch) =>{
    try{
      const  checkoutSessionData  = await commonService.withToken(`/create-checkout-session`,data);
      if(checkoutSessionData){
        dispatch({type: checkoutSessionConst.GET_CHECKOUTSESSION_SUCCESS , payload: {datasss: checkoutSessionData.data}})
        if(checkoutSessionData?.data && checkoutSessionData?.data?.data?.url){
          window.location.href = checkoutSessionData.data.data.url;
        }
      }
      else{
        dispatch({type: checkoutSessionConst.GET_CHECKOUTSESSION_SUCCESS , payload: {data: checkoutSessionData.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: checkoutSessionConst.GET_CHECKOUTSESSION_SUCCESS , payload: error.message})
  }
}

export default checkoutSession;