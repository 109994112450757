import { resendEmailVerificationConstants, clearResendEmailVerificationData} from "../constants/user.constants";
import { commonService } from "../services/common.service";

const ResendEmailVerification = (datass) => async (dispatch) => {
    try {
        const data = await commonService.withOutToken(`/resend-email-verification`, datass);
        if (data) {
            dispatch({ type: resendEmailVerificationConstants.GET_RESENDVERIFICATION_SUCCESS, payload: { datasss: data.data, loading: false } })
        }
        else {
            dispatch({ type: resendEmailVerificationConstants.GET_RESENDVERIFICATION_FAILURE, payload: { data: data.data } })
        }
    } 
    catch (error) {
        console.log(`Error while calling login api ${error}`);
        dispatch({ type: resendEmailVerificationConstants.GET_RESENDVERIFICATION_FAILURE, payload: error.message })
    }
}

export const clearResendEmailMsg = () => async (dispatch)=>{
    try{
      dispatch({type : clearResendEmailVerificationData.CLEAR_RESENDEMAILVERIFICATION_MESSAGE , payload : []})
    }catch(err){
      
    }
}

export default ResendEmailVerification;
