import { toast } from "react-toastify";
import VendorHeader from './includes/header';
import VendorFooter from "../includes/footer";
import VendorSidebar from './includes/sidebar';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorProfileAction, { clearPasswordMsg }from '../../actions/VendorProfileAction';
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';
import changePasswordAction from '../../actions/changePasswordAction';
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";


const ChangePassword = () => {

    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [vendorInfo, setVendorInfo] = useState([]);
    const [icon, setIcon] = useState(eyeOff);

    useEffect(() => {
        dispatch(VendorProfileAction());
    }, []);

    // Get vendor all information
    const vendorProfile = useSelector((state) => state.vendorProfile?.vendorProfile);
    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setVendorInfo(vendorProfile.datasss.data);
        }
    }, [vendorProfile]);
    // function for change password.
    const changePassword = async (e) => {
        e.preventDefault();
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError(true);
            return false;
        } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/).test(newPassword)) {
            toast.warn("Password format is invalid. Password should be atlest 8 Characters, 1 upercase, 1 lowercase, 1 numeric, 1 special charactor.");
            return false;
        }
        else {
            dispatch(changePasswordAction({
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }));
        }
    }

    // select data from change password reducer and set toaster here
    const changePasswordDtl = useSelector((state) => state.changePassword.changePassword)
    useEffect(() => {
        if (changePasswordDtl && changePasswordDtl?.datasss && changePasswordDtl?.datasss?.status === true) {
            toast.success(changePasswordDtl?.datasss?.message)
            // password change from firebase as well
            async function fetchData() {
                if(vendorInfo.email){
                    try {
                        const auth = getAuth();
                        const user = auth.currentUser;
                        const credential = EmailAuthProvider.credential(
                            user.email,
                            currentPassword
                        );
                        await reauthenticateWithCredential(user, credential);
                        await updatePassword(user, newPassword);
                        toast.success('Password updated successfully from firebase as well.');
                    } catch (error) {
                        alert('Error changing password on firsbase: please contact with admin to resend again reset password link on your email' + error.message);
                    }
                }
            }
            fetchData();
            dispatch(clearPasswordMsg());
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            dispatch(VendorProfileAction());
        }
        if (changePasswordDtl && changePasswordDtl?.datasss && changePasswordDtl?.datasss?.status === false) {
            toast.error(changePasswordDtl?.datasss?.message)
            dispatch(clearPasswordMsg());
        }
    }, [changePasswordDtl]);

    const handelToggle = (fieldName) => {
        if (fieldName === "password") {
            setPasswordType((passwordType === "password") ? "text" : "password");
        } else if (fieldName === "new_password") {
            setNewPasswordType((newPasswordType === "password") ? "text" : "password");
        } else if (fieldName === "confirm_password") {
            setConfirmPasswordType((confirmPasswordType === "password") ? "text" : "password");
        }
    };
    

    return (
        <>
            <div className="wrapper">
                <VendorHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <VendorSidebar />
                        <div className="right-content-block">
                            <div className="right-top-text">
                                <h3>{vendorInfo.first_name}'s profile</h3>
                            </div>
                            <div className="security-setting">
                                <h6><i className="fa fa-cog"></i> Security Setting</h6>
                                <div className="security-password" id="security-password">
                                    <form onSubmit={changePassword}>
                                        <div className="form-group input-parent">
                                            <input type={passwordType}  name="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current Password" />
                                            {error && !currentPassword && (
                                                <span style={{ color: "red" }} className="invalidInput">
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group input-parent">
                                            <input type={newPasswordType} name="new_password" value={newPassword} className="password-eye" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                                            {error && !newPassword && (
                                                <span style={{ color: "red" }} className="invalidInput">
                                                    Required*
                                                </span>
                                            )}
                                            <span
                                                onClick={()=>handelToggle('new_password')}
                                                className="input-icon "
                                            >
                                                <Icon icon={newPasswordType === 'password' ? eyeOff : eye} size={25} />
                                            </span>
                                        </div>
                                        <div className="form-group input-parent">
                                            <input type={confirmPasswordType}  name="confirm_password" className="password-eye" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                                            {error && !confirmPassword && (
                                                <span style={{ color: "red" }} className="invalidInput">
                                                    Required*
                                                </span>
                                            )}
                                            <span
                                                onClick={()=>handelToggle('confirm_password')}
                                                className="input-icon "
                                            >
                                                <Icon icon={confirmPasswordType === 'password' ? eyeOff : eye} size={25} />
                                            </span>
                                        </div>
                                        <div className="change-password-btn btn">
                                            <input type="submit" name="change-password" value="Change Password" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <VendorFooter />
            </div>
        </>
    )
}

export default ChangePassword;