import { commonService } from "../services/common.service";
import { EventDetailsConst } from "../constants/user.constants";

const eventDetailsAction = (eventId) => async (dispatch) => {
    try {
        const eventDetails = await commonService.withLogin(`/event-details/${eventId}`);
        if (eventDetails) {
            dispatch({ type: EventDetailsConst.GET_EVENTDETAILS_SUCCESS, payload: { datasss: eventDetails.data } });
        }
        else {
            dispatch({ type: EventDetailsConst.GET_EVENTDETAILS_FAILURE, payload: { data: eventDetails.data } });
        }
    }
    catch (error) {
        dispatch({ type: EventDetailsConst.GET_EVENTDETAILS_FAILURE, payload: error.message })
    }
}

export default eventDetailsAction;