import moment from 'moment';
import { toast } from "react-toastify";
import ConsumerHeader from "./includes/header";
import ConsumerFooter from "../includes/footer";
import ConsumerSidebar from "./includes/sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consumerProfileAction, { clearProfileMsg } from '../../actions/consumerProfileAction';
import uploadProfileAction from "../../actions/uploadProfileAction";
import changePasswordAction from '../../actions/changePasswordAction';
import deleteProfileAction, {clearDeleteProfileMsg} from '../../actions/deleteProfileAction';
import { useNavigate } from 'react-router';
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from "axios";
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';

const ConsumerProfile = () => {

    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const auth = getAuth();
    const [error, setError] = useState(false);
    const [consumerDtl, setConsumerDtl] = useState([]);
    const [profilePicture, setProfilePicture] = useState("");
    const [location, setLocation] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [chatNotificationChecked, setChatNotificationChecked] = useState(false);
    const [reviewNotificationChecked, setReviewNotificationChecked] = useState(false);
    const [newsNotificationChecked, setNewsNotificationChecked] = useState(false);

    let tokenObj = JSON.parse(localStorage.getItem("token"));
    const [icon, setIcon] = useState(eyeOff);

    useEffect(() => {
        dispatch(consumerProfileAction());
    }, []);

    //  set consumer profile details.
    const consumerInfo = useSelector((state) => state?.consumerProfile?.consumerProfile);
    useEffect(() => {
        if (consumerInfo && consumerInfo?.datasss && consumerInfo?.datasss?.status === true) {
            setConsumerDtl(consumerInfo?.datasss?.data);
            setLocation(consumerInfo?.datasss?.data.user_information?.location);
            if(consumerInfo?.datasss?.data.chat_notify_status){
                setChatNotificationChecked(true)
            }
            if(consumerInfo?.datasss?.data.new_promotions_notify){
                setNewsNotificationChecked(true)
            }
            if(consumerInfo?.datasss?.data.new_review_notify){
                setReviewNotificationChecked(true)
            }
        }
    }, [consumerInfo]);
    // select data from upload profile reducer and set toaster here
    const uploadProfileDtl = useSelector((state) => state.uploadProfile.uploadProfile)
    useEffect(() => {
        if (uploadProfileDtl && uploadProfileDtl?.datasss && uploadProfileDtl?.datasss?.status === true) {
            toast.success(uploadProfileDtl?.datasss?.message)
            dispatch(clearProfileMsg());
            dispatch(consumerProfileAction());
            setProfilePicture("");
        }
        if (uploadProfileDtl && uploadProfileDtl?.datasss && uploadProfileDtl?.datasss?.status === false) {
            toast.error(uploadProfileDtl?.datasss?.message)
            dispatch(clearProfileMsg());
        }
    }, [uploadProfileDtl]);

    // upload profile photo form action
    const uploadProfile = async (e) => {
        e.preventDefault();
        const res = await dispatch(uploadProfileAction({
            profile_picture: profilePicture,
            location: location
        })
        );
    }

    // selet and set a profile photo and convert into base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };
    const handleProfileUpload = async (e) => {
        let file = e.target.files[0];
        let base64 = await convertToBase64(file);
        if (base64) {
            let base64Split = base64.split(';');
            let splitExtension = base64Split[0].split('/');
            if ((splitExtension[1].toLowerCase() === 'png') || (splitExtension[1].toLowerCase() === 'jpg') || (splitExtension[1].toLowerCase() === 'jpeg')) {
                setProfilePicture(base64);
            } else {
                toast.warn('The file must be type of : jpeg,png,jpg');
            }
        }
    }

    // function for change password.
    const changePassword = async (e) => {
        e.preventDefault();
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError(true);
            return false;
        } else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/).test(newPassword)) {
            toast.warn("Password format is invalid. Password should be atlest 8 Characters, 1 upercase, 1 lowercase, 1 numeric, 1 special charactor.");
            return false;
        }
        else {
            dispatch(changePasswordAction({
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            })
            );
        }
    }

    // select data from change password reducer and set toaster here
    const changePasswordDtl = useSelector((state) => state.changePassword.changePassword)
    useEffect(() => {
        if (changePasswordDtl && changePasswordDtl?.datasss && changePasswordDtl?.datasss?.status === true) {
            toast.success(changePasswordDtl?.datasss?.message)
            // password change from firebase as well
            async function fetchData() {
                if(consumerDtl.email){
                    try {
                        const auth = getAuth();
                        const user = auth.currentUser;
                        const credential = EmailAuthProvider.credential(
                            user.email,
                            currentPassword
                        );
                        await reauthenticateWithCredential(user, credential);
                        await updatePassword(user, newPassword);
                        toast.success('Password updated successfully from firebase as well.');
                    } catch (error) {
                        alert('Error changing password on firsbase: please contact with admin to resend again reset password link on your email' + error.message);
                    }
                }
            }
            fetchData();
            dispatch(clearProfileMsg());
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            // Navigate('/consumer-profile');
            dispatch(consumerProfileAction());
        }
        if (changePasswordDtl && changePasswordDtl?.datasss && changePasswordDtl?.datasss?.status === false) {
            toast.error(changePasswordDtl?.datasss?.message)
            dispatch(clearProfileMsg());
        }
    }, [changePasswordDtl]);

    const handelToggle = (fieldName) => {
        if (fieldName === "password") {
            setPasswordType((passwordType === "password") ? "text" : "password");
        } else if (fieldName === "new_password") {
            setNewPasswordType((newPasswordType === "password") ? "text" : "password");
        } else if (fieldName === "confirm_password") {
            setConfirmPasswordType((confirmPasswordType === "password") ? "text" : "password");
        }
    };

    const handleCheckedChatNotification = (checked) => {
        setChatNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/manage-chat-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            
        })
    }
    const handleCheckedReviewNotification = (checked) => {
        setReviewNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/new-review-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            
        })
    }
    const handleCheckedNewsNotification = (checked) => {
        setNewsNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/news-promotions-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            
        })
    }

    const deleteProfile = useSelector((state) => state.deleteProfile.deleteProfile);
    // Function for delete profile.
    const removeProfile =()  => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to permanently Delete your account?",
            icon: "question",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteProfileAction());
            }
        });
    };
    useEffect(()=> {
        if(deleteProfile && deleteProfile.datasss && deleteProfile?.datasss?.status === true){
            dispatch(clearDeleteProfileMsg());
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("FCM_TOKEN");
            localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
            Navigate("/login");
            Swal.fire({
                title: "Done!",
                text: 'Profile Deleted Successfully',
                icon: "success",
            })
        }
         if(deleteProfile && deleteProfile.datasss && deleteProfile?.datasss?.status === false){
            Swal.fire({
                title: "Warning!",
                text: deleteProfile?.datasss?.message,
                icon: "warning",
            })
            dispatch(clearDeleteProfileMsg());
         }
     },[deleteProfile])

    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <ConsumerSidebar />
                        <div className="right-content-block">
                            <div className="right-top-text">
                                <h3>{consumerDtl.first_name}'s profile</h3>
                            </div>
                            <div className="jason-profile-block">
                                <form onSubmit={uploadProfile}>
                                    <div className="profile-img">
                                        <a href="#">
                                            {/* <img src="./img/Form_Edit_Top_Image.svg" alt=""/> */}
                                            <img src={profilePicture ? profilePicture : (consumerDtl?.profile) ? consumerDtl?.profile : ""} alt="" />
                                            <input type="file" className="hidden-file"
                                                name="profile_picture"
                                                accept=".jpeg, .png, .jpg"
                                                value={''}
                                                onChange={(e) => handleProfileUpload(e)}
                                            />
                                        </a>
                                    </div>
                                    <div className="josan-profile-list">
                                        <div className="jason-profile-input josan-profile-name">
                                            <input type="text" name="name" value={consumerDtl.first_name + ' ' + consumerDtl.last_name} readOnly />
                                        </div>
                                        <div className="jason-profile-input josan-profile-email">
                                            <input type="text" name="name" value={consumerDtl.email} readOnly />
                                        </div>
                                        <div className="jason-profile-input josan-profile-location">
                                            <input type="text" name="location" placeholder="Address..." onChange={(e) => setLocation(e.target.value)} value={location || ''} />
                                        </div>
                                        <div className="jason-profile-input jason-profile-text">
                                            <p>{consumerDtl?.user_information?.profile_description}</p>
                                        </div>
                                        <div className="jason-profile-submit">
                                            <input type="submit" name="submit" className="btn" value="Save Changes" />
                                        </div>
                                    </div>
                                </form>
                                <div className='notification-setting mt-5'>
                                    <h6>Email notification setting:</h6>
                                    <div className="toggle-btn d-flex justify-content-between my-2">
                                        <h6>New Message</h6>
                                        <label className="switch">
                                            <input type="checkbox" checked={chatNotificationChecked} onChange={(e) => { handleCheckedChatNotification(e) }} id="checbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="toggle-btn d-flex justify-content-between my-2">
                                        <h6>New Review</h6>
                                        <label className="switch">
                                            <input type="checkbox" checked={reviewNotificationChecked} onChange={(e) => { handleCheckedReviewNotification(e) }} id="checbox1" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="toggle-btn d-flex justify-content-between my-2">
                                        <h6>Ozgigs News / Promotions</h6>
                                        <label className="switch">
                                            <input type="checkbox" checked={newsNotificationChecked} onChange={(e) => { handleCheckedNewsNotification(e) }} id="checbox2" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="member-since text-end">
                                    <p>Member Since :<span> {moment(consumerDtl.email_verified_at).format('MMMM YYYY,')}</span></p>
                                </div>
                            </div>
                            <div className="security-setting">
                                <h6><i className="fa fa-cog"></i> Security Setting</h6>
                                {consumerDtl.login_type === 'email' ?
                                <>
                                    <div className="security-password" id="security-password">
                                        <form onSubmit={changePassword}>
                                            <div className="form-group input-parent">
                                                <input type={passwordType}  name="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current Password" />
                                                {error && !currentPassword && (
                                                    <span style={{ color: "red" }} className="invalidInput">
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group input-parent">
                                                <input type={newPasswordType} name="new_password" value={newPassword} className="password-eye" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                                                {error && !newPassword && (
                                                    <span style={{ color: "red" }} className="invalidInput">
                                                        Required*
                                                    </span>
                                                )}
                                                <span
                                                    onClick={()=>handelToggle("new_password")}
                                                    className="input-icon "
                                                >
                                                    <Icon icon={newPasswordType === 'password' ? eyeOff : eye} size={25} />
                                                </span>
                                            </div>
                                            <div className="form-group input-parent">
                                                <input type={confirmPasswordType}  name="confirm_password" className="password-eye" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                                                {error && !confirmPassword && (
                                                    <span style={{ color: "red" }} className="invalidInput">
                                                        Required*
                                                    </span>
                                                )}
                                                <span
                                                    onClick={()=>handelToggle("confirm_password")}
                                                    className="input-icon "
                                                >
                                                    <Icon icon={confirmPasswordType === 'password' ? eyeOff : eye} size={25} />
                                                </span>
                                            </div>
                                            <div className="change-password-btn btn">
                                                <input type="submit" name="change-password" value="Change Password" />
                                            </div>

                                        </form>
                                    </div>
                                </>
                                : ''}
                                <div className="setting-list">
                                    <div className="row ">
                                        <div className="col-md-6 col-sm-6 col-xs-8 col-6">
                                            <p className='mb-2 mt-2'>Delete My Profile:</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-3 col-6 setting-button remove_profile_btn">
                                            <Link onClick={removeProfile}>Remove / Delete</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    )
}

export default ConsumerProfile;