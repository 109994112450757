import React, { useEffect, useState } from 'react';
import VendorHeader from './includes/header';
import VendorFooter from "../includes/footer";
import VendorSidebar from './includes/sidebar';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, } from "react-router-dom";
import checkoutSession from '../../actions/checkoutSessionAction';
import sessionRetrive from '../../actions/sessionRetrive';
import productAction from '../../actions/productAction';
import vendorSubscriptionDetails, { clearSessionMsg } from '../../actions/vendorSubDetailsAction';
import { toast } from 'react-toastify';
import moment from "moment";
import Swal from "sweetalert2";
import axios from 'axios'

const ManageSubscription = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [productList, setProductlist] = useState([]);
    const [priceID, setPriceID] = useState();
    const [priceAmount, setPriceAmount] = useState();
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const [yearSubscription, setYearSubscription] = useState(true)
    const [priceAmountYear, setPriceAmountYear] = useState()
    let tokenObj = JSON.parse(localStorage.getItem("token"));

    const subscription = () => {
        dispatch(checkoutSession({ price_id: priceID, url: 'manageSubscription' }));
    }
    //  get session id from return url.
    const search = location.search;
    const params = new URLSearchParams(search);
    const session_id = params.get('session_id');

    // call the actions and seesion retrive api with session id
    useEffect(() => {
        if (session_id) {
            dispatch(sessionRetrive(session_id));
        }
        dispatch(vendorSubscriptionDetails());
        dispatch(productAction());
    }, []);

    useEffect(() => {
        const yearIndex = (productList?.prices?.data)?.findIndex(item => item?.recurring?.interval === 'year');
        setPriceID(productList?.prices?.data[yearIndex]?.id);
        setPriceAmountYear(productList?.prices?.data[yearIndex].nickname);
    }, [productList])
    // get subscriptions of vendor
    const subscritptionDtl = useSelector((state) => state.vendorSubscriptionDetails?.vendorSubscriptionDetails);
    useEffect(() => {
        if (subscritptionDtl && subscritptionDtl?.datasss && subscritptionDtl?.datasss.data) {
            subscritptionDtl?.datasss?.data.map(data => {
                if (data.price_details?.recurring?.interval === 'year') {
                    setYearSubscription(false)
                }

            })
            // if(subscritptionDtl?.datasss.data.subscription_details && subscritptionDtl?.datasss.data.subscription_details.plan.interval === 'month'){
            //     setPriceAmount(subscritptionDtl?.datasss.data.subscription_details.plan.nickname)
            // }
            setSubscriptionDetails(subscritptionDtl?.datasss?.data);
        }
    }, [subscritptionDtl]);

    // get product list
    const productDtl = useSelector((state) => state.product.product);
    useEffect(() => {
        if (productDtl && productDtl?.datasss && productDtl?.datasss.data) {
            setProductlist(productDtl?.datasss?.data);
        }
    }, [productDtl]);

    // get resposne and redirect to home 
    const sessionRetriveDtl = useSelector((state) => state.sessionRetrive.sessionRetrive);
    useEffect(() => {
        if (sessionRetriveDtl && sessionRetriveDtl?.datasss && sessionRetriveDtl?.datasss.status === true) {
            toast.success(sessionRetriveDtl?.datasss?.message);
            navigate("/vendor-dashboard");
            dispatch(clearSessionMsg());
        }
    }, [sessionRetriveDtl]);

    // const priceIDchecked = (e) => {
    //     const yearIndex = (productList?.prices?.data)?.findIndex(item => item?.recurring?.interval === 'year');
    //     const monthIndex = (productList?.prices?.data)?.findIndex(item => item?.recurring?.interval === 'month');
    //     if (e.target.checked === true) {
    //         setPriceID(productList?.prices?.data[yearIndex].id);
    //         setPriceAmount(productList?.prices?.data[yearIndex].nickname);
    //     } else {
    //         setPriceID(productList?.prices?.data[monthIndex].id);
    //         setPriceAmount(productList?.prices?.data[monthIndex].nickname);
    //     }
    // }

    const handleOnChangePlan = (status) => {
        if (status === 0) {
            Swal.fire({
                title: "Are you sure?",
                text: "Do you really want to disable your auto renewal?",
                icon: "warning",
                confirmButtonText: 'OK',
                showCancelButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    const headers = {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${tokenObj.api_token}`
                    }
                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/auto-renewal`,{}, { headers }).then(response => {
                        toast.success(response?.data?.message);
                        dispatch(vendorSubscriptionDetails());

                    })
                }
            });
        }else{
            toast.warn("You can not enable the plan until the expiration date.");
        }

    };

    return (
        <>
            <div className="wrapper">
                <VendorHeader />
                <section className="sidebar-content-wr consumer-block Subscription_details">
                    <div className="container">
                        <VendorSidebar />
                        <div className="right-content-block">
                            <h4>Manage Subscription</h4>
                            <div className="setting-cards manage-subs-card">
                                {subscriptionDetails && subscriptionDetails.length && subscriptionDetails?.map(data => {
                                    return (<div key={data.id} className="setting-inner-card premium-card">
                                        <img src="./img/premium.svg" alt="" />
                                        <h4 className="common-label">{data.price_details?.recurring?.interval}ly</h4>
                                        <div className="toggle-btn">
                                        {(data && data.free_trial == 1) ? <p className='expire-date'><b>Cancel Free Trial</b></p> : <p className='expire-date'><b>Auto Renewal Switch</b></p>}
                                            <label className="switch">
                                                <input type="checkbox" checked={data?.status === 0 ? true : false} onChange={() => handleOnChangePlan(data.status)} id="checbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <p className='expire-date'>Auto Renewal Date<br />{moment(data?.expiry_date).format('DD-MM-YYYY')}</p>
                                        <strong style={{ color: '#d51818' }} className='subscription-price'><b>{data.price_details?.nickname}</b></strong>
                                    </div>)
                                })}
                                {yearSubscription && <div className="setting-inner-card premium-card">
                                    <img src="./img/premium.svg" alt="" />
                                    <h4 className="common-label">Yearly</h4>
                                    <h4 className="common-label">Pay annually and <br/>get 2 months Free!</h4>
                                    <strong style={{ color: '#d51818' }}><b>{priceAmountYear}</b></strong>
                                    <div className="your-plan">
                                        <button className=" btn" onClick={() => subscription()}>Change Your Plan</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </section>
                <VendorFooter />
            </div>
        </>
    )
}

export default ManageSubscription;