import { CategoryRegionListConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const regionCategoryListAction = () => async (dispatch) => {
    try {
        const categoryRegionList = await commonService.withLogin(`/category-region-list`);
        if (categoryRegionList) {
            dispatch({ type: CategoryRegionListConst.CATEGORY_REGIONS_LIST_SUCCESS, payload: { datasss: categoryRegionList.data } });
        }
        else {
            dispatch({ type: CategoryRegionListConst.CATEGORY_REGIONS_LIST_FAILURE, payload: { data: categoryRegionList.data } });
        }
    } 
    catch (error) {
        dispatch({ type: CategoryRegionListConst.CATEGORY_REGIONS_LIST_FAILURE, payload: error.message })
    }
}

export default regionCategoryListAction;