import React, { useEffect, useState } from "react";
// import Loader from 'react-js-loader';
import ConsumerSidebar from "../includes/sidebar";
import ConsumerHeader from "../includes/header";
import ConsumerFooter from "../../includes/footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import recentEventsAction from "../../../actions/recentEventsAction";
import deleteEventAction from "../../../actions/deleteEventAction";
import $ from 'jquery';
import moment from 'moment';

const UpcomingEvents = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [recentEventList, setrecentEventList] = useState([]);
    const currentDate = new Date();
    useEffect(() => {
        dispatch(recentEventsAction());
    },[])

    // select list from reducer
    const recentEventsDtl = useSelector((state) => state.recentEvents.recentEvents)
    useEffect(() => {
        if(recentEventsDtl && recentEventsDtl.datasss && recentEventsDtl.datasss.status === true ){
            setrecentEventList(recentEventsDtl.datasss.data)
        }
    }, [recentEventsDtl]);
    
    // condition for delete selected event and set backend message on toaster
    const deleteEvent = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this event?",
            icon: "warning",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteEventAction(id));
            }
        });
    };

    const deleteEventsDtl = useSelector((state) => state.deleteEvent.deleteEvent)
    useEffect(() => {
        if(deleteEventsDtl && deleteEventsDtl?.datasss && deleteEventsDtl?.datasss?.status === true ){
            Swal.fire({
                title: "Done!",
                text: deleteEventsDtl?.datasss?.message,
                icon: "success",
                button: false
            })
            if ($.fn.DataTable.isDataTable('#upcomingEvents')) {
                $('#upcomingEvents').DataTable().destroy(); // Destroy the existing DataTable instance
            }
            dispatch(recentEventsAction());
        } 
        else if(deleteEventsDtl && deleteEventsDtl?.datasss && deleteEventsDtl?.datasss?.status === false ){
            Swal.fire({
                title: "Error!",
                text: deleteEventsDtl?.datasss?.message,
                icon: "error",
                button: false
            })
        }
    }, [deleteEventsDtl]);

    // function for redirection to upcoming and past event's
    const setEvents = (event) => {
        const value = event.target.value;
        if(value === 'upcoming'){
            navigate("/upcoming-events");
        }
        if(value === 'past'){
            navigate("/past-events");
        }
    } 

    useEffect(() => {
        {recentEventList && (recentEventList.length > 0 && (
            $(document).ready(function () {
                setTimeout(function(){
                      $('#upcomingEvents').DataTable({
                        bDestroy: true, 
                        lengthMenu: [[5,10,25,50,100], [5,10,25,50,100]],
                        pageLength: 10
                      });
                } ,500);
            })
        ))}
    }, [recentEventList])
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <ConsumerSidebar />
                        <div className="right-content-block">
                            <div className="col-lg-12 col-md-12">
                                <div className="right-top-text d-flex">
                                    <div className="col-sm-9">
                                        <h3>Events</h3>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <select className="events-form-control" onChange={setEvents}>
                                            <option value="upcoming">Upcoming Events</option>
                                            <option value="past">Past Events</option>
                                        </select>
                                    </div> */}
                                    <div className="col-sm-3 text-end"> 
                                        <Link to={'/create-event-page'} className="btn"> <i className="fa fa-plus"> </i> Create Event </Link>
                                    </div>
                                </div>
                                <div className="table-responsive table-custom">
                                    <table id="upcomingEvents" className="table head-design proposalTable">
                                        <thead>
                                            <tr>
                                                <th>EVENT NAME</th>
                                                <th>BUDGET</th>
                                                <th>SERVICE TYPE</th>
                                                <th>LOCATION</th>
                                                <th>STARTING DATE</th>
                                                <th>END DATE</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(recentEventList.length > 0) ? (
                                                <>
                                                    {recentEventList.map((event, index) => (
                                                            <tr key={event.id}>
                                                                <td>{event.event_name}</td>
                                                                <td>{(event.event_budget) ? '$ ' + numberWithCommas(event.event_budget) : '-' }</td>
                                                                <td>{event.event_type ?? '-'}</td>
                                                                <td>{event.location}
                                                                    {/* <span className="countryColor"> Australia</span> */}
                                                                </td>
                                                                <td>{moment(event.event_from_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                                <td>{moment(event.event_to_date).format('DD-MM-YYYY')?? '-'}</td>
                                                                <td>
                                                                    <Link className="listing-btn" to={`/view-event/${event.id}`}>View</Link>
                                                                    {event.event_to_date >=  moment(currentDate).format('YYYY-MM-DD') ? (  
                                                                        <Link className="listing-btn" to={`/edit-event-page/${event.id}`}>Edit</Link> 
                                                                     ):  <></>} 
                                                                    <Link className="listing-btn" onClick={() => {deleteEvent(event.id)}}>Delete</Link>
                                                                </td>
                                                            </tr>
                                                       
                                                    ))}
                                                </>
                                            ): (
                                                <tr>
                                                    <td colSpan={7} align={'center'}> No recent events available </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    )
}

export default UpcomingEvents;