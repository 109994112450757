import { toast } from "react-toastify";
import { sessionRetriveConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const sessionRetrive = (session_id) => async (dispatch) =>{
    try{
      const  sessionRetriveData  = await commonService.withLogin(`/session-retrive/${session_id}`);
      if(sessionRetriveData){
        dispatch({type: sessionRetriveConst.GET_SESSIONRETRIVE_SUCCESS , payload: {datasss: sessionRetriveData.data}})
      }
      else{
        dispatch({type: sessionRetriveConst.GET_SESSIONRETRIVE_SUCCESS , payload: {data: sessionRetriveData.data}})
      }
  }catch(error){
      console.log(`Error while calling session retrive api ${error}`)
      dispatch({ type: sessionRetriveConst.GET_SESSIONRETRIVE_SUCCESS , payload: error.message})
      toast.error("Error in Subsription")

  }
}

export default sessionRetrive;