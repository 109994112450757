import { eventTypeConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const eventType = () => async (dispatch) =>{
    try{
      const  eventType  = await commonService.withOutLogin( `/event-type`);
      if(eventType){
        dispatch({type: eventTypeConst.GET_EVENTTYPE_SUCCESS , payload: {datasss: eventType.data}})
      }
      else{
        dispatch({type: eventTypeConst.GET_EVENTTYPE_FAILURE , payload: {data: eventType.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: eventTypeConst.GET_EVENTTYPE_FAILURE , payload: error.message})
  }
}

export default eventType;