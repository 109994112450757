import { commonService } from "../services/common.service"
import { updateVendorConstants } from "../constants/user.constants"
import { clearUpdateVendorData } from "../constants/user.constants"

 const updateVendorAction = (data) => async (dispatch) =>{
  try{
    if(data === undefined){
        dispatch({ type: clearUpdateVendorData.CLEAR_UPDATEVENDOR_MESSAGE, payload: [] })
    }else{
    const updateVendor = await commonService.withToken(`/update-vendor`, data)
      // if (updateVendor.data.status == true) {
      //   localStorage.setItem("userData", JSON.stringify(updateVendor));
      // }
      if(updateVendor){
          dispatch({type: updateVendorConstants.GET_UPDATEVENDOR_SUCCESS , payload: {datasss: updateVendor.data}})
      }
      else{
        dispatch({type: updateVendorConstants.GET_UPDATEVENDOR_FAILURE , payload: {data: updateVendor.data}})
      }
    }
  }catch(error){
      console.log(`Error while calling reset api ${error}`)
      dispatch({ type: updateVendorConstants.GET_UPDATEVENDOR_FAILURE , payload: error.message})
  }
}

export const clearUpdateVendorMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearUpdateVendorData.CLEAR_UPDATEVENDOR_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
}

export default updateVendorAction;
