import { commonService } from "../services/common.service";
import { ConsumerProfileConst, clearProfileData} from "../constants/user.constants";

const consumerProfileAction = () => async(dispatch) => {
    try{
        const consumerProfile = await commonService.withLogin(`/consumer-profile`); 
        if (consumerProfile) {
            dispatch({ type: ConsumerProfileConst.GET_CONSUMERPROFILE_SUCCESS, payload: { datasss: consumerProfile.data } });
        }
        else {
            dispatch({ type: ConsumerProfileConst.GET_CONSUMERPROFILE_FAILURE, payload: { data: consumerProfile.data } });
        }
    }catch(error){
        dispatch({ type: ConsumerProfileConst.GET_CONSUMERPROFILE_FAILURE, payload: error.message })
    }
}

export const clearProfileMsg = () => async (dispatch)=>{
    try{
        dispatch({type : clearProfileData.CLEAR_PROFILE_MESSAGE , payload : []})
    } catch(err){
        console.log(`Error while calling login api ${err}`);
    }
}

export default consumerProfileAction;
