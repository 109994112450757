import { CreateEventConst,clearCreateEventData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const createEventAction = (data) => async (dispatch) => {
    try {
        const createEvent = await commonService.withToken(`/create-event`, data);
        if (createEvent) {
            dispatch({ type: CreateEventConst.GET_CREATEEVENT_SUCCESS, payload: { datasss: createEvent.data } });
        }
        else {
            dispatch({ type: CreateEventConst.GET_CREATEEVENT_FAILURE, payload: { data: createEvent.data } });
        }
    }
    catch (error) {
        dispatch({ type: CreateEventConst.GET_CREATEEVENT_FAILURE, payload: error.message })
    }
}

export const clearCreateEventMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearCreateEventData.CLEAR_CREATEEVENT_MESSAGE, payload: [] })
    } catch (err) {

    }
}

export default createEventAction;

