import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Loader from 'react-js-loader';
import VendorProfileAction from "../../actions/VendorProfileAction";


export const PrivateRouteVendor = ({ children }) => {
  const auth = JSON.parse(localStorage.getItem('userData'));
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
  useEffect(() => {
    dispatch(VendorProfileAction());
  }, []);

  useEffect(() => {
    // Simulating data loading with a timeout
    if (vendorProfile && vendorProfile?.datasss?.data) {
      setIsLoading(false);
    }
  }, [vendorProfile]);
  if (auth) {
    const userData = auth?.data?.data?.user;
    if (vendorProfile && vendorProfile?.datasss?.data && vendorProfile?.datasss?.data?.vendor_subscription) {
      return auth && userData.role === 2 ? children : <Navigate to="/consumer-dashboard" replace />;
    } else {
      return (
        <>
          {!isLoading ? (
            <Navigate to="/subscription" replace />
          ) : (
            <div className="loader-item">
              <Loader type="spinner-circle" bgColor="#FFFFFF" color="#FFFFFF" size={150} />
            </div>
          )}
          {/* <Navigate to="/subscription" replace /> */}
        </>
      );
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const PrivateRouteConsumer = () => {
  const auth = JSON.parse(localStorage.getItem('userData'));
  if (auth) {
    const userData = auth?.data?.data?.user
    return auth && userData.role === 3 ? <Outlet /> : <Navigate to="/vendor-dashboard" replace />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const AuthRoute = () => {
  const auth = JSON.parse(localStorage.getItem('userData'));
  if (!auth) {
    return !auth ? <Outlet /> : <Navigate to="/login" replace />;
  } else {
    const userData = auth?.data?.data?.user
    return auth && userData.role === 3 ? <Navigate to="/consumer-dashboard" replace /> : <Navigate to="/vendor-dashboard" replace />;
  }
};

