import { varificationConstants,clearVarificationData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const varificationLogin = (id) => async (dispatch) => {

  try {
    const userdata = await commonService.withOutLogin(`/email-verification/`+id);
    if (userdata) {
      if (userdata.data.status === 2) {
        dispatch({
          type: varificationConstants.GET_VARIFICATION_FAILURE,
          payload: { datasss: userdata.data },
        });
      } else {
        if (userdata.data.status) {
          dispatch({
            type: varificationConstants.GET_VARIFICATION_SUCCESS,
            payload: { datasss: userdata.data },
          });
        } 
      }
    } else {
      dispatch({
        type: varificationConstants.GET_VARIFICATION_FAILURE,
        payload: { datasss: userdata.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error.message}`);
    dispatch({
      type: varificationConstants.GET_VARIFICATION_FAILURE,
      payload: error.message,
    });
  }
};


export const clearVarificationMsg = () => async (dispatch)=>{
  try{
    dispatch({type : clearVarificationData.CLEAR_VARIFICATION_MESSAGE , payload : []})
  }catch(err){
    
  }
}

export default varificationLogin;
