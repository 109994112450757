import React, { useEffect, useState } from "react";
// import Loader from 'react-js-loader';
import ConsumerSidebar from "../includes/sidebar";
import ConsumerHeader from "../includes/header";
import ConsumerFooter from "../../includes/footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import pastEventsAction from "../../../actions/pastEventsAction";
import deleteEventAction from "../../../actions/deleteEventAction";
import $ from "jquery";
import 'datatables.net' 
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';

const PastEvents = () => {
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pastEventList, setpastEventList] = useState([]);

    useEffect(() => {
        dispatch(pastEventsAction());
    }, []);

    // select list from reducer
    const pastEventsDtl = useSelector((state) => state.pastEvents.pastEvents);
    useEffect(() => {
        if (
            pastEventsDtl &&
            pastEventsDtl.datasss &&
            pastEventsDtl.datasss.status === true
        ) {
            setpastEventList(pastEventsDtl.datasss.data);
        }
    }, [pastEventsDtl]);

    // condition for delete selected event and set backend message on toaster
    const deleteEvent = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this event?",
            icon: "warning",
            confirmButtonText: "OK",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEventAction(id));
            }
        });
    };
    const deleteEventsDtl = useSelector((state) => state.deleteEvent.deleteEvent);
    useEffect(() => {
        if (
            deleteEventsDtl &&
            deleteEventsDtl?.datasss &&
            deleteEventsDtl?.datasss?.status === true
        ) {
            Swal.fire({
                title: "Done!",
                text: deleteEventsDtl?.datasss?.message,
                icon: "success",
                button: false,
            });
            dispatch(pastEventsAction());
        } else if (
            deleteEventsDtl &&
            deleteEventsDtl?.datasss &&
            deleteEventsDtl?.datasss?.status === false
        ) {
            Swal.fire({
                title: "Error!",
                text: deleteEventsDtl?.datasss?.message,
                icon: "error",
                button: false,
            });
        }
    }, [deleteEventsDtl]);

    // function for redirection to upcoming and past event's
    const setEvents = (event) => {
        const value = event.target.value;
        if(value === 'upcoming'){
            navigate("/upcoming-events");
        }
        if(value === 'past'){
            navigate("/past-events");
        }
    }

    //initialize datatable
    {pastEventList && (pastEventList.length > 0 && (
        $(document).ready(function () {
            setTimeout(function () {
                $("#pastEvents").DataTable();
            }, 500);
        })
    ))}

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <ConsumerSidebar />
                        <div className="right-content-block">
                            <div className="col-lg-12 col-md-12">
                                <div className="right-top-text d-flex">
                                    <div className="col-sm-5">
                                        <h3>Past Events</h3>
                                    </div>
                                    <div className="col-sm-4">
                                        <select className="events-form-control" onChange={setEvents}>
                                            <option value="past">Past Events</option>
                                            <option value="upcoming">Upcoming Events</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-3 text-end"> <Link to={'/create-event-page'} className="btn"> <i className="fa fa-plus"> </i> Create Event </Link></div>
                                </div>
                                <div className="table-responsive table-custom">
                                    <table id="pastEvents" className="table head-design proposalTable" >
                                        <thead>
                                            <tr>
                                                <th>EVENT NAME</th>
                                                <th>BUDGET</th>
                                                <th>TYPE OF SERVICE</th>
                                                <th>LOCATION</th>
                                                <th>EVENT STARTING DATE</th>
                                                <th>EVENT END DATE</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pastEventList.length > 0 ? (
                                                <>
                                                    {pastEventList.slice(0, 10).map((event) => (
                                                        <>
                                                            <tr>
                                                                <td>{event.event_name}</td>
                                                                <td>{(event.event_budget) ? '$ ' + numberWithCommas(event.event_budget) : '-' }</td>
                                                                <td>{event.event_type ?? "-"}</td>
                                                                <td>{event.location ?? '-'} 
                                                                    {/* <span className="countryColor">Australia</span> */}
                                                                </td>
                                                                <td>{moment(event.event_from_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                                <td>{moment(event.event_to_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                                <td>
                                                                    <Link className="listing-btn" to={`/view-event/${event.id}`}>View</Link>
                                                                    <Link className="listing-btn" onClick={() => { deleteEvent(event.id);}}>Delete</Link>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} align={"center"}>
                                                        {" "}
                                                        No past events available{" "}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    );
};

export default PastEvents;