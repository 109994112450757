import { signupConstants, clearSignupData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const authenticationSignup = (datass) => async (dispatch) => {
    try {
        localStorage.removeItem("googleLogin");
        const signupdata = await commonService.withOutToken(`/register`, datass);
        if (signupdata.status) {
            if(signupdata?.data && signupdata?.data?.data && signupdata?.data?.data?.user && signupdata?.data?.data?.user?.login_type === 'google'){
                localStorage.setItem("userData", JSON.stringify(signupdata));
            }
            dispatch({ type: signupConstants.GET_SIGNUP_SUCCESS, payload: { data: signupdata.data },});
        }else{
            dispatch({ type: signupConstants.GET_SIGNUP_FAILURE, payload: { error: signupdata.data },});
        }
    } catch (error) {
        dispatch({ type: signupConstants.GET_SIGNUP_FAILURE, payload: error.response, });
    }
    
};

export const clearSignupMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearSignupData.CLEAR_SIGNUP_MESSAGE, payload: [] })
    } catch (err) {

    }
}


export default authenticationSignup;
