import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorFooter from "../includes/footer";
import VendorHeader from "./includes/header";
import { useNavigate } from "react-router";
import { useLocation, } from "react-router-dom";
import checkoutSession from '../../actions/checkoutSessionAction';
import sessionRetrive from '../../actions/sessionRetrive';
import productAction from '../../actions/productAction';
import VendorProfileAction from "../../actions/VendorProfileAction";
import vendorSubscriptionDetails, { clearSessionMsg } from '../../actions/vendorSubDetailsAction';
import { toast } from 'react-toastify';

const Subscription = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [productList, setProductlist] = useState([]);
  const [yearNickname, setYearNickname] = useState("");
  const [monthNickname, setMonthNickname] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [priceID, setPriceID] = useState();
  const [priceAmount, setPriceAmount] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const userInfo = JSON.parse(localStorage.getItem('userData'));
  const search = location.search;
  const params = new URLSearchParams(search);
  const session_id = params.get('session_id');
  const userData = userInfo?.data?.data?.user;
  // call the actions and seesion retrive api with session id
  useEffect(() => {
   
    if (session_id) {
      dispatch(sessionRetrive(session_id));
    }
    dispatch(vendorSubscriptionDetails());
    dispatch(productAction());
  }, []);

  useEffect(() => {
    if (productList && productList.prices) {
      setSubscriptionPlan(productList.prices.data)
    }
  }, [productList])

  // get subscriptions of vendor
  const subscritptionDtl = useSelector((state) => state.vendorSubscriptionDetails?.vendorSubscriptionDetails);
  useEffect(() => {
    if (subscritptionDtl && subscritptionDtl?.datasss && subscritptionDtl?.datasss.data) {
      setSubscriptionDetails(subscritptionDtl?.datasss?.data);
      // toast.success(subscritptionDtl?.datasss.message)
    }
  }, [subscritptionDtl]);

  // get product list
  const productDtl = useSelector((state) => state.product.product);

  // useEffect to set the productList
  useEffect(() => {
    if (productDtl && productDtl?.datasss && productDtl?.datasss.data) {
      setProductlist(productDtl?.datasss?.data);
    }
  }, [productDtl]);

  // useEffect to find and set the indices
  useEffect(() => {
    if (productList && productList?.prices?.data) {
      const yearIndex = productList?.prices?.data?.findIndex(item => item?.recurring?.interval === 'year');
      const monthIndex = productList?.prices?.data?.findIndex(item => item?.recurring?.interval === 'month');
      if (yearIndex !== -1) {
        setYearNickname(productList?.prices?.data[yearIndex]?.unit_amount_decimal);
      }
      if (monthIndex !== -1) {
        setMonthNickname(productList?.prices?.data[monthIndex]?.unit_amount_decimal);
      }
    }
  }, [productList]);

  // get resposne and redirect to home 
  const sessionRetriveDtl = useSelector((state) => state.sessionRetrive.sessionRetrive);
  const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
  useEffect(()=>{
    if(vendorProfile && vendorProfile?.datasss?.data && vendorProfile?.datasss?.data?.vendor_subscription){
      navigate('/vendor-dashboard')
    }
  },[vendorProfile])
 
  useEffect(() => {
    if (sessionRetriveDtl && sessionRetriveDtl?.datasss && sessionRetriveDtl?.datasss.status === true) {
      dispatch(VendorProfileAction());
      // navigate("/vendor-dashboard");
      toast.success(sessionRetriveDtl?.datasss?.message);
      dispatch(clearSessionMsg());
    }else{
      toast.error(sessionRetriveDtl?.datasss?.message);
    }
  }, [sessionRetriveDtl]);

  const handlePlan = (plan) => {
    const yearIndex = (productList?.prices?.data)?.findIndex(item => item?.recurring?.interval === 'year');
    const monthIndex = (productList?.prices?.data)?.findIndex(item => item?.recurring?.interval === 'month');
    if(plan === 'month'){
      if(subscriptionPlan[monthIndex]){
        dispatch(checkoutSession({ price_id: subscriptionPlan[monthIndex].id, url: 'subscription'}));
        // setPriceID(subscriptionPlan[monthIndex].id);
        setPriceAmount(productList?.prices?.data[monthIndex].nickname);
      }else{
        toast.error('The price specified is inactive. This field only accepts active prices.');
      }
    }

    if(plan === 'year'){
      if(subscriptionPlan[yearIndex]){
        dispatch(checkoutSession({ price_id: subscriptionPlan[yearIndex].id, url: 'subscription'}));
        // setPriceID(subscriptionPlan[yearIndex].id);
        setPriceAmount(productList?.prices?.data[yearIndex].nickname);
      }else{
        toast.error('The price specified is inactive. This field only accepts active prices.');
      }
    }
  }

  return (
      <div className="wrapper">
        <VendorHeader />
        <section className="sidebar-content-wr setting-sec subscription-wr">
          <div className="container">
            <div className="right-content-block">
              <div className="right-top-text">
                <h3>subscription</h3>
                <h4>90 day free trial</h4>
                <span className="small color" style={{color: "#b8b9ba", fontSize: "16px"}}>Cancel anytime within trial period. Automatic billing starts after the trial period ends.</span>
              </div>
              <div className="setting-cards">
                <div className="setting-inner-card basic-card">
                {/* <div className="best-price-block">
                    <p>Best Price</p>
                  </div> */}
                  <img src="./img/crown.png" alt="" />
                  <h4 className="common-label">Billed monthly</h4>
                  <div className="your-plan">
                    <button className="btn" onClick={()=>handlePlan("month")}>{ "$ "+monthNickname/100}</button>
                  </div>
                  <p>90 day free trial</p>
                </div>
                <div className="setting-inner-card premium-card">
                  <div className="best-price-block">
                    <p>Best Price</p>
                  </div>
                  <img src="./img/premium.svg" alt="" />
                  <h4 className="common-label">Billed yearly, get 2 months free</h4>
                  <div className="your-plan">
                    <button className=" btn" onClick={()=>handlePlan("year")}>{"$ "+yearNickname/100}</button>
                  </div>
                  <p>90 day free trial</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <VendorFooter />
      </div>
  )
};
export default Subscription;
