import React, { useEffect, useState } from "react";
import ConsumerFooter from "../../includes/footer";
import ConsumerHeader from "../includes/header";
import ConsumerSidebar from "../includes/sidebar";
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import {useParams} from "react-router-dom";
import categoryAction from "../../../actions/categoryAction";
import regionAction from "../../../actions/regionAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery"
import editEventAction from "../../../actions/editEventAction";
import updateEventAction from "../../../actions/updateEventAction";
import { clearUpdateEventMsg } from "../../../actions/updateEventAction";
import AWS from 'aws-sdk';

const Marker = ({ text }) => (
    <div style={{ position: "relative", top: "-50%", left: "-50%", width: "30px", height: "30px" }}>
      <img src="../img/location-pin.png" alt="marker" style={{ width: "100%", height: "auto" }} />
    </div>
);
const EditEvent = (props) => {
    
    const { id } = useParams();
    const google = window.google;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [eventDtl, seteventDtl] = useState([]);

    const [event_name, setEventName] = useState("");
    const [event_description, setEventDescription] = useState("");
    const [event_budget, setEventBudget] = useState("");
    const [errorvalidateBudget, setErrorvalidateBudget] = useState(false);
    const [event_type, setEventType] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [location, setLocation] = useState("");
    const [region, setRegion] = useState("");
    const [file, setFile] = useState("");
    const [event_file, setEventFile] = useState("");
    const [photos, setPhotos] = useState([]);
    const [event_photos, setEventPhotos] = useState([]);
    const [isRender , setIsRender] = useState(false)
    const [delete_photos, setDeletePhotos] = useState([]);
    const [error, setError] = useState(false);
    const [eventCategory, setEventCategory] = useState([]);
    const [categoryList, setCategory] = useState([]);
    const [regionList, setRegionList] = useState([]);
    
    const [wedding, setWedding] = useState(false);
    const [corporate, setCorporate] = useState(false);
    const [occasions, setOccasions] = useState(false);
    const [party, setParty] = useState(false);

    const [cat, setCat] = useState([]);
    const [checkedSubcat , setCheckedSubCat] = useState([]);
    const [selectedPhotos,setSelectedPhotos] = useState([])
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [loadingForm, setLoadingForm] = useState(false);
    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_YOUR_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_YOUR_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_YOUR_REGION,
    });

    useEffect(() => {
        dispatch(categoryAction());
        dispatch(regionAction());
        dispatch(editEventAction(id));
    }, []);

    // check budget is integer or not
    const budgetValidate = (budget) => {
        if (budget === '' || new RegExp(/^[0-9\b]+$/).test(budget)) { 
            setEventBudget(budget);
            setErrorvalidateBudget(false) }
        else{ 
            setErrorvalidateBudget(true)
        }
    }
    // // function for set address from google map.
    // function initializeMap() {
    //     if (navigator.geolocation) {
            
    //         const options = {
    //             center: { lat: Number(latitude), lng: Number(longitude) },
    //             zoom: 17,
    //             disableDefaultUI: true,
    //         };
    //         const map = new google.maps.Map(document.getElementById("map"), options);
    //         const marker = new google.maps.Marker({
    //             position: { lat: Number(latitude), lng: Number(longitude) },
    //             map: map,
    //             title: "My Location",
    //         });
    //         //     }
    //         // );
    //     } else {
    //         alert("Location is not supported by this browser.");
    //     }
    // }


    // useEffect(() => {
    //     window.addEventListener('load', () => initializeMap(latitude, longitude));
    // }, [latitude, longitude]);

    function initialize() {
        var input = document.getElementById('address');
        // to permmition for location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert('Geolocation is not supported by this browser.'); 
        }
        function showPosition(position) {
            setLatitude(position.coords.latitude); 
            setLongitude(position.coords.longitude);
        }

        const options = {
            componentRestrictions: { country: "aus" },
            fields: ["address_components", "geometry", "icon", "name"],
            // strictBounds: false,
            // types: ["establishment"],
        };        
        let autocomplete = new google.maps.places.Autocomplete(input,options);
        // Set initial restriction to the greater list of countries.
        autocomplete.setComponentRestrictions({
            country: ["aus"],
        });
        autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            setLatitude(place.geometry.location.lat);
            setLongitude(place.geometry.location.lng);
            setLocation(input.value)
        });
    }
    // useEffect(()=>{
    //     initialize();
    //     google.maps.event.addDomListener(window, 'load', initialize);
    // },[])

    useEffect(() => {
        initialize();
        window.addEventListener('load', initialize);
      
        return () => {
          window.removeEventListener('load', initialize);
        };
      }, []);

    //function for check category of subcatgeory and update it
    const handleClick  = (catid , subid) => {
        var obj = [...cat];
        if(subid){
            var data = cat.find((ele) => ele.category_id === catid);
            if (data === undefined) {
                obj.push({ category_id: catid });
            }
            $('#checkbox'+ catid ).prop('checked', true);
            var index = obj.findIndex(item => item.category_id === catid)
            if(obj[index].subcategory && obj[index].subcategory.length > 0){
                if(!obj[index].subcategory.includes(subid)){
                    obj[index].subcategory.push(subid); 
                }else{
                    obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
                    checkedSubcat.splice(checkedSubcat.indexOf(subid));
                }
            } else{
                obj[index].subcategory = [];
                obj[index].subcategory.push(subid);
            }
            handleCategory(obj);
        } else{
            var data = cat.find((ele) => ele.category_id === catid);
            if(data === undefined){
                obj.push({category_id : catid});
                $('#collapse' + catid + ' input:checkbox').prop('checked', true);
                $("#collapse" + catid).find('input:checkbox:checked').each(function () {
                    let subid = $(this).val();
                    var index = obj.findIndex(item => item.category_id === catid)
                    if (obj[index].subcategory && obj[index].subcategory.length > 0) {
                        if (!obj[index].subcategory.includes(subid)) {
                            obj[index].subcategory.push(parseInt(subid));
                        } else {
                            obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
                            checkedSubcat.splice(checkedSubcat.indexOf(subid));
                        }
                    } else {
                        obj[index].subcategory = [];
                        obj[index].subcategory.push(parseInt(subid));
                    }
                    handleCategory(obj);
                })
            }else{
                $('#collapse'+ catid +' input:checkbox').prop('checked', false);
                obj.splice(obj.findIndex(item => item.category_id === catid), 1)
            }
            handleCategory(obj);
        }
    }
    const handleCategory = (obj) => {
        setCat(obj)
    }
    
    // get and set category list form category api 
    const { category } = useSelector((state) => state.category);
     useEffect(() => {
         if (category && category?.datasss && category?.datasss?.data && category?.datasss?.data?.length > 0) {
             setCategory(category?.datasss?.data);
         }
    }, [category]);

    // get all the regions 
    const regionDtl = useSelector((state) => state.region.region);
    useEffect(() => {
        if (regionDtl && regionDtl?.datasss && regionDtl?.datasss?.status === true) {
            setRegionList(regionDtl?.datasss?.data);
        }
    }, [regionDtl]);

    // get event data via id and set into the variable
    const editEvent = useSelector((state) => state.editEvent.editEvent);
    useEffect(() => {
        if(editEvent && editEvent?.datasss && editEvent?.datasss?.status === true ){
            seteventDtl(editEvent?.datasss?.data)
            setEventCategory(editEvent?.datasss?.data?.event_category)
            let catArr = editEvent?.datasss?.data?.event_category;
            let tempArr = [];
            catArr.map((x)=>{
                if(x.event_sub_category && Array.isArray(x.event_sub_category) && x.event_sub_category.length > 0 ){

                    tempArr.push({category_id : x.category_id ,budget:x.budget, subcategory : x.event_sub_category.map((y)=>  y.subcategory_id)}) 
                    x.event_sub_category.map( async (y) =>  
                        handleClick(x.category_id,y.subcategory_id)
                    )
                }else{
                tempArr.push({category_id : x.category_id, budget:x.budget })
                    handleClick(x.category_id)
                }
            })
            setCat(tempArr)
            setEventName(editEvent?.datasss?.data?.event_name);
            setEventDescription(editEvent?.datasss?.data?.event_description);
            // console.log(editEvent?.datasss?.data?.event_budget)
            setEventBudget(editEvent?.datasss?.data?.event_budget);
            setEventType(editEvent?.datasss?.data?.event_type);
            setPhone_no(editEvent?.datasss?.data?.phone_no);
            setLocation(editEvent?.datasss?.data?.location);
            setLatitude(editEvent?.datasss?.data?.latitude);
            setLongitude(editEvent?.datasss?.data?.longitude);
            setRegion(editEvent?.datasss?.data?.region_id);
            setEventFile(editEvent?.datasss?.data?.event_file);
            setEventPhotos(editEvent?.datasss?.data?.event_photos);
        }
    }, [editEvent,categoryList]);


    // function for update event 
    const updateEvent = async (e) => {
        e.preventDefault();
        setLoadingForm(true);
        if (!event_name || !event_description  || event_type.length <= 0 || cat.length <= 0 ) {
            setLoadingForm(false);
            setError(true);
            return false;
        } else {
            let tempImage = []
            if(selectedPhotos){
                for (const file of selectedPhotos) {
                    const params = {
                        Bucket: 'staging-media',
                        Key: `event_photos/${file.file.name}`,
                        Body: file.file,
                        ContentType: file.type
                    };
                    try {
                        const data = await s3.upload(params).promise();
                        //videoArr.push(data.Location);
                        let obj = {
                            // type: file.type,
                            file: data.Location
                        }
                        tempImage.push(obj)
                    } catch (err) {
                    console.log('Error uploading file:', err);
                    return;
                    }
                }
            }
            dispatch(updateEventAction({
                event_id : id, 
                event_name : event_name, 
                event_description : event_description, 
                event_budget : event_budget, 
                location : location,
                latitude : latitude,
                longitude : longitude,
                region : region,
                event_type : event_type,
                phone_no : phone_no,
                event_category : cat,
                event_file : file, 
                event_photos : tempImage,
                deleteFile : delete_photos
            }));
            setLoadingForm(false);
        }
    };

    // select data from reducer(backend) and set toaster(message) here
    const updateEventDtl = useSelector((state) => state.updateEvent.updateEvent)
    useEffect(() => {
        if(updateEventDtl && updateEventDtl?.datasss && updateEventDtl?.datasss?.status === true ){
            toast.success(updateEventDtl?.datasss?.message);
            dispatch(clearUpdateEventMsg);
            navigate("/upcoming-events");
        }
        if(updateEventDtl && updateEventDtl?.datasss && updateEventDtl?.datasss?.status === false ){
            toast.error(updateEventDtl?.datasss?.message);
            dispatch(clearUpdateEventMsg);
        }
    }, [updateEventDtl]);

    // selet and set event file and convert into base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };
    const handleFileUpload = async (e) => {
        let file = e.target.files[0];
        let base64 = await convertToBase64(file);

        if (base64) {
            let base64Split = base64.split(';');
            let splitExtension = base64Split[0].split('/');
            // let splitType = splitExtension[0].split(':');
            if((splitExtension[1].toLowerCase() === 'png') || (splitExtension[1].toLowerCase() === 'jpg') || (splitExtension[1].toLowerCase() === 'jpeg') || (splitExtension[1].toLowerCase() === 'pdf')){
                setFile(base64);
            }else {
                toast('The file must be type of : jpeg,png,jpg,pdf');
            }
        }
    };

    // select multiple event photos
    const handleEventPhotos = async (e) => {
        let files = Array.prototype.slice.call(e.target.files);
        
        const fileArr = [...selectedPhotos]
        const tempUrl = [...photos]
        
        for(let file of files){
            let type = file.type
            let splitExtension = type.split('/');
            if((splitExtension[1].toLowerCase() === 'png') || (splitExtension[1].toLowerCase() === 'jpg') || (splitExtension[1].toLowerCase() === 'jpeg')){
                let obj = {
                    // type:'image',
                    file: file
                }
                fileArr.push(obj)
                setSelectedPhotos(fileArr)
                let url = URL.createObjectURL(obj.file);
                tempUrl.push(url);
                // setPhotos(tempUrl);

                let totalPhotos = [...tempUrl]
                setPhotos(totalPhotos);
                setIsRender(!isRender)
            }else {
                toast('The file must be type of : jpeg,png,jpg');
            }
        }
        // let totalPhotos = [...tempUrl]
        // setPhotos(totalPhotos);
        // setIsRender(!isRender)
    }

    useEffect(()=>{
        setIsRender(!isRender)
    },[photos , event_photos])

    const deleteImage = (event, index) => {   // delete image
        let temp = [...event_photos]
        let deleteImg = [...delete_photos]
        temp.splice(index, 1)
        setEventPhotos(temp)
        if (event.id) {
          deleteImg.push(event.id)
          setDeletePhotos(deleteImg);
        }
    }
    const deleteCurrentImage = (index, event)=>{
        let temp = [...selectedPhotos]
        temp.splice(index,1)
        setSelectedPhotos(temp)
        let newArr = photos.filter((id, idx)=>idx!==index);
        setPhotos(newArr);
    }

    const handleDiscription = (e) => {
        let tempDecData = e.target.value;
        if(tempDecData.length <= 5000){
            setEventDescription(e.target.value)
        }
    }

    // check phone no. is integer or not
    const PhoneNoValidate = (phoneNo) => {
        if (phoneNo === '' || new RegExp(/^[0-9\b]+$/).test(phoneNo)) {
            setPhone_no(phoneNo.replace(/[^0-9]/g, ''));
        }
    }
    // for event type checkbox
    const weddingCheck = () =>{
        setWedding(true);
        setOccasions(false);
        setParty(false);
        setCorporate(false);
        setEventType("Wedding")
    }
    const corporateCheck = () =>{
        setWedding(false);
        setOccasions(false);
        setParty(false);
        setCorporate(true);
        setEventType("Corporate")
    }
    const partyCheck = () =>{
        setWedding(false);
        setOccasions(false);
        setParty(true);
        setCorporate(false);
        setEventType("Party")
    }
    const dinnerCheck = () =>{
        setWedding(false);
        setOccasions(true);
        setParty(false);
        setCorporate(false);
        setEventType("Occasions")
    }

    const handleCategoryInput = (e, catid) => {
        const isChecked = $('#checkbox' + catid).is(':checked');
        const inputField = $('#categoryInput' + catid);
        const isDisabled = inputField.prop('disabled');
      
        if (isChecked && !isDisabled) {
          inputField.prop('disabled', false);
          const updatedCat = cat.map((item) => {
            if (item.category_id === catid) {
              return {
                ...item,
                budget: parseFloat(e.target.value)
              };
            }
            return item;
          });
      
          setCat(updatedCat);
        } else {
          inputField.prop('disabled', true);
          inputField.val('');
        }
    };
      

    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <ConsumerSidebar />
                        <div className="right-content-block">
                            <form onSubmit={updateEvent}>
                                <div className="right-top-text">
                                    <h3>create new event</h3>
                                    <div className="form-container event-form">
                                        <h4 className="common-label blue-text">event basic details</h4>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-fname">event name<span className="text-danger star-required">*</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Event Name"
                                                        className="form-control"
                                                        value={event_name }
                                                        onChange={(e) => setEventName(e.target.value)}
                                                    />
                                                    {error && !event_name && (
                                                        <span style={{ color: "red" }} className="invalidInput">
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="event-budget">$ event budget (optional)</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Event Budget"
                                                        className="form-control"
                                                        value={event_budget}
                                                        onChange={(e) => budgetValidate(e.target.value)}
                                                        name="event_budget"
                                                    />
                                                    {((errorvalidateBudget) || (errorvalidateBudget && error))&& (
                                                        <span style={{ color: "red" }} className="invalidInput" >
                                                            Invalid Amount
                                                        </span>
                                                    )}
                                                    {error && !event_budget && !errorvalidateBudget && (
                                                        <span style={{ color: "red" }} className="invalidInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group fadeInUp wow">
                                            <label htmlFor="location">regions</label>
                                            <select className="form-control" id="event-select" placeholder="-- Select region --" value={region}
                                                    onChange={(e) => setRegion(e.target.value)}>
                                                {regionList.map((data) => (
                                                    <option value={data.id}>{data.name}</option>
                                                ))}
                                            </select>
                                            {error && !region && (
                                                <span style={{ color: "red" }} className="invalidInput">
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group fadeInUp wow">
                                            <label htmlFor="location">ADDRESS (OPTIONAL)</label>
                                            <input type="text" id="address" placeholder="Enter Event Location" className="form-control"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)} 
                                                name='location' />
                                        </div>
                                        {/* <div className="map-img" id="map">
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                                                yesIWantToUseGoogleMapApiInternals
                                                center={{
                                                    lat: Number(latitude),
                                                    lng: Number(longitude)
                                                }}
                                                defaultZoom={17}
                                            >
                                            <Marker
                                                lat={Number(latitude)}
                                                lng={Number(longitude)}
                                            />
                                            </GoogleMapReact>
                                        </div> */}
                                        <div className="desc-sec fadeInUp wow">
                                            <div className="form-group">
                                                <label className="common-label" htmlFor="">Description <span className="small">{event_description?.length}/5000</span><span className="text-danger star-required">*</span></label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="..."
                                                    rows="3"
                                                    value={event_description}
                                                    onChange={handleDiscription}>
                                                </textarea>
                                                {error && !event_description && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                                <span className="small color">You can enter a maximum of <b>5000</b> characters in this description box.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-sec service-request service-request-event">
                                        <h4 className="common-label blue-text">services required<span className="text-danger star-required">*</span></h4>
                                    </div>
                                    <div className="event-sec fadeInUp wow ">
                                        <div className="form-group">
                                            <h4 className="common-label">EVENT TYPE<span className="text-danger star-required">*</span></h4>
                                            <ul>
                                                <li>
                                                    <div className="checkboxbtn">
                                                        <input type="checkbox" name="Wedding" value={"Wedding"} onChange={(e) => weddingCheck(e)} 
                                                        checked={event_type === 'Wedding' ? true : false }
                                                        />
                                                        <label htmlFor="checkbox1" className="checkbox-label">Wedding</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="checkboxbtn">
                                                        <input type="checkbox" name="Corporate" value={"Corporate"} onChange={(e) => corporateCheck(e)}
                                                        checked={event_type === 'Corporate' ? true : false }
                                                        />
                                                        <label htmlFor="checkbox2" className="checkbox-label">CORPORATE</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="checkboxbtn">
                                                        <input type="checkbox" name="Occasions" value={"Occasions"} onChange={(e) => dinnerCheck(e)}
                                                        checked={event_type === 'Occasions' ? true : false }/>
                                                        <label htmlFor="checkbox3" className="checkbox-label">Special Occasions</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="checkboxbtn">
                                                        <input type="checkbox" value={"Party"} onChange={(e) => partyCheck(e)}
                                                        checked={event_type === 'Party' ? true : false }/>
                                                        <label htmlFor="checkbox4" className="checkbox-label">PARTY</label>
                                                    </div>
                                                </li>
                                                {error && !event_type && (
                                                    <span style={{ color: "red" }} className="invalidInput">
                                                        Required*
                                                    </span>
                                                )} 
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="event-categories checkboxbtn fadeInUp wow ">
                                        <h4 className="common-label">services required<span className="text-danger star-required">*</span></h4>
                                        <div className="categories-accordion">
                                            <div className="accordion" id="accordionExample">
                                                {categoryList.map((x, i) => {
                                                return (
                                                    <>
                                                    <div className="accordion-item ">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                data-bs-target={"#collapse" + x.id} aria-expanded="true"
                                                                aria-controls="collapseOne">
                                                                <input type="checkbox" id={"checkbox" + x.id} onChange={()=>{handleClick(x.id)}}
                                                                checked={cat.find((ele) => ele.category_id === x.id) ? true : false }
                                                                value={cat}
                                                                />
                                                                <label className="checkbox-label" htmlFor={"checkbox" + x.id}>
                                                                    {x.category_name}
                                                                </label>
                                                            </button>
                                                        </h2>
                                                        
                                                        <div id={"collapse" + x.id} className="accordion-collapse collapse"
                                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <ul>
                                                                {x.sub_category.map((y) => {
                                                                     let tempArr = []
                                                                     let sub = cat.map((x)=>{ if(x.subcategory){
                                                                        tempArr= [...tempArr,...x.subcategory] 
                                                                     }})
                                                                return (
                                                                    <>
                                                                    <li>
                                                                        <input type="checkbox" value={y.id} id={"subcategory"+y.id} onChange={()=>{handleClick(x.id, y.id)}}
                                                                        checked={ tempArr.includes(y.id) }
                                                                        />
                                                                        <label htmlFor={"subcategory"+y.id} className="checkbox-label" >
                                                                            {y.subcategory_name}
                                                                        </label>
                                                                    </li>
                                                                    </>
                                                                    );
                                                                })}
                                                                </ul>
                                                                <div className="budget-option">
                                                                    <div className="min-sec min-max-sec">
                                                                        <span>Budget</span>
                                                                        <input className="form-control" id={"categoryInput" + x.id} value={cat.find((ele) => ele.category_id === x.id) ? cat.find((ele) => ele.category_id === x.id).budget : ''} name="budget" type="number" onChange={(e) => { handleCategoryInput(e,x.id) }} disabled={!$('#checkbox' + x.id).is(':checked')}/>
                                                                    </div>
                                                                    <span className="small color m-2"> ( Optional )</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    );
                                                })}
                                            </div>
                                            {error && !cat.length > 0 && (
                                                <span style={{ color: "red" }} className="invalidInput">
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="other-details event-budget-block photography-details">
                                        <h4 className="common-label blue-text">other details</h4>
                                        <div className="other-detail-form">
                                            <div className="other-detail-feild">
                                                <label>start date</label>
                                                <div className="d-flex">
                                                    <span><img src="/img/calenders_.svg" alt=""/></span>
                                                    <DatePicker value={eventDtl.event_from_date} readOnly/>
                                                </div>
                                            </div>
                                            <div className="other-detail-feild">
                                                <label>end date</label>
                                                <div className="d-flex">
                                                    <span><img src="/img/calenders_.svg" alt=""/></span>
                                                    {/* <input type="date" name="other-details-date"/> */}
                                                    <DatePicker value={eventDtl.event_to_date} readOnly/>
                                                </div>
                                            </div>
                                            <div className="other-detail-feild other-details-alternate">
                                                <label>contact phone no. (optional)</label>
                                                <span><img src="/img/contact.svg" alt=""/></span>
                                                <input type="text"
                                                    value={phone_no}
                                                    onChange={(e) => PhoneNoValidate(e.target.value)}
                                                    name="phone_no"
                                                    placeholder="Enter phone no."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-request">
                                        <h4 className="common-label blue-text">Event File (optional)</h4>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="profile-cover-section">
                                                    <div className="avatar-upload-sec profile-avtar-sec">
                                                        <span>
                                                            <img src="/img/Download.svg" alt="" />
                                                            <p className="mt-2">Drag & Drop your document</p>
                                                        </span>
                                                        <input 
                                                            type="file"
                                                            name="event_file"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            onChange={(e) => handleFileUpload(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="profile-cover-section">
                                                    <div className="avatar-upload-sec profile-avtar-sec">
                                                        <span>
                                                            <iframe src={file ? file : event_file ? event_file : ""} alt="" style={{height:'100%',opacity: 0.7}} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-request">
                                        <h4 className="common-label blue-text">Event Photo's</h4>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="profile-cover-section">
                                                    <div className="avatar-upload-sec profile-avtar-sec">
                                                        <span>
                                                            <img src="/img/Download.svg" alt="" />
                                                            <p className="mt-2">Drag & Drop event photos</p>
                                                        </span>
                                                        <input
                                                            type="file"
                                                            name="event_photos"
                                                            accept=".jpeg,.png,.jpg"
                                                            onChange={(e) => handleEventPhotos(e)}
                                                            multiple
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="vendor-photography portfolio-photos fadeIn wow">
                                                    {/* {event_photos?.length > 0 || photos?.length > 0 && ( */}
                                                        <div className="vendor-event-carousel fadeIn wow" >
                                                            <OwlCarousel id="owl-carousel-one" className="owl-carousel owl-theme owl-loaded owl-drag" margin={20}>
                                                                {photos.map((photo, index) => {
                                                                    return (<>
                                                                        <div className="item image" key={index}>
                                                                        <a href="#" className="portfolio-slider">
                                                                            <img src={photo} style={{ height: '100%', opacity: 0.7 }} alt="viewImage" />
                                                                            <i className="fa fa-close" onClick={() => deleteCurrentImage(index)}></i>
                                                                            {/* <span onClick={() => deleteCurrentImage(index)} >&times;</span> */}
                                                                        </a>
                                                                        </div>
                                                                    </>)
                                                                })}
                                                                {event_photos?.map((photo, index) => {
                                                                    return (
                                                                        <>
                                                                        <div className='item' key={photo.id}>
                                                                            <a href="#" className="portfolio-slider">
                                                                                <img src={photo.photos} alt="vendorimage" />
                                                                                <i className="fa fa-close" onClick={() => deleteImage(photo, index)}></i>
                                                                            </a>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    {/* )} */}
                                                </div>
                                                {/* <div className="profile-cover-section">
                                                    <div className="avatar-upload-sec profile-avtar-sec">
                                                    <output>
                                                            {event_photos.map((event,index) => {
                                                                return(<>
                                                                    <div className="image">
                                                                        { event.photos ?
                                                                        <img src={event.photos} style={{height:'100%',opacity: 0.7}} alt="image"/> :
                                                                        <img src={event.selectedPhotos} style={{height:'100%',opacity: 0.7}} alt="image"/> }
                                                                            <span onClick={() => deleteImage(index, event)} >&times;</span>
                                                                    </div>
                                                                </>)
                                                            })}
                                                            {photos.map((photo,index) => {
                                                                return(<>
                                                                    <div className="image">
                                                                        <img src={photo} style={{height:'100%',opacity: 0.7}} alt="image" />
                                                                        <span onClick={() => deleteCurrentImage(index)} >&times;</span>
                                                                    </div>
                                                                </>)
                                                            })}
                                                    </output>
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-md-3">
                                                    <div className="profile-cover-section">
                                                        <div className="avatar-upload-sec profile-avtar-sec">
                                                            <span>
                                                                <img src="/img/Download.svg" alt="" />
                                                                <p className="mt-2">Drag & Drop event photos</p>
                                                                </span>
                                                            <input 
                                                                type="file"
                                                                name="event_photos"
                                                                accept=".jpeg, .png, .jpg"
                                                                onChange={(e) => handleEventPhotos(e)}
                                                                multiple
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-event-btn">
                                        {(loadingForm) ? (
                                            <>
                                                <button type="submit" className="btn" disabled >
                                                    <div className={"btn-loader"}>
                                                        <img src="/img/loader.svg" className="loader-svg-img"  />
                                                    </div>
                                                </button>
                                            </>
                                        ) : (
                                            <button type="submit" className="btn btn-fill btn-info">
                                                Update Event
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    );
};

// export default EditEvent;
export default EditEvent;
