import React,{useState,useEffect} from "react";
import Loader from 'react-js-loader';
import { useDispatch, useSelector } from "react-redux";
import { Link} from "react-router-dom";
import VendorSidebar from "./includes/sidebar";
import VendorHeader from "./includes/header";
import VendorFooter from "../includes/footer";
import vendorEventsAction from "../../actions/vendorEventsAction";
import moment from "moment";


const VendorEvents = () => {

    const [vendorEventsList, setVendorEventsList] = useState([]);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [eventDecription,setEventDecription] = useState([])

    useEffect(() => {
        // setLoading(true);
        // setTimeout(() => { setLoading (false); },1000);

        dispatch(vendorEventsAction());
    }, []);

    const vendorEventInfo = useSelector((state) => state.vendorEvents.vendorEvents);
    useEffect(() => {
        if (vendorEventInfo && vendorEventInfo.datasss && vendorEventInfo.datasss.status === true) {
            setVendorEventsList(vendorEventInfo.datasss.data);
            const eventDescription = vendorEventInfo.datasss.data.event_description;
            const descriptionLines = eventDescription ? eventDescription.split('\n') : [];
            setEventDecription(descriptionLines)
        }
        setLoading(false);
    }, [vendorEventInfo]);

    // get budget comma separate
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleLocationClick = (loc) => {
        if (loc) {
          const location = encodeURIComponent(loc);
          const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;
          window.open(googleMapsUrl, "_blank");
        }
      };

    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className="sidebar-content-wr consumer-block vendor_events">
                            <div className="container">
                                <VendorSidebar />
                                <div className="right-content-block">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="right-top-text mb-4">
                                            <h3>Event Proposals</h3>
                                        </div>
                                        {(vendorEventsList.length > 0) ?  (
                                            <div>
                                                {vendorEventsList.map((data) => {
                                                    let totalBudget = 0;
                                                    if (data.category) {
                                                        data.category.forEach(x => {
                                                          totalBudget += x.budget;
                                                        });
                                                    }
                                                    
                                                    return(
                                                    <div key={data.id}>
                                                        {/* <div className="joshn-wedding">
                                                            <div className="banner-inner-block nancy-pool">
                                                                <div className="joshn-weds-inner">
                                                                    <h4>{data.event_name}</h4>
                                                                    <div className="videography-block">
                                                                        <p>Event Type : ( {data.event_type ?? '-'} )</p>
                                                                    </div>
                                                                    <div className="event-date-sec">
                                                                        <div className="event-block">
                                                                            <img src="./img/ic_baseline-date-range.svg" alt=""/>
                                                                            <span>Event Date:</span>
                                                                        </div>
                                                                        <p>{data.event_from_date+' - '+data.event_to_date}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="event-budget-block">
                                                                    <ul>
                                                                        <li><span><img src="./img/Frame.svg" alt=""/></span>Event Budget:<p>{(data.event_budget) ? '$ ' + numberWithCommas(data.event_budget) : '-' } </p>
                                                                        </li>
                                                                        <li><span><img src="./img/ep_location.png" alt=""/></span>Location:<p>{data.location},<strong className="countryColor"> Australia</strong></p>
                                                                        </li>
                                                                        <li><span> <img src="./img/carbon_user-multiple.svg" alt=""/></span>Number of Guests:<p>{(data.num_of_guest) ?? '-'}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="event-brief-block common-description">
                                                                    <div className="event-brief">
                                                                        <h4 className="common-label">event brief</h4>
                                                                        <p className="paragraph event-description">{data.event_description}</p>
                                                                    </div>
                                                                    <div className="event-document">
                                                                        <h4 className="common-label">event documents</h4>
                                                                        <div className="documents-downloads">
                                                                            <a href="#" id="download-document"><img src="./img/Frame_.svg" alt=""/>
                                                                                <p>Download Document</p>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="event-listing-type-date">
                                                            <div className="event-listing-type">
                                                                <ul>
                                                                    <li>{data.event_name}</li>
                                                                    <li>Event Type : {data.event_type ?? '-'}</li>
                                                                </ul>
                                                            </div>
                                                            
                                                            <div className="event-listing-date">
                                                                <span><img src="./img/ic_baseline-date-range.svg"/></span>
                                                                <p>Event Date:</p>
                                                                <p>{moment(data.event_from_date).format('DD-MM-YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="event-listing-budget-block">
                                                            <div className="event-listing-budget-inner">
                                                                <span><img src="img/Frame.svg"/></span>
                                                                <h5>Event Budget Total:</h5>
                                                                {/* <p>{(data.event_budget) ? '$ ' + numberWithCommas(data.event_budget) : '-' } </p> */}
                                                                <p>{totalBudget ? '$' + numberWithCommas(totalBudget) : 'N/A' } </p>
                                                            </div>
                                                            <div className="event-listing-budget-inner">
                                                                <span><img src="img/location_icon.svg"/></span>
                                                                <h5>Location:</h5>
                                                                <a href="#" onClick={()=>handleLocationClick(data?.location)}>{data?.location ? data?.location  : 'NA'}</a>
                                                            </div>
                                                            <div className="event-listing-budget-inner">
                                                                <span><img src="./img/carbon_user-multiple.svg"/></span>
                                                                <h5>Number of Guests:</h5>
                                                                <p>{(data?.num_of_guest) ? data?.num_of_guest : 'N/A' }</p>
                                                            </div>
                                                        </div>
                                                        {data.category?.length > 0 ? <>
                                                            <div className="event-listing-service-block">
                                                                <div className="event-listing-service-head">
                                                                    <span><img src="img/Frame.svg"/></span>
                                                                    <h5>Vendor Required:</h5>
                                                                </div>
                                                                <div className="event-listing-service-list">
                                                                    {data.category.map((x , index) => {
                                                                        return <>
                                                                            <ul>
                                                                                <li key={index}>{x.category.category_name} {(x.budget) ? '- $' + numberWithCommas(x.budget)+' budget' : '- Message for budget' }</li>
                                                                            </ul>
                                                                        </>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </> : null}
                                                        <div className="event-brief-description-block">
                                                            <div className="event-brief-description-head">
                                                                <h4 className="common-label">event brief</h4>
                                                            </div>
                                                            <div className="event-brief-description-box">
                                                                {/* <p>{data.event_description}</p> */}
                                                                {

                                                            data && data.event_description.split('\n').map((line,index)=>{
                                                                return (
                                                                        <span key={index}>{line}<br/></span>
                                                                    )
                                                                    })
                                                                }
                                                                <Link to={`/event-details/${data.id}`} className="btn">View details</Link>
                                                            </div>
                                                        </div>
                                                        <hr style={{marginTop : "80px" , marginBottom:'80px', borderTop: '1px solid', opacity:'unset'}}/>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        ): (
                                            <div>
                                                <p  align={'center'}> No Recent Proposals Available </p>
                                            </div>
                                        )}
                                        {/* <div className="load-more">
                                            <a href="#" className="btn">Load More</a>
                                        </div> */}
                                        {/* {(vendorEventsList?.length > 0) && (
                                            <div className="fav-vendor-pagination paginatin-sec fadeInUp wow" 
                                            // style="visibility: visible; animation-name: fadeInUp;"
                                            >
                                                <nav className="data-pagination">
                                                    <a href="#" className="arrow"><i className="fa fa-chevron-left"></i></a>
                                                    <ul>
                                                        <li className="current active"><a href="#1">1</a></li>
                                                        <li><a href="#2">2</a></li>
                                                        <li><a href="#3">3</a></li>
                                                        <li><a href="#10">…</a></li>
                                                        <li><a href="#41">8</a></li>
                                                    </ul>
                                                    <a href="#" className="arrow"><i className="fa fa-chevron-right"></i></a>
                                                </nav>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <VendorFooter />
                    </div>
                </>
            )}
        </>
    );
};

export default VendorEvents;
