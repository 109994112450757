import { commonService } from "../services/common.service";
import { VendorReviewsConst } from "../constants/user.constants";

const vendorReviewsAction = () => async(dispatch) => {
    try{
        const vendorReviews = await commonService.withLogin('/review-list');
        if (vendorReviews) {
            dispatch({ type: VendorReviewsConst.GET_VENDORREVIEWS_SUCCESS, payload: { datasss: vendorReviews.data } });
        }
        else {
            dispatch({ type: VendorReviewsConst.GET_VENDORREVIEWS_SUCCESS, payload: { data: vendorReviews.data } });
        }
    }catch(error){
        dispatch({ type: VendorReviewsConst.GET_VENDORREVIEWS_FAILURE, payload: error.message })
    }
}
export default vendorReviewsAction;
