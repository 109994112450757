import React, { useEffect, useState } from "react";
import ConsumerSidebar from "./includes/sidebar";
import ConsumerHeader from "./includes/header";
import ConsumerFooter from "../includes/footer";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import favouriteVendorAction from "../../actions/favouriteVendorAction";
import vendorFavUnfavAction from "../../actions/vendorFavUnfavAction";

const FavouriteVendor = () => {

    const [favVendorList, setFavVenList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(favouriteVendorAction());
    }, []);
    
    // Get and Set the all vendor info in input field form action
    const favVenInfo = useSelector((state) => state.favouriteVendor.favouriteVendor);
    useEffect(() => {
        if (favVenInfo && favVenInfo.datasss && favVenInfo.datasss.status === true) {
            setFavVenList(favVenInfo.datasss.data);
        }
    }, [favVenInfo]);
    
    // redirect vendor detail start
    const getVendorDetails = (slug) => {
        navigate('/vendor/' + slug);
    }

    // Function for unfavourite the vendor.
    const unFav = (Id)  => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to unfavourite them?",
            icon: "question",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(vendorFavUnfavAction({vendor_id:Id}));
                Swal.fire({
                    title: "Done!",
                    text: 'Vendor Successfully Disliked',
                    icon: "success",
                    button: false
                })
            }
            dispatch(favouriteVendorAction());
        });
    };

    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <ConsumerSidebar />
                        <div className="right-content-block">
                            <div className="right-top-text">
		  			            <h3>favourite vendors</h3>
                            </div>
                            <div className="fav-vendors-wr page-heading">
                                {(favVendorList?.length > 0) ? (
                                    favVendorList?.map((fav) => {
                                        let result = fav.category.map(cat => cat.category?.category_name).join(", ")
                                        //console.log('test', result)
                                        return (
                                            
                                            <div key={fav.id} className="fav-vendors-block">
                                                <div className="musician-location-block">
                                                    <h6>Starting at  {fav.starting_price ? '$'+fav.starting_price : 'NA'}</h6>
                                                    <h6>{fav.sofarYear}</h6>
                                                </div>
                                                <div className="musician-location-block location-block">
                                                    <h6>{fav.state}, Aus</h6>
                                                    <h6>{(fav.rating) ? (fav.rating) : 0}</h6>
                                                </div>
                                                <div className="fav-vendors-name-para discription">
                                                    <h3 className="common-label">{fav.first_name} {fav.last_name}</h3>
                                                    <h6 style={{fontSize:'11px'}}>{result}</h6>
                                                       
                                                    <p>{fav.profile_description ? fav.profile_description : '...'}</p>
                                                </div>
                                                <div className="fav-vendors-btn">
                                                    <div className="learn-more-btn">
                                                        {/* <a href="#" >Learn More</a> */}
                                                        <a style={{ cursor: "pointer" }} onClick={() => getVendorDetails(fav.slug)}>View Profile</a>
                                                    </div>
                                                    <div className="heart-btn">
                                                        <a style={{ cursor: "pointer" }} onClick={()=>{unFav(fav.id)}}>
                                                            <img src="img/heart_icon.svg" alt="Heart Icon"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        );
                                    })
                                ) : (
                                    <div className="col-md-12 col-12">
                                        <div className="finaliseEvent">
                                            <div className="finaliseCard">
                                                <p className="finalContent text-center">No favourite vendors yet. </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {(favVendorList?.length > 0) && (
                                <div className="fav-vendor-pagination paginatin-sec fadeInUp wow" 
                                // style="visibility: visible; animation-name: fadeInUp;"
                                >
                                    <nav className="data-pagination">
                                        <a href="#" className="arrow"><i className="fa fa-chevron-left"></i></a>
                                        <ul>
                                            <li className="current active"><a href="#1">1</a></li>
                                            <li><a href="#2">2</a></li>
                                            <li><a href="#3">3</a></li>
                                            <li><a href="#10">…</a></li>
                                            <li><a href="#41">8</a></li>
                                        </ul>
                                        <a href="#" className="arrow"><i className="fa fa-chevron-right"></i></a>
                                    </nav>
                                </div>
                            )} */}
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    );
};

export default FavouriteVendor;