import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import consumerProfileAction from '../../../actions/consumerProfileAction';

const ConsumerSidebar = () => {

  const dispatch = useDispatch();
  const [consumerInfo, setConsumerInfo] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('userData'));
  const path = window.location.pathname;
   
  // Get vendor all information
  const consumerProfile = useSelector((state) => state.consumerProfile.consumerProfile);
  useEffect(() => {
      if (consumerProfile && consumerProfile.datasss && consumerProfile.datasss.status === true) {
          setConsumerInfo(consumerProfile.datasss.data);
      }
  }, [consumerProfile]);

  useEffect(() => {
    const toggleSidebar = () => {
      $(".left-sidebar").toggleClass("sidebar-toggle");
    };
  
    const handleReviewerBlockClick = () => {
      $("html, body").animate(
        {
          scrollTop: $(".user-review-right").offset().top,
        },
        1000
      );
    };
  
    const handleClickOutside = (event) => {
      const sidebar = $(".left-sidebar");
      if (!sidebar.is(event.target) && sidebar.has(event.target).length === 0) {
        sidebar.removeClass("sidebar-toggle");
      }
    };
  
    $(".sidebar-mob-menu").click(toggleSidebar);
    $(".reviewer-block").click(handleReviewerBlockClick);
    $(document).on("click", handleClickOutside);
  
    return () => {
      $(".sidebar-mob-menu").off("click", toggleSidebar);
      $(".reviewer-block").off("click", handleReviewerBlockClick);
      $(document).off("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="left-sidebar">
        <div className="sidebar-inner">
          <div className="sidebar-mob-menu"></div>
          <div className='sidemenu-com'>
            <div className="sidebar-img">
                <img src={(consumerInfo?.profile) ? consumerInfo?.profile : "./img/default-profile-picture.png"} alt="Profile"/>
                {/* <p>{(consumerInfo) ? (consumerInfo.first_name+' '+consumerInfo.last_name) : 'None'}</p> */}
            </div>
            <div className="sidebar-icon-block">
              <div className="sidebar-icon">
                  <ul>
                      <li >
                          <Link className={(path === '/consumer-home') ? 'active' :''}  to="/consumer-home" title="Home Page"><img src="/img/home.png" alt="Sidebar icon"/></Link>
                      </li>
                      <li>
                          <Link className={(path === '/consumer-dashboard') ? 'active' :''} to="/consumer-dashboard" title="Dashboard"><img src="/img/dashboard.svg" alt="Sidebar icon"/></Link>
                      </li>
                      <li>
                          <Link className={(path === '/upcoming-events') ? 'active' :''} to="/upcoming-events" title="Recent Events"><img src="/img/recent-events.png" alt="Sidebar icon"/></Link>
                      </li>
                      <li>
                          <Link  className={(path === '/consumer-chats') ? 'active' :''} to="/consumer-chats" title="Messages"><img src="/img/chat-box-30.png" alt="Sidebar icon"/></Link>
                      </li>
                      <li>
                          <Link  className={(path === '/favourite-vendor-list') ? 'active' :''} to="/favourite-vendor-list" title="Favourite Vendor"><img src="/img/favourite.png" alt="Sidebar icon"/></Link>
                      </li>
                      <li>
                          <Link  className={(path === '/consumer-reviews') ? 'active' :''} to="/consumer-reviews" title="Reviews"><img src="/img/reviews-30.png" alt="Sidebar icon"/></Link>
                      </li>
                      {/* <li>
                          <a className="" href="javascript:;"><img src="/img/sidebar-icon-6.svg" alt="Sidebar icon"/></a>
                      </li> */}
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsumerSidebar;