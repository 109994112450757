// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth,setPersistence, browserSessionPersistence,onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getMessaging} from "firebase/messaging";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage();
export default app;

// export const messaging = getMessaging(app);
    // Set persistence to 'none' to force registration on each sign-in
// setPersistence(auth, browserSessionPersistence);
// export const fetchToken = async (setToken) => {
//   await getToken(messaging, { vapidKey: 'BFDaVWthCZUkwk6W0BYLKwHudfCyfiJhzx74AnGsHsAtL75RV4_eCX7qaOXcenxL1DY79yZABS3vWhjlkQsypGQ' }).then((currentToken) => {
//     if (currentToken) {
//       console.log('currentToken',currentToken);
//       setToken(currentToken)
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//     }
//   }).catch((err) => {
//     alert('An error occurred while retrieving token. ', err);
//   });
// }
export const stateChange = onAuthStateChanged (auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    // ...
  } else {
    console.log("firebase user logout!!")
  }
});
