import { googleConstants } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const authenticationGoogle = (datass) => async (dispatch) => {
  try {
    const userdata = await commonService.withOutToken(`/login/google`, datass);
    if (userdata) {
      if (userdata.data.status === 2) {
        dispatch({
          type: googleConstants.GET_GOOGLE_FAILURE,
          payload: { datasss: userdata.data },
        });
      } else {
        if (userdata.data.status) {
          localStorage.setItem("userData", JSON.stringify(userdata));
          dispatch({
            type: googleConstants.GET_GOOGLE_SUCCESS,
            payload: { datasss: userdata.data },
          });
        } else {
          localStorage.setItem("googleLogin", JSON.stringify(datass));
        }
      }
    } else {
      dispatch({
        type: googleConstants.GET_GOOGLE_FAILURE,
        payload: { datasss: userdata.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error.message}`);
    dispatch({
      type: googleConstants.GET_GOOGLE_FAILURE,
      payload: error.message,
    });
  }
};

export const clearGoogleLogin = () => async (dispatch)=>{
  try{
    dispatch({type : googleConstants.CLEAR_GOOGLE_LOGIN , payload : []})
  } catch(err){
    console.log(`Error while calling login api ${err}`);
  }
}

export default authenticationGoogle;
