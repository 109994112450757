import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        <Header />
        <section className="privacy-policy-banner">
          <div className="privacy-policy-banner-img">
            <figure>
              <img src="img/privacy_policy_banner.jpg" alt="Privacy Policy" />
            </figure>
          </div>
          <div className="privacy-policy-banner-content">
            <div className="container">
              <div className="privacy-policy-heading">
                <h1>TERMS AND CONDITIONS</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="privacy-policy-wr">
          <div className="container">
            <div className="privacy-policy-content">
              <div className="privacy-policy-text-block">
                <ol>
                  <li>
                    <h3>WEBSITE TERMS AND CONDITIONS</h3>
                  </li>
                  <p class="pt-1">
                    This website <b>(Website)</b> is operated by Ozgigs Global Pty Ltd (ACN 659 327 849) (we, our or us).
                  </p>
                  <li>
                    <h3>CONSENT</h3>
                  </li>


                  <p class="pt-1">
                    By accessing and/or using our Website, you agree to these terms and conditions and our Privacy
                    Policy (available on our Website) <b>(Terms)</b>. Please read these Terms carefully and immediately stop
                    using our Website if you do not agree to them.
                  </p>


                  <li>
                    <h3>VARIATIONS</h3>
                  </li>
                  <p class="pt-1">
                    We may, at any time and at our discretion, vary these Terms by publishing the varied terms on our
                    Website. We recommend you check our Website regularly to ensure you are aware of our current
                    terms. Materials and information on this Website <b>(Content)</b> are subject to change without notice.
                  </p>
                  <li>
                    <h3>LICENCE TO USE OUR WEBSITE</h3>
                  </li>
                  <p class="pt-1">
                    We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our
                    Website in accordance with these Terms. All other uses are prohibited without our prior written
                    consent.
                  </p>
                  <p class="pt-1">
                    This Website and its contents are subject to copyright which is owned by us or a third party. We do
                    not grant you any intellectual property rights in this Website, its applications, interface or contents
                    other than the licence referred to above. You must not use any trademark displayed on this Website.
                  </p>
                  <p class="pt-1">
                    We cannot guarantee any file, data or program available for download from this Website (or any
                    linked website) is free of viruses and you assume the risk of any damage to your computer as a result
                    of using this Website. This Website may be inaccessible from time to time due to events outside our
                    control or maintenance requirements.
                  </p>
                  <p class="pt-1">
                    We may use cookies to gather data in relation to this Website and you consent to us doing so
                    (although you may be able to disable cookies on your web browser).
                  </p>
                  <li>
                    <h3>PROHIBITED CONDUCT</h3>

                   <p class="pt-1">By accessing our Website, you must not do or attempt to do anything that is unlawful, prohibited by
                    any laws applicable to our Website, anything which we would consider inappropriate, or which might
                    bring us or our Website into disrepute, including (without limitation): </p> 

                      <ul className="alpha">
                        <li>
                          anything that would constitute a breach of an individual’s privacy (including uploading private
                          or personal information without an individual's consent) or any other legal rights
                        </li>
                        <li>
                          defaming, harass, threaten, menace or offend any person
                        </li>
                        <li>
                          interfering with any user using our Website
                        </li>
                        <li>
                          tampering with or modifying our Website, including but not limited to knowingly transmitting
                          viruses or other disabling features, or damaging or interfering with our Website by using trojan
                          horses, viruses or piracy or programming routines that may damage or interfere with our
                          Website
                        </li>
                        <li>
                          using our Website to send unsolicited email messages or
                        </li>
                        <li>
                          facilitating or assisting a third party to do any of the above acts.
                        </li>
                      </ul>
                
                  </li>
                  <li>
                    <h3>INFORMATION</h3>
                  </li>
                  <p class="pt-1">
                    The Content is not comprehensive and is for general information purposes only. While we use
                    reasonable attempts to ensure the accuracy and completeness of the Content, we make no
                    representation or warranty in relation to it, to the maximum extent permitted by law.
                  </p>
                  <p class="pt-1">
                    While the information and material contained on the Website is believed to be accurate and current, itis provided by us in good faith on an "as is" basis, and we and our directors, officers, employees,
                    contractors and agents accept no responsibility for and make no representations or warranties to you
                    or to any other person as to the reliability, accuracy or completeness of the information contained on
                    the Website.
                  </p>
                  <p class="pt-1">
                    The information contained on the Website does not constitute advice and we exclude liability for loss
                    or damage arising from any errors or omissions in this website or your use of this website (including
                    any interference with or damage to your computer system). You acknowledge that it is not reasonable
                    for you in the circumstances to have, and you are not relying on the information on the Website and
                    will be relying on your own enquiries and advice in deciding whether the listed products and services
                    are right for you. We accept no responsibility and will not be liable for any harm, loss and/or damage
                    that you or any third party may suffer, directly or indirectly, as a result of any information contained on
                    our Website being inaccurate, incomplete, unsuitable or incorrect.
                  </p>
                  <p class="pt-1">
                    We provide no warranty as to, and to the extent permitted by law expressly disclaim any liability for
                    harm, loss and/or damage that you or any third party may suffer, directly or indirectly, as a result of a
                    product or service listed on the Website being unsafe, unsuitable for you or them or unfit for your or
                    their purposes and/or use, because of your or their particular circumstances or condition, and/or the
                    product or service not providing an expected result, even if you disclosed that purpose to us before
                    purchasing the product or service, as you acknowledge that you are not relying on our skill or
                    judgement.
                  </p>
                  <p class="pt-1">
                    Prices listed on this Website and terms and conditions of any kind relating to products or services
                    displayed on this site may not be current or complete and therefore under no circumstances constitute
                    an offer or undertaking by us. We make no representation that the products or services listed in this
                    site are currently available.
                  </p>
                  <li>
                    <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                    <p class="pt-1">
                      We own or licence all rights, title and interest (including intellectual property rights) in our Website and
                      all of the Content. Your use of our Website and your use of and access to any Content does not grant
                      or transfer to you any rights, title or interest in relation to our Website or the Content other than as
                      stated herein. You must not:
                    </p>
                      <ul className="alpha">
                        <li>
                          copy or use, in whole or in part, any Content
                        </li>
                        <li>
                          reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any content
                          to any third party or
                        </li>
                        <li>
                          breach any intellectual property rights connected with our Website or the Content, including
                          (without limitation) altering or modifying any of the Content, causing any of the content to be
                          framed or embedded in another website or platform, or creating derivative works from the
                          Content.
                        </li>
                      </ul>
                  </li>
                    
                  <li>
                    <h3>USER CONTENT</h3>
                    <p class="pt-1">
                    You may be permitted to post, upload, publish, submit or transmit relevant information and content
                    <b>(User Content)</b> on or through our Website. By making available any User Content on or through our
                    Website, you grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free
                    licence to use the User Content, with the right to use, view, copy, adapt, modify, distribute, license,
                    sell, transfer, communicate, publicly display, publicly perform, transmit, stream, broadcast, access, or
                    otherwise exploit such User Content on, though, or by means of our Website.</p>
                    <p class="pt-1">
                      You agree that you are solely responsible for all User Content that you make available on or through
                      our Website. You represent and warrant that:
                    </p>
                   
                      <ul className="alpha">
                        <li>
                          you are either the sole and exclusive owner of all User Content or you have all rights,
                          licences, consents and releases that are necessary to grant to us the rights in such User
                          Content (as contemplated by these terms)
                        </li>
                        <li>
                          you will not post or transmit any User Content which is libellous or defamatory, or which
                          discloses private or personal matters concerning any person. You may not post or transmit
                          any message, data, image or program that is indecent, obscene, pornographic, harassing,threatening, abusive, hateful, racially or ethnically offensive; that encourages conduct that
                          would be considered a criminal offense, give rise to civil liability or violate any law or that is
                          otherwise inappropriate
                        </li>
                        <li>
                          you will not copy or use personal identifying or business contact information about other users
                          without their permission. Unsolicited e-mails, mailings, telephone calls, or other
                          communications to individuals or companies whose contact details you obtain through the
                          Website is prohibited
                        </li>
                        <li>
                          we may use any feedback, suggestions, or ideas you post in any way, including in future
                          modifications of the Website, other products or services, advertising or marketing materials
                          and
                        </li>
                        <li>
                          neither the User Content nor the posting, uploading, publication, submission or transmission
                          of the User Content or our use of the User Content on, through or by means of our Website
                          will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of
                          publicity or privacy, or result in the violation of any applicable law or regulation.
                        </li>
                      </ul>
                      <p class="pt-1">
                        We do not endorse or approve, and are not responsible for, any User Content. We may, at any time
                        (at our sole discretion), remove any User Content.
                      </p>
                  </li>
                
                  <li>
                    <h3>THIRD PARTY SITES</h3>
                  </li>
                  <p class="pt-1">
                    Our Website may contain links to websites operated by third parties. Unless expressly stated
                    otherwise, we do not control, endorse or approve, and are not responsible for, the content on those
                    websites. You should make your own investigations with respect to the suitability of those websites.
                  </p>
                  <p class="pt-1">
                    Any information, advertisements or links to third party sites are purely for information purposes only
                    and are not endorsements by us as to the quality, accuracy or completeness of the data, information
                    or sites.
                  </p>
                  <p class="pt-1">
                    If you decide to access any of the third party websites advertised or linked, you do so entirely at your
                    own risk and, as far as lawfully possible, we accept no liability or responsibility.
                  </p>
                  <li>
                    <h3>DISCONTINUANCE</h3>
                  </li>
                  <p class="pt-1">
                    We may, at any time and without notice to you, discontinue our Website, in whole or in part. We may
                    also exclude any person from using our Website, at any time and at our sole discretion. We are not
                    responsible for any liability you may suffer arising from or in connection with any such discontinuance
                    or exclusion.
                  </p>
                  <li>
                    <h3>WARRANTIES AND DISCLAIMERS</h3>
                  <p class="pt-1">
                    To the maximum extent permitted by law, we make no representations or warranties about our
                    Website or the Content, including (without limitation) that:</p>
                  
                      <ul className="alpha">
                        <li>
                          they are complete, accurate, reliable, up-to-date and suitable for any particular purpose
                        </li>
                        <li>
                          access will be uninterrupted, error-free or free of viruses or
                        </li>
                        <li>
                          our Website will be secure.
                        </li>
                      </ul>
                  
                    <p class="pt-1">
                      You read, use and act on our Website and the Content at your own risk
                    </p>
                  </li>
                  
                  <li>
                    <h3>LIMITATION OF LIABILITY</h3>
                  </li>
                  <p class="pt-1">
                    To the maximum extent permitted by law, we are not responsible for any loss, damage or expense,
                    howsoever arising, whether direct or indirect and/or whether present, unascertained, future or
                    contingent suffered by you or any third party, arising from or in connection with your use of our
                    Website and/or the Content and/or any inaccessibility of, interruption to or outage of our Website and/
                    or any loss or corruption of data and/or the fact that the Content is incorrect, incomplete or out-of-
                    date.
                  </p>
                  <p class="pt-1">
                    In no event will we be liable to any party for any direct, indirect, incidental or consequential damages
                    or losses whatsoever arising from access to, reliance on, or use of or downloading of information from
                    the Website, (or any associated or linked online service), including, without limitation, damages for
                    loss of profits, business interruption, loss or information or damage to systems due to viruses or other
                    harmful components.
                  </p>
                  <li>
                    <h3>RELEASE</h3>
                  </li>
                  <p class="pt-1">
                    You release us, ours servants and our agents to the fullest extent permitted by law from any and all
                    claims arising out of or related to the use of material or information made available through this
                    Website.
                  </p>
                  <li>
                    <h3>TERMINATION</h3>
                  </li>
                  <p class="pt-1">
                    These Terms are effective until terminated by us, which we may do at any time and without notice to
                    you. In the event of termination, all restrictions imposed on you by these Terms and limitations of
                    liability set out in these Terms will survive.
                  </p>
                  <li>
                    <h3>DISPUTES</h3>
                  <p class="pt-1">
                    In the event of any dispute arising from, or in connection with, these Terms (Dispute), the party
                    claiming there is a Dispute must give written notice to the other party setting out the details of the
                    Dispute and proposing a resolution. Within 7 days after receiving the notice, the parties must, by their
                    senior executives or senior managers (who have the authority to reach a resolution on behalf of the
                    party), meet at least once to attempt to resolve the Dispute in good faith. All aspects of every such
                    conference, except the fact of the occurrence of the conference, will be privileged.
                  </p>
                  <p class="pt-1">
                    If the relevant Parties are unable to resolve the Dispute with 15 Business Days, any Party involved in
                    the Dispute may (by written notice to the other parties) submit the Dispute to mediation administered
                    by the Resolution Institute of Australia, with such mediation to be conducted:</p>
           
                      <ul className="alpha">
                        <li>
                          in good faith
                        </li>
                        <li>
                          in New South Wales and
                        </li>
                        <li>
                          in accordance with the Resolution Institute of Australia Mediation Guidelines.
                        </li>
                      </ul>
                  
                  <p class="pt-1">
                    The costs of mediation are to be split between the relevant parties, provided that each party will bear
                    its own costs in relation to the mediation.
                  </p>
                  <p class="pt-1">
                    If the Dispute has not been settled within 20 Business Days after the appointment of a mediator, or
                    such other period as agreed in writing between the parties, the Dispute may be referred by any party
                    involved in the Dispute (by written notice to the other parties) to litigation.
                  </p>
                  <p class="pt-1">
                    If the parties do not resolve the Dispute within 21 days after receipt of the notice, the Dispute may be
                    referred by either party (by notice in writing to the other party) to litigation.
                  </p>
                  </li>
                  <li>
                    <h3>SEVERANCE</h3>
                  </li>
                  <p class="pt-1">
                    If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be
                    read down as narrowly as necessary to allow it to be valid or enforceable. If it is not possible to read
                    down a provision (in whole or in part), that provision (or that part of that provision) is severed from
                    these Terms without affecting the validity or enforceability of the remainder of that provision or the
                    other provisions in these Terms.
                  </p>
                  <li>
                    <h3>JURISDICTION</h3>
                  </li>
                  <p class="pt-1">
                    Your use of our Website and these Terms are governed by the laws of New South Wales. You
                    irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating in New
                    South Wales and any courts entitled to hear appeals from those courts and waive any right to object
                    to proceedings being brought in those courts.
                  </p>
                  <p class="pt-1">
                    Our Website may be accessed throughout Australia and overseas. We make no representation thatour Website complies with the laws (including intellectual property laws) of any country outside
                    Australia. If you access our Website from outside Australia, you do so at your own risk and are
                    responsible for complying with the laws of the jurisdiction where you access our Website.
                  </p>
                  <p class="pt-1">
                    For any questions and notices, please contact us at: <a>support@ozgigs.com.au</a>
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default TermsAndConditions;
