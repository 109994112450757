import React, {useState, useEffect} from "react";
import authenticationSignup, { clearSignupMsg } from "../../../actions/signUpAction";
import authenticationGoogle, {clearGoogleLogin} from "../../../actions/googleAction";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../includes/header";
import Footer from "../../includes/footer";
import { gapi } from "gapi-script";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import PasswordChecklist from "react-password-checklist";
import { getMessaging, getToken } from "firebase/messaging";
import validator from 'validator';
import axios from "axios";
import Swal from "sweetalert2";
import {  createUserWithEmailAndPassword ,signInWithEmailAndPassword, GoogleAuthProvider,signInWithPopup  } from 'firebase/auth';
import { auth } from '../../../firebase';

const Signup = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupdata = useSelector((state) => state.register);
    const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;
    
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [email, setEmail] = useState("");
    const [reenteremail, setReEnterEmail] = useState("");
    const [password, setPassword] = useState("");
    const [postcode, setPostcode] = useState("");
    const [errorPostCode, setErrorPostCode] = useState(false);
    const [state, setState] = useState("");
    const [suburb, setSuburb] = useState("");
    const [error, setError] = useState(false);
    const [profile, setProfileImg] = useState("");
    const [googleLogin, setGoogleLogin] = useState(false);
    const [userType, setUserType] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(eyeOff);
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);

    let checkIfValidEmail = validator.isEmail(email);
    const levelBarCss = (level) => ({
        height: "8px",
        width: level > 0 ? `${(100 / 4) * level}%` : "100%",
        marginTop: 16,
        transition: "width 0.5s ease",
        backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
        borderRadius: 0,
    });

    // for google login
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const onLoginSuccess = async (e) => {
        await dispatch(authenticationGoogle(e.profileObj));
        let tokenObj = JSON.parse(localStorage.getItem("googleLogin"));
        // if (tokenObj) { navigate("/signup"); }
        if (tokenObj) { window.location = "/signup"; }
    };
    const onLoginFailure = (res) => {
        console.log("login failure", res);
    };
    // Google Login
    const google = useSelector((state) => state.google);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userData"));
        const initClient = async () => {
            gapi.client.init({ CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID, scope: "" });
            if (google?.google?.datasss?.status === true && user) {

                let tokenObj = { email: google?.google?.datasss?.data?.user.email, api_token: google?.google?.datasss?.data?.api_token }
                localStorage.setItem('token', JSON.stringify(tokenObj));
                
                signInWithEmailAndPassword(auth, tokenObj.email, "Password@123") // generateing fcm token if user exst in firebase
                .then((userCredential) => {
                        const access_token = userCredential.user.getIdToken()
                        access_token.then(data=>{
                            localStorage.setItem('FIREBASE_ACCESS_TOKEN',data)
                        })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === "auth/user-not-found") {
                        createUserWithEmailAndPassword(auth, tokenObj.email, "Password@123").then((userRegistered) => { // creating user user noy exst in firebase
                            const access_token = userRegistered.user.getIdToken()
                            access_token.then(data=>{
                                localStorage.setItem('FIREBASE_ACCESS_TOKEN',data);
                            })
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                });
                
                // store device token of user
                try {
                    const messaging = getMessaging(); // Assuming it returns a promise
                    const currentToken = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
                    });
                    if (currentToken) {
                        localStorage.setItem("FCM_TOKEN", currentToken);
                        const headers = {
                            "content-type": "application/json",
                            Authorization: `Bearer ${tokenObj.api_token}`,
                        };
                        let obj = {
                            user_id: google?.google?.datasss?.data?.user.id,
                            device_token: currentToken,
                        };
                        try {
                            const response = await axios.post(
                                `${process.env.REACT_APP_API_ENDPOINT}/api/store-device-token`,
                                obj,
                                { headers }
                            );
                        } catch (error) {
                            console.log("Error storing device token:", error);
                            // Handle the error appropriately.
                        }
                    } else {
                        console.log("No FCM token available.");
                    }
                } catch (error) {
                    console.log("Error getting FCM token:", error);
                }
                toast.success(google?.google.datasss.message);
                dispatch(clearGoogleLogin());
                if (google?.google?.datasss?.data?.user?.role === 2) {
                    if (google?.google?.datasss?.data?.user?.step === 1) { navigate("/vendor-profile"); }
                    if (google?.google?.datasss?.data?.user?.step === 0) { navigate("/vendor-dashboard"); }
                }
                else if (google?.google?.datasss?.data?.user?.role === 3) { navigate("/consumer-dashboard"); }
                else { navigate("/home"); }
            }
            if (google && google?.google && google?.google?.datasss && google?.google?.datasss?.status === false) {
                toast.error(google?.google && google?.google.datasss && google?.google.datasss.message);
                dispatch(clearGoogleLogin());
            }
            if (google && google?.error && google?.error?.datasss && google?.error?.datasss?.status === 2) {
                toast.warn(google?.error && google?.error.datasss && google?.error.datasss.message);
                dispatch(clearGoogleLogin());
            }
        };
        gapi.load("client:auth2", initClient);
    }, [google]);

    // Direct login after registor if user registor from google login 
    const signup = useSelector((state) => state?.signup);
    useEffect(() => {
        if (signup?.authuser && signup?.authuser?.data && signup?.authuser?.data?.status ===   true) {
            const user = JSON.parse(localStorage.getItem("userData"));
            if (signup?.authuser?.data?.data?.user?.login_type === 'google' && user) {
                let tokenObj = { email: signup?.authuser?.data?.data?.user?.email, api_token: signup?.authuser?.data?.data?.api_token }
                localStorage.setItem('token', JSON.stringify(tokenObj));
                if (signup?.authuser?.data?.data?.user?.role ===   2) {
                    if (signup?.authuser?.data?.data?.user?.step ===   1){ 
                        toast.success('Login Successful! Set up your profile now.');
                        dispatch(clearSignupMsg()); 
                        navigate("/vendor-profile");
                    }
                    if (signup?.authuser?.data?.data?.user?.step ===   0) { 
                        toast(signup?.authuser?.data?.message);
                        dispatch(clearSignupMsg());
                        navigate("/vendor-dashboard");
                    }
                }
                else if (signup?.authuser?.data?.data?.user?.role ===   3) { 
                    toast.success(signup?.authuser?.data?.message);
                    dispatch(clearSignupMsg());
                    navigate("/consumer-dashboard"); 
                }
                else { navigate("/home"); }
            }else{
                setLoading(false);
                Swal.fire({
                    title: "Done!",
                    text: signup?.authuser?.data?.message,
                    icon: "success",
                })
                dispatch(clearSignupMsg());
                navigate("/login");
            }
        }
        if(signup?.authuser && signup?.authuser?.data && signup?.authuser?.data?.email){
            setLoading(false);
            toast.warn(signup?.authuser?.data?.email[0]);
            dispatch(clearSignupMsg());
        }
        else if (signup?.errorsss && signup?.errorsss?.data) {
            if (typeof signup.errorsss.data === "object") {
                let arr = Object.values(signup.errorsss.data);
                let tempObj = "";
                arr.map((x) => {
                    tempObj = x;
                });
                toast(tempObj[0]);
                setLoading(false);
                dispatch(clearSignupMsg());
            }
        }
    }, [signup]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!googleLogin) {
            if (!first_name || !email || !password || !last_name || !reenteremail || !postcode || !state || !suburb || !userType || !check) {
                setError(true);
                setLoading(false);
                return false;
            }else if (email != reenteremail) {
                toast.warn("Email & Re-enter should be same.");
                setLoading(false);
                return false;
            }else if (!email) {
                toast.warn("Email is required.");
                setLoading(false);
                return false;
            }else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,}){1,}$/).test(email)) {
                toast.warn("Email should be in the proper format.");
                setLoading(false);
                return false;
            }else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/).test(password)) {
                toast.warn("Password format is invalid. Your password should be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 numeric digit, and 1 special character.");
                setLoading(false);
                return false;
            }else {
                const res = await dispatch(authenticationSignup({ 
                    first_name: first_name, 
                    last_name: last_name, 
                    email: email, 
                    password: password, 
                    "re_enter_email": reenteremail, 
                    postcode: postcode, 
                    state: state, 
                    suburb: suburb, 
                    role: userType, 
                    type: googleLogin ? "google" : "email", 
                }));
                createUserWithEmailAndPassword(auth, email, password) // for firebase registration 
            }
            // dispatch(clearSignupMsg());
        }
        else {
            if (!first_name || !email || !last_name || !postcode || !state || !suburb || !userType || !check) {
                setError(true);
                setLoading(false);
                return false;
            } else {
                const res = await dispatch(authenticationSignup({ 
                    first_name: first_name, 
                    last_name: last_name, 
                    email: email, 
                    password: "Password@123", 
                    "re_enter_email": reenteremail,
                    postcode: postcode, 
                    state: state, 
                    suburb: suburb, 
                    role: userType, 
                    type: googleLogin ? "google" : "email" }));
            }
            createUserWithEmailAndPassword(auth, email, "Password@123") // for firebase registration
            // dispatch(clearSignupMsg());
        }
    };

    useEffect(() => {
        let tokenObj = JSON.parse(localStorage.getItem("googleLogin"));
        if (tokenObj) {
            setFirst_name(tokenObj.givenName);
            setLast_name(tokenObj.familyName);
            setEmail(tokenObj.email);
            setReEnterEmail(tokenObj.email);
            setProfileImg(tokenObj.imageUrl);
            setGoogleLogin(true);
        }
    }, []);

    const handelToggle = () => {
        if (type === "password") {
            setIcon(eye);
            setType("text");
        } else {
            setIcon(eyeOff);
            setType("password");
        }
    };
    const validateEmail = (mail) => {
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(mail)) {
            return true;
        }
        alert("You are writing invalid email address!");
        return false;
    };

    const PostCodeValidate = (postCode) => {
        if (postCode === '' || new RegExp(/^[0-9\b]+$/).test(postCode)) { 
            setPostcode(postCode);
            setErrorPostCode(false) }
        else{ 
            setErrorPostCode(true)
        }
    }
    
    const firstNameValidate = (firstName) => {
        if (firstName === '' || new RegExp(/^[A-Za-z ]+$/).test(firstName)) { setFirst_name(firstName); }
        else { toast.warn('First Name allows only a Character.') }
    }
    const lastNameValidate = (lastName) => {
        if (lastName === '' || new RegExp(/^[A-Za-z ]+$/).test(lastName)) { setLast_name(lastName); }
        else { toast.warn('Last Name allows only a Character.') }
    }

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <Header />
                <section className=" middle-section">
                    <div className="signup-page">
                        <div className="container">
                            <div className="custom-form login_screen common-inner-container  fadeIn wow">
                                <div className="row custom-form-inner">
                                    <div className="col-md-5 col-12 cust-remove-padding">
                                        <div className="login-left-sec">
                                            <img src="/img/stockSignUp.jpeg" alt="" />

                                            <div className="login-tagline">
                                                <h2 className="fadeInUp wow">WELCOME!</h2>
                                                {/* <p className="fadeInUp wow">
                                                    It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when
                                                    looking at its layout.
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-12 cust-remove-padding login-right">
                                        <div className="form-container">
                                            <div className="signin-other-account fadeInUp d-flex justify-content-around">
                                                <div className="text-center">
                                                    <h1>Sign Up</h1>
                                                    <h6>Create an account</h6>
                                                </div>
                                                <div className="text-center mt-2">
                                                    <p>OR</p>
                                                </div>
                                                <div className="text-center">
                                                    <GoogleLogin
                                                        clientId={CLIENT_ID}
                                                        buttonText="Sign In with Google"
                                                        onSuccess={onLoginSuccess}
                                                        onFailure={onLoginFailure}
                                                        cookiePolicy={"single_host_origin"}
                                                        render={(renderProps) => (
                                                            <a onClick={renderProps.onClick} disabled={renderProps.disabled} href="#">
                                                                <img src="img/gmail.svg" alt="" />
                                                            </a>
                                                        )}
                                                    />
                                                    <h6 className="google-text">Sign up with</h6>
                                                    <h6>Google Account</h6>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit} className="loginForm signInForm" >
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-fname">FIRST NAME</label>
                                                            <input
                                                                className="form-control"
                                                                value={first_name}
                                                                // placeholder="enter first Name"
                                                                onChange={(e) => firstNameValidate(e.target.value)}
                                                                type="text"
                                                                id="u-fname"
                                                                name="firstname"
                                                                maxLength={50}
                                                            />
                                                            {error && !first_name && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-fname">LAST NAME</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="u-lname"
                                                                name="lasttname"
                                                                value={last_name}
                                                                // placeholder="enter first name"
                                                                onChange={(e) => lastNameValidate(e.target.value)}
                                                                maxLength={50}
                                                            />
                                                            {error && !last_name && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-email">Valid email address</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        value={email}
                                                        readOnly={googleLogin}
                                                        // placeholder="enter email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        id="u-email"
                                                        name="usermail"
                                                        maxLength={50}
                                                    />
                                                    {error && !email && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-email">Re-enter email address</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        value={reenteremail}
                                                        readOnly={googleLogin}
                                                        // placeholder=" re enter email"
                                                        onChange={(e) => setReEnterEmail(e.target.value)}
                                                        id="u-email"
                                                        name="usermail"
                                                        maxLength={50}
                                                    />
                                                    {error && !reenteremail && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                {googleLogin ? null : (
                                                    <>
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-password">PASSWORD</label>
                                                            <div className="input-iconParent ">
                                                                <input
                                                                    type={type}
                                                                    value={password}
                                                                    placeholder="Password"
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    id="u-password"
                                                                    className="form-control"
                                                                    name="password"
                                                                    // maxLength={15}
                                                                    minLength={8}
                                                                />
                                                                <span
                                                                    onClick={handelToggle}
                                                                    className="input-icon "
                                                                >
                                                                    <Icon icon={icon} size={25} />
                                                                </span>
                                                            </div>
                                                            {error && !password && (
                                                                <span style={{ color: "red" }} className="invalidInput">
                                                                    Required*
                                                                </span>
                                                            )}
                                                            <PasswordChecklist
                                                                rules={["minLength","specialChar","number","capital",]}
                                                                minLength={8}
                                                                value={password}
                                                                messages={{
                                                                    minLength:"Password should be at least 8 Characters",
                                                                    specialChar: "1 special character",
                                                                    number: "1 numerical",
                                                                    capital: "1 uppercase",
                                                                }}
                                                            />
                                                        </div>{" "}
                                                    </>
                                                )}
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-state">User Type</label>
                                                            <select
                                                                className="form-control"
                                                                name=""
                                                                value={userType}
                                                                // placeholder="enter password email"
                                                                onChange={(e) => setUserType(e.target.value)}
                                                                id="u-type"
                                                            >
                                                                <option value="">Select User Type</option>
                                                                <option value="2">I provide event services. (Vendor)</option>
                                                                <option value="3">I’m looking for event services. (Consumer)</option>
                                                            </select>
                                                            {error && !userType && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-postcode">Postcode</label>
                                                            <input
                                                                // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                                                className="form-control"
                                                                value={postcode}
                                                                // placeholder="enter postcode"
                                                                onInput={(e) => PostCodeValidate(e.target.value)}
                                                                type="text"
                                                                id="u-postcode"
                                                                name="postcode"
                                                                maxLength={4}
                                                            />
                                                            {((errorPostCode) || (errorPostCode && error))&& (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Allowed only numbers
                                                                </span>
                                                            )}
                                                            {error && !postcode && !errorPostCode && (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-state">State</label>
                                                            <select
                                                                className="form-control"
                                                                name=""
                                                                value={state}
                                                                // placeholder="enter password email"
                                                                onChange={(e) => setState(e.target.value)}
                                                                id="u-state"
                                                            >
                                                                <option value="">Select state</option>
                                                                <option value="New South Wales">
                                                                    New South Wales
                                                                </option>
                                                                <option value="Queensland">Queensland</option>
                                                                <option value="Northern Territory">
                                                                    Northern Territory
                                                                </option>
                                                                <option value="Western Australia">
                                                                    Western Australia
                                                                </option>
                                                                <option value="South Australia">
                                                                    South Australia
                                                                </option>
                                                                <option value="Victoria">Victoria</option>
                                                                <option value="the Australian Capital Territory">
                                                                    The Australian Capital Territory
                                                                </option>
                                                                <option value="Tasmania">Tasmania</option>
                                                                <option value="Northern Territory">
                                                                    Northern Territory
                                                                </option>
                                                            </select>
                                                            {error && !state && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-suburb">Suburb</label>
                                                    <input
                                                        className="form-control"
                                                        value={suburb}
                                                        // placeholder="enter"
                                                        onChange={(e) => setSuburb(e.target.value)}
                                                        type="text"
                                                        id="u-suburb"
                                                        name="suburb"
                                                        maxLength={50}
                                                    />
                                                    {error && !suburb && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group ">
                                                    <div className="checkboxbtn fadeInUp wow">
                                                        <input
                                                            id="checkbox2"
                                                            value={check}
                                                            type="checkbox"
                                                            onChange={(e) => setCheck(e.target.checked)}
                                                        />
                                                        <label htmlFor="checkbox2" className="small-text">
                                                        By Creating an account, you agree that you've read and accepted our <a target="_blank" href="/terms-of-use">Marketplace Terms of Service</a>, <a target="_blank" href="/privacy-policy">Privacy Notice</a>, <a target="_blank" href="/terms-and-conditions">Website Terms and Conditions</a> and agree to receiving marketing communications from us.
                                                        </label>
                                                        {error && !check && (
                                                            <span
                                                                style={{ color: "red" }}
                                                                className="invalidInput"
                                                            >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group form-footer">
                                                    {(loading) ? (
                                                        <>
                                                            <button type="submit" className="btn my-4 fadeInUp wow" disabled >
                                                                <div className={"btn-loader"}>
                                                                    <img src="/img/loader.svg" className="loader-svg-img" />
                                                                </div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button type="submit" className="btn my-4 fadeInUp wow" > Sign Up </button>
                                                        </>
                                                    )}
                                                    <p className="footerlink fadeInUp wow">
                                                        Already have an account?{" "}
                                                        <a href="/login">Sign In</a>
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer-upper fadeIn wow">
                    <div className="footer-blur-img">
                        <img src="./img/cycn-cir.svg" alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Signup;
