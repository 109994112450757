import { SendChatNotification } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const sendChatNotification = (data) => async (dispatch) => {
    try {
        const sendChatNotification = await commonService.withToken(`/send-chat-notification`, data);
        if (sendChatNotification) {
            dispatch({ type: SendChatNotification.GET_SENDCHATNOTIFICATION_SUCCESS, payload: { datasss: sendChatNotification.data } });
        }
        else {
            dispatch({ type: SendChatNotification.GET_SENDCHATNOTIFICATION_FAILURE, payload: { data: sendChatNotification.data } });
        }
    }
    catch (error) {
        dispatch({ type: SendChatNotification.GET_SENDCHATNOTIFICATION_FAILURE, payload: error.message })
    }
}

export default sendChatNotification;