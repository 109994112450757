import React, { useEffect, useState, useRef } from "react";
import ConsumerSidebar from "./consumer/includes/sidebar";
import ConsumerHeader from "./consumer/includes/header";
import VendorHeader from "./vendor/includes/header";
import VendorSidebar from "./vendor/includes/sidebar";
import sendChatNotification from "../actions/sendChatNotification";
import Footer from "./includes/footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import usersChatAction from "../actions/chatListAction";
import notificationAction from "../actions/notificationListAction";
import { getDatabase, ref, set, push, onValue, runTransaction, remove, get, off, } from "firebase/database";
// import { getStorage, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import moment from "moment";
import Modal from "react-modal";
import { Rating } from "react-simple-star-rating";
import StarRating from "react-star-ratings";
import { getDownloadURL, ref as storageRef, getStorage, uploadBytes, uploadBytesResumable, } from "firebase/storage";
import axios from "axios";
import { toast } from "react-toastify";
import io from 'socket.io-client';
import Loader from "react-js-loader";
// import Echo from "laravel-echo"; 
// import { createClient } from 'redis';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
        width: "500px",
        maxWidth: "100%",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "20px",
        zIndex: "9999",
        color: "#000000",
    },
};

const Chats = (props) => {
    const chatMessageBox = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [eventId, setEventId] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [userId, setUserId] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverProfile, setReceiverProfile] = useState("");
    const [deletedAt, setDeletedAt] = useState("");
    const [eventName, setEventName] = useState("");
    const [avgRating, setAvgRating] = useState("");
    const [messages, setMessages] = useState([]);
    const [chatList, setchatList] = useState("");
    const [textMessage, setTextMessage] = useState("");
    const [currentChatUser, setCurrentChatUser] = useState("");
    const [chatInfo, setChatInfo] = useState();
    const [flagRedirect, setFlagRedirect] = useState(false);
    const [openRatingModal, setOpenRatingModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [flagImage, setImageFlag] = useState(0);
    const [userReviewStatus, setUserReviewStatus] = useState(false);
    const [unreadChanged, setUnreadChanged] = useState(false);
    const [duplicateChat, setDuplicateChat] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredChatList, setFilteredChatList] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [fcmTokens, setFcmTokens] = useState([]);
    const [priviousUser, setPriviousUser] = useState({});
    const [loadingForm, setLoadingForm] = useState(false);
    const [chatLoader, setChatLoader] = useState(true);
    const [flagPDF, setFlagPDF] = useState(false);
    const [selectedPDF, setSelectedPDF] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [vendorSlug, setVendorSlug] = useState("");

    const auth = getAuth();
    const { id } = useParams();

    const [description, setDescription] = useState("");
    const storage = getStorage();

    const user = auth.currentUser;
    const createChat = useSelector((state) => state.createChat);
    useEffect(() => {
        setTimeout(() => {
            setChatLoader(false)
        }, 2000);
        dispatch(usersChatAction());
        scrollToBottom();
    }, [dispatch , createChat]);

    // useEffect(() => {
    //   var sock = io("http://localhost:9000");
    //   sock.on("read-message:App\\Events\\ReadMessage", function (data) {
    //   dispatch(usersChatAction());
    //   });
    // }, []);

    // const redisOptions = {
    //   host: '127.0.0.1',
    //   port: '6379',
    //   // ... Add any additional Redis configuration options if necessary
    // };

    // const echo = new Echo({
    //   broadcaster: 'socket.io',
    //   host: 'http://localhost:6001',
    //   client: new createClient(redisOptions),
    // });

    // echo.public("read-message").listen('.ReadMessage', (event) => {
    //   // Handle new chat message event
    //   dispatch(usersChatAction());
    //   // Update the chat receiver's end with the new message
    // });

    // useEffect(() => {
    //   // var sock = io("http://localhost:6001");
    //   const sock = io('http://localhost:6001', {transports:['polling']});
    //   sock.on('read-message:App\\Events\\ReadMessage', (data) => {
    //     dispatch(usersChatAction());
    //   });

    //   // sock.on("read-message:App\\Events\\ReadMessage", function (data) {
    //   // dispatch(usersChatAction());
    //   // // });
    //   return () => {
    //     sock.disconnect();
    //   };
    // }, []);

    // get vendor all chat list
    const userChat = useSelector((state) => state.chatList.chatList);
    useEffect(() => {
        if (userChat && userChat?.datasss && userChat?.datasss?.status === true) {
            setchatList(userChat.datasss.data.chats);
            setDuplicateChat(userChat.datasss.data.chats);
            setUserData(userChat.datasss.data.user);
        }
    }, [userChat]);

    const [locationState , setLocationState] = useState("");
    useEffect(() => {
        if(location.state){
            setLocationState(location.state)
        }
    } , [location.state])
    
    // redirect on perticular chat node
    useEffect(() => {
        if (chatList && chatList.length) {
            const getData = chatList.find((ele) => ele.event_id === Number(id));
            if (locationState) {
                localStorage.setItem(
                    "current_chat_id",
                    JSON.stringify({
                        event_id: Number(locationState.eventId),
                        vendor_id: Number(locationState.vendorId),
                        user_id: Number(locationState.userId),
                        name: locationState.name,
                        profile: locationState.profile,
                        deleted_at: locationState.deleted_at,
                        event_name: locationState.eveName,
                        vendor_slug: locationState.vendorSlug,
                    })
                );

                setFlagRedirect(true);
                handleClick(
                    Number(locationState.eventId),
                    Number(locationState.vendorId),
                    Number(locationState.userId),
                    locationState.name,
                    locationState.profile,
                    locationState.deleted_at,
                    2,
                    locationState.chatList
                );
                setChatInfo({
                    event_id: Number(locationState.eventId),
                    vendor_id: Number(locationState.vendorId),
                    user_id: Number(locationState.userId),
                    name: locationState.name,
                    profile: locationState.profile,
                    deleted_at: locationState.deleted_at,
                    avgRating: 2,
                    event_name: locationState.eveName,
                    vendor_slug: locationState.vendorSlug,
                });
            } else if (getData) {
                let name =
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.first_name
                        : getData?.vendor_dtl?.first_name;
                let profile =
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.user_profile
                        : getData?.vendor_dtl?.user_profile;
                let deleted_at =
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.deleted_at
                        : getData?.vendor_dtl?.deleted_at;
                let avgRating =
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.avgRating
                        : getData?.vendor_dtl?.avgRating;
                let event_name = getData?.event_dtl
                    ? getData?.event_dtl?.event_name
                    : "";
                let vendor_slug =
                    loginUser.role === 2
                        ? ""
                        : getData?.vendor_dtl?.slug;
                setChatInfo({
                    event_id: getData.event_id,
                    vendor_id: getData.vendor_id,
                    user_id: getData.user_id,
                    name: name,
                    profile: profile,
                    deleted_at:deleted_at,
                    avgRating: avgRating,
                    event_name: event_name,
                    vendor_slug: vendor_slug,
                });
                localStorage.setItem(
                    "current_chat_id",
                    JSON.stringify({
                        event_id: Number(getData.event_id),
                        vendor_id: Number(getData.vendor_id),
                        user_id: Number(getData.user_id),
                        name: name,
                        profile: profile,
                        deleted_at:deleted_at,
                        event_name: event_name,
                        vendor_slug: vendor_slug,
                    })
                );
                handleClick(
                    getData.event_id,
                    getData.vendor_id,
                    getData.user_id,
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.first_name
                        : getData?.vendor_dtl?.first_name,
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.user_profile
                        : getData?.vendor_dtl?.user_profile,
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.deleted_at
                        : getData?.vendor_dtl?.deleted_at,
                    loginUser.role === 2
                        ? getData?.consumer_dtl?.avgRating
                        : getData?.vendor_dtl?.avgRating,
                    getData?.event_dtl ? getData?.event_dtl?.event_name : ""
                );
            } else {
                const preuser = localStorage.getItem("current_chat_id");
                setPriviousUser(JSON.parse(preuser));
                let data = chatList[0];
                let name =
                    loginUser.role === 2
                        ? data?.consumer_dtl?.first_name
                        : data?.vendor_dtl?.first_name;
                let profile =
                    loginUser.role === 2
                        ? data?.consumer_dtl?.user_profile
                        : data?.vendor_dtl?.user_profile;
                let deleted_at =
                    loginUser.role === 2
                        ? data?.consumer_dtl?.deleted_at
                        : data?.vendor_dtl?.deleted_at;
                let event_name = data?.event_dtl ? data?.event_dtl?.event_name : "";
                let avgRating =
                    loginUser.role === 2
                        ? data?.consumer_dtl?.avgRating
                        : data?.vendor_dtl?.avgRating;
                let vendor_slug =
                    loginUser.role === 2
                        ? ""
                        : data?.vendor_dtl?.slug;
                localStorage.setItem(
                    "current_chat_id",
                    JSON.stringify({
                        event_id: Number(data.event_id),
                        vendor_id: Number(data.vendor_id),
                        user_id: Number(data.user_id),
                        name: name,
                        profile: profile,
                        deleted_at:deleted_at,
                        event_name: event_name,
                        vendor_slug: vendor_slug,
                    })
                );

                handleClick(
                    data.event_id,
                    data.vendor_id,
                    data.user_id,
                    loginUser.role === 2
                        ? data?.consumer_dtl?.first_name
                        : data?.vendor_dtl?.first_name,
                    loginUser.role === 2
                        ? data?.consumer_dtl?.user_profile
                        : data?.vendor_dtl?.user_profile,
                    loginUser.role === 2
                        ? data?.consumer_dtl?.deleted_at
                        : data?.vendor_dtl?.deleted_at,
                    loginUser.role === 2
                        ? data?.consumer_dtl?.avgRating
                        : data?.vendor_dtl?.avgRating,
                    data?.event_dtl ? data?.event_dtl?.event_name : ""
                );

                setChatInfo({
                    event_id: data.event_id,
                    vendor_id: data.vendor_id,
                    user_id: data.user_id,
                    name: name,
                    profile: profile,
                    deleted_at:deleted_at,
                    avgRating: avgRating,
                    event_name: event_name,
                    vendor_slug: vendor_slug,
                });
            }
        }
    }, [duplicateChat]);

    useEffect(() => {
        if (chatInfo) {
            const {
                event_id,
                vendor_id,
                user_id,
                name,
                profile,
                deleted_at,
                avgRating,
                event_name,
            } = chatInfo;
            handleClick(
                event_id,
                vendor_id,
                user_id,
                name,
                profile,
                deleted_at,
                avgRating,
                event_name
            );
        }
    }, [chatInfo]);

    // get user review list
    const getUserReview = async (id) => {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenObj.api_token}`,
        };

        let obj = {
            to_id: id,
        };
        const result = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/get-user-review`,
            obj,
            { headers }
        );

        if (result.status === 200 && result.data.data === 1) {
            setUserReviewStatus(true);
        } else {
            setUserReviewStatus(false);
        }
    };

    const handleSelectUser = (
        event_id,
        vendor_id,
        user_id,
        name,
        profile,
        deleted_at,
        avgRating,
        event_name,
        vendor_slug
    ) => {
        const db = getDatabase();
        const currentRef = ref(
            db,
            "messages/" +
            chatInfo?.event_id +
            "_" +
            chatInfo?.vendor_id +
            "_" +
            chatInfo?.user_id
        );
        setPriviousUser(chatInfo);
        off(currentRef);
        setChatInfo({
            event_id: event_id,
            vendor_id: vendor_id,
            user_id: user_id,
            name: name,
            profile: profile,
            deleted_at: deleted_at,
            avgRating: avgRating,
            event_name: event_name,
            vendor_slug: vendor_slug,
        });
        localStorage.setItem(
            "current_chat_id",
            JSON.stringify({
                event_id: event_id,
                vendor_id: vendor_id,
                user_id: user_id,
                name: name,
                profile: profile,
                deleted_at: deleted_at,
                event_name: event_name,
                vendor_slug: vendor_slug,
            })
        );
    };

    const handleClick = (
        event_id,
        vendor_id,
        user_id,
        name,
        profile,
        event_name
    ) => {
        // var msgData = [];
        const db = getDatabase();
        const localStorageChatData = JSON.parse(
            localStorage.getItem("current_chat_id")
        );
        const previousChatData = localStorageChatData;
        // Mark previous user as inactive
        if (priviousUser) {
            const {
                event_id: prevEventId,
                vendor_id: prevVendorId,
                user_id: prevUserId,
            } = priviousUser;
            const previousActiveUserRef =
                loginUser.role === 2
                    ? `active_users/${prevEventId}_${prevVendorId}_${prevUserId}`
                    : `active_users/${prevEventId}_${prevUserId}_${prevVendorId}`;
            let removeActiveUser = ref(db, previousActiveUserRef);
            remove(removeActiveUser)
                .then((data) => {
                    // console.log(data, "node removed");
                })
                .catch((error) => {
                    console.log(error, "node error");
                });
        }
        if (loginUser.role === 2) {
            const activeUserRef = ref(
                db,
                `active_users/${localStorageChatData.event_id +
                "_" +
                localStorageChatData.vendor_id +
                "_" +
                localStorageChatData.user_id
                }`
            );
            set(activeUserRef, true);
        }
        if (loginUser.role === 3) {
            const activeUserRef = ref(
                db,
                `active_users/${+localStorageChatData.event_id +
                "_" +
                localStorageChatData.user_id +
                "_" +
                localStorageChatData.vendor_id
                }`
            );
            set(activeUserRef, true);
        }

        // const unreadMsgRef = ref(db, 'unread/' + event_id + "_" + user_id + "_" + vendor_id);
        const unreadMsgRef =
            loginUser.role === 2
                ? "unread/" +
                localStorageChatData.event_id +
                "_" +
                localStorageChatData.vendor_id +
                "_" +
                localStorageChatData.user_id
                : "unread/" +
                localStorageChatData.event_id +
                "_" +
                localStorageChatData.user_id +
                "_" +
                localStorageChatData.vendor_id;
        const unreadChatNode = ref(db, unreadMsgRef);
        remove(unreadChatNode, {
            before: true,
            applyLocally: false,
            middlewares: [
                {
                    mutate: async (args, apply) => {
                        const user = auth.currentUser;
                        const token = await user.getIdToken();
                        args[0].headers = {
                            ...args[0].headers,
                            Authorization: `Bearer ${token}`,
                        };
                        await apply(args);
                    },
                },
            ],
        })
            // .then(() => {
            //     console.log("Unread node removed successfully");
            // })
            // .catch((error) => {
            //     console.error("Error removing unread node:", error);
            // });

        // set selected chat user profile
        setCurrentChatUser(profile);
        onValue(
            ref(
                db,
                "messages/" +
                localStorageChatData?.event_id +
                "_" +
                localStorageChatData?.vendor_id +
                "_" +
                localStorageChatData?.user_id
            ),
            (snapshot) => {
                const data = snapshot.val();
                console.log("chat count", Object.keys(data).length);
                console.log("chat data", data);
                if (data) {
                    let msgData = [];
                    let keys = Object.keys(data);
                    for (let key of keys) {
                        let getDateTime = data[key].created_at;
                        data[key].created_at = getDateTime;
                        msgData.push(data[key]);
                    }
                    let obj = [...msgData];
                    setMessages(obj);
                    // if(!apiCall){
                    //   apiCall=true;
                    // dispatch(usersChatAction());
                    // }
                } else {
                    setMessages([]);
                }
            }
        );

        if (loginUser.role === 2) {
            getUserReview(user_id);
        } else {
            getUserReview(vendor_id);
        }

        scrollToBottom();
    };

    useEffect(() => {
        const db = getDatabase();
        if (chatInfo) {
            setEventId(chatInfo.event_id);
            setVendorId(chatInfo.vendor_id);
            setUserId(chatInfo.user_id);
            setReceiverName(chatInfo.name);
            setReceiverProfile(chatInfo.profile);
            setDeletedAt(chatInfo.deleted_at);
            setAvgRating(chatInfo.avgRating);
            setEventName(chatInfo.event_name);
            setVendorSlug(chatInfo.vendor_slug);
        }
    }, [chatInfo]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingForm(true);
        const userInfo = JSON.parse(localStorage.getItem("userData"));
        const userData = userInfo?.data?.data?.user;
        // TODO: Handle submission logic
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenObj.api_token}`,
        };
        let obj = {};
        if (userData.role === 2) {
            obj = {
                from_id: vendorId,
                to_id: userId,
                review: description,
                rating: rating,
            };
        }
        if (userData.role === 3) {
            obj = {
                from_id: userId,
                to_id: vendorId,
                review: description,
                rating: rating,
            };
        }

        if (description !== "" && rating> 0) {
                axios
                    .post(`${process.env.REACT_APP_API_ENDPOINT}/api/rating-review`, obj, {
                        headers,
                    })
                    .then((response) => {
                        if (response) {
                            toast.success(response.data.message);
                            getUserReview(obj.to_id);
                            dispatch(notificationAction());
                            setOpenRatingModal(!openRatingModal);
                            setLoadingForm(false);
                        }
                    });
                
        } else if(description && rating === 0){
            setLoadingForm(false);
            toast.warn("Please select a rating.");
        } else {
            setLoadingForm(false);
            toast.warn("Please write a review.");
        }
    };

    const handleRating = (rate) => {
        setRating(rate);
    };

    useEffect(() => {
        if (
            filteredChatList &&
            Array.isArray(filteredChatList) &&
            filteredChatList.length > 0
        ) {
            let tempChatList = [...filteredChatList]; // Create a copy of chatList

            const db = getDatabase();
            const unreadMessagesQuery = ref(db, "unread/");

            const handleUnreadMessages = (snapshot) => {
                const unreadMessages = snapshot.val();
                if (unreadMessages) {
                    const updatedChatList = tempChatList.map((data, index) => {
                        let currentId;
                        if (data && data.consumer_dtl && data.consumer_dtl.role === 3) {
                            currentId =
                                data.event_id + "_" + data.vendor_id + "_" + data.user_id;
                        }
                        if (data && data.vendor_dtl && data.vendor_dtl.role === 2) {
                            currentId =
                                data.event_id + "_" + data.user_id + "_" + data.vendor_id;
                        }
                        if (unreadMessages[currentId]) {
                            setUnreadChanged(true);
                            return { ...data, unreadCount: unreadMessages[currentId] };
                        } else {
                            return { ...data, unreadCount: 0 };
                        }
                    });
                    // Update the chatList state only if it has changed
                    if (
                        JSON.stringify(updatedChatList) !== JSON.stringify(filteredChatList)
                    ) {
                        setFilteredChatList(updatedChatList);
                    }
                }
            };

            onValue(unreadMessagesQuery, handleUnreadMessages);
        }
    }, [filteredChatList]);
    // fetch chat node according to ids and push it in array
    useEffect(() => {
        scrollToBottom();
    }, [currentChatUser, eventId]);

    //get logged in user data
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    var loginUser = userInfo?.data?.data?.user;

    // send message from firebase
    const sendMessage = async () => {
        // Get active user
        if(textMessage || flagImage || flagPDF){
            setLoadingState(true);
        }
        const db = getDatabase();
        //  Get FCM tokens from database for send push notification
        let obj = {};
        if (loginUser.role === 2) {
            obj = {
                user_id: userId,
            };
        }
        if (loginUser.role === 3) {
            obj = {
                user_id: vendorId,
            };
        }
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const localStorageChatData = JSON.parse(
            localStorage.getItem("current_chat_id")
        );

        const headers = {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenObj.api_token}`,
        };
        await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/api/get-device-token`, obj, {
                headers,
            })
            .then((response) => {
                if (response && response.data) {
                    setFcmTokens(response.data);
                }
            });
        const activeUserRef = loginUser.role === 3 ? `active_users/${eventId}_${vendorId}_${userId}`  : `active_users/${eventId}_${userId}_${vendorId}`;
        const activeUserSnapshot = await get(ref(db, activeUserRef));

        if (textMessage && flagImage === 0) {
            const user = auth.currentUser;

            // Construct the chat node path
            const chatNodePath =
                loginUser.role === 2
                    ? "messages/" +
                    localStorageChatData.event_id +
                    "_" +
                    localStorageChatData.vendor_id +
                    "_" +
                    localStorageChatData.user_id
                    : "messages/" +
                    localStorageChatData.event_id +
                    "_" +
                    localStorageChatData.vendor_id +
                    "_" +
                    localStorageChatData.user_id;
            
            const chatNode = ref(db, chatNodePath);
            
            // get last node message created_at time and send notification
            try {
                const snapshot = await get(ref(db, chatNodePath));
                const data = snapshot.val();
            
                if (data) {
                    const keys = Object.keys(data).sort((a, b) => b - a);
                    const lastMessage = data[keys[keys.length - 1]];
            
                    const lastMessageTimestamp = lastMessage.created_at;
                    const currentTime = new Date().getTime();
                    const timeDifference = currentTime - lastMessageTimestamp;
            
                    if (timeDifference > 10 * 60 * 1000) { // 10 minutes in milliseconds
                        dispatch(sendChatNotification({ 
                            event_id  : localStorageChatData.event_id,
                            vendor_id : localStorageChatData.vendor_id,
                            user_id   : localStorageChatData.user_id 
                        }));
                        console.log('More than 10 minutes since the last message.');
                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
                // Handle the error here, for example, you could log it or take some other action.
            }

            let msg = {
                message: textMessage,
                mesgType: "TEXT",
                sender_id: loginUser.id,
                sender_name: loginUser?.first_name,
                sender_profile: loginUser?.profile,
                receiver_id: loginUser.role === 2 ? userId : vendorId,
                receiver_name: receiverName,
                receiver_profile: receiverProfile,
                created_at: Date.now(),
            };
           
            const chatNodePush = push(chatNode);
            set(chatNodePush, msg);
            updateChatLogs(localStorageChatData.event_id, localStorageChatData.vendor_id , localStorageChatData.user_id, loginUser.id, msg.created_at );
            // dispatch(usersChatAction())
            setLoadingState(false);
            if (!activeUserSnapshot.exists()) {
                sendPushNotification(fcmTokens, msg.message);
            }
            // Update unread chat message count for consumer
            if (loginUser.role === 2 && !activeUserSnapshot.exists()) {
                const unreadChatNode = ref(
                    db,
                    "unread/" +
                    localStorageChatData.event_id +
                    "_" +
                    localStorageChatData.user_id +
                    "_" +
                    localStorageChatData.vendor_id +
                    "/count"
                );
                runTransaction(unreadChatNode, (count) => (count || 0) + 1, {
                    before: true,
                    applyLocally: false,
                    middlewares: [
                        {
                            mutate: async (args, apply) => {
                                const user = auth.currentUser;
                                const token = await user.getIdToken();
                                args[0].headers = {
                                    ...args[0].headers,
                                    Authorization: `Bearer ${token}`,
                                };
                                await apply(args);
                            },
                        },
                    ],
                });
            }

            // Update unread chat message count for vendor
            if (loginUser.role === 3 && !activeUserSnapshot.exists()) {
                const unreadChatNode = ref(
                    db,
                    "unread/" +
                    localStorageChatData.event_id +
                    "_" +
                    localStorageChatData.vendor_id +
                    "_" +
                    localStorageChatData.user_id +
                    "/count"
                );
                runTransaction(unreadChatNode, (count) => (count || 0) + 1, {
                    before: true,
                    applyLocally: false,
                    middlewares: [
                        {
                            mutate: async (args, apply) => {
                                const user = auth.currentUser;
                                const token = await user.getIdToken();
                                args[0].headers = {
                                    ...args[0].headers,
                                    Authorization: `Bearer ${token}`,
                                };
                                await apply(args);
                            },
                        },
                    ],
                });
            }
            // handleClick(eventId, vendorId, userId, receiverName, receiverProfile, eventName);
            setTextMessage("");
            scrollToBottom();
        } else if (flagImage === 1) {
            const storageTemp = storageRef(storage, "chat-img/" + selectedImage.name);

            // Create a reference for the upload task
            const uploadTask = uploadBytesResumable(storageTemp, selectedImage);
            let downloadURL;
            // Track the upload progress
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Get the progress percentage
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setTextMessage(`${progress}% complete`);
                },
                (error) => {
                    // Handle any errors that occur during the upload
                    console.error("Error uploading file:", error);
                },
                async () => {
                    // Upload complete
                    downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    setLoadingState(false);
                    setSelectedImage(null);
                    
                    // Update the text message and image flag
                    setTextMessage(downloadURL);
                    setImageFlag(0);
                    const db = getDatabase();
                    // const auth = getAuth();
                    const user = auth.currentUser;

                    // Construct the chat node path
                    const chatNodePath =
                        loginUser.role === 2
                            ? "messages/" + eventId + "_" + vendorId + "_" + userId
                            : "messages/" + eventId + "_" + vendorId + "_" + loginUser.id;
                    const chatNode = ref(db, chatNodePath);

                    // get last node message created_at time and send notification
                    try {
                        const snapshot = await get(ref(db, chatNodePath));
                        const data = snapshot.val();
                    
                        if (data) {
                            const keys = Object.keys(data).sort((a, b) => b - a);
                            const lastMessage = data[keys[keys.length - 1]];
                    
                            const lastMessageTimestamp = lastMessage.created_at;
                            const currentTime = new Date().getTime();
                            const timeDifference = currentTime - lastMessageTimestamp;
                    
                            if (timeDifference > 10 * 60 * 1000) { // 10 minutes in milliseconds
                                dispatch(sendChatNotification({ 
                                    event_id  : localStorageChatData.event_id,
                                    vendor_id : localStorageChatData.vendor_id,
                                    user_id   : localStorageChatData.user_id 
                                }));
                                console.log('More than 10 minutes since the last message.');
                            }
                        }
                    } catch (error) {
                        console.error('An error occurred:', error);
                        // Handle the error here, for example, you could log it or take some other action.
                    }

                    let msg = {
                        message: downloadURL,
                        mesgType: "IMAGE",
                        sender_id: loginUser.id,
                        sender_name: loginUser?.first_name,
                        sender_profile: loginUser?.profile,
                        receiver_id: loginUser.role === 2 ? userId : vendorId,
                        receiver_name: receiverName,
                        receiver_profile: receiverProfile,
                        created_at: Date.now(),
                    };

                    const chatNodePush = push(chatNode);
                    if (!activeUserSnapshot.exists()) {
                        sendPushNotification(fcmTokens, msg.message);
                    }
                    set(chatNodePush, msg);
                    updateChatLogs(localStorageChatData.event_id, localStorageChatData.vendor_id , localStorageChatData.user_id, loginUser.id, msg.created_at );
                    // Update unread chat message count for consumer
                    if (loginUser.role === 2) {
                        const unreadChatNode = ref(
                            db,
                            "unread/" +
                            localStorageChatData.event_id +
                            "_" +
                            localStorageChatData.user_id +
                            "_" +
                            localStorageChatData.vendor_id +
                            "/count"
                        );
                        runTransaction(unreadChatNode, (count) => (count || 0) + 1);
                    }
                    // Update unread chat message count for vendor
                    if (loginUser.role === 3) {
                        const unreadChatNode = ref(
                            db,
                            "unread/" +
                            localStorageChatData.event_id +
                            "_" +
                            localStorageChatData.vendor_id +
                            "_" +
                            localStorageChatData.user_id +
                            "/count"
                        );
                        runTransaction(unreadChatNode, (count) => (count || 0) + 1);
                    }
                    // handleClick(eventId, vendorId, userId, receiverName, receiverProfile, eventName);
                    setTextMessage("");
                    scrollToBottom();
                }
            );
        } else if (flagPDF) {
            const storageTemp = storageRef(storage, "chat-pdf/" + selectedPDF.name);

            // Create a reference for the upload task
            const uploadTask = uploadBytesResumable(storageTemp, selectedPDF);
            let downloadURL;
            // Track the upload progress
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Get the progress percentage
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setTextMessage(`${progress}% complete`);
                },
                (error) => {
                    // Handle any errors that occur during the upload
                    console.error("Error uploading file:", error);
                },
                async () => {
                    // Upload complete
                    downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    setLoadingState(false);
                    // Update the text message and image flag
                    setTextMessage(downloadURL);
                    setImageFlag(0);
                    const db = getDatabase();
                    // const auth = getAuth();
                    const user = auth.currentUser;

                    // Construct the chat node path
                    const chatNodePath =
                        loginUser.role === 2
                            ? "messages/" + eventId + "_" + vendorId + "_" + userId
                            : "messages/" + eventId + "_" + vendorId + "_" + loginUser.id;
                    const chatNode = ref(db, chatNodePath);

                    // get last node message created_at time and send notification
                    try {
                        const snapshot = await get(ref(db, chatNodePath));
                        const data = snapshot.val();
                    
                        if (data) {
                            const keys = Object.keys(data).sort((a, b) => b - a);
                            const lastMessage = data[keys[keys.length - 1]];
                    
                            const lastMessageTimestamp = lastMessage.created_at;
                            const currentTime = new Date().getTime();
                            const timeDifference = currentTime - lastMessageTimestamp;
                    
                            if (timeDifference > 10 * 60 * 1000) { // 10 minutes in milliseconds
                                dispatch(sendChatNotification({ 
                                    event_id  : localStorageChatData.event_id,
                                    vendor_id : localStorageChatData.vendor_id,
                                    user_id   : localStorageChatData.user_id 
                                }));
                                console.log('More than 10 minutes since the last message.');
                            }
                        }
                    } catch (error) {
                        console.error('An error occurred:', error);
                        // Handle the error here, for example, you could log it or take some other action.
                    }

                    let msg = {
                        message: downloadURL,
                        mesgType: "PDF",
                        sender_id: loginUser.id,
                        sender_name: loginUser?.first_name,
                        sender_profile: loginUser?.profile,
                        receiver_id: loginUser.role === 2 ? userId : vendorId,
                        receiver_name: receiverName,
                        receiver_profile: receiverProfile,
                        created_at: Date.now(),
                    };

                    const chatNodePush = push(chatNode);
                    if (!activeUserSnapshot.exists()) {
                        sendPushNotification(fcmTokens, msg.message);
                    }
                    set(chatNodePush, msg);
                    updateChatLogs(localStorageChatData.event_id, localStorageChatData.vendor_id , localStorageChatData.user_id, loginUser.id, msg.created_at );
                    // Update unread chat message count for consumer
                    if (loginUser.role === 2) {
                        const unreadChatNode = ref(
                            db,
                            "unread/" +
                            localStorageChatData.event_id +
                            "_" +
                            localStorageChatData.user_id +
                            "_" +
                            localStorageChatData.vendor_id +
                            "/count"
                        );
                        runTransaction(unreadChatNode, (count) => (count || 0) + 1);
                    }
                    // Update unread chat message count for vendor
                    if (loginUser.role === 3) {
                        const unreadChatNode = ref(
                            db,
                            "unread/" +
                            localStorageChatData.event_id +
                            "_" +
                            localStorageChatData.vendor_id +
                            "_" +
                            localStorageChatData.user_id +
                            "/count"
                        );
                        runTransaction(unreadChatNode, (count) => (count || 0) + 1);
                    }
                    // handleClick(eventId, vendorId, userId, receiverName, receiverProfile, eventName);
                    setSelectedPDF(null);
                    setFlagPDF(false);
                    setTextMessage("");
                    scrollToBottom();
                }
            );
        }
    
    };

    const sendPushNotification = async (fcmTokens, msg) => {
        fcmTokens?.data.map(async (data) => {
            await fetch("https://fcm.googleapis.com/fcm/send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
                },
                body: JSON.stringify({
                    to: JSON.stringify(data), // Ensure the `data` value is a JSON string
                    notification: {
                        title: "New Message",
                        body: msg,
                        // Add additional notification options if needed
                    },
                }),
            })
                .then((response) => {
                    console.log("Push notification sent successfully:", response);
                })
                .catch((error) => {
                    console.error("Error sending push notification:", error);
                });
        });
    };
    // update chatlog count and last activity timestamp
    const updateChatLogs = (
        event_id,
        vendor_id,
        user_id,
        sent_by,
        sent_at,
    ) => {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenObj.api_token}`,
        };

        let obj = {
            event_id: event_id,
            vendor_id: vendor_id,
            user_id: user_id,
            sent_by: sent_by,
            sent_at: sent_at,
        };
        console.log("chat details", obj);
        axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/update-event-chats-activity`,
            obj,
            { headers }
        ).then((response) => {
        }).catch(err => {
            console.log('error in updating chat logs', err);
        });
    };
    // message send on press enter
    const sendOnEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // chat list scroll to the bottom of div whenever any message is send
    const scrollToBottom = () => {
        if (chatMessageBox.current) {
            chatMessageBox.current.scrollTop = chatMessageBox.current.scrollHeight;
        }
    };
    const canvasRef = useRef(null);
    const handleUpload = (file) => {
        // let fileName = file.target.files[0].name;
        const tempFile = file.target.files[0];
        if (tempFile) {
            const fileType = tempFile.type;
            if (fileType.startsWith("image/")) {
                setSelectedImage(tempFile);
                setImageFlag(1);
                file.target.value = null;
            } else if (fileType === "application/pdf") {
                setSelectedPDF(tempFile);
                setFlagPDF(true);
                file.target.value = null;
            }
        }
        // const storageTemp = storageRef(storage, 'chat-img/' + fileName);

        // // Create a reference for the upload task
        // const uploadTask = uploadBytesResumable(storageTemp, file.target.files[0]);

        // // Track the upload progress
        // uploadTask.on('state_changed',
        //   (snapshot) => {
        //     // Get the progress percentage
        //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        //     // Update the progress bar here
        //     // For example, you can set the progress to a state variable or update the UI
        //     setTextMessage(`${progress}% complete`)
        //   },
        //   (error) => {
        //     // Handle any errors that occur during the upload
        //   },
        //   async () => {
        //     // Upload complete
        //     const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        //     // Update the text message and image flag
        //     setTextMessage(downloadURL);
        //     setImageFlag(1);
        //   }
        // );
    };
    
    // Open report model
    const handleOpenPopUp = () => {
        //check if user has delete his profile
        if (deletedAt) {
            toast.warn('User has temporarily deleted his profile.');
        } else {
            setOpenRatingModal(!openRatingModal);
            setRating(0);
            setDescription("");
        }
    };

    useEffect(() => {
        if (Array.isArray(chatList)) {
            if (loginUser.role === 2) {
                setFilteredChatList(
                    chatList.filter(
                        (data) =>
                            data?.consumer_dtl?.first_name
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                            data?.event_dtl?.event_name
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase())
                    )
                );
            }
            if (loginUser.role === 3) {
                setFilteredChatList(
                    chatList.filter(
                        (data) =>
                            data?.vendor_dtl?.first_name
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                            data?.vendor_dtl?.business_name
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase())
                    )
                );
            }
        }
    }, [searchText, chatList]);

    // search to user from chat list
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleSetText = (e) => {
        setTextMessage(e.target.value);
        setImageFlag(0);
        // Update the typing status in the database
        //   const typingRef = ref(db, "typing/" + auth.currentUser);
        //   if (message) {
        //     set(typingRef, true);
        //   } else {
        //     set(typingRef, null);
        //   }
    };
    
    const handleDownloadPDF = async (pdfURL) => {
        const link = document.createElement("a");
        link.href = pdfURL;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        // Simulate a click event on the link to trigger the download
        link.click();
    };

    const messageFunction = () => {
        return messages.map((value) => {
            return value.receiver_id == loginUser.id ? (
                <div key={value.id} className="user-review-img">
                    <figure>
                        <img
                            className="chatbox-image"
                            src={
                                currentChatUser
                                    ? currentChatUser
                                    : filteredChatList[0]?.consumer_dtl
                                        ? filteredChatList[0]?.consumer_dtl?.user_profile
                                        : filteredChatList[0]?.vendor_dtl?.user_profile ||
                                        "./img/default-profile-picture.png"
                            }
                            alt="user img"
                        />
                    </figure>

                    <div className="reviewer-review">
                        {value.mesgType === "TEXT" && (
                            <div className="reviewer-review-top chat-icon-left">
                                <p>{value.message}</p>
                            </div>
                        )}
                        {value.mesgType === "TEXT" && (
                            <div>
                                <span className="">{moment(value.created_at).fromNow()}</span>
                            </div>
                        )}
                        {value.mesgType === "PDF" && (
                            <div style={{ textAlign: "left" }}>
                                <img
                                    className="chat-img-src-pdf"
                                    src="img/icons8-pdf-96.png"
                                    onClick={() => {
                                        let pdfURL = value.message;
                                        handleDownloadPDF(pdfURL);
                                    }}
                                    alt="Selected Image"
                                />
                                <div>
                                    <span className="">{moment(value.created_at).fromNow()}</span>
                                </div>
                            </div>
                        )}
                        {value.mesgType === "IMAGE" && (
                            <div className="chat-img-container-left">
                                <img
                                    className="chat-img-src img-radius"
                                    src={value.message}
                                    alt="image"
                                />
                                <div>
                                    <span className="">{moment(value.created_at).fromNow()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div key={value.id} className="user-review-img">
                    <div className="reviewer-review">
                        {value.mesgType === "TEXT" && (
                            <>
                                <div className="reviewer-review-top chat-icon-right">
                                    <p>{value.message}</p>
                                </div>
                                <span>{moment(value.created_at).fromNow()}</span>
                            </>
                        )}
                        {value.mesgType === "PDF" && (
                            <div style={{ textAlign: "right" }}>
                                <img
                                    title="download pdf"
                                    className="chat-img-src-pdf"
                                    src="img/icons8-pdf-96.png"
                                    onClick={() => {
                                        let pdfURL = value.message;
                                        handleDownloadPDF(pdfURL);
                                    }}
                                    alt="Selected Image"
                                />
                                <div>
                                    <span className="">{moment(value.created_at).fromNow()}</span>
                                </div>
                            </div>
                        )}
                        {value.mesgType === "IMAGE" && (
                            <div className="chat-img-container">
                                <img
                                    className="chat-img-src img-radius"
                                    src={value.message}
                                    alt="image"
                                />
                                <div>
                                    <span className="">{moment(value.created_at).fromNow()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <figure>
                        <img
                            className="chatbox-image mx-2"
                            src={userData?.profile ? userData?.profile : "./img/default-profile-picture.png"}
                            alt="user img"
                        />
                    </figure>
                </div>
            );
        });
    };

    return (
        <>
            <div className="wrapper">
                {loginUser.role === 2 ? <VendorHeader /> : <ConsumerHeader />}
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        {loginUser.role === 2 ? <VendorSidebar /> : <ConsumerSidebar />}
                        <div className="right-content-block recent-review-content-block chats-outer-block">
                            {chatLoader ? (
                                <div className={"loader-item"}>
                                    <Loader
                                        type="spinner-circle"
                                        bgColor={"#FFFFFF"}
                                        color={"#FFFFFF"}
                                        size={150}
                                    />
                                </div>
                            ): (
                            <>
                                {chatList && chatList.length > 0 ? (
                                    <div className="page-heading">
                                        <div className="user-review-left">
                                            <div className="recent-review-search-block">
                                                <div className="recent-review-search">
                                                    <input
                                                        type="text"
                                                        onChange={handleSearch}
                                                        className="form-control"
                                                        id="inlineFormInputGroup"
                                                        placeholder="Search"
                                                        aria-label="search"
                                                    />
                                                </div>
                                            </div>
                                            <div className="reviewer-name-wr tabber-list-blocks">
                                                {filteredChatList.length > 0 ? (
                                                    <>
                                                        {filteredChatList.map((data) => (
                                                            <div key={data.id}>
                                                                <div
                                                                    className={
                                                                        data.event_id +
                                                                            "-" +
                                                                            data.vendor_id +
                                                                            "-" +
                                                                            data.user_id ===
                                                                            eventId + "-" + vendorId + "-" + userId
                                                                            ? "reviewer-block tabber-list hover-active"
                                                                            : "reviewer-block tabber-list"
                                                                    }
                                                                >
                                                                    <a
                                                                        onClick={() =>
                                                                            handleSelectUser(
                                                                                data.event_id,
                                                                                data.vendor_id,
                                                                                data.user_id,
                                                                                loginUser.role === 2
                                                                                    ? data?.consumer_dtl?.first_name
                                                                                    : data?.vendor_dtl?.first_name,
                                                                                loginUser.role === 2
                                                                                    ? data?.consumer_dtl?.user_profile
                                                                                    : data?.vendor_dtl?.user_profile,
                                                                                loginUser.role === 2
                                                                                    ? data?.consumer_dtl?.deleted_at
                                                                                    : data?.vendor_dtl?.deleted_at,
                                                                                loginUser.role === 2
                                                                                    ? data?.consumer_dtl?.avgRating
                                                                                    : data?.vendor_dtl?.avgRating,
                                                                                data?.event_dtl
                                                                                    ? data?.event_dtl?.event_name
                                                                                    : "",
                                                                                data?.vendor_dtl?.slug,
                                                                            )
                                                                        }
                                                                        className="d-flex w-100"
                                                                    >
                                                                        {loginUser?.role === 2 ? (
                                                                            <figure>
                                                                                <img
                                                                                    className="chat-image"
                                                                                    src={data?.consumer_dtl?.user_profile}
                                                                                    alt="user img"
                                                                                />
                                                                                <span className="active"></span>
                                                                            </figure>
                                                                        ) : (
                                                                            <figure>
                                                                                <img
                                                                                    className="chat-image"
                                                                                    src={data?.vendor_dtl?.user_profile}
                                                                                    alt="user img"
                                                                                />
                                                                                <span className="active"></span>
                                                                            </figure>
                                                                        )}
                                                                        <div className="reviewer-details">
                                                                            {loginUser?.role === 2 ? (
                                                                                <>
                                                                                    <h3 className="common-label">
                                                                                        {data?.consumer_dtl?.first_name}{" "}
                                                                                        {data?.consumer_dtl?.last_name}
                                                                                    </h3>
                                                                                    <h5>{data?.event_dtl?.event_name}</h5>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <h3>
                                                                                        {data?.vendor_dtl?.first_name}{" "}
                                                                                        {data?.vendor_dtl?.last_name}
                                                                                    </h3>
                                                                                    <h5>{data?.event_dtl?.event_name}</h5>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        {data &&
                                                                            data?.unreadCount &&
                                                                            data?.unreadCount.count > 0 ? (
                                                                            <h5 className="unreadMessage">
                                                                                {data?.unreadCount?.count}
                                                                            </h5>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-review-right ">
                                            <div className="user-review-block tabber-content tab1">
                                                <div className="user-chat-top-box chat-box d-flex">
                                                    <div className="user-chat-head-left">
                                                        <figure>
                                                            <img
                                                                className="chatbox-image mx-2"
                                                                src={receiverProfile}
                                                                alt="user img"
                                                            />
                                                        </figure>
                                                        <div className="user-chat-box-left">
                                                            <div className="user-name-rating">
                                                                {loginUser?.role === 3 ? (
                                                                    <h4
                                                                        onClick={() => {
                                                                            if (deletedAt) {
                                                                                toast.warn('User has temporarily deleted his profile.');
                                                                            } else {
                                                                                navigate(`/vendor/${vendorSlug}`);
                                                                            }
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {receiverName}
                                                                    </h4>
                                                                ) : (
                                                                    <h4>{receiverName}</h4>
                                                                )}
                                                                <span className="rating" title="Rating">
                                                                    {avgRating}
                                                                    <span className="fa fa-star">
                                                                        {/* <img src="../img/rating-star.svg/" alt="" /> */}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            {loginUser?.role === 2 ? (
                                                                <p
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/event-details/${chatInfo.event_id}`
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="chat-event-name"
                                                                >
                                                                    {eventName}{" "}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    onClick={() =>
                                                                        navigate(`/view-event/${chatInfo.event_id}`)
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="chat-event-name"
                                                                >
                                                                    {eventName}{" "}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="user-chat-head-right">
                                                        {userReviewStatus ? (
                                                            <button className="btn btn_normal">
                                                                {" "}
                                                                Review Submitted
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn_normal"
                                                                onClick={handleOpenPopUp}
                                                            >
                                                                {" "}
                                                                Write a review
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="user-chat-bottom">
                                                    <div
                                                        ref={chatMessageBox}
                                                        className="user-review-block-inner"
                                                        id="chat-message-box"
                                                        style={{ height: "300px"}}
                                                    >
                                                        {messageFunction()}
                                                        <div></div>
                                                    </div>
                                                    {/* <div>
                                                        <input type='file' id='attach-doc' onChange={handleUpload} />
                                                    </div> */}
                                                    {flagImage ? (
                                                        <div className="imagePreview">
                                                            <div className="imageContainer">
                                                                <img
                                                                    className="chat-img-src"
                                                                    src={URL.createObjectURL(selectedImage)}
                                                                    alt="Selected Image"
                                                                />
                                                                <button
                                                                    className="crossButton"
                                                                    onClick={() => {
                                                                        setSelectedImage(null);
                                                                        setImageFlag(0);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {flagPDF && (
                                                        <div className="imagePreviewPdf">
                                                            <div className="imageContainer">
                                                                <img
                                                                    className="chat-img-src-pdf"
                                                                    src="img/icons8-pdf-96.png"
                                                                    alt="pdf"
                                                                />
                                                                <p className="pdf-label">{selectedPDF.name}</p>
                                                                <button
                                                                    className="crossButtonPdf"
                                                                    onClick={() => {
                                                                        setSelectedPDF(null);
                                                                        setFlagPDF(false);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(deletedAt) ? (                        
                                                        <div className="chats-textarea-block d-flex">
                                                            <div className="chats-textarea-form manage-chat-form">
                                                                <div className="chats-textarea">
                                                                    <p className="mt-3">
                                                                        User profile is temporarily disabled.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="chats-textarea-block d-flex">
                                                            <div className="chat-link-icon">
                                                                <figure>
                                                                    <label htmlFor="attach-doc">
                                                                        <img src="img/link-icon.svg" alt="Link" />
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        id="attach-doc"
                                                                        onChange={(e)=> handleUpload(e)}
                                                                        style={{ display: "none" }}
                                                                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="chats-textarea-form">
                                                                <div className="chats-textarea">
                                                                    <textarea
                                                                        placeholder="Write your message"
                                                                        type="text"
                                                                        className="form-control"
                                                                        onKeyPress={(e) => sendOnEnter(e)}
                                                                        onChange={(e) => handleSetText(e)}
                                                                        value={textMessage}
                                                                        aria-label="message…"
                                                                        disabled={flagImage ? true : false}
                                                                    />
                                                                </div>
                                                                {
                                                                    !loadingState ? 
                                                                    <button
                                                                        className="chats-submit-btn"
                                                                        type="button"
                                                                        onClick={() => sendMessage()}
                                                                    ></button>
                                                                    :
                                                                    <button
                                                                        className="chats-submit-btn-loader"
                                                                        type="button"
                                                                        disabled
                                                                    >
                                                                        <div className={"btn-loader"}>
                                                                            <img src="/img/loader.svg" className="loader-svg-img" />
                                                                        </div>
                                                                </button>    
                                                                }
                                                            
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="chat-note-text">
                                                    <p>
                                                        Note: OzGigs does not facilitate payment between
                                                        consumers and vendors.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-12 col-12">
                                        <div className="finaliseEvent">
                                            <div className="finaliseCard">
                                                <p className="finalContent text-center">No chats yet!</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                            )}
                        </div>
                    </div>
                </section>
                {loginUser.role === 2 ? <Footer /> : <Footer />}
            </div>
            <div>
                {openRatingModal && (
                    <Modal
                        isOpen={openRatingModal}
                        style={customStyles}
                        contentLabel="User Rating Modal"
                        overlayClassName="Overlay"
                    >
                        <button onClick={handleOpenPopUp} className="custom-close-btn">
                            X
                        </button>

                        {/* <div className="right-top-text mb-4">
                                            <h2>Rate your experience</h2>
                                        </div> */}
                        <form className="ratingPopup" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="rating">Rating:</label>
                                <StarRating
                                    rating={rating}
                                    starRatedColor="#1FBCFF"
                                    starEmptyColor="gray"
                                    changeRating={handleRating}
                                    numberOfStars={5}
                                    starSpacing="5px"
                                    starDimension="25px"
                                    step={0.5}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Write a review:</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    rows="4"
                                    cols="50"
                                    className="form-control"
                                ></textarea>
                            </div>
                            {loadingForm ? (
                                <>
                                    <button type="submit" className="btn" disabled>
                                        <div className={"btn-loader"}>
                                            <img src="/img/loader.svg" className="loader-svg-img" />
                                        </div>
                                    </button>
                                </>
                            ) : (
                                <button type="submit" className="btn">
                                    Submit
                                </button>
                            )}
                        </form>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default Chats;
