import { VendorDashboardData } from '../constants/user.constants';
import { commonService } from '../services/common.service';

const vendorDashboardAction = () => async (dispatch) =>  {
    try {
        const vendorDashboard = await commonService.withLogin(`/vendor-dashboard`);
        if (vendorDashboard) {
            dispatch({ type: VendorDashboardData.GET_VENDORDASHBOARD_SUCCESS, payload: { datasss: vendorDashboard.data } });
        }
        else {
            dispatch({ type: VendorDashboardData.GET_VENDORDASHBOARD_FAILURE, payload: { data: vendorDashboard.data } });
        }
    }
    catch(error) {
        dispatch({ type: VendorDashboardData.GET_VENDORDASHBOARD_FAILURE, payload: error.message })
    }
}

export default vendorDashboardAction;