import "./App.css";
import "./assets/css/responsive.css";
import { PrivateRouteVendor, PrivateRouteConsumer, AuthRoute } from "./components/PrivateRoute";
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import SidebarLayout from "./SidebarLayout";
import ForgotPassword from "./components/auth/password/forgetPassword";
import ResetPassword from "./components/auth/password/resetPassword";
import Login from "./components/auth/login/login";
import Signup from "./components/auth/registration/signup";
import { ToastContainer } from "react-toastify";
import EmailVerification from "./components/auth/emailVerification/EmailVerification";
import ResendEmailVerification from "./components/auth/emailVerification/ResendEmailVerification";
import Chats from "./components/chats";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

import HomePage from "./components/home/homePage";
import VendorHome from "./components/vendor/VendorHome";
import VendorUpdate from "./components/vendor/vendorUpdate";
import VendorDetails from "./components/vendor/VendorDetails";
import VendorDetailsBySlug from "./components/vendor/VendorDetailsBySlug";
import VendorDashboard from "./components/vendor/vendorDashboard/vendorDashboard";
import VendorEvents from "./components/vendor/VendorEvents";
import VendorPaymentHistory from "./components/vendor/vendorPaymentHistory";
import VendorPreview from "./components/vendor/VendorPreview";


import VendorSetting from "./components/vendor/VendorSetting";
import EventDetails from "./components/vendor/events/EventDetails";
import VendorReviews from "./components/vendor/VendorReviews";

import ConsumerDashboard from "./components/consumer/ConsumerDashboard";
import ConsumerProfile from "./components/consumer/ConsumerProfile";
import ConsumerReviews from "./components/consumer/ConsumerReviews";
import RecentEvents from "./components/consumer/events/RecentEvents";
import PastEvents from "./components/consumer/events/PastEvents";
import UpcomingEvents from "./components/consumer/events/UpcomingEvent";
import ViewEvent from "./components/consumer/events/ViewEvent";
import CreateEvent from "./components/consumer/events/CreateEvent";
import EditEvent from "./components/consumer/events/EditEvent";
import FavouriteVendor from "./components/consumer/FavouriteVendor";
import SubscriptionPage from './components/vendor/Subscription'

import {stateChange} from "./firebase"
import axios from "axios";
import ChangePassword from "./components/vendor/ChangePassword";
import ManageSubscription from "./components/vendor/ManageSubscription";
import AnalyticsComponent from "./components/vendor/AnalyticsComponent";
import TermsAndConditions from "./components/TermsAndConditions";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Faq from "./components/Faq";
import AboutUs from "./components/AboutUs";
import ContactSupport from "./components/ContactSupport";
import VendorInformationPage from "./components/VendorInformationPage";
import { getDatabase, ref, remove } from "firebase/database";

const App = (navigation) => {
    const [userDataStep, setUserDataStep] = useState();
    const [vendorSubscriptionStatus,setVendorSubscriptionStatus] = useState(null)
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const userData = userInfo?.data?.data?.user;
    const isMobile = require("is-mobile");
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    // Get vendor all information
    const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setUserDataStep(vendorProfile.datasss.data?.step)
            setVendorSubscriptionStatus(vendorProfile.datasss.data?.vendor_subscription)
        }
    }, [vendorProfile]);
    // const messaging = getMessaging();

    useEffect( () => {
        let initiate = async () => {
            stateChange();
        }
        initiate ();
    }, []);

    const idleTimeRef = useRef(null);
    const onIdle = () => {
        
        const db = getDatabase();
        const localStorageChatData = JSON.parse(
            localStorage.getItem("current_chat_id")
        );
        
        if(localStorageChatData){
            const previousActiveUserRef =
                userData.role === 2
                        ? `active_users/${localStorageChatData.event_id}_${localStorageChatData.vendor_id}_${localStorageChatData.user_id}`
                        : `active_users/${localStorageChatData.event_id}_${localStorageChatData.user_id}_${localStorageChatData.vendor_id}`;
            let removeActiveUser = ref(db, previousActiveUserRef);
            remove(removeActiveUser)
            .then((data) => {
                localStorage.removeItem("current_chat_id");
            })
            .catch((error) => {
                console.log(error, "node error");
            });
        }
        if (userInfo) {
            let fcm_token = localStorage.getItem('FCM_TOKEN')
            const headers = {
                'content-type': 'application/json',
                Authorization: `Bearer ${tokenObj.api_token}`
            }
            let obj = {
                user_id: userData.id,
                device_token: fcm_token,
                device_type : isMobile() ? 'mobile' : 'desktop'
            }
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`, obj, { headers }).then(response => {
                localStorage.removeItem("current_chat_id");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                localStorage.removeItem("FCM_TOKEN");
                localStorage.removeItem("isunsaved");
                localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
                window.location.href = "/home";
            })

        }
    };
    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimeRef,
        timeout: 15 * 60000,    
        // timeout: 5 * 1000,
        onIdle: onIdle,
    });

    //function to get and update user spent time on site
    useEffect(() => {
        // Function to handle focus event
        const handleFocus = () => {
            if (userInfo && userData && tokenObj) {
                const headers = { 'content-type': 'application/json', Authorization: `Bearer ${tokenObj.api_token}`};
                // Prepare the request payload
                const payload = { 
                    user_id: userData.id,
                    device_type : isMobile() ? 'mobile' : 'desktop',
                };
                // Send POST request to update spent time
                axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/update-spent-time`, payload, { headers })
                .then(response => {
                })
                .catch(error => {
                    console.error('Error updating spent time:', error);
                });
            }
        };
        // Call handleFocus immediately
        handleFocus();
        // Call handleFocus every 5 minutes (300000 milliseconds) if window is in focus
        const intervalId = setInterval(() => {
            if (document.hasFocus()) {
                handleFocus();
            }
        }, 300000);
        // Cleanup interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, [userInfo,tokenObj]);
    
    
    return (
        <BrowserRouter>
            <div idletimer={idleTimer}></div>
            <ToastContainer />
            <Routes>
                <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
                <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/vendor-information-page" element={<VendorInformationPage />}></Route>
                <Route path="/faq" element={<Faq />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/contact-us" element={<ContactSupport />}></Route>
                <Route element={<AuthRoute />}>
                    {/*************************** Route For Authentication *************************/}
                    <Route path="/" element={<HomePage />}></Route>
                    <Route path="/home" element={<HomePage />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/signup" element={<Signup />}></Route>
                    <Route path="/email-verification/:id" element={<EmailVerification />}></Route>
                    <Route path="/resend-email-verification" element={<ResendEmailVerification />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
                    
                </Route>
                {(userDataStep === 1 && vendorSubscriptionStatus) ? 
                    <>
                        <Route path="*" element={<Navigate to='/vendor-profile' />}></Route>
                        <Route path="/vendor-home" element={<PrivateRouteVendor><VendorHome /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-preview" element={<PrivateRouteVendor><VendorPreview /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-profile" element={<VendorUpdate />}></Route>
                    </>
                    :
                    <>
                        <Route path="*" element={<Navigate to='/vendor-home' />}></Route>
                        <Route path="/vendor-preview" element={<PrivateRouteVendor><VendorPreview /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-home" element={<PrivateRouteVendor><VendorHome /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-dashboard" element={<PrivateRouteVendor><VendorDashboard /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-profile/:id?" element={<PrivateRouteVendor><VendorUpdate /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-events" element={<PrivateRouteVendor><VendorEvents /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-settings" element={<PrivateRouteVendor><VendorSetting /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-payment-history" element={<PrivateRouteVendor><VendorPaymentHistory /></PrivateRouteVendor>}></Route>
                        <Route path="/manage-subscription" element={<PrivateRouteVendor><ManageSubscription /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-analytics" element={<PrivateRouteVendor><AnalyticsComponent /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-chats/:id?" element={<PrivateRouteVendor><Chats /></PrivateRouteVendor>}></Route>
                        <Route path="/event-details/:id" element={<PrivateRouteVendor><EventDetails /></PrivateRouteVendor>}></Route>
                        <Route path="/vendor-reviews" element={<PrivateRouteVendor><VendorReviews /></PrivateRouteVendor>}></Route>
                        <Route path="/change-password" element={<ChangePassword />}></Route>
                    </>
                }

                <Route path="/subscription" element={<SubscriptionPage />}></Route>
                {/* <Route path="/vendor-profile/:id?" element={<VendorUpdate />}></Route>   */}
                <Route path="/vendor-detail/:id" element={<VendorDetails />}></Route>
                <Route path="/vendor/:slug" element={<VendorDetailsBySlug />}></Route>
                <Route path="/view-event/:id" element={<ViewEvent />}></Route>
                
                <Route element={<PrivateRouteConsumer />}>
                    {/**************************** Route For consumer module **************************/}
                    <Route path="*" element={<Navigate to='/consumer-home' />}></Route>
                    <Route path="/consumer-home" element={<HomePage />}></Route>
                    <Route path="/consumer-dashboard" element={<ConsumerDashboard />}></Route>
                    <Route path="/consumer-profile" element={<ConsumerProfile />}></Route>
                    <Route path="/favourite-vendor-list" element={<FavouriteVendor />}></Route>
                    <Route path="/consumer-reviews" element={<ConsumerReviews />}></Route>
                    <Route path="/consumer-chats" element={<Chats />}></Route>
                    {/**************************** Route For Events module **************************/}
                   
                    <Route path="/create-event-page" element={<CreateEvent />}></Route>
                    <Route path="/edit-event-page/:id" element={<EditEvent />}></Route>
                    <Route path="/recent-events" element={<RecentEvents />}></Route>
                    <Route path="/upcoming-events" element={<UpcomingEvents />}></Route>
                    <Route path="/past-events" element={<PastEvents />}></Route>
                </Route>

            </Routes>
        </BrowserRouter>
    );
};
export default App;
