import { configureStore } from "@reduxjs/toolkit";
import {
  authenticationReducer,
  consumerReducer,
  dashboardReducer,
  detailsReducer,
  extraReducer,
  feedbackReducer,
  forgetReducer,
  googleReducer,
  logoutReducer,
  paymentReducer,
  reportReducer,
  signupReducer,
  subscriptionHistoryReducer,
  userblockReducer,
  registerReducer,
  vendorReducer,
  varificationReducer,
  resendVerificationReducer,
  resetReducer,
  updateVendorReducer,
  eventTypeReducer,
  categoryReducer,
  RegionReducer,
  chatListReducer,
  createChatReducer,
  sendChatNotificationReducer,

  vendorFavUnfavReducer,
  vendorHomeReducer,
  vendorDetailsReducer,
  slugIdReducer,
  vendorReelsReducer,
  searchVendorReelsReducer,
  vendorEventReducer,
  eventDetailsReducer,
  vendorDashboardReducer,
  vendorProfileReducer,
  vendorSubscriptionReducer,
  productReducer,
  checkoutSessionReducer,
  sessionRetriveReducer,
  vendorSubscriptionDetailsReducer,
  regionsCategoryListReducer,

  consumerDashboardReducer,
  consumerProfileReducer,
  consumerReviewsReducer,
  uploadProfileReducer,
  changePasswordReducer,
  favouriteVendorReducer,
  deleteProfileReducer,
  
  recentEventsReducer,
  pastEventsReducer,
  viewEventReducer,
  createEventReducer,
  editEventReducer,
  updateEventReducer,
  deleteEventReducer,
  notificationReducer,
  vendorReviewsReducer,
  staticDataListReducer,
  headerReducer,
} from "./reducers/reducer";

const store = configureStore({
  reducer: {
    user      : authenticationReducer,
    consumer  : consumerReducer,
    logout    : logoutReducer,
    forget    : forgetReducer,
    vendor    : vendorReducer,
    dashboard : dashboardReducer,
    payment   : paymentReducer,
    report    : reportReducer,
    feedback  : feedbackReducer,
    subscriptionHistory : subscriptionHistoryReducer,
    userblock : userblockReducer,
    details   : detailsReducer,
    signup    : registerReducer,
    register  : signupReducer,
    extra     : extraReducer,
    google    : googleReducer,
    varification : varificationReducer,
    resendVerification : resendVerificationReducer,
    reset     : resetReducer,
    updateVendor: updateVendorReducer,
    category  : categoryReducer,
    eventType : eventTypeReducer,
    region    : RegionReducer,
    favUnfav  : vendorFavUnfavReducer,
    chatList  : chatListReducer,
    createChat: createChatReducer,
    sendChatNotification : sendChatNotificationReducer,

    vendorHome    : vendorHomeReducer,
    vendorDashboard : vendorDashboardReducer,
    vendorDetails : vendorDetailsReducer,
    vendorReels   : vendorReelsReducer,
    searchReels   : searchVendorReelsReducer,
    vendorEvents  : vendorEventReducer,
    eventDetails  : eventDetailsReducer,
    vendorProfile : vendorProfileReducer,
    vendorReviews : vendorReviewsReducer,
    vendorSubscription : vendorSubscriptionReducer,
    product       : productReducer,
    checkoutSession : checkoutSessionReducer,
    sessionRetrive  : sessionRetriveReducer,
    vendorSubscriptionDetails : vendorSubscriptionDetailsReducer,
    regionsCategoryList: regionsCategoryListReducer,
    slugData: slugIdReducer,
    
    
    consumerDashboard : consumerDashboardReducer,
    consumerProfile   : consumerProfileReducer,
    consumerReviews   : consumerReviewsReducer,
    uploadProfile     : uploadProfileReducer,
    changePassword    : changePasswordReducer,
    favouriteVendor   : favouriteVendorReducer,
    deleteProfile     : deleteProfileReducer,
    
    recentEvents  : recentEventsReducer,
    pastEvents    : pastEventsReducer,
    // eventType:vendorEventReducer,
    viewEvent     : viewEventReducer,
    createEvent   : createEventReducer,
    editEvent     : editEventReducer,
    updateEvent   : updateEventReducer,
    deleteEvent   : deleteEventReducer,
    notificationList : notificationReducer,
    staticDataList : staticDataListReducer,
    header: headerReducer,
  },
});
export default store;
