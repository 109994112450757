import { commonService } from "../services/common.service";
import { VendorHomeConst } from "../constants/user.constants";

const vendorHomeAction = (data) => async (dispatch) => {
    try{
        const vendorHome = await commonService.withToken(`/vendor-home`, data);
        if (vendorHome) {
            dispatch({ type: VendorHomeConst.GET_VENDORHOME_SUCCESS, payload: { datasss: vendorHome.data } });
        }
        else {
            dispatch({ type: VendorHomeConst.GET_VENDORHOME_FAILURE, payload: { data: vendorHome.data } });
        }
    }
    catch (error) {
        dispatch({ type: VendorHomeConst.GET_VENDORHOME_FAILURE, payload: error.message })
    }
}

export default vendorHomeAction;