import { commonService } from "../services/common.service"
import { UpdateEventConst } from "../constants/user.constants"
import { clearUpdateEventData } from "../constants/user.constants"

const updateEventAction = (data) => async (dispatch) =>{
    try{
        const updateEvent = await commonService.withToken(`/edit-event`, data)
        if(updateEvent){
            dispatch({type: UpdateEventConst.GET_UPDATEEVENT_SUCCESS , payload: {datasss: updateEvent.data}})
        }
        else{
            dispatch({type: UpdateEventConst.GET_UPDATEEVENT_FAILURE , payload: {data: updateEvent.data}})
        }
    }catch(error){
        console.log(`Error while calling reset api ${error}`)
        dispatch({ type: UpdateEventConst.GET_UPDATEEVENT_FAILURE , payload: error.message})
    }
}

export const clearUpdateEventMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearUpdateEventData.CLEAR_UPDATEEVENT_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
}

export default updateEventAction; 