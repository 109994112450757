import React, { useState, useEffect } from "react";
import authenticationReset from "../../../actions/reset";
import clearResetPasswordMsg from "../../../actions/reset";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../includes/header";
import Footer from "../../includes/footer";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import PasswordChecklist from "react-password-checklist";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_Password] = useState("");
    const [error, setError] = useState(false);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(eyeOff);
    const [loading, setLoading] = useState(false);

    const [cptype, setTypePass] = useState("password");
    const [cpicon, setIconPass] = useState(eyeOff);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!password || !confirm_password) {
            setError(true);
            setLoading(false);
            return false;
        }
        else if (password != confirm_password) { 
            toast.warn("Password should be same"); 
            dispatch(clearResetPasswordMsg());
            setLoading(false);
            return false;
        }
        else if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/).test(password)) {
            toast.warn("Password format is invalid. Password should be atlest 8 Characters, 1 upercase, 1 lowercase, 1 numeric, 1 special charactor.");
            dispatch(clearResetPasswordMsg());
            setLoading(false);
            return false;
        }
        else {
            await dispatch(authenticationReset({
                email: id,
                password: password,
                confirm_password: confirm_password,
            }));
        }
    };

    const { reset } = useSelector((state) => state.reset);
    useEffect(() => {
        /*if (reset?.datasss && reset?.datasss?.status === 400) {
            toast(reset?.datasss?.data.password[0]);
            dispatch(clearResetPasswordMsg());
            setLoading(false);
        }*/
        if (reset && reset?.datasss && reset?.datasss && reset?.datasss?.status === true) {
            toast.success(reset?.datasss?.message);
            dispatch(clearResetPasswordMsg());
            setLoading(false);
            navigate("/login");
        }
    }, [reset]);

    const handelToggle = () => {
        if (type === "password") { setIcon(eye); setType("text"); }
        else { setIcon(eyeOff); setType("password"); }
    };

    const handelTogglePassword = () => {
        if (cptype === "password") {
            setIconPass(eye);
            setTypePass("text");
        } else {
            setIconPass(eyeOff);
            setTypePass("password");
        }
    };
    return (
        <>
            <div className="wrapper">
                <Header />
                <section className=" middle-section">
                    <div className="signup-page">
                        <div className="container">
                            <div className="custom-form login_screen common-inner-container fadeIn wow">
                                <div className="row g-0 custom-form-inner">
                                    <div className="col-md-5 col-12 cust-remove-padding">
                                    <div className="login-left-sec">
                                            <img src="/img/stockSignUp.jpeg" alt="login-bg" />
                                            {/* <div className="bg-gradients">
                                                    </div> */}
                                            <div className="login-tagline ">
                                                <h2 className=" fadeInUp wow">WELCOME!</h2>
                                                {/* <p className=" fadeInUp wow">
                                                    It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when
                                                    looking at its layout.
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-12 cust-remove-padding login-right mt-5">
                                        <div className="form-container">
                                            <div className="text-center fadeInUp wow">
                                                <h1>Reset Password</h1>
                                            </div>
                                            <form action="#" onSubmit={handleSubmit} className="loginForm">
                                                <div className="form-group fadeInUp wow">
                                                    <label>Password</label>
                                                    <div className="input-iconParent mb-3">
                                                        <input type={type} value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} id="u-password" className="form-control" name="password" />
                                                        <span onClick={handelToggle} className="input-icon ">
                                                            <Icon icon={icon} size={25} />
                                                        </span>
                                                        {error && !password && (
                                                            <span
                                                                style={{ color: "red" }}
                                                                className="invalidInput"
                                                            >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                    <PasswordChecklist rules={["minLength","specialChar","number","capital",]}
                                                        minLength={8}
                                                        value={password}
                                                        messages={{
                                                            minLength:
                                                                "At least 8 characters—the more characters, the better",
                                                            specialChar: "One special character",
                                                            number: "At least 1 number",
                                                            capital: "At least Capital Latter",
                                                        }}
                                                    />
                                                    {/* {error && !password && (
                                                        <span style={{ color: "red" }} className="invalidInput"> Required *</span>
                                                    )} */}
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <div className="input-iconParent mb-3">
                                                        <input type={cptype} value={confirm_password} placeholder="Password" onChange={(e) => setConfirm_Password(e.target.value)} id="uc-password" className="form-control" name="password" />
                                                        <span onClick={handelTogglePassword} className="input-icon ">
                                                            <Icon icon={cpicon} size={25} />
                                                        </span>
                                                        {error && !confirm_password && (
                                                            <span style={{ color: "red" }} className="invalidInput" > Required * </span>
                                                        )}
                                                    </div>
                                                    <div className="form-group form-footer mt-4">
                                                        {(loading) ? (
                                                            <>
                                                                <button type="submit" className="btn fadeInUp wow" disabled >
                                                                    <div className={"btn-loader"}>
                                                                        <img src="/img/loader.svg" className="loader-svg-img" />
                                                                    </div>
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button type="submit" className="btn fadeInUp wow"> Reset Password </button>
                                                            </>
                                                        )}                                                        
                                                        <div
                                                            className="forgot"
                                                            style={{ marginTop: "2rem" }}
                                                        >
                                                            {/* <Link to="/login">Login</Link> */}
                                                            <p className="footerlink fadeInUp wow">
                                                                Already Have An Account?{" "}
                                                                <Link to="/login">Login</Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default ResetPassword;
