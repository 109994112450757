import React, { useEffect, useState, useRef } from 'react';
// import $, { data } from "jquery"
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import VendorHeader from '../includes/header';
import VendorFooter from "../../includes/footer";
import Loader from 'react-js-loader';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import eventDetailsAction from '../../../actions/eventDetails';
import createChatAction from '../../../actions/eventChatAction';
import { getDatabase, ref, set,push ,onValue} from "firebase/database";
import { getAuth } from "firebase/auth";
import { toast } from 'react-toastify';
import moment from "moment";

const EventDetails = () => {
    
    // const google = window.google;
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollToRef = useRef(null);
    const [user_id, setUser_id] = useState("");
    const [loading, setLoading] = useState(false);
    const [eventDtl,seteventDtl] = useState("");
    // const [latitude, setLatitude] = useState("");
    // const [longitude, setLongitude] = useState("");
    
    const [msgField , setMsgField] = useState(false);
    const [message, setMessage] = useState("");
    const [totalBudget, setTotalBudget] = useState(0)
    const [eventDecription,setEventDecription] = useState([])


    // function initialize(latitude, longitude) {
    //     var input = document.getElementById('address');
    //     // to permmition for location
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(showPosition);
    //     } else {
    //         alert('Geolocation is not supported by this browser.'); 
    //     }
    //     function showPosition(position) {
    //         setLatitude(latitude); 
    //         setLongitude(longitude);
    //     }

    //     const options = {
    //         componentRestrictions: { country: "aus" },
    //         fields: ["address_components", "geometry", "icon", "name"],
    //         // strictBounds: false,
    //         // types: ["establishment"],
    //     };        
    //     let autocomplete = new google.maps.places.Autocomplete(input,options);
    //     // Set initial restriction to the greater list of countries.
    //     autocomplete.setComponentRestrictions({
    //         country: ["aus"],
    //     });
    //     autocomplete.addListener('place_changed', function () {
    //     var place = autocomplete.getPlace();
    //         // place variable will have all the information you are looking for.
    //         setLatitude(latitude);
    //         setLongitude(longitude);
    //     });
    // }

    
    // useEffect(() => {
    //     initialize( )
    //     setLoading(true);
    //     dispatch(eventDetailsAction(id));
    //     window.addEventListener('load', () => initialize(latitude, longitude));
    // }, [latitude, longitude]);

    useEffect(() => {
       
        setLoading(true);
        dispatch(eventDetailsAction(id));
        
    },[dispatch, id]);

    // useEffect(()=>{
    // initialize(latitude,longitude);
    //     google.maps.event.addDomListener(window, 'load',initialize);
    // },[latitude,longitude])
    
    const event = useSelector((state) => state.eventDetails.eventDetails);
    useEffect(() => {
        if(event && event.datasss && event.datasss.status === true ){
            const eventDescription = event.datasss.data.event_description;
            const descriptionLines = eventDescription ? eventDescription.split('\n') : [];
            setEventDecription(descriptionLines)
            seteventDtl(event.datasss.data)
            setUser_id(event.datasss.data.user_id)
            // setLatitude(event.datasss.data.latitude)
            // setLongitude(event.datasss.data.longitude)
            setLoading(false);
        }
    }, [event]);

    useEffect(()=> {
        const sum = eventDtl?.event_category?.reduce((total , element) => total + element.budget, 0)
        setTotalBudget(sum)
    },[event,eventDtl])


    let nodeData = '';
    const db = getDatabase();
    // const auth = getAuth();
    // const user = auth.currentUser;
    const users = JSON.parse(localStorage.getItem("userData"));
    var vendorData = users?.data?.data?.user;

    const OpenMessageBox = () => {
        const chatNode = ref(db, 'messages/'+id+"_"+vendorData.id+"_"+user_id)
        // console.log()
        onValue(chatNode, (snapshot) => {
            nodeData = snapshot.val();
            if(nodeData === null || nodeData === ""){
                setMsgField(true)
                if (scrollToRef.current) {
                    // Calculate the position to scroll to
                    const scrollOptions = {
                      top: scrollToRef.current.offsetTop,
                      behavior: 'smooth', // Optional: Scroll with smooth animation
                    };
                    // Scroll to the target element
                    window.scrollTo(scrollOptions);
                }
            }else{
                navigate('/vendor-chats',{state:{
                    eventId : Number(id),
                    vendorId: vendorData.id,
                    userId  : user_id,
                    name    : eventDtl?.user?.first_name,
                    profile : eventDtl?.user?.profile ?? '',
                    deleted_at : eventDtl?.user?.deleted_at,
                    eveName : eventDtl?.event_name
                }});
            }
        });
    }

    // create chat node or save chat listin database
    const sendMessage = async () => {
        if(message){
            if (id && vendorData && user_id) {
                const chatNode = ref(db, 'messages/'+id+"_"+vendorData.id+"_"+user_id)
                onValue(chatNode, (snapshot) => {
                    nodeData = snapshot.val();
                    if(nodeData === null) {
                        set(push(ref(db, 'messages/'+id+"_"+vendorData.id+"_"+user_id)), {
                            // uid           : user.uid,
                            message       : message,
                            mesgType      : 'TEXT',
                            sender_id     : vendorData?.id,
                            sender_name   : vendorData?.first_name,
                            sender_profile: vendorData?.profile ?? '',
                            receiver_id   : user_id,
                            receiver_name : eventDtl?.user?.first_name,
                            receiver_profile : eventDtl?.user?.profile ?? '',
                            created_at    : Date.now()
                        });
                        
                        dispatch(
                            createChatAction({
                                event_id  : id,
                                vendor_id : vendorData.id,
                                user_id   : user_id,
                                chatType  : 'eventchat'
                            })
                        );
                    }
                })
            } else {
                toast('something went wrong');
            }
        }
    }

    // message send on press enter
    const sendOnEnter =(e)=>{
        if(e.key === "Enter"){
            sendMessage()
        }
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      const handleLocationClick = (loc) => {
        if (loc) {
          const location = encodeURIComponent(loc);
          const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;
          window.open(googleMapsUrl, "_blank");
        }
      };

    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className="sidebar-content-wr consumer-block event-listing-details-wrapper event-details">
                            <div className="container">
                                <div className="right-content-block">
                                    <div className="joshn-wedding">
                                        <div className="banner-inner-block">
                                            <div className="event-listing-type-date">
                                                <div className="event-listing-type">
                                                    <ul>
                                                        <li>{eventDtl.event_name}</li>
                                                        <li>Event Type : {eventDtl.event_type ?? '-'} </li>
                                                    </ul>
                                                </div>
                                                <div className="event-listing-date">
                                                    <div className="event-listing-date-inner">
                                                        <span><img src="./../img/event_calender_icon.svg" alt="new" /></span>
                                                        <p>Event Date:</p>
                                                        <p>{moment(eventDtl.event_from_date).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                    <div className="event-listing-date-inner">
                                                        <div className="event-footer">
                                                            {/* <Link to={`/vendor-chats/${eventDtl.id}`}>Chat Now</Link> */}
                                                            <Link className="button button-primary" onClick={(e)=>{e.preventDefault();OpenMessageBox()}}>Chat Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="event-listing-consumer-block">
                                                <div className="event-listing-consumer-inner">
                                                    <span><img src="./../img/listing_consumer_icon.svg" alt="new"/></span>
                                                    <h5>Consumer’s name:</h5>
                                                    <p>{eventDtl?.user?.first_name}</p>
                                                </div>
                                                <div className="event-listing-consumer-inner">
                                                    <span><img src="./../img/listing_member_icon.svg" alt="new" /></span>
                                                    <h5>Member Since:</h5>
                                                    <p>{moment(eventDtl?.user?.email_verified_at).format('MMMM YYYY,')}</p>
                                                </div>
                                                <div className="event-listing-consumer-inner">
                                                    <span><img src="./../img/listing_rating_icon.svg" alt="new" /></span>
                                                    <h5>Ratings:</h5>                                
                                                    <span><img src="./../img/listing_rating_star.svg" alt="new"/></span>
                                                    <p>{(eventDtl?.user?.avgRating) ? eventDtl?.user?.avgRating : 0 } ({(eventDtl?.user?.user_review) ? eventDtl?.user?.user_review.length : 0 } Reviews)</p>
                                                </div>
                                            </div>
                                            <div className="event-listing-budget-block">
                                                <div className="event-listing-budget-inner">
                                                    <span><img src="./../img/Frame.svg" alt="new" /></span>
                                                    <h5>Event Budget Total:</h5>
                                                    {/* <p>{(eventDtl.event_budget) ? '$ ' + numberWithCommas(eventDtl.event_budget) : 'N/A' } </p> */}
                                                    <p>{totalBudget ? '$ ' + numberWithCommas(totalBudget) : 'N/A' } </p>
                                                    
                                                </div>
                                                <div className="event-listing-budget-inner">
                                                    <span><img src="./../img/location_icon.svg" alt="new" /></span>
                                                    <h5>Address:</h5>
                                                    <a href='#' onClick={()=>handleLocationClick(eventDtl?.location)}>{eventDtl?.location ? eventDtl?.location : 'N/A'}</a>
                                                </div>
                                                <div className="event-listing-budget-inner">
                                                    <span><img src="./../img/carbon_user-multiple.svg" alt="new" /></span>
                                                    <h5>Number of Guests:</h5>
                                                    <p>{(eventDtl?.num_of_guest ? eventDtl?.num_of_guest : 'N/A')}</p>
                                                </div>
                                            </div>
                                            {eventDtl?.event_category?.length > 0 ? <>
                                                <div className="event-listing-service-block">
                                                    <div className="event-listing-service-head">
                                                        <span><img src="./../img/Frame.svg" alt="new" /></span>
                                                        <h5>Vendor Required:</h5>
                                                    </div>
                                                    <div className="event-listing-service-list">
                                                        <ul>
                                                        {eventDtl.event_category.map((x , index) => {
                                                            return <ul key={index}>
                                                                <li>{x.category.category_name} {(x.budget) ? '- $' + numberWithCommas(x.budget) +' budget' : '- Message for budget' }</li>
                                                            </ul>
                                                        })}
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </> : null}
                                            <div className="event-brief-description-block">
                                                <div className="event-brief-description-head">
                                                    <h4 className="common-label">Event description</h4>
                                                </div>
                                                <div className="event-brief-description-box">
                                                {eventDecription.map((line, index) => (
                                                <span key={index}>{line}<br/></span>
                                                ))}
                                                    {/* <p>{eventDtl.event_description}</p> */}
                                                    <a  href={eventDtl.event_file} download={eventDtl.event_file} target="_blank" rel="noreferrer">View Document</a>
                                                </div>
                                            </div>
                                            <div className="event-listing-details-map">
                                            {/* <div className="map-img" id="map">
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                                                yesIWantToUseGoogleMapApiInternals
                                                center={{
                                                    lat: Number(latitude),
                                                    lng: Number(longitude)
                                                }}
                                                defaultZoom={17}
                                            >
                                            <Marker
                                                lat={Number(latitude)}
                                                lng={Number(longitude)}
                                            />
                                            </GoogleMapReact>
                                        </div> */}
                                              
                                            </div>
                                            <div className="event-listing-details-btn">
                                                {(msgField) ? (
                                                    <>
                                                        <div className="chats-textarea-block">
                                                            <div className="chats-textarea-form">
                                                                <div className="chats-textarea">
                                                                    <textarea placeholder="Write your messsage" type="text" className="form-control" onKeyPress={(e)=> sendOnEnter(e)} onChange={(e) => setMessage(e.target.value)} aria-label="message…" />
                                                                </div>
                                                                <button className="chats-submit-btn" type="button" onClick={sendMessage}> </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className="event-footer" ref={scrollToRef}>
                                                        <Link className="button button-primary" onClick={(e)=>{e.preventDefault();OpenMessageBox()}}>Send Message</Link>
                                                    </div>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <VendorFooter />
                    </div>
                </>
            )}
        </>
    )
}

export default EventDetails;
