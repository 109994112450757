import { resetPassword } from "../constants/user.constants";
import { commonService } from "../services/common.service";
import { clearResetPasswordData } from "../constants/user.constants";

const authenticationReset = (datass) => async (dispatch) => {
    try {
        const data = await commonService.withOutToken(`/reset-password`, datass);
        if (data) {
            dispatch({ type: resetPassword.GET_RESET_SUCCESS, payload: { datasss: data.data, loading: false } })
        }
        else {
            dispatch({ type: resetPassword.GET_RESET_FAILURE, payload: { data: data.data } })
        }
    } 
    catch (error) {
        console.log(`Error while calling login api ${error}`);
        dispatch({ type: resetPassword.GET_RESET_FAILURE, payload: error.message })
    }
}

export const clearResetPasswordMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearResetPasswordData.CLEAR_RESET_PASSWORD_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
}


export default authenticationReset;