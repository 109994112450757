import { commonService } from "../services/common.service";
import { ChangePasswordConst } from "../constants/user.constants";

const changePasswordAction = (data) => async (dispatch) => {
    try {
        const changePassword = await commonService.withToken(`/change-password`, data);
            if (changePassword) {
            dispatch({ type: ChangePasswordConst.GET_CHANGEPASSWORD_SUCCESS, payload: { datasss: changePassword.data } });
        }
        else {
            dispatch({ type: ChangePasswordConst.GET_CHANGEPASSWORD_FAILURE, payload: { data: changePassword.message } });
        }
    }
    catch (error) {
        dispatch({ type: ChangePasswordConst.GET_CHANGEPASSWORD_FAILURE, payload: error.message })
    }
}

export default changePasswordAction;