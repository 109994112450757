import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VendorProfileAction from "../../../actions/VendorProfileAction";

const VendorSidebar = () => {
    
    const dispatch = useDispatch();
    const [vendorInfo, setVendorInfo] = useState([]);
    
    // useEffect(() => {
    //     dispatch(VendorProfileAction());
    // }, [dispatch]);

    const path = window.location.pathname;

    // Get vendor all information
    const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setVendorInfo(vendorProfile.datasss.data);
        }
    }, [vendorProfile]);
    
    useEffect(() => {
        const toggleSidebar = () => {
          $(".left-sidebar").toggleClass("sidebar-toggle");
        };
      
        const handleReviewerBlockClick = () => {
          $("html, body").animate(
            {
              scrollTop: $(".user-review-right").offset().top,
            },
            1000
          );
        };
      
        const handleClickOutside = (event) => {
          const sidebar = $(".left-sidebar");
          if (!sidebar.is(event.target) && sidebar.has(event.target).length === 0) {
            sidebar.removeClass("sidebar-toggle");
          }
        };
      
        $(".sidebar-mob-menu").click(toggleSidebar);
        $(".reviewer-block").click(handleReviewerBlockClick);
        $(document).on("click", handleClickOutside);
      
        return () => {
          $(".sidebar-mob-menu").off("click", toggleSidebar);
          $(".reviewer-block").off("click", handleReviewerBlockClick);
          $(document).off("click", handleClickOutside);
        };
      }, []);

    return (
        <>
            <div className="left-sidebar">
                <div className="sidebar-inner">
                    <div className="sidebar-mob-menu"></div>
                    <div className='sidemenu-com'>
                    <div className="sidebar-img">
                        <img src={(vendorInfo?.profile) ? vendorInfo?.profile : ""} alt="Profile"/>
                        {/* <p>{(vendorInfo) ? (vendorInfo.first_name+' '+vendorInfo.last_name) : 'None'}</p> */}
                    </div>
                    <div className="sidebar-icon-block">
                        <div className="sidebar-icon">
                            <ul>
                                <li>
                                    <Link className={(path === '/vendor-home') ? 'active' :''} to="/vendor-home" title="Event Marketplace"><img src="/img/shop-icon.png" alt="Sidebar icon"/></Link>
                                </li>
                                <li>
                                    <Link className={(path === '/vendor-dashboard') ? 'active' :''} to="/vendor-dashboard" title="Dashboard"><img src="/img/dashboard.svg" alt="Sidebar icon"/></Link>
                                </li>
                                <li>
                                    <Link className={(path === '/vendor-events') ? 'active' :''} to="/vendor-events" title="Events"><img src="/img/recent-events.png" alt="Sidebar icon"/></Link>
                                </li>
                                <li>
                                    <Link className={(path === '/vendor-chats') ? 'active' :''} to="/vendor-chats" title="Messages"><img src="/img/chat-box-30.png" alt="Sidebar icon"/></Link>
                                </li>
                                <li>
                                    <Link className={(path === '/vendor-reviews') ? 'active' :''} to="/vendor-reviews" title="Reviews"><img src="/img/reviews-30.png" alt="Sidebar icon"/></Link>
                                </li>
                                <li>
                                    <Link className={(path === '/vendor-analytics') ? 'active' :''} to="/vendor-analytics" title="Analytics"><img src="/img/analytics.png" alt="Sidebar icon"/></Link>
                                </li>
                                {/* <li>
                                    <a href="#"><i className="fa fa-credit-card"></i></a>
                                </li> */}
                                <li>
                                    <Link className={(path === '/vendor-settings') ? 'active' :''}  to="/vendor-settings" title="Settings"><img src="/img/sidebar-icon-6.svg" alt="Sidebar icon"/></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorSidebar;