import React, { useEffect, useState } from "react";
import Loader from 'react-js-loader';
import VendorHeader from "./includes/header";
import VendorFooter from "../includes/footer";
import VendorSidebar from "./includes/sidebar";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const AnalyticsComponent = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [vendorInfo, setVendorInfo] = useState([]);
  const [graphCheck, setGraphCheck] = useState("yearly");
  const [graphData, setGraphData] = useState([]);
  const [currentID, setCurrentID] = useState("");

  const [landScapeData, setLandScapeData] = useState({});
  const [portraitData, setPortraitData] = useState({});
  const [videosData, setVideosData] = useState([]);
  const [loading, setLoading] = useState(true);
  const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);

  let tokenObj = JSON.parse(localStorage.getItem("token"));
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${tokenObj.api_token}`,
  };

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => { setLoading (false); },1000);
  // },[]);

  useEffect(() => {
    if (
      vendorProfile &&
      vendorProfile.datasss &&
      vendorProfile.datasss.status === true
    ) {
      setVendorInfo(vendorProfile.datasss.data);
      let tempData = vendorProfile.datasss.data;

      let landscapeIndex = tempData.reels.find(
        (reel) => reel?.type === "landscape"
      );
      let portraitIndex = tempData.reels.find(
        (reel) => reel?.type === "portrait"
      );

      setLandScapeData(landscapeIndex);
      setPortraitData(portraitIndex);

      let videos = tempData.vendor_photos.filter(
        (photo) => photo?.type === "video"
      );
      let finalVideos =
        videos &&
        videos.map((item, i) => {
          return {
            ...item,
            tab_id: i + 3,
          };
        });
      setVideosData(finalVideos && finalVideos);

      if (portraitIndex && landscapeIndex) {
        setActiveTab("tab1");
      } else if (landscapeIndex) {
        setActiveTab("tab1");
      } else if (portraitIndex) {
        setActiveTab("tab2");
      }

      const reelData = {
        post_id: tempData.reels[0].id,
        type: "yearly",
        // "vendor_id": tempData.reels[0].vendor_id
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    setLoading (false);
  }, [vendorProfile]);

  const chartYearlyData = [
    {
      name: "Views",
      data: graphData.map((data) => data.views),
    },
  ];

  const chartMonthlyData = [
    {
      name: "Views",
      data: graphData.map((data) => data.views),
    },
  ];

  const chartOptions = {
    chart: {
      id: "dashed-line-chart",
      toolbar: {
        show: false, // Remove the toolbar
      },
    },
    stroke: {
      dashArray: 0,
    },
    xaxis: {
      //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      categories:
        graphCheck === "yearly"
          ? graphData.map((data) => data.month)
          : graphCheck === "monthly"
            ? graphData.map((data) => data.day)
            : null,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#FF0000"], // Change value font color here
      },
      background: {
        enabled: true,
        foreColor: "#FFFFFF", // Change label background color here
        borderRadius: 50, // Adjust the value to change the circular shape
        padding: 6, // Adjust the value to control the label padding
      },
    },
    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      background: "#f3f3f3",
      style: {
        fontSize: "12px",
      },
    },
  };

  const handleActiveTab = (tab, id, type) => {
    setActiveTab(tab);
    setCurrentID(id);
    let tempData = vendorProfile?.datasss?.data;

    if (tab === "tab1" && type === "landscape") {
      const reelData = {
        post_id: id,
        type: graphCheck,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (tab === "tab2" && type === "portrait") {
      const reelData = {
        post_id: id,
        type: graphCheck,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (tab === "tab3" || tab === "tab4") {
      const videos = tempData.vendor_photos.filter(
        (data) => data.type === "video"
      );
      const index = tab === "tab3" ? 0 : 1;
      if (videos.length > index) {
        const id = videos[index].id;
        const reelData = {
          post_id: id,
          type: graphCheck,
        };
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
            reelData,
            { headers }
          )
          .then((response) => {
            setGraphData(response.data.data);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const handleActiveGraph = (value) => {
    setGraphCheck(value);
    if (value === "monthly" && activeTab === "tab1") {
      const reelData = {
        post_id: landScapeData.id,
        type: "monthly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (value === "yearly" && activeTab === "tab1") {
      const reelData = {
        post_id: landScapeData.id,
        type: "yearly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (value === "monthly" && activeTab === "tab2") {
      const reelData = {
        post_id: portraitData.id,
        type: "monthly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (value === "yearly" && activeTab === "tab2") {
      const reelData = {
        post_id: portraitData.id,
        type: "yearly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (value === "monthly" && activeTab === "tab3") {
      const reelData = {
        post_id: videosData[0].id,
        type: "monthly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (value === "yearly" && activeTab === "tab3") {
      const reelData = {
        post_id: videosData[0].id,
        type: "yearly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (value === "monthly" && activeTab === "tab4") {
      const reelData = {
        post_id: videosData[1].id,
        type: "monthly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (value === "yearly" && activeTab === "tab4") {
      const reelData = {
        post_id: videosData[1].id,
        type: "yearly",
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/video-analytics`,
          reelData,
          { headers }
        )
        .then((response) => {
          setGraphData(response.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      {loading ? (
          <div className={"loader-item"}>
              <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
          </div>
      ) : (
        <>
          <div className="wrapper">
            <VendorHeader />
            <section className="sidebar-content-wr consumer-block">
              <div className="container">
                <VendorSidebar />
                <div className="right-content-block">
                  <div className="right-top-text">
                    <h3>ANALYTICS</h3>
                  </div>
                  <div className="analytics-tabber-list-block tabber-list-blocks">
                    {landScapeData ? (
                      <div
                        className={`analytics-tabber-list tabber-list ${activeTab === "tab1" ? "hover-active" : ""
                          }`}
                        tab-id="tab1"
                        onClick={() =>
                          handleActiveTab(
                            "tab1",
                            landScapeData.id,
                            landScapeData.type
                          )
                        }
                      >
                        <div className="analytics-tabber-top">
                          <div className="analytics-tabber-number">
                            <h4>
                              {landScapeData.view_count
                                ? landScapeData.view_count
                                : 0}
                            </h4>
                          </div>
                          <div className="analytics-tabber-arrow">
                            <a>
                              <img
                                src="img/chevron_white_up.svg"
                                alt="Chevron Icon"
                              />
                            </a>
                          </div>
                        </div>

                        <div className="analytics-tabber-para">
                          <p>Number of Views on landscape Reel</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={"analytics-tabber-list tabber-list"}
                        tab-id="tab1"
                        onClick={() =>
                          toast.warn("There are no landscape reel yet!")
                        }
                      >
                        <div className="analytics-tabber-top">
                          <div className="analytics-tabber-number">
                            <h4>0</h4>
                          </div>
                          <div className="analytics-tabber-arrow">
                            <a >
                              <img
                                src="img/chevron_white_up.svg"
                                alt="Chevron Icon"
                              />
                            </a>
                          </div>
                        </div>

                        <div className="analytics-tabber-para">
                          <p>Number of Views on landscape Reel</p>
                        </div>
                      </div>
                    )}

                    {portraitData ? (
                      <div
                        className={`analytics-tabber-list tabber-list ${activeTab === "tab2" ? "hover-active" : ""
                          }`}
                        tab-id="tab2"
                        onClick={() =>
                          handleActiveTab(
                            "tab2",
                            portraitData.id,
                            portraitData.type
                          )
                        }
                      >
                        <div className="analytics-tabber-top">
                          <div className="analytics-tabber-number">
                            <h4>
                              {portraitData.view_count
                                ? portraitData.view_count
                                : 0}
                            </h4>
                          </div>
                          <div className="analytics-tabber-arrow">
                            <a>
                              <img
                                src="img/chevron_white_up.svg"
                                alt="Chevron Icon"
                              />
                            </a>
                          </div>
                        </div>

                        <div className="analytics-tabber-para">
                          <p>Number of Views on Vertical Reel</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={"analytics-tabber-list tabber-list"}
                        tab-id="tab2"
                        onClick={() =>
                          toast.warn("There are no portrait reel yet!")
                        }
                      >
                        <div className="analytics-tabber-top">
                          <div className="analytics-tabber-number">
                            <h4>0</h4>
                          </div>
                          <div className="analytics-tabber-arrow">
                            <a>
                              <img
                                src="img/chevron_white_up.svg"
                                alt="Chevron Icon"
                              />
                            </a>
                          </div>
                        </div>

                        <div className="analytics-tabber-para">
                          <p>Number of Views on Vertical Reel</p>
                        </div>
                      </div>
                    )}
                    {videosData &&
                      videosData.length === 2 &&
                      videosData.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className={`analytics-tabber-list tabber-list ${activeTab === `tab${item.tab_id}`
                              ? "hover-active"
                              : ""
                              }`}
                            tab-id={`tab${item.tab_id}`}
                            onClick={() =>
                              handleActiveTab(
                                `tab${item.tab_id}`,
                                item.id,
                                item.type
                              )
                            }
                          >
                            <div className="analytics-tabber-top">
                              <div className="analytics-tabber-number">
                                <h4>{item.view_count}</h4>
                              </div>
                              <div className="analytics-tabber-arrow">
                                <a>
                                  <img
                                    src="img/chevron_white_up.svg"
                                    alt="Chevron Icon"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="analytics-tabber-para">
                              <p>Number of Views on profile video {i + 1} </p>
                            </div>
                          </div>
                        );
                      })}

                    {videosData && videosData.length === 1 && (
                      <>
                        {videosData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className={`analytics-tabber-list tabber-list ${activeTab === "tab3" ? "hover-active" : ""
                                }`}
                              tab-id="tab3"
                              onClick={() =>
                                handleActiveTab("tab3", item.id, item.type)
                              }
                            >
                              <div className="analytics-tabber-top">
                                <div className="analytics-tabber-number">
                                  <h4>{item.view_count}</h4>
                                </div>
                                <div className="analytics-tabber-arrow">
                                  <a>
                                    <img
                                      src="img/chevron_white_up.svg"
                                      alt="Chevron Icon"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="analytics-tabber-para">
                                <p>Number of Views on profile video 1</p>
                              </div>
                            </div>
                          );
                        })}
                        <div
                          className={`analytics-tabber-list tabber-list ${activeTab === "tab4" ? "hover-active" : ""
                            }`}
                          tab-id="tab4"
                          onClick={() => toast.warn("There is no video yet!")}
                        >
                          <div className="analytics-tabber-top">
                            <div className="analytics-tabber-number">
                              <h4>0</h4>
                            </div>
                            <div className="analytics-tabber-arrow">
                              <a>
                                <img
                                  src="img/chevron_white_up.svg"
                                  alt="Chevron Icon"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="analytics-tabber-para">
                            <p>Number of Views on profile video 2</p>
                          </div>
                        </div>
                      </>
                    )}
                    {videosData && videosData.length === 0 && (
                      <>
                        <div
                          className={`analytics-tabber-list tabber-list ${activeTab === "tab3" ? "hover-active" : ""
                            }`}
                          tab-id="tab3"
                          onClick={() => toast.warn("There is no video yet!")}
                        >
                          <div className="analytics-tabber-top">
                            <div className="analytics-tabber-number">
                              <h4>0</h4>
                            </div>
                            <div className="analytics-tabber-arrow">
                              <a>
                                <img
                                  src="img/chevron_white_up.svg"
                                  alt="Chevron Icon"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="analytics-tabber-para">
                            <p>Number of Views on profile video 1</p>
                          </div>
                        </div>
                        <div
                          className={`analytics-tabber-list tabber-list ${activeTab === "tab4" ? "hover-active" : ""
                            }`}
                          tab-id="tab4"
                          onClick={() => toast.warn("There is no video yet!")}
                        >
                          <div className="analytics-tabber-top">
                            <div className="analytics-tabber-number">
                              <h4>0</h4>
                            </div>
                            <div className="analytics-tabber-arrow">
                              <a>
                                <img
                                  src="img/chevron_white_up.svg"
                                  alt="Chevron Icon"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="analytics-tabber-para">
                            <p>Number of Views on profile video 2</p>
                          </div>
                        </div>
                      </>
                    )}

                    {/* test */}
                  </div>
                  <div className="analytics-tabber-content-block">
                    <div className="analytics-tabber-content tabber-content tab1">
                      <div className="analytics-tabber-select-block">
                        <div className="analytics-tabber-select">
                          <select
                            onChange={(e) => handleActiveGraph(e.target.value)}
                          >
                            <option value="yearly">Yearly</option>
                            <option value="monthly">Monthly</option>
                          </select>
                        </div>
                      </div>
                      {graphCheck === "yearly" && (
                        <Chart
                          options={chartOptions}
                          series={chartYearlyData}
                          type="line"
                          height={350}
                        />
                      )}
                      {graphCheck === "monthly" && (
                        <Chart
                          options={chartOptions}
                          series={chartMonthlyData}
                          type="line"
                          height={350}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <VendorFooter />
          </div>
        </>
      )}
    </>
  );
};

export default AnalyticsComponent;
