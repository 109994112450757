import React, { useEffect, useState } from "react";
import Loader from 'react-js-loader';
import VendorSidebar from "../includes/sidebar";
import VendorHeader from "../includes/header";
import VendorFooter from "../../includes/footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import vendorDashboardAction from "../../../actions/vendorDashboardAction";
import usersChatAction from '../../../actions/chatListAction';
import { getDatabase, ref, get } from "firebase/database";
import ShowMoreText from "react-show-more-text";

const VendorDashboard = () => {
    const dispatch = useDispatch();
    const [dashboardDtls, setDashboardDtls] = useState([]);
    const [vendorInfo, setVendorInfo] = useState([]);

    const [loading, setLoading] = useState(true);
    const [chatList, setChatList] = useState([]);
    

    useEffect(() => {
        // setLoading(true);
        // setTimeout(() => { setLoading(false); }, 500);
        dispatch(vendorDashboardAction());
        dispatch(usersChatAction());
    }, [dispatch]);

    const dashboardInfo = useSelector((state) => state.vendorDashboard.vendorDashboard);
    const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
     // get vendor all chat list
    const userChat = useSelector((state) => state.chatList.chatList);
    

    useEffect(() => {
        if (dashboardInfo && dashboardInfo.datasss && dashboardInfo.datasss.status === true) {
            setDashboardDtls(dashboardInfo.datasss.data);
        }
        setLoading(false);
    }, [dashboardInfo]);

    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setVendorInfo(vendorProfile.datasss.data);
        }
        setLoading(false);
    }, [vendorProfile]);

    useEffect(() => {
        const db = getDatabase();
        if (userChat && userChat?.datasss && userChat?.datasss?.status === true) {
            let chatData = userChat.datasss.data.chats;

            let chatFinalData = chatData.map(async (item) => {
                const snapshot = await get(ref(db, `messages/${item?.event_id}_${item?.vendor_id}_${item?.user_id}`));
                const data = snapshot.val();
               
                if (data) {
                  const keys = Object.keys(data).sort((a, b) => b - a);
                  const lastMessage = data[keys[keys.length - 1]];
                  return {
                    ...item,
                    message_data: lastMessage,
                  };
                } else {
                  return {
                    ...item,
                    messag_data: null,
                  };
                }
              });
              Promise.all(chatFinalData).then((finalData) => {
                // Process the finalData array with the transformed chat data
                setChatList(finalData)
              });
            
        }
    }, [userChat]);


    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    const handleDownloadPDF = async (pdfURL) => {
        const link = document.createElement('a');
        link.href = pdfURL;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
      
        // Simulate a click event on the link to trigger the download
        link.click();
    };  

    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className="sidebar-content-wr consumer-block">
                            <div className="container">
                                <VendorSidebar />
                                <div className="right-content-block">
                                    <div className="user-title-name">
                                        Welcome <span>{vendorInfo?.business_name}</span>
                                    </div>
                                    <ShowMoreText
                                          lines={3}
                                          more="Read more"
                                          less="Read less"
                                          className="content-css small readmore-color"
                                          anchorClass="show-more-less-clickable"
                                        //   onClick={executeOnClick}
                                          expanded={false}
                                          // width={280}
                                          truncatedEndingComponent={"... "}
                                        >
                                          <span>
                                            This is your dashboard. Here you can find a snapshot of your profile.<br/>
                                            Access your messages, settings, profile analytics and other information on the sidebar to the left.<br/>
                                            There are 3 ways to get work : <br/>
                                            1. Reply to direct messages from customers inquiring about your services.<br/>
                                            2. Browse the Event Marketplace for upcoming events that need your services and respond directly to these listings if you’re interested.<br/>
                                            3. We’ll also notify you if your services match the requirements of a listing on the Event Marketplace via email. <br/><br/>
                                            For more information, head to the <Link className="remove-a-formate" target="_blank" to="/vendor-information-page">Vendor information page</Link>
                                          </span>
                                    </ShowMoreText>
                                    <div className="upper-card-section">
                                        <div className="upper-card-row">
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.vendorEventCount > 0) ? dashboardDtls.vendorEventCount : 0}</p>
                                                        </span>
                                                        <Link to="/vendor-home"><p className="events-heading">Event Marketplace</p></Link>
                                                        <img src="./img/shop-icon-color.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.messagesCount > 0) ? dashboardDtls.messagesCount : 0}</p>
                                                        </span>
                                                        <Link to="/vendor-chats"><p className="events-heading ">Messages</p></Link>
                                                        <img src="/img/message.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.vendorReviewCount > 0) ? dashboardDtls.vendorReviewCount : 0}</p>
                                                        </span>
                                                        <Link to="/vendor-reviews"><p className="events-heading ">Recent Reviews</p></Link>
                                                        <img src="./img/recentmessage.svg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- event card section End --> */}
                                        <div className="recentSection">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="right-top-text">
                                                        <h3>Recent Proposals</h3>
                                                    </div>
                                                    <div className="proposalTable table-responsive">
                                                        <table className="table head-design">
                                                            <thead>
                                                                <tr>
                                                                    <th>EVENT NAME</th>
                                                                    <th>BUDGET</th>
                                                                    <th>LOCATION</th>
                                                                    <th>START DATE</th>
                                                                    <th>END DATE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(dashboardDtls?.vendorProposal?.length > 0) ? (
                                                                    dashboardDtls?.vendorProposal?.slice(0, 4).map((prop) => {
                                                                        return (
                                                                            <tr key={prop.id}>
                                                                                <td><Link to={`/view-event/${prop.id}`}>{prop.event_name ?? '-'}</Link></td>
                                                                                <td>{(prop.event_budget) ? '$ ' + numberWithCommas(prop.event_budget) : 'N/A' }</td>
                                                                                <td>{prop.region_name}
                                                                                    <span className="countryColor">, Australia</span>
                                                                                </td>
                                                                                <td>{prop.event_from_date}</td>
                                                                                <td>{prop.event_to_date}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={5} align={'center'}> No Recent Proposals Available </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {/* <div className="col-lg-4 col-md-12">
                                                   
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="lastSection">
                                            <div className="row">
                                            <div className="right-top-text">
                                                <h3>Recent Reviews</h3>
                                            </div>
                                            <div className="reviewSection">
                                                <div className="accordion" id="accordionRatingReview">
                                                    {(dashboardDtls?.vendorReview?.length > 0) ? (
                                                        dashboardDtls?.vendorReview?.slice(0, 3).map((rev, index) => {
                                                            return (
                                                                    <div className="accordion-item" key={rev.id}>
                                                                        <h2 className="accordion-header" id={"heading" + rev.id}>
                                                                            <button className={(index === 0) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + rev.id} aria-expanded="true" aria-controls={"collapse" + rev.id}>
                                                                                {rev?.first_name + " " + rev?.last_name} <span className="accordianRation">{rev.rating} <i className="fa fa-star"></i></span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id={"collapse" + rev.id} className={(index === 0) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + rev.id} data-bs-parent="#accordionRatingReview">
                                                                            <div className="accordion-body">
                                                                                {rev.review}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div className="item">
                                                            <p className="text-center" type="button"><b> No Recent Reviews available </b></p>
                                                        </div>
                                                    )}
                                                        {dashboardDtls?.vendorReview?.length > 3 && ( 
                                                        <div className="header-popup-btn-review mt-2 mb-3 d-flex justify-content-center">
                                                            <a href="#!">See more</a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="reacentMEssgage">
                                                    <div className="right-top-text">
                                                        <h3>Recent Messages</h3>
                                                    </div>
                                                    <div className="reviewSection">
                                                        <div className="accordion" id="accordionRatingMessage">
                                                                {(chatList?.length > 0) ? (
                                                                    chatList?.slice(0, 3).map((val, index) => {
                                                                        return (
                                                                            <div className="accordion-item" key={val.id}>
                                                                                <h2 className="accordion-header" id={"heading" + val.id}>
                                                                                    <button className={(index === 0) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + val.id} aria-expanded="true" aria-controls={"collapse" + val.id}>
                                                                                        {val?.consumer_dtl?.first_name + " " + val?.consumer_dtl?.last_name} <span style={{color:'rebeccapurple', fontSize:'14px'}}>{val?.event_dtl?.event_name && `( ${val?.event_dtl?.event_name} )`}</span>
                                                                                    </button>
                                                                                </h2>
                                                                                <div id={"collapse" + val.id} className={(index === 0) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + val.id} data-bs-parent="#accordionRatingMessage">
                                                                                    {
                                                                                        val?.message_data?.mesgType === 'TEXT' &&
                                                                                        <div className="accordion-body">
                                                                                            {val?.message_data?.message}
                                                                                        </div>
                                                                                    }
                                                                                   {
                                                                                     val?.message_data?.mesgType === 'PDF' &&
                                                                                     <div className="accordion-body">
                                                                                           <img className='chat-img-src-pdf' src='img/icons8-pdf-96.png'
                                                                                            onClick={() => {
                                                                                                let pdfURL = val.message_data.message;
                                                                                                handleDownloadPDF(pdfURL)
                                                                                            }}
                                                                                            alt="Selected" />
                                                                                     </div>
                                                                                   }
                                                                                   {val?.message_data?.mesgType === 'IMAGE' && (
                                                                                    <div className="accordion-body">
                                                                                        <img className="chat-img-src img-radius" style={{ height:'100px',width:'100px' }} src={val.message_data.message} alt="Selected" />
                                                                                    </div>
                                                                                  )}
                                                                                </div>
                                                                            </div>
                                                                         );
                                                                    })
                                                                ) : (
                                                                    <div className="item">
                                                                        <p className="text-center" type="button"><b> No Recent Messages available </b></p>
                                                                    </div>
                                                                )}
                                                                {/* {chatList?.length > 3 && ( 
                                                                <div className="header-popup-btn-review mt-2 mb-3 d-flex justify-content-center">
                                                                    <Link to='/vendor-chats'>See more</Link>
                                                                </div>
                                                                )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <VendorFooter/>
                    </div>
                </>
            )}
        </>
    );
};

export default VendorDashboard;