import { logoutData, clearLogoutData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const logoutFunction = () => async (dispatch) => {
    try {

        const logout = await commonService.withLogin(`/logout`)

        if (logout) {

            dispatch({ type: logoutData.GET_LOGOUT_SUCCESS, payload: { datasss: logout.data } })
        }
        else {
            dispatch({ type: logoutData.GET_LOGOUT_FAILURE, payload: { data: logout.data } })
        }

    } catch (error) {

        console.log(`Error while calling login api ${error}`)
        dispatch({ type: logoutData.GET_LOGOUT_FAILURE, payload: error.message })
    }
}

export const clearLogoutMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearLogoutData.CLEAR_LOGOUT_MESSAGE, payload: [] })
    } catch (err) {
        //
    }
}


export default logoutFunction;