import React, { useEffect, useState } from 'react';
import VendorHeader from './includes/header';
import VendorFooter from "../includes/footer";
import VendorSidebar from './includes/sidebar';
import axios from 'axios';
import 'datatables.net' 
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';

const VendorPaymentHistory = () => {
    
    const [historyData, setHistoryData] = useState([]);
   
    JSON.parse(localStorage.getItem('userData'));
    useEffect(()=> {
            let tokenObj = JSON.parse(localStorage.getItem("token"));
            const headers = {
                'content-type': 'application/json',
                Authorization: `Bearer ${tokenObj.api_token}`
            }
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/vendor-subscription-list`, { headers }).then(response => {
                if(response && response.data){
                    setHistoryData(response.data?.data)
                }
            })
    },[])

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    return (
        <>
            <div className="wrapper">
                <VendorHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <VendorSidebar />
                        <div className="right-content-block">
                            <div className="right-top-text">
                                <h3>Vendor Subscription Payment history</h3>
                            </div> 
                            <div className="table-responsive table-custom">
                                <table id="pastEvents" className="table head-design proposalTable" >
                                    <thead>
                                        <tr>
                                            <th>Start Date</th>
                                            <th>Automatic Payment Renewal Date</th>
                                            <th>Selected Plan</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyData.length > 0 ? (
                                            <>
                                                {historyData.slice(0, 5).map((history) => (
                                                    <React.Fragment key={history.id}>
                                                        <tr>
                                                            <td >{moment(history.start_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                            <td>{moment(history.expiry_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                            <td>
                                                                { history?.free_trial === 0 
                                                                ?
                                                                    (history.price_details?.recurring?.interval+"ly").charAt(0).toUpperCase() + 
                                                                    (history.price_details?.recurring?.interval+"ly").slice(1) ?? '-'
                                                                : "Free-Trial"}
                                                            </td>
                                                            <td>{ history?.free_trial === 0
                                                            ?
                                                            (history.price_details?.unit_amount) ? '$ ' + numberWithCommas(history.price_details?.unit_amount/100) : '-' 
                                                            : '90 days'}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={7} align={"center"}>
                                                    {" "}
                                                    No Payment History available{" "}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <VendorFooter />
            </div>
        </>
    )
}

export default VendorPaymentHistory;