import { NotificationListConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const notificationAction = () => async (dispatch) =>{
    try{
      const  notificationList  = await commonService.withLogin(`/notification-list`)
      if(notificationList){
        dispatch({type: NotificationListConst.GET_NOTIFICATION_LIST_SUCCESS , payload: {datasss: notificationList.data}})
      }
      else{
        dispatch({type: NotificationListConst.GET_NOTIFICATION_LIST_FAILURE , payload: {data: notificationList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: NotificationListConst.GET_NOTIFICATION_LIST_FAILURE , payload: error.message})
  }
}

export default notificationAction;