import { forgetPassword, clearForgetData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const forgetPass = (datass) => async (dispatch) => {
    try {
        const userdata = await commonService.withOutToken(`/forgot-password`, datass );
        if (userdata) {
            dispatch({ type: forgetPassword.GET_FORGET_SUCCESS, payload: { datasss: userdata }});
        } else {
            dispatch({ type: forgetPassword.GET_FORGET_FAILURE, payload: { data: userdata.data }});
        }
    } catch (error) {
        console.log(`Error while calling login api ${error}`);
        dispatch({
            type: forgetPassword.GET_FORGET_FAILURE,
            payload: error.message,
        });
    }
};



export const clearForgetMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearForgetData.CLEAR_FORGET_MESSAGE, payload: [] })
    } catch (err) {

    }
}

export default forgetPass;
