import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";
import staticPagesAction from '../actions/staticPagesAction';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Faq = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(staticPagesAction());
    }, []);

    // get vendor all chat list
    const staticData = useSelector((state) => state.staticDataList);
    const [dataList, setDataList] = useState("");

    useEffect(() => {
        if (staticData && staticData?.notification && staticData?.notification?.datasss && staticData?.notification?.datasss?.status === true) {
            setDataList(staticData.notification.datasss.data)
        }
    }, [staticData]);

    useEffect(() => {
        const { pathname, hash } = location;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <Header />
                <section className="privacy-policy-banner">
                    <div className="privacy-policy-banner-img">
                        <figure>
                            <img src="img/faq_banner.jpeg" alt="FAQs" />
                        </figure>
                    </div>
                    <div className="privacy-policy-banner-content">
                        <div className="container">
                            <div className="privacy-policy-heading">
                                <h1>FAQ</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="privacy-policy-wr" id="Faq">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="privacy-policy-text-block">
                                <div className="freq-asked-que-section">
                                    <div className="freq-asked-section">
                                        <div className="accordion-body-sec">
                                            <div className="accordion" id="accordionExample">
                                                <h1 className="fadeInUp wow">Frequently Asked Questions</h1>
                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                        >
                                                            What is OzGigs
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Ozgigs is an online marketplace for the event’s industry. It’s a free and fast way for you to find services for your event.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseTwo"
                                                            aria-expanded="false"
                                                            aria-controls="collapseTwo"
                                                        >
                                                            Is it free to search and book vendors?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseTwo"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingTwo"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Ozgigs is free to use. Simply create a free account and start browsing!

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseThree"
                                                            aria-expanded="false"
                                                            aria-controls="collapseThree"
                                                        >
                                                            How do I use Ozgigs?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseThree"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingThree"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Browse the vendor showreels for services that you need for your event. Add the vendors you like to your favourites list, and/or message them directly through their profile.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion" id="BookingaccordionExample">
                                                <h1 className="fadeInUp wow">Booking a vendor</h1>
                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="bookheadingOne">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#bookcollapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="bookcollapseOne"
                                                        >
                                                            How do I book a vendor?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="bookcollapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="bookheadingOne"
                                                        data-bs-parent="#BookingaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Once you’ve created your free account, find a vendor you like, tap on the message button and negotiate with them directly. Please note, Ozgigs does not take payments. All payments and contracts are negotiated between you and the vendor directly.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="bookheadingTwo">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#bookcollapseTwo"
                                                            aria-expanded="false"
                                                            aria-controls="bookcollapseTwo"
                                                        >
                                                            Does OzGigs charge a booking fee?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="bookcollapseTwo"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="bookheadingTwo"
                                                        data-bs-parent="#BookingaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            No! Ozgigs was created to eliminate the “middleman”. Booking directly saves you time and money.

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="bookheadingThree">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#bookcollapseThree"
                                                            aria-expanded="false"
                                                            aria-controls="bookcollapseThree"
                                                        >
                                                            Does Ozgigs take payments on behalf of the vendors?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="bookcollapseThree"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="bookheadingThree"
                                                        data-bs-parent="#BookingaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            No. Ozgigs connects you to vendors that you may need for your event. All payment, contracts and event details are handled directly between you and the vendor.

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="bookheadingFour">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#bookcollapseFour"
                                                            aria-expanded="false"
                                                            aria-controls="bookcollapseFour"
                                                        >
                                                            Does Ozgigs take responsibility for the services provided by the vendors?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="bookcollapseFour"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="bookheadingFour"
                                                        data-bs-parent="#BookingaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            No. Please be sure to have a signed contract with the vendor that states the terms of their service. You can leave a review of your experience with a vendor on their profile after your event.

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion" id="FeedbackaccordionExample">
                                                <h1 className="fadeInUp wow">Feedback</h1>
                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="FeedheadingOne">
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#feedcollapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="feedcollapseOne"
                                                        >
                                                            I can’t find what I need for my event!
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="feedcollapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="FeedheadingOne"
                                                        data-bs-parent="#FeedbackaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Please contact us and let us know what services are missing for your event.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item fadeInUp wow">
                                                    <h2 className="accordion-header" id="FeedheadingTwo">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#feedcollapseTwo"
                                                            aria-expanded="false"
                                                            aria-controls="feedcollapseTwo"
                                                        >
                                                            I’m having trouble with the site.
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="feedcollapseTwo"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="FeedheadingTwo"
                                                        data-bs-parent="#FeedbackaccordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            If you’re having trouble using our site, please contact us at support@ozgigs.com.au for help.

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="privacy-policy-wr margin-remove" id="VendorBooking">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="accordion" id="accordionExample">
                                <h1 className="fadeInUp wow">Booking a vendor</h1>
                                <div className="accordion-item fadeInUp wow">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            How do I book a vendor?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Once you’ve created your free account, find a vendor you like, tap on the message button and negotiate with them directly. Please note, Ozgigs does not take payments. All payments and contracts are negotiated between you and the vendor directly.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item fadeInUp wow">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Does OzGigs charge a booking fee?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            No! Ozgigs was created to eliminate the “middleman”. Booking directly saves you time and money.

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item fadeInUp wow">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#bookcollapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            Does Ozgigs take payments on behalf of the vendors?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            No. Ozgigs connects you to vendors that you may need for your event. All payment, contracts and event details are handled directly between you and the vendor.

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item fadeInUp wow">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            Does Ozgigs take responsibility for the services provided by the vendors?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            No. Please be sure to have a signed contract with the vendor that states the terms of their service. You can leave a review of your experience with a vendor on their profile after your event.

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="freq-asked-que-section">
                    <div className="container">
                        <div className="freq-asked-section common-inner-container">
                            <div className="accordion-body-sec">
                                <div className="accordion" id="FeedbackaccordionExample">
                                    <h1 className="fadeInUp wow">Feedback</h1>
                                    <div className="accordion-item fadeInUp wow">
                                        <h2 className="accordion-header" id="FeedheadingOne">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#feedcollapseOne"
                                                aria-expanded="true"
                                                aria-controls="feedcollapseOne"
                                            >
                                                I can’t find what I need for my event!
                                            </button>
                                        </h2>
                                        <div
                                            id="feedcollapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="FeedheadingOne"
                                            data-bs-parent="#FeedbackaccordionExample"
                                        >
                                            <div className="accordion-body">
                                                Please contact us and let us know what services are missing for your event.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item fadeInUp wow">
                                        <h2 className="accordion-header" id="FeedheadingTwo">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#feedcollapseTwo"
                                                aria-expanded="false"
                                                aria-controls="feedcollapseTwo"
                                            >
                                                I’m having trouble with the site.
                                            </button>
                                        </h2>
                                        <div
                                            id="feedcollapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="FeedheadingTwo"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                If you’re having trouble using our site, please contact us at support@ozgigs.com.au for help.

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <section className="privacy-policy-wr margin-remove" id="Support">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="privacy-policy-text-block faq-about-us">
                                <ul>
                                    <li>
                                        <h3>Support + Contact Us</h3>
                                        <p className="pt-2">
                                            Can’t find an answer to your question? Contact us at <a style={{ color: "#0A93D5" }}>support@ozgigs.com.au</a>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <section className="privacy-policy-wr margin-remove" id="AboutUs">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="privacy-policy-text-block faq-about-us">
                                <ul>
                                    <li>
                                        <h3>About Us</h3>
                                        <p className="pt-2">
                                            Headquartered in Sydney Australia, OzGigs is the brainchild of Cavan Te Ratana, a professional singer with over 30 years of experience in the Australian events industry.
                                        </p>
                                        <p className="pt-2">
                                            As a service provider Cavan felt the need for a dedicated event supplier marketplace. One that will offer affordability to vendors listing their services, combined with a convenient and easy way for consumers to search, find and connect.
                                        </p>
                                        <p className="pt-2">
                                            Teaming up with long time friend and professional guitarist Stan Wong they refined OzGigs into a sleek user friendly site.
                                        </p>
                                        <p className="pt-2">
                                            “Our innovative approach to online marketplaces is achieved by placing supplier promotional videos as the first point of reference. We provide consumers with free enhanced searching capabilities and an affordable vendor subscription.”
                                        </p>
                                        <p className="pt-2">
                                            The aim of OzGigs is to showcase these services on a global scale in the most affordable, accessible and vividly engaging way.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
            </div>
        </>
    );
};

export default Faq;
