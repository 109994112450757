import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ConsumerHeader from "./includes/header";
import ConsumerSidebar from "./includes/sidebar";
import ConsumerFooter from "../includes/footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import consumerReviewsAction from '../../actions/consumerReviewsAction';
//import consumerProfileAction from '../../../actions/consumerProfileAction';
import StarRating from 'react-star-ratings';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import axios from "axios";
import moment from 'moment';

const ConsumerReviews = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const reviewId = params.get('review_id');
  const [reviewsDtl, setReviewDtl] = useState("");
  const [showReplyBtn, setShowReplyBtn] = useState(false)
  const [reviewData, setReviewData] = useState({});
  const [text, setText] = useState('')
  const [userId, setUserId] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [consumerInfo, setConsumerInfo] = useState([]);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchText, setSearchText] = useState("")
  const [filteredReviewsDtl, setFilteredReviewsDtl] = useState([])
  const [reportList, setReportList] = useState([])
  const [selectedValues, setSelectedValues] = useState([]);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(consumerReviewsAction());
    //dispatch(consumerProfileAction());
    getReportList()
  }, []);

  // Get vendor all information
  const consumerProfile = useSelector((state) => state.consumerProfile.consumerProfile);
  useEffect(() => {
    if (consumerProfile && consumerProfile.datasss && consumerProfile.datasss.status === true) {
      setConsumerInfo(consumerProfile.datasss.data);
    }
  }, [consumerProfile]);

  // Get and Set the all reviews info from action
  const reviews = useSelector((state) => state.consumerReviews.consumerReviews);
  useEffect(() => {
    if (reviews && reviews.datasss && reviews.datasss.status === true) {
      setReviewDtl(reviews.datasss.data);

      if (reviewData && reviewData.id) {
        const review = reviews.datasss.data.find((review) => review.id === reviewData.id);
        setReviewData(review);
      } else {
        setReviewData(reviews.datasss.data[0]);
      }
    }
  }, [reviews]);

  // useEffect(()=> {
  //   setReviewData(reviewsDtl[0])
  // },[reviewsDtl])

  // search function for vendor reviews
  useEffect(() => {
    setFilteredReviewsDtl(
      reviewsDtl && reviewsDtl.filter((data) =>
        data?.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
        data?.last_name?.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [reviewsDtl, searchText]);

  useEffect(() => {
    if (reviewId && reviewId !== 'null') {
      const findUser = reviewsDtl.length > 0 && reviewsDtl?.findIndex((user) => user.id === parseInt(reviewId))
      setSelectedTab(findUser === -1 ? 0 : findUser);

      const review = reviewsDtl.length > 0 && reviewsDtl?.find((review) => review.id === parseInt(reviewId));
      if (review !== undefined && review !== null) {
        setReviewData(review && review);
      }

    }
  }, [reviewsDtl])

  // get vednor report action list
  const getReportList = async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/report-reason-list`)
    if (result.status === 200 && result.data.data) {
      setReportList(result.data.data)
    }
  }

  const handleTab = (id, tabIndex) => {
    setText('')
    setSelectedTab(tabIndex);
    const review = reviewsDtl.find((review) => review.id === id);
    setReviewData(review)
  }

  const handleSubmitReply = () => {
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${tokenObj.api_token}`
    }
    let obj = {
      id: reviewData.id,
      reply: text
    }
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/review-reply`, obj, { headers }).then(response => {
      toast(response.data.message)
      dispatch(consumerReviewsAction());
      setShowReplyBtn(false)
    })
  }

  // handle report change
  const handleReportChange = (event) => {
    const value = Number(event.target.value);
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }

  };

  const handleClick = (user_id, vendor_id) => {
    setUserId(user_id);
    setVendorId(vendor_id);
  }

  // submit report on review
  const handleReportSubmit = (e) => {
    e.preventDefault()
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${tokenObj.api_token}`,
    };
    let obj = {
      report_against: reviewData.user_id,
      report_reasons: selectedValues,
      post_id: reviewData.id,
      report_type_id: '3'
    };
    if (selectedValues.length) {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/api/submit_report`, obj, {
          headers,
        })
        .then((response) => {
          toast.success(response.data.message);
          setReportModalOpen(false)
        });
    } else {
      toast.warn("Please select a reason.")
    }
  }

  const renderHTMLContent = () => {
    return (
        <div className="reviewer-review-report">
          <div className="sure-to-report">
            <div className="report-popup-wr report-popup-options">
              <div className="report-popup-content comn-para-white">
                <div className="notification-popup-text">
                  <p>Report</p>
                </div>
                  <div className="report-popup-type-btn">
                      {
                        reportList && reportList.map((item, i) => {
                          return (
                            <div className="report-popup-type" key={i}>
                              <label>
                                {item.reason}
                                <input
                                  type="checkbox"
                                  name={`report-${item.id}`}
                                  id={`report-${item.id}`}
                                  value={item.id}
                                  // checked={true}
                                  onChange={handleReportChange}
                                />
                                <span className="checkmark-report"></span>
                              </label>
                            </div>
                          )
                        })
                      }
                      <div className="report-popup-btn">
                        <a
                          href="#"
                          className="cancel-btn"
                          onClick={() => {
                            setReportModalOpen(false)
                            setSelectedValues([])
                          }}
                        >
                          Cancel
                        </a>
                        <input
                          type="submit"
                          name="report-submit"
                          onClick={handleReportSubmit}
                        />
                      </div>
                  </div>
                </div>
             </div>
           </div>
        </div>
    );
  };

  return (
    <>
      <div className="wrapper">
        <ConsumerHeader />
        <section className="sidebar-content-wr consumer-block">
          <div className="container">
            <ConsumerSidebar />
            {/* <div className="right-content-block">
              <div className="right-top-text">
                <h3>Recent Reviews</h3>
              </div>
              <div className="right-box page-heading">
                <div className="user-review-left">
                  <div className="reviewer-name-txt">
                    <h4>Reviewers name</h4>
                  </div>
                  <div className="reviewer-name-wr tabber-list-blocks">
                    {(reviewsDtl?.length > 0) ? (
                      reviewsDtl?.map((data, index) => {
                        const isActive = index === 0; // Check if it's the first tab
                        return (
                          <a onClick={() => handleClick(data.id, data.vendor_id)}>
                            <div className={(data.id ===  userId) ? "reviewer-block tabber-list hover-active" :  "reviewer-block tabber-list"} tab-id={"tab" + data.id} key={data.id} onClick={() => handleTab(data.id)}>
                                <figure>
                                  <img src={data.profile} alt="Reviewers Image" className="review-pro-img" />
                                </figure>
                                <div className="reviewer-details">
                                  <h3 className="common-label">{data.first_name} {data.last_name}</h3>
                                  <h5>Wedding</h5>
                                </div>
                            </div>
                          </a>
                        );
                      })
                    ) : (
                      <div className="col-md-12 col-12">
                        <div className="finaliseEvent">
                          <div className="finaliseCard">
                            <p className="finalContent text-center">Oops !! No reviews yet. </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  reviewsDtl?.length > 0 ? <>
                  <div className="user-review-right">
                  <div className="user-review-block" >
                    <div className="usreviewDataer-review-img">
                      <figure>
                        <img src={reviewData?.profile} alt="Reviewers Image" className="review-pro-img" />
                      </figure>
                      <div className="reviewer-review">
                        <div className="reviewer-review-top">
                          <h3 className="common-label">{reviewData?.first_name +' '+ reviewData?.last_name}</h3>
                          <p>{reviewData?.review}</p>
                        </div>
                        <div className="reviewer-review-bottom">
                          <div className="review-date-star">
                            <div className="review-date">
                              <h5>1/11/2022</h5>
                            </div>
                            <div className="review-star">
                            <StarRating
                                        rating={Number(reviewData?.rating) || 0}
                                        starRatedColor="#5858E7"
                                        starEmptyColor="gray"
                                        // changeRating={handleRating}
                                        readOnly
                                        numberOfStars={5}
                                        starSpacing="5px"
                                        starDimension="30px"
                                        step={0.5}
                                    />
                                    
                            </div>
                          </div>
                         
                         {
                          reviewData?.reply === null ?
                          <div className="reply-btn">
                            <a href="#!" onClick={()=>setShowReplyBtn(!showReplyBtn)}>Reply</a>
                          </div> : <></>}
                          
                        </div>
                      </div>
                    </div>

                    <div className="usreviewDataer-review-img mt-5 ">
                      <figure style={{display:'flex', justifyContent:'end'}}>
                        <img src={consumerInfo?.profile} alt="Reviewers Image" className="review-pro-img" />
                      </figure>
                      <div className="reviewer-review">
                        <div className="reviewer-review-top">
                          <h3 className="common-label mt-2" style={{textAlign:'end'}}>{consumerInfo?.first_name +' '+ consumerInfo?.last_name}</h3>
                          <p style={{display:'flex', justifyContent:'end'}}>{reviewData?.reply}</p>
                        </div>
                        <div className="reviewer-review-bottom">
                          <div className="review-date-star">
                            <div className="review-date">
                              <h5>1/11/2022</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-review-img">
                        <figure>
                              <img src="img/reviewer-name-2.svg" alt="Reviewers Image"/>
                        </figure>
                        <div className="reviewer-review">
                           <div className="reviewer-review-top">
                              <a href="javascript:;"><img src="img/hover-report-icon.svg"/></a>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting df.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  {showReplyBtn && (
                    <div className="chats-textarea-form mt-4">
                     <div className="chats-textarea">
                         <textarea placeholder="Write your Reply" type="text" className="form-control" onChange={(e)=>setText(e.target.value)} aria-label="message…" />
                     </div>
                     <button className="chats-submit-btn" type="button" onClick={handleSubmitReply}></button>
                 </div>
                  )}
                </div>
                  </> : <></>
                }
              </div>
            </div> */}

            {/* updatedd design */}
            <div className="right-content-block recent-review-content-block">
              <div className="event-details-heading recent-review-heading comn-para-white">
                <h3
                  className="text-center"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "24px",
                    letterSpacing: "6px",
                  }}
                >
                  Recent Reviews
                </h3>
              </div>
              <div className="right-box page-heading">
                <div className="user-review-left">
                  <div className="recent-review-search-block">
                    <form method="post" action="">
                      <div className="recent-review-search">
                        <input
                          type="text"
                          name="search-review"
                          placeholder="Search..."
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="reviewer-name-wr tabber-list-blocks">
                    {reviewsDtl?.length > 0 ? (
                      filteredReviewsDtl && filteredReviewsDtl?.map((data, index) => {
                        //const isActive = index === 0; // Check if it's the first tab
                        return (
                          <div key={data.id}>
                            <a onClick={() => handleClick(data.id, data.vendor_id)}>
                              <div
                                className={`reviewer-block tabber-list ${selectedTab === index ? "hover-active" : ""
                                  }`}
                                tab-id={"tab" + data.id}
                                key={data.id}
                                onClick={() => handleTab(data.id, index)}
                              >
                                <figure>
                                  <img
                                    src={data.profile}
                                    className="review-pro-img"
                                    alt="Reviewers Image"
                                  />
                                </figure>
                                <div className="reviewer-details">
                                  <h3 className="common-label">
                                    {data.first_name} {data.last_name}
                                  </h3>
                                  {/* <h5>Wedding</h5> */}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12 col-12">
                        <div className="finaliseEvent">
                          <div className="finaliseCard">
                            <p className="finalContent text-center">
                              No reviews yet.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {reviewsDtl?.length > 0 ? (
                  <>
                    <div className="user-review-right">
                      <div className="user-review-block tabber-content tab1">
                        <div className="user-review-img">
                          <figure>
                            <img
                              src={reviewData?.profile}
                              alt="Reviewers Image"
                              className="review-pro-img"
                            />
                          </figure>
                          <div className="reviewer-review">
                            <div className="reviewer-review-top reviewer-review-report-block">
                              <Tooltip className="review-tooltip" html={renderHTMLContent()} position="top" trigger="click" interactive={true} 
                                open={reportModalOpen} distance={10} onRequestClose={() => {
                                  setReportModalOpen(false);
                                  // setSelectedValues([]);
                                }}
                              >
                                {/* <a
                                  href="#"
                                  onClick={() =>
                                    setReportModalOpen(!reportModalOpen)
                                  }
                                > */}
                                <img onClick={()=> setReportModalOpen(true)} src="img/hover-report-icon.svg" />
                                {/* </a> */}
                              </Tooltip>
                              <h3 className="common-label">
                                {reviewData?.first_name +
                                  " " +
                                  reviewData?.last_name}
                              </h3>
                              <p>{reviewData?.review}</p>
                            </div>
                            <div className="reviewer-review-bottom reply-button">
                              <div className="review-date-star star_review">
                                <div className="review-date">
                                  <h5>{moment(reviewData?.created_at).format('DD/MM/YYYY')}</h5>
                                </div>
                                <div className="review-star">
                                  <StarRating
                                    rating={Number(reviewData?.rating) || 0}
                                    starRatedColor="#5858E7"
                                    starEmptyColor="gray"
                                    readOnly
                                    numberOfStars={5}
                                    starSpacing="5px"
                                    starDimension="20px"
                                    step={0.5}
                                  />
                                </div>
                              </div>
                              {reviewData?.reply === null ? (
                                <div className="reply-btn">
                                  <a
                                    href="#"
                                    onClick={() =>
                                      setShowReplyBtn(!showReplyBtn)
                                    }
                                  >
                                    Reply
                                  </a>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* {reportModalOpen && (
                              <div className="reviewer-review-report">
                                <div className="sure-to-report">
                                  <div className="report-popup-wr report-popup-options">
                                    <div className="report-popup-content comn-para-white">
                                      <div className="notification-popup-text">
                                        <p>Report</p>
                                      </div>
                                      <div className="report-popup-type-btn">
                                        <form method="post" action="#">
                                          {
                                            reportList && reportList.map((item, i) => {
                                              return (
                                                <div className="report-popup-type" key={i}>
                                                  <label>
                                                    {item.reason}
                                                    <input
                                                      type="checkbox"
                                                      name={`report-${item.id}`}
                                                      id={`report-${item.id}`}
                                                      value={item.id}
                                                      // checked={true}
                                                      onChange={handleReportChange}
                                                    />
                                                    <span className="checkmark-report"></span>
                                                  </label>
                                                </div>
                                              )
                                            })
                                          }
                                          <div className="report-popup-btn">
                                            <a
                                              href="#"
                                              className="cancel-btn"
                                              onClick={() => {
                                                setReportModalOpen(false)
                                                setSelectedValues([])
                                              }}
                                            >
                                              Cancel
                                            </a>
                                            <input
                                              type="submit"
                                              name="report-submit"
                                              onClick={handleReportSubmit}
                                            />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>

                        {/* reply */}
                        {reviewData?.reply && (
                          <div className="user-review-img reply-reverse">
                            <figure style={{ float: "right" }}>
                              <img
                                src={consumerInfo?.profile}
                                alt="Reviewers Image"
                                className="review-pro-img"
                              />
                            </figure>
                            <div className="reviewer-review">
                              <div className="reviewer-review-top">
                                {/* <a href="!#">
                                  <img src="img/hover-report-icon.svg" />
                                </a> */}
                                {/* <h3 className="common-label mt-2" style={{textAlign:'end'}}>{consumerInfo?.first_name +' '+ consumerInfo?.last_name}</h3> */}
                                <p>{reviewData?.reply}</p>
                              </div>
                              <div className="reviewer-review-bottom">
                                <div className="review-date-star">
                                  <div className="review-date">
                                    <h5>{moment(reviewData?.reply_date).format('DD/MM/YYYY')}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {showReplyBtn && (
                        <div className="chats-textarea-form mt-4">
                          <div className="chats-textarea">
                            <textarea
                              placeholder="Write your Reply"
                              type="text"
                              className="form-control"
                              onChange={(e) => setText(e.target.value)}
                              aria-label="message…"
                            />
                          </div>
                          <button
                            className="chats-submit-btn"
                            type="button"
                            onClick={handleSubmitReply}
                          ></button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
        <ConsumerFooter />
      </div>
    </>
  )
}

export default ConsumerReviews;