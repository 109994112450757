import { EditEventConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const editEventAction = (id) => async (dispatch) => {
    try{
        const editEvent = await commonService.withLogin(`/view-edit-event/${id}`);
        if (editEvent) {
            dispatch({ type: EditEventConst.GET_EDITEVENT_SUCCESS, payload: { datasss: editEvent.data } });
        }
        else {
            dispatch({ type: EditEventConst.GET_EDITEVENT_FAILURE, payload: { data: editEvent.data } });
        }
    }
    catch (error) {
        dispatch({ type: EditEventConst.GET_EDITEVENT_FAILURE, payload: error.message })
    }
}

export default editEventAction;