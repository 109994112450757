import { vendorSubscriptionDetailsConst ,clearSessionData} from "../constants/user.constants";
import { commonService } from "../services/common.service";

const vendorSubscriptionDetails = () => async (dispatch) => {
    try {
        const vendorSubscriptionDetails = await commonService.withLogin('/vendor-subscription-details');
        if(vendorSubscriptionDetails) {
            dispatch({type: vendorSubscriptionDetailsConst.GET_VENDORSUBSCRIPTIONDETAILS_SUCCESS, payload: {datasss: vendorSubscriptionDetails.data} });
        }
        else {
            dispatch({ type: vendorSubscriptionDetailsConst.GET_VENDORSUBSCRIPTIONDETAILS_FAILURE, payload: { data: vendorSubscriptionDetails.data } });
        }
    }
    catch (error) {
        dispatch({ type: vendorSubscriptionDetailsConst.GET_VENDORSUBSCRIPTIONDETAILS_FAILURE, payload: error.message });
    }
}

export const clearSessionMsg = () => async (dispatch)=>{
    try{
        dispatch({type : clearSessionData.CLEAR_SESSION_MESSAGE , payload : []})
    } catch(err){
        console.log(`Error while calling login api ${err}`);
    }
}

export default vendorSubscriptionDetails;