import React, { useEffect, useState } from 'react';
import VendorHeader from './includes/header';
import VendorFooter from "../includes/footer";
import VendorSidebar from './includes/sidebar';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import deleteProfileAction, { clearDeleteProfileMsg } from '../../actions/deleteProfileAction';


import axios from 'axios';


import { toast } from 'react-toastify';
import VendorProfileAction from '../../actions/VendorProfileAction';

const VendorSetting = () => {

    const dispatch = useDispatch();
    const Navigate = useNavigate();

    const [chatNotificationChecked, setChatNotificationChecked] = useState(false);
    const [eventNotificationChecked, setEventNotificationChecked] = useState(false);
    const [newReviewtNotificationChecked, setNewReviewNotificationChecked] = useState(false);
    const [newsPromotionsNotificationChecked, setNewsPromotionsNotificationChecked] = useState(false);

    const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    const handleCheckedChatNotification = (checked) => {
        setChatNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/manage-chat-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            dispatch(VendorProfileAction());
        })
    }

    const handleCheckedEventNotification = (checked) => {
        setEventNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/manage-event-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            dispatch(VendorProfileAction());
        })
    }

    const handleNewsPromotionNotification = (checked) => {
        setNewsPromotionsNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/news-promotions-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            dispatch(VendorProfileAction());
        })
    }

    const handleNewReviewsNotification = (checked) => {
        setNewReviewNotificationChecked(checked.target.checked)
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (checked.target.checked) {
            obj = {
                status: 1
            }
        } else {
            obj = {
                status: 0
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/new-review-notification`, obj, { headers }).then(response => {
            toast.success(response?.data?.message);
            dispatch(VendorProfileAction());
        })
    }

    const deleteProfile = useSelector((state) => state.deleteProfile.deleteProfile);
    // Function for remove profile photo.
    const removeProfile = ()  => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to permanently Delete your account?",
            icon: "question",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteProfileAction());
            }
        });
    };
    useEffect(()=> {
       if(deleteProfile && deleteProfile.datasss && deleteProfile?.datasss?.status === true){
            dispatch(clearDeleteProfileMsg());
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("FCM_TOKEN");
            localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
            Navigate("/login");
            Swal.fire({
                title: "Done!",
                text: 'Profile Deleted Successfully',
                icon: "success",
            })
        }
        if(deleteProfile && deleteProfile.datasss && deleteProfile?.datasss?.status === false){
            Swal.fire({
                title: "Warning!",
                text: deleteProfile?.datasss?.message,
                icon: "warning",
            })
            dispatch(clearDeleteProfileMsg());
        }
    },[deleteProfile])

    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            if (vendorProfile.datasss.data.chat_notify_status) {
                setChatNotificationChecked(true);
            } else {
                setChatNotificationChecked(false);
            }

            if (vendorProfile.datasss.data.event_notify_status) {
                setEventNotificationChecked(true)
            } else {
                setEventNotificationChecked(false)
            }

            if (vendorProfile.datasss.data.new_review_notify) {
                setNewReviewNotificationChecked(true)
            } else {
                setNewReviewNotificationChecked(false)
            }

            if (vendorProfile.datasss.data.new_promotions_notify) {
                setNewsPromotionsNotificationChecked(true)
            } else {
                setNewsPromotionsNotificationChecked(false)
            }

        }
    }, [vendorProfile])


    return (
        <>
            <div className="wrapper">
                <VendorHeader />
                <section className="sidebar-content-wr consumer-block">
                    <div className="container">
                        <VendorSidebar />
                        <div className="right-content-block">
                            <div className="right-top-text">
                                <h3>settings</h3>
                            </div>
                            <div className="setting-list">
                                <div className="row ">
                                    <div className="col-md-3 col-sm-6 col-xs-8 col-6">
                                        <p className='mb-2 mt-2'>Security:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6 setting-button">
                                        <Link to={'/change-password'}>Change Password</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-xs-8 col-6">
                                        <p className='mb-2 mt-2'>Subscription:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6 setting-button">
                                        <Link to={'/manage-subscription'}>Manage Subscription</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-xs-8 col-6">
                                        <p className='mb-2'>Payment History:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6 setting-button">
                                        <Link to='/vendor-payment-history'>View History</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-xs-8 col-6">
                                        <p className='mb-2 mt-2'>My Profile:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6 setting-button">
                                        <Link to={'/vendor-profile'}>My Profile</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-xs-8 col-6">
                                        <p className='mb-2 mt-2'>Delete My Profile: </p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6 setting-button">
                                        <Link onClick={removeProfile}>Remove / Delete</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="setting-list">
                                <div className="right-top-text">
                                    <h3>Email Notification Settings</h3>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-4 col-sm-6 col-xs-8 col-6">
                                        <p>New Message Notification:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6">
                                        <div className="toggle-btn">
                                            <label className="switch">
                                                <input type="checkbox" checked={chatNotificationChecked} onChange={(e) => { handleCheckedChatNotification(e) }} id="checbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-8 col-6">
                                        <p>Event Marketplace Notification:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6">
                                        <div className="toggle-btn">
                                            <label className="switch">
                                                <input type="checkbox" checked={eventNotificationChecked} onChange={(e) => { handleCheckedEventNotification(e) }} id="checbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-8 col-6">
                                        <p>New Reviews Notification:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6">
                                        <div className="toggle-btn">
                                            <label className="switch">
                                                <input type="checkbox" checked={newReviewtNotificationChecked} onChange={(e) => { handleNewReviewsNotification(e) }} id="checbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-8 col-6">
                                        <p>Ozgigs News/Promotions:</p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-3 col-6">
                                        <div className="toggle-btn">
                                            <label className="switch">
                                                <input type="checkbox" checked={newsPromotionsNotificationChecked} onChange={(e) => { handleNewsPromotionNotification(e) }} id="checbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <VendorFooter />
            </div>
        </>
    )
}

export default VendorSetting;