import { DeleteEventConst, PastEventsConst, UpdateEventConst } from '../constants/user.constants';
import { commonService } from '../services/common.service';

const pastEventsAction = () => async (dispatch) =>  {
    try {
        const pastEvents = await commonService.withLogin(`/past-events`);
        if (pastEvents) {
            dispatch({ type: PastEventsConst.GET_PASTEVENTS_SUCCESS, payload: { datasss: pastEvents.data } });
            dispatch({ type: UpdateEventConst.UPDATE_EVENT_CLEAR, payload: { } });
            dispatch({ type: DeleteEventConst.DELETEEVENT_CLEAR, payload: { } });
        }
        else {
            dispatch({ type: PastEventsConst.GET_PASTEVENTS_FAILURE, payload: { data: pastEvents.data } });
        }
    }
    catch(error) {
        dispatch({ type: PastEventsConst.GET_PASTEVENTS_FAILURE, payload: error.message })
    }
}

export default pastEventsAction;