import { FavouriteVendorConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const favouriteVendorAction = (id) => async (dispatch) => {
    try {
        const favouriteVendor = await commonService.withToken(`/favourite-vendor-list`);
        if (favouriteVendor) {
            dispatch({ type: FavouriteVendorConst.GET_FAVOURITEVENDOR_SUCCESS, payload: { datasss: favouriteVendor.data } });
        }
        else {
            dispatch({ type: FavouriteVendorConst.GET_FAVOURITEVENDOR_FAILURE, payload: { data: favouriteVendor.data } });
        }
    }
    catch (error) {
        dispatch({ type: FavouriteVendorConst.GET_FAVOURITEVENDOR_FAILURE, payload: error.message })
    }
}

export default favouriteVendorAction;