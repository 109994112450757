import { vendorReelsData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const vendorReelsAction = (nextReelUrl, data) => async (dispatch) => {
    let vendorReels = null;
    try {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        {userInfo ? 
            vendorReels = await commonService.withToken(`${nextReelUrl}`, data)
        :   vendorReels = await commonService.withOutToken(`${nextReelUrl}`, data)
        }

        if (vendorReels) {
            dispatch({ type: vendorReelsData.GET_VENDORREELS_SUCCESS, payload: { datasss: vendorReels.data } });
        }
        else {
            dispatch({ type: vendorReelsData.GET_VENDORREELS_FAILURE, payload: { data: vendorReels.data } });
        }
    }
    catch (error) {
        dispatch({ type: vendorReelsData.GET_VENDORREELS_FAILURE, payload: error.message })
    }    
}

export default vendorReelsAction;