import React, { useEffect, useState } from "react";
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import createEventAction, { clearCreateEventMsg } from "../../../actions/createEventAction";
import ConsumerFooter from "../../includes/footer";
import ConsumerHeader from "../includes/header";
import ConsumerSidebar from "../includes/sidebar";
//import DatePicker from "react-datepicker";
import Loader from 'react-js-loader';
import { useNavigate } from "react-router";
import categoryAction from "../../../actions/categoryAction";
import regionAction from "../../../actions/regionAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import $ from "jquery"
//import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const Marker = ({ text }) => (
    <div style={{ position: "relative", top: "-50%", left: "-50%", width: "30px", height: "30px" }}>
      <img src="../img/location-pin.png" alt="marker" style={{ width: "100%", height: "auto" }} />
      {/* <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>{text}</div> */}
    </div>
  );
const AddEvent = (props) => {
    const google = window.google;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [event_name, setEventName] = useState("");
    const [event_description, setEventDescription] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(startDate);
    const [startTime, onStartTime] = useState("00:00");
    const [endTime, onEndTime] = useState("00:00");
    const [event_budget, setEventBudget] = useState("");
    const [num_of_guest,setNumOfGuest] = useState("")
    const [errorvalidateBudget, setErrorvalidateBudget] = useState(false);
    const [errorvalidateNumGuest,setErrorvalidateNumGuest] = useState(false)
    const [event_type, setEventType] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [location, setLocation] = useState("");
    const [region, setRegion] = useState(1);
    const [event_file, setEventFile] = useState("");
    const [event_doc, setEventDoc] = useState("");
    const [error, setError] = useState(false);
  
    const [categoryList, setCategory] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [wedding, setWedding] = useState(false);
    const [corporate, setCorporate] = useState(false);
    const [occasions, setOccasions] = useState(false);
    const [party, setParty] = useState(false);
    
    const [latitude, setLatitude] = useState(-33.868820);
    const [longitude, setLongitude] = useState(151.209290);



    useEffect(() => {
        dispatch(categoryAction());
        dispatch(regionAction());
    }, []);
    // function for set address from google map.
    function initialize() {
        var input = document.getElementById('address');
        // to permmition for location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert('Geolocation is not supported by this browser.'); 
        }
        function showPosition(position) {
            setLatitude(position.coords.latitude); 
            setLongitude(position.coords.longitude);
        }

        const options = {
            componentRestrictions: { country: "aus" },
            fields: ["address_components", "geometry", "icon", "name"],
            // strictBounds: false,
            // types: ["establishment"],
        };        
        let autocomplete = new google.maps.places.Autocomplete(input,options);
        // Set initial restriction to the greater list of countries.
        autocomplete.setComponentRestrictions({
            country: ["aus"],
        });
        autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            setLatitude(place.geometry.location.lat);
            setLongitude(place.geometry.location.lng);
            setLocation(input.value)
        });
    }
    // useEffect(()=>{
    //     initialize();
    //     google.maps.event.addDomListener(window, 'load', initialize);
    // },[])

    useEffect(() => {
        initialize();
        window.addEventListener('load', initialize);
      
        return () => {
          window.removeEventListener('load', initialize);
        };
    }, []);

    // get and set category list form category ipi 
    const { category } = useSelector((state) => state.category);
    useEffect(() => {
        if (category && category.datasss && category.datasss.data && category.datasss.data.length > 0) {
            setCategory(category.datasss.data);
        }
    }, [category]);

    // get all the regions 
    const regionsDtl = useSelector((state) => state.region.region);
    useEffect(() => {
        if (regionsDtl && regionsDtl.datasss && regionsDtl.datasss.status === true) {
            setRegionList(regionsDtl.datasss.data);
        }
    }, [regionsDtl]);

    const [cat, setCat] = useState([]);
    const [checkedSubcat, setCheckedSubCat] = useState([]);

    const handleCategoryInput = (e,catid) => {      
        const isChecked = $('#checkbox' + catid).is(':checked');
        const inputField = $('#categoryInput' + catid);
        const isDisabled = inputField.prop('disabled');
        if (isChecked && !isDisabled) {
            inputField.prop('disabled', false); 
            const index = cat.findIndex((item) => item.category_id === catid);
            const value = e.target.value;
            const name = e.target.name;
            
            if (index !== -1) {
              cat[index][name] = value;
              if (name === 'budget') {
                cat[index][name] = parseFloat(value);
              }
            } else {
              cat.push({ category_id: catid, [name]: value, subcategory:[] });
            }
            handleCategory(cat);
        } else{
            inputField.prop('disabled', true); // disable input field
            inputField.val('');
            // $('#collapse' + catid + ' input:categoryInput').prop('disabled', true);
        }

    }
    
    //function for check category of subcatgeory and submit it
    const handleClick = (catid, subid) => {
        var obj = [...cat];
        if (subid) {
            var data = cat.find((ele) => ele.category_id === catid);

            if (data === undefined) {
                obj.push({ category_id: catid });
            }
            $('#checkbox'+ catid ).prop('checked', true);
            var index = obj.findIndex(item => item.category_id === catid)
            if (obj[index].subcategory && obj[index].subcategory.length > 0) {
                if (!obj[index].subcategory.includes(subid)) {
                    obj[index].subcategory.push(subid);
                } else {
                    obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
                    checkedSubcat.splice(checkedSubcat.indexOf(subid));
                }
            } else {
                obj[index].subcategory = [];
                obj[index].subcategory.push(subid);
            }
            handleCategory(obj);
        } else {
            var data = cat.find((ele) => ele.category_id === catid);
            if (data === undefined) {
                obj.push({ category_id: catid });
                // $('#collapse' + catid + ' input:checkbox').prop('checked', true);
                $("#collapse" + catid).find('input:checkbox:checked').each(function () {
                    let subid = $(this).val();
                    var index = obj.findIndex(item => item.category_id === catid)
                    if (obj[index].subcategory && obj[index].subcategory.length > 0) {
                        if (!obj[index].subcategory.includes(subid)) {
                            obj[index].subcategory.push(parseInt(subid));
                        } else {
                            obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
                            checkedSubcat.splice(checkedSubcat.indexOf(subid));
                        }
                    } else {
                        obj[index].subcategory = [];
                        obj[index].subcategory.push(parseInt(subid));
                    }
                    handleCategory(obj);
                })
            }else {
                $('#collapse' + catid + ' input:checkbox').prop('checked', false);
                obj.splice(obj.findIndex(item => item.category_id === catid), 1)
            }
            handleCategory(obj);
        }
    }
    const handleCategory = (obj) => {
        setCat(obj)
    }

    // function for create an event with selected field and set backend message on toaster
    const eventSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!event_name || !event_description || !startDate || !endDate || event_type.length <= 0 || cat.length <= 0 ) {
            setError(true);
            setLoading(false);
            return false;
        } else {
            const res = await dispatch(
                createEventAction({
                    event_name : event_name,
                    event_description : event_description,
                    event_from_date : new Date(startDate).toString(),
                    event_to_date : new Date(endDate).toString(),
                    event_from_time : startTime,
                    event_to_time : endTime,
                    event_budget : event_budget,
                    location : location,
                    latitude : latitude,
                    longitude : longitude,
                    region : region,
                    event_file : event_file ? event_file : event_doc,
                    phone_no : phone_no,
                    event_type : event_type,
                    event_category : cat,
                    num_of_guest: num_of_guest
                })
            );
        }
    };

    // select data from reducer and set toaster here
    const createEventDtl = useSelector((state) => state.createEvent.createEvent)
    useEffect(() => {
        if (createEventDtl && createEventDtl?.datasss && createEventDtl?.datasss?.status === true) {
            setLoading(false);
            navigate("/upcoming-events")
            toast.success(createEventDtl?.datasss?.message)
        }else{
            setLoading(false);
            toast.error(createEventDtl?.datasss?.message)
        }
        dispatch(clearCreateEventMsg());
    }, [createEventDtl]);

    // selet and set event file and convert into base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };
    const handleFileUpload = async (e) => {
        let file = e.target.files[0];
        let base64 = await convertToBase64(file);
        if (base64) {
            let base64Split = base64.split(';');
            let splitExtension = base64Split[0].split('/');
            let splitType = splitExtension[0].split(':');
            if((splitExtension[1].toLowerCase() === 'png') || (splitExtension[1].toLowerCase() === 'jpg') || (splitExtension[1].toLowerCase() === 'jpeg') || (splitExtension[1].toLowerCase() === 'pdf')){
                if(splitType[1] === 'application') {
                    setEventDoc(base64);
                    setEventFile('');
                }
                if(splitType[1] === 'image'){
                    setEventFile(base64);
                    setEventDoc('');
                }
            }else {
                toast.warn('The accepted file formats are: jpeg,png,jpg,pdf');
            }
        }
    };
    
    //check budget is integer or not
    const budgetValidate = (budget) => {
        if (budget === '' || new RegExp(/^[0-9\b]+$/).test(budget)) { 
            setEventBudget(budget);
            setErrorvalidateBudget(false) }
        else{ 
            setErrorvalidateBudget(true)
        }
    }

    const numberGuestValidate = (guests) => {
        if (guests === '' || new RegExp(/^[0-9\b]+$/).test(guests)) { 
            setNumOfGuest(guests);
            setErrorvalidateNumGuest(false) }
        else{ 
            setErrorvalidateNumGuest(true)
        }
    }
    // check phone no. is integer or not
    const PhoneNoValidate = (phoneNo) => {
        if (phoneNo === '' || new RegExp(/^[0-9\b]+$/).test(phoneNo)) {
            setPhone_no(phoneNo.replace(/[^0-9]/g, ''));
        }
    }
    // check limit of description and validate it
    const handleDiscription = (e) => {
        let tempDecData = e.target.value;
        if(tempDecData.length <= 5000){
            setEventDescription(e.target.value)
        }
    }
    // for event type checkbox
    const weddingCheck = () => {
        setWedding(true);
        setOccasions(false);
        setParty(false);
        setCorporate(false);
        setEventType("Wedding")
    }
    const corporateCheck = () => {
        setWedding(false);
        setOccasions(false);
        setParty(false);
        setCorporate(true);
        setEventType("Corporate")
    }
    const partyCheck = () => {
        setWedding(false);
        setOccasions(false);
        setParty(true);
        setCorporate(false);
        setEventType("Party")
    }
    const dinnerCheck = () => {
        setWedding(false);
        setOccasions(true);
        setParty(false);
        setCorporate(false);
        setEventType("Occasions")
    }

    const handleChangeRegion = (selectedOption) => {
        const [selectedId, selectedName] = selectedOption.target.value.split(',');
        setRegion(selectedId)
        // Replace YOUR_API_KEY with your Google Maps API Key
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    // Encode location name to use in API request
    const encodedLocationName = encodeURIComponent(selectedName);

    // Construct API request URL
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocationName} Region&key=${apiKey}`;

    // Send API request
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Get latitude and longitude from API response
        const location = data.results[0]?.geometry.location;
        const latitude = location.lat;
        const longitude = location.lng;

        // Update state with latitude and longitude
        setLatitude(latitude);
        setLongitude(longitude);

      })
      .catch(error => {
        console.error(error);
      });
    }

    const onMapChange = () => {
        const newCenter = {
          lat: latitude,
          lng: longitude,
        };
        setLatitude(newCenter.lat)
        setLongitude(newCenter.lng)
      };

      const handleStartDateChange = (date) => {
        setStartDate(date);
        setEndDate(date);
      };
    

    return (
        <>
            {loading ? (
                <div className={"loader-item vendor-update"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                    <p>Please wait... <br/>
                    Your event is in the process of being created.</p>
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <ConsumerHeader />
                        <section className="sidebar-content-wr consumer-block">
                            <div className="container">
                                <ConsumerSidebar />
                                <div className="right-content-block">
                                    <form onSubmit={eventSubmit}>
                                        <div className="right-top-text">
                                            <h3>create new event</h3>
                                            <div className="form-container event-form">
                                                {/* <h4 className="common-label blue-text">event basic details</h4> */}
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="u-fname">event name<span className="text-danger star-required">*</span></label>
                                                            <input className="form-control" type="text" id="u-fname" name="firstname"
                                                                value={event_name}
                                                                onChange={(e) => setEventName(e.target.value)}
                                                                placeholder="Enter Event Name" />
                                                            {error && !event_name && (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="event-budget">Number of guests (optional)</label>
                                                            <input className="form-control" type="text"
                                                                value={num_of_guest}
                                                                onChange={(e) => numberGuestValidate(e.target.value)}
                                                                name="num_of_guest"
                                                                placeholder="Number of guests"
                                                            />
                                                            {((errorvalidateNumGuest) || (errorvalidateNumGuest && error))&& (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Invalid Amount
                                                                </span>
                                                            )}
                                                            {/* {error && !num_of_guest && !errorvalidateNumGuest && (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Required*
                                                                </span>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="regions">regions</label>
                                                            <select className="form-control"  placeholder="-- Select region --" 
                                                                onChange={(e) => handleChangeRegion(e)}>
                                                                {regionList.map((data,i) => (
                                                                    <option key={i} value={`${data.id},${data.name}`}>{data.name}</option>
                                                                ))}
                                                            </select>
                                                            {error && !region && (
                                                                <span style={{ color: "red" }} className="invalidInput">
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group fadeInUp wow">
                                                            <label htmlFor="event-budget">$ event budget (optional)</label>
                                                            <input className="form-control" type="text"
                                                                value={event_budget}
                                                                onChange={(e) => budgetValidate(e.target.value)}
                                                                name="event_budget"
                                                                placeholder="Enter Event Budget"
                                                            />
                                                            {((errorvalidateBudget) || (errorvalidateBudget && error))&& (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Invalid Amount
                                                                </span>
                                                            )}
                                                            {/* {error && !event_budget && !errorvalidateBudget && (
                                                                <span style={{ color: "red" }} className="invalidInput" >
                                                                    Required*
                                                                </span>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="location">Address (optional)</label>
                                                    <input className="form-control" type="text" id="address" name="location"
                                                        // value={location }
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        placeholder="Enter Address" />
                                                </div>
                                                {/* <div className="map-img">
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                                                        yesIWantToUseGoogleMapApiInternals
                                                        onChange={onMapChange}
                                                        center={{
                                                                lat: latitude,
                                                                lng: longitude
                                                            }}
                                                        defaultZoom={17}
                                                    >
                                                        <Marker
                                                            lat={latitude}
                                                            lng={longitude}
                                                        />
                                                    </GoogleMapReact>
                                                </div> */}
                                                <div className="desc-sec fadeInUp wow">
                                                    <div className="form-group">
                                                        <label className="common-label" htmlFor="">Description <span className="small">{event_description?.length}/5000<span className="text-danger star-required">*</span></span></label>
                                                        <textarea row="6"
                                                            className="form-control"
                                                            placeholder="..."
                                                            rows="4"
                                                            value={event_description}
                                                            onChange={handleDiscription}
                                                        >
                                                        {/* {event_description} */}
                                                        </textarea>
                                                        {error && !event_description && (
                                                            <span style={{ color: "red" }} className="invalidInput">
                                                                Required*
                                                            </span>
                                                        )}
                                                        <span className="small color">You can enter a maximum of <b>5000</b> characters in this description box.</span>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className="event-sec fadeInUp wow ">
                                                <div className="form-group">
                                                    <h4 className="common-label">EVENT TYPE<span className="text-danger star-required">*</span></h4>
                                                    <ul>
                                                        <li>
                                                            <div className="checkboxbtn">
                                                                <input type="checkbox" name="Wedding" value={"Wedding"} onChange={(e) => weddingCheck(e)}
                                                                    checked={wedding}
                                                                />
                                                                <label htmlFor="checkbox1" className="checkbox-label">Wedding</label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="checkboxbtn">
                                                                <input type="checkbox" name="Corporate" value={"Corporate"} onChange={(e) => corporateCheck(e)}
                                                                    checked={corporate}
                                                                />
                                                                <label htmlFor="checkbox2" className="checkbox-label">CORPORATE</label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="checkboxbtn">
                                                                <input type="checkbox" name="Occasions" value={"Occasions"} onChange={(e) => dinnerCheck(e)}
                                                                    checked={occasions} />
                                                                <label htmlFor="checkbox3" className="checkbox-label">Special Occasions</label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="checkboxbtn">
                                                                <input type="checkbox" value={"Party"} onChange={(e) => partyCheck(e)}
                                                                    checked={party} />
                                                                <label htmlFor="checkbox4" className="checkbox-label">PARTY</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    {error && !event_type && (
                                                        <span style={{ color: "red" }} className="invalidInput">
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="event-sec service-request service-request-event">
                                                <h4 className="common-label blue-text">services required</h4>
                                            </div> */}
                                            <div className="event-categories checkboxbtn fadeInUp wow ">
                                                <h4 className="common-label">services required<span className="text-danger star-required">*</span></h4>
                                                <div className="categories-accordion">
                                                    <div className="accordion" id="accordionExample">
                                                        {categoryList.map((x, index) => {
                                                            return (
                                                                <>
                                                                    <div className="accordion-item" key={index}>
                                                                        <h2 className="accordion-header" id="headingOne">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target={"#collapse" + x.id} aria-expanded="true"
                                                                                aria-controls="collapseOne" htmlFor={"checkbox" + x.id}>
                                                                                <input type="checkbox" id={"checkbox" + x.id} onChange={() => { handleClick(x.id) }} />
                                                                                <label className="checkbox-label" htmlFor={"checkbox" + x.id} >
                                                                                    {x.category_name}
                                                                                </label>
                                                                            </button>
                                                                        </h2>

                                                                        <div id={"collapse" + x.id} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <ul>
                                                                                    {x.sub_category.map((y,index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <li key={index}>
                                                                                                    <input type="checkbox" id={"subcategory" + y.id} onChange={() => { handleClick(x.id, y.id) }} value={y.id} />
                                                                                                    <label htmlFor={"subcategory" + y.id} className="checkbox-label" >
                                                                                                        {y.subcategory_name}
                                                                                                    </label>
                                                                                                </li>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                                <div className="budget-option">
                                                                                    <div className="min-sec min-max-sec">
                                                                                        <span>Budget</span>
                                                                                        <input className="form-control" id={"categoryInput" + x.id} name="budget" type="number" onChange={(e) => { handleCategoryInput(e,x.id) }} disabled={!$('#checkbox' + x.id).is(':checked')}/>
                                                                                    </div>
                                                                                    <span className="small color m-2"> ( Optional )</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                        {error && !cat.length > 0 && (
                                                            <span style={{ color: "red" }} className="invalidInput">
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="other-details event-budget-block photography-details">
                                                <h4 className="common-label blue-text">other details</h4>
                                                <div className="other-detail-form d-flex justify-content-between align-items-center">
                                                    <div className="new-detail-feild form-group">
                                                        <label>Start date<span className="text-danger star-required">*</span></label>
                                                        <div className="d-flex">
                                                            {/* <span><img src="img/calenders_.svg" alt="" /></span> */}
                                                            {/* <input type="date" name="other-details-date"/> */}
                                                           {/* <DatePicker
                                                          
                                                                selected={startDate}
                                                                // onChange={(date) => setStartDate(date)}
                                                                onChange={handleStartDateChange}
                                                                minDate={new Date()}
                                                            /> */}
                                                            <DatePicker 
                                                             onChange={handleStartDateChange}
                                                             value={startDate}
                                                             minDate={new Date()}
                                                            //  formatWeekday={(weekday) => {
                                                            //     const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                                                            //     return weekdays[weekday];
                                                            //   }}
                                                             />
                                                            {error && !startDate && (
                                                                <div style={{ color: "red" }} className="invalidInput">
                                                                    Required*
                                                                </div>
                                                            )}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="new-detail-feild form-group">
                                                        <label>End date<span className="text-danger star-required">*</span></label>
                                                        <div className="d-flex">
                                                            {/* <span><img src="img/calenders_.svg" alt="" /></span> */}
                                                            {/* <input type="date" name="other-details-date"/> */}
                                                            {/* <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                                minDate={startDate}
                                                            /> */}
                                                            <DatePicker 
                                                             onChange={(date) => setEndDate(date)}
                                                             value={endDate}
                                                             minDate={startDate}
                                                             />
                                                            {error && !endDate && (
                                                                <div style={{ color: "red" }} className="invalidInput">
                                                                    Required*
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="other-detail-feild other-details-alternate form-group">
                                                        <label>Contact Phone No.(optional)</label>
                                                        <span><img src="img/contact.svg" alt="" /></span>
                                                        <input type="text"
                                                            value={phone_no}
                                                            // onChange={(e) => setPhone_no(e.target.value)}
                                                            onChange={(e) => PhoneNoValidate(e.target.value)}
                                                            name="phone_no"
                                                            placeholder="Enter phone no."
                                                            />
                                                      
                                                        {/* {((errorvalidatePhoneNo) || (errorvalidatePhoneNo && error))&& (
                                                            <div style={{ color: "red" }} className="invalidInput" >
                                                                Allowed only numbers
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                      
                                            <div className="service-request">
                                                <h4 className="common-label blue-text">Event File (optional)</h4>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="profile-cover-section">
                                                            <div className="avatar-upload-sec profile-avtar-sec">
                                                                <span>
                                                                    {event_file ? 
                                                                        <img src={event_file} alt="" style={{ height: '100%', opacity: 0.7 }} />
                                                                    : ''}
                                                                    {event_doc ? 
                                                                        <iframe src={event_doc} alt="" style={{ height: '100%', opacity: 0.7 }} />
                                                                    : ''}
                                                                </span>
                                                            </div>
                                                            {/* <span className="small color">You can enter a maximum of <b>5000</b> characters in this description box.</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="profile-cover-section">
                                                            <div className="avatar-upload-sec profile-avtar-sec">
                                                                <span>
                                                                    <img src="/img/Download.svg" alt="" />
                                                                    <p className="mt-2">Drag & Drop your document</p>
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    name="event_file"
                                                                    accept=".jpeg, .png, .jpg"
                                                                    onChange={(e) => handleFileUpload(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-event-btn">
                                                <button type="submit" className="btn btn-fill btn-info">
                                                    Post Event
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <ConsumerFooter />
                    </div>
                </>
            )}
        </>
    );
};

// export default AddEvent;
export default AddEvent;
