import React, { useState, useEffect } from "react";
import ResendEmail, { clearResendEmailMsg } from "../../../actions/resendEmailVerification";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../includes/header";
import Footer from "../../includes/footer";

const ResendEmailVerification = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    
    useEffect(() => {
        setEmail(location?.state?.email);
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        if (!email) { 
            setError(true);
            setLoading(false);
            return false;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            toast.warn("Email should be in proper format.");
            setLoading(false);
            return false;
        }
        else{
            await dispatch(ResendEmail({ email: email }));
        }
        dispatch(clearResendEmailMsg());
    };

    const { resendVerification } = useSelector((state) => state.resendVerification);
    useEffect(() => {
        if (resendVerification?.datasss && resendVerification?.datasss.status === 400) {
            setLoading(false);
            toast.warn(resendVerification?.datasss?.email[0]);
        }
        if (resendVerification && resendVerification?.datasss && resendVerification?.datasss?.status === true ) {
            setLoading(false);
            Swal.fire({
                title: "Done!",
                text: resendVerification?.datasss?.message,
                icon: "success",
            })
            navigate("/login");
        } else if ( resendVerification && resendVerification?.datasss && resendVerification?.datasss?.status === false ) {
            setLoading(false);
            toast.error(resendVerification?.datasss?.message);
        }
    }, [resendVerification]);

    return (
        <>
            <ToastContainer />
            <Header />
            <div className="wrapper">
                <section className=" middle-section">
                    <div className="signup-page">
                        <div className="container">
                            <div className="custom-form login_screen common-inner-container  fadeIn wow">
                                <div className="row g-0 custom-form-inner">
                                    <div className="col-md-5 col-12 cust-remove-padding">
                                        <div className="login-left-sec">
                                            <img src="img/stockSignUp.jpeg" alt="login-bg" />
                                            {/* <div className="bg-gradients">
                                                    </div> */}
                                            <div className="login-tagline ">
                                                <h2 className=" fadeInUp wow">WELCOME!</h2>
                                                {/* <p className=" fadeInUp wow">
                                                    It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when
                                                    looking at its layout.
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-12 cust-remove-padding login-right mt-5 mb-5">
                                        <div className="form-container mt-5">
                                            <div className="text-center fadeInUp wow">
                                                <h1>Resend Verification Email</h1>
                                            </div>
                                            <form action="#" onSubmit={(e)=>{e.preventDefault();handleSubmit()}} className="loginForm" >
                                                <div className="form-group fadeInUp wow">
                                                    <label htmlFor="u-name">Email address</label>
                                                    <input
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={location?.state?.email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                    {error && !email && (
                                                        <span style={{ color: "red" }} className="invalidInput" >
                                                            Required
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group form-footer mt-3">
                                                    {loading ? (
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn fadeInUp wow"
                                                                disabled
                                                            >
                                                                <div className={"btn-loader"}>
                                                                    <img
                                                                        src="/img/loader.svg"
                                                                        className="loader-svg-img"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button type="submit" className="btn fadeInUp wow" >
                                                                {" "}Resend Email{" "}
                                                            </button>
                                                        </>
                                                    )}
                                                    <div
                                                        className="forgot"
                                                        style={{ marginTop: "2rem" }}
                                                    >
                                                        {/* <Link to="/login">Login Here</Link> */}
                                                        <p className="footerlink fadeInUp wow">
                                                            Already Have An Account?{" "}
                                                            <Link to="/login">Login</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default ResendEmailVerification;
