import React, { useEffect, useState } from "react";
import Loader from 'react-js-loader';
import ConsumerSidebar from "../includes/sidebar";
import ConsumerHeader from "../includes/header";
import ConsumerFooter from "../../includes/footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import recentEventsAction from "../../../actions/recentEventsAction";

const RecentEvents = () => {
    
    const navigate = useNavigate();

    const [recentEventList, setrecentEventList] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(recentEventsAction());
    },[])

    // select list from reducer
    const recentEventsDtl = useSelector((state) => state.recentEvents.recentEvents)    
    useEffect(() => {
        if(recentEventsDtl && recentEventsDtl.datasss && recentEventsDtl.datasss.status === true ){
            setrecentEventList(recentEventsDtl.datasss.data)
        }
        setLoading(false);
    }, [recentEventsDtl]);

    // function for redirection to upcoming and past event's
    const setEvents = (event) => {
        const value = event.target.value;
        if(value === 'upcoming'){
            navigate("/upcoming-events");
        }
        if(value === 'past'){
            navigate("/past-events");
        }
    } 

    return (
    <>
        {loading ? (
            <div className={"loader-item"}>
                <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
            </div>
        ) : (
            <>
                <div className="wrapper">
                    <ConsumerHeader />
                    <section className="sidebar-content-wr consumer-block">
                        <div className="container">
                            <ConsumerSidebar />
                            <div className="right-content-block">
                                <div className="col-lg-12 col-md-12">
                                    <div className="right-top-text d-flex">
                                        <div className="col-sm-5 login_screen">
                                            <h3>My Event's</h3>
                                            <h6>( Recent Event's )</h6>
                                        </div>
                                        {/* <Link to={'/upcoming-events'} className="btn col-2">Upcoming</Link>
                                        <Link to={'/past-events'} className="btn col-2">Past Event's </Link> */}
                                        <div className="col-sm-4">
                                            <select className="events-form-control" onChange={setEvents}>
                                                <option value="">Recent Event's</option>
                                                <option value="upcoming">Upcoming Events</option>
                                                <option value="past">Past Event's</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-3 text-end"> <Link to={'/create-event-page'} className="btn"> <i className="fa fa-plus"> Cretae Event </i> </Link></div>
                                    </div>
                                    <div className="proposalTable table-responsive">
                                        <table className="table head-design">
                                            <thead>
                                                <tr>
                                                    <th>EVENT NAME</th>
                                                    <th>BUDGET</th>
                                                    <th>SERVICE TYPE</th>
                                                    <th>LOCATION</th>
                                                    <th>STARTING DATE</th>
                                                    <th>END DATE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(recentEventList.length > 0) ? (
                                                    <>
                                                        {recentEventList.slice(0, 10).map((event) => (
                                                            <>
                                                                <tr>
                                                                    <td>{event.event_name}</td>
                                                                    <td>${event.event_budget ?? '-'}</td>
                                                                    <td>{event.event_type ?? '-'}</td>
                                                                    <td>{event.location} <span className="countryColor"> Australia</span></td>
                                                                    <td>{event.event_from_date}</td>
                                                                    <td>{event.event_to_date}</td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </>
                                                ): (
                                                    <tr>
                                                        <td colSpan={7} align={'center'}> No  Recent  Event's  Available </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="learn-more-btn view-more-list text-end">
                                                            <Link to="/upcoming-events">Show More</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                    {/* {(recentEventList?.length > 0) && (
                                        <div className="fav-vendor-pagination paginatin-sec fadeInUp wow" 
                                        // style="visibility: visible; animation-name: fadeInUp;"
                                        >
                                            <nav className="data-pagination">
                                                <a href="#" className="arrow"><i className="fa fa-chevron-left"></i></a>
                                                <ul>
                                                    <li className="current active"><a href="#1">1</a></li>
                                                    <li><a href="#2">2</a></li>
                                                    <li><a href="#3">3</a></li>
                                                    <li><a href="#10">…</a></li>
                                                    <li><a href="#41">8</a></li>
                                                </ul>
                                                <a href="#" className="arrow"><i className="fa fa-chevron-right"></i></a>
                                            </nav>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <ConsumerFooter />
                </div>
            </>
        )}
    </>
    )
}

export default RecentEvents;