import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { clearLogoutMsg } from "../../../actions/logoutAction";
import VendorProfileAction from "../../../actions/VendorProfileAction";
import { openNav } from "../../../assets/js/customNav"
import { closeNav } from "../../../assets/js/customNav"
import axios from "axios";
import notificationAction from "../../../actions/notificationListAction";
import { getDatabase, ref, remove} from "firebase/database";

const VendorHeader = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [vendorInfo, setVendorInfo] = useState([]);
    const [vendorNotificationData, setVendorNotificationData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [notificatioCount, setnotificatioCount] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [isIos, setIsIos] = useState(false);
    const popupRef = useRef(null);
    const isMobile = require("is-mobile");
    
    const userInfo = JSON.parse(localStorage.getItem('userData'));
    const userData = userInfo?.data?.data?.user;
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    
    const handleLogout = () => {

        const db = getDatabase();
        const localStorageChatData = JSON.parse(
            localStorage.getItem("current_chat_id")
        );  
        if(localStorageChatData){
            const previousActiveUserRef =
                userData.role === 2
                        ? `active_users/${localStorageChatData.event_id}_${localStorageChatData.vendor_id}_${localStorageChatData.user_id}`
                        : `active_users/${localStorageChatData.event_id}_${localStorageChatData.user_id}_${localStorageChatData.vendor_id}`;
            let removeActiveUser = ref(db, previousActiveUserRef);
            remove(removeActiveUser)
            .then((data) => {
                localStorage.removeItem("current_chat_id");
            })
            .catch((error) => {
                console.log(error, "node error");
            });
        }
        let fcm_token = localStorage.getItem('FCM_TOKEN')
        if (userInfo) {
            const headers = {
                'content-type': 'application/json',
                Authorization: `Bearer ${tokenObj.api_token}`
            }
            let obj = {
                user_id: userData.id,
                device_token: fcm_token,
                device_type : isMobile() ? 'mobile' : 'desktop'
            }
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`, obj, { headers }).then(response => {
                localStorage.removeItem("current_chat_id");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                localStorage.removeItem("FCM_TOKEN");
                localStorage.removeItem("isunsaved");
                localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
                navigate("/home")
                // window.location.href = "/home";
            })
        }
        dispatch(clearLogoutMsg());
    };

    // useEffect(() => {
    //     dispatch(VendorProfileAction());
    // }, [dispatch]);

    useEffect(() => {
        // to get device
        var isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);;
        setIsIos(isOnIOS);
    },[])
    
    // To check vendor saved there data or not (for Iphone)
    const handlePageChange = (e = "") => {
        var isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);;
        // if(isOnIOS){
            if(vendorInfo.step === 1){
                var key = localStorage.getItem('isunsaved');
                if (key) {
                    const confirmNavigation = window.confirm(
                        "You have unsaved changes. Are you sure you want to leave?"
                    );
                    if (!confirmNavigation) {
                        // User cancelled navigation
                        return;
                    }else{
                        if(e != ""){
                            window.location.href = e; // Replace with your desired route
                        }else{
                            handleLogout();
                        } 
                    }
                }
            }
        // }else{
            // handleLogout();
        // }
    }
    
    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        if (!userInfo) {
            // setData(userInfo.data.data.user);
            navigate("/login");
            toast('Login required.');
        }
    }, [navigate])
    // Get vendor all information
    const vendorProfile = useSelector((state) => state.vendorProfile.vendorProfile);
    const vendorNotifications = useSelector((state) => state.notificationList.notification);

    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setVendorInfo(vendorProfile.datasss.data);
        }
    }, [vendorProfile]);

    useEffect(() => {
        if (vendorNotifications && vendorNotifications.datasss && vendorNotifications.datasss.status === true) {
            setVendorNotificationData(vendorNotifications.datasss.data);
            let count = vendorNotifications.datasss.data.filter(count => count.status === '0')
            setnotificatioCount(count.length);
        }
    }, [vendorNotifications]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(false);
          }
        };
    
        const handleEscKey = (event) => {
          if (event.keyCode === 27) {
            setShowPopup(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleEscKey);
        };
    }, []);

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        if (!userInfo) {
            navigate("/login");
            toast('Login required.');
        }
        dispatch(notificationAction());
    }, [dispatch, navigate]);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }
    const seeMoreData = () => {
        setShowMore(!showMore);
    }

    const handleReadNotification = (id, postId, type) => {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {
            id: id,
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/notification-seen/`, obj, { headers }).then(response => {
            if (response && response.data) {
                dispatch(notificationAction())
            }
        })
        if(type === "Event"){
            navigate(`/event-details/${postId}`)
        }else if(type === "Review"){
            navigate(`/vendor-reviews?review_id=${postId}`)
        }else if(type === "Reported"){

        }else if(type === "Change Password"){

        }else if(type === "Subscription"){

        }
        
    }

    return (
        <>
            <header className="header fadeInDown">
                <div className="container">
                    <nav className="navbar">
                        <div className="container">
                            {(vendorInfo.step === 0) ?
                                <Link to={localStorage.getItem('isunsaved') ? undefined : '/vendor-dashboard'} 
                                    onClick={localStorage.getItem('isunsaved') ? () => ( isIos ? handlePageChange('/vendor-dashboard') : window.location.href = '/vendor-dashboard') : undefined} 
                                    className="navbar-brand desktop-brand">
                                    <img src="/img/logo.svg" alt="ozgigs" />
                                </Link>
                            :   
                                <Link className="navbar-brand desktop-brand">
                                    <img src="/img/logo.svg" alt="ozgigs" />
                                </Link>
                            }
                            <div id="mySidenav" className="sidenav">
                                <span className="closebtn" onClick={closeNav} >&times;</span>
                                {/* <Link onClick={() => window.location.href = '/vendor-home'} className="navbar-brand">
                                    <img src="/img/logo.svg" alt="ozgigs" />
                                </Link> */}
                                {(vendorInfo.step === 0) ?
                                    <Link to={localStorage.getItem('isunsaved') ? undefined : '/vendor-dashboard'} 
                                        onClick={localStorage.getItem('isunsaved') ? () => ( isIos ? handlePageChange('/vendor-dashboard') : window.location.href = '/vendor-dashboard') : undefined} 
                                        className="navbar-brand">
                                        <img src="/img/logo.svg" alt="ozgigs" />
                                    </Link>
                                :
                                    <Link className="navbar-brand">
                                        <img src="/img/logo.svg" alt="ozgigs" />
                                    </Link> 
                                }
                                <ul>
                                    <li>
                                        <Link target="_blank" to="/faq#AboutUs" className="nav-link">
                                            <img src="/img/Search.svg" alt="" />
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" to="/faq#Support" className="nav-link">
                                            <img src="/img/Search.svg" alt="" />
                                            Contact
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" to="/faq" className="nav-link">
                                            <img src="/img/Circle_Warning.svg" alt="" />
                                            Help
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <span className="menu-btn" onClick={openNav} >&#9776;</span>
                            <div className="signup-signin-sec">
                                {(() => {
                                    if (vendorInfo.step === 0) {
                                        if ((window.location.href.indexOf("vendor-home") > -1) || (window.location.href.indexOf("event-details") > -1)) {
                                            return (<><Link to={'/vendor-dashboard'} className="btn">Home</Link> </>)
                                        }
                                        else {
                                            return (<>
                                                <div className="web-alignment">
                                                    <Link to={'/vendor-dashboard'} className="btn btn_normal mx-1"> Home</Link>
                                                    <Link to={'/vendor-home'} className="btn btn_normal mx-1"> Event Marketplace</Link>
                                                    <Link to={'/vendor-profile'} className="btn btn_normal mx-1">My Profile</Link>
                                                </div>

                                                <div className="mobile-alignment">
                                                    <Link to={'/vendor-dashboard'} className="btn btn_normal mx-1">
                                                        <img src="/img/dashboard.svg" alt="Sidebar icon" tooltip="Dashboard" className="dashboard-mob-icon"/>
                                                    </Link>
                                                    <Link to={'/vendor-home'} className="btn btn_normal mx-1">
                                                        <img src="/img/shop-icon.png" alt="Sidebar icon" tooltip="Event Marketplace" className="shop-mob-icon"/>
                                                    </Link>
                                                    <Link to={'/vendor-profile'} className="btn btn_normal mx-1">
                                                        <img src="./img/user.svg" alt="" tooltip="Profile" className="profile-mob-icon" />
                                                    </Link>
                                                </div>
                                            </>)
                                        }
                                    } else {
                                        if ((window.location.href.indexOf("vendor-profile") > -1)) {
                                            // return (
                                            //     <>
                                            //         <Link to={localStorage.getItem('isunsaved') ? undefined : '/vendor-home'} 
                                            //             onClick={localStorage.getItem('isunsaved') ? () => ( isIos ? handlePageChange('/vendor-home') : window.location.href = '/vendor-home') : undefined} 
                                            //             className="btn">Home
                                            //         </Link> 
                                            //     </>
                                            // )
                                        }
                                        else {
                                            return (<><Link to={'/vendor-profile'} className="btn btn_normal"> My Profile</Link></>)
                                        }
                                    }
                                })()}
                                <div className="web-alignment">
                                    <button  onClick={() => { localStorage.getItem('isunsaved') ? handlePageChange() : handleLogout(); }} className="btn">Logout</button>
                                </div>
                                <div className="mobile-alignment">
                                    <button onClick={() => { localStorage.getItem('isunsaved') ? handlePageChange() : handleLogout(); }} className="btn">
                                        <img src="/img/logout-icon.png" alt="Sidebar icon" tooltip="Logout" className="logout-mob-icon"/>
                                    </button>
                                </div>
                                <figure>
                                    {/* <a href="" data-toggle="tooltip" data-placement="right" title="Under Process">
                                        <img src="/img/Header_Notification.svg" alt=""/>
                                    </a> */}
                                    <button onClick={togglePopup} data-toggle="tooltip" data-placement="right" title="Under Process">
                                        <img src="/img/Header_Notification.svg" alt="" />
                                        {/* {notificatioCount !== 0 && ( */}
                                        <figcaption>{notificatioCount}</figcaption>
                                        {/* )} */}
                                    </button>
                                    {showPopup && (
                                        <div className="notification-popup-wr comn-para-white" ref={popupRef}>
                                            <div className="notification-popup-text">
                                                <div className="header-notification-text">
                                                    <p>Notification</p>
                                                </div>
                                                <div className="header-popup-setting">
                                                        <figure>
                                                            {(vendorInfo.step === 0)?
                                                                <Link to="/vendor-settings">
                                                                    <img src="/img/header-setting-icon.svg" alt="Header Setting Icon" />
                                                                </Link>
                                                            :   <img src="/img/header-setting-icon.svg" alt="Header Setting Icon" />
                                                            }
                                                        </figure>
                                                
                                                </div>
                                            </div>
                                            <div className="header-popup-names-block">
                                                {!showMore && vendorNotificationData
                                                    .slice(0, 3)
                                                    .map((notification, index) => (
                                                        <div key={index} className="header-popup-names-inner">
                                                            {Number(notification.status) ? <></> : <div className="show-read-unread show-unread"></div>}
                                                            <div className="header-popup-img-text" onClick={() => handleReadNotification(notification.id, notification.post_id, notification.notification_type)}>
                                                                <figure>
                                                                    <img className="noti-img" src='/img/favicon.png' alt="Joe Rogan" />
                                                                </figure>
                                                                <div className="header-popup-text">
                                                                    <p>{notification.notification_type}</p>
                                                                    <p>{notification.text}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {showMore &&
                                                    vendorNotificationData.map((notification, index) => (
                                                            <div
                                                                key={index}
                                                                className="header-popup-names-inner"
                                                            >
                                                                {Number(notification.status) ? <></> : <div className="show-read-unread show-unread"></div>}
                                                                <div className="header-popup-img-text" onClick={() => handleReadNotification(notification.id, notification.post_id, notification.notification_type)}>
                                                                    <figure>
                                                                        <img className="noti-img" src='/img/favicon.png' alt="Joe Rogan" />
                                                                    </figure>
                                                                    <div className="header-popup-text">
                                                                        <p>{notification.notification_type}</p>
                                                                        <p>{notification.text}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                            </div>
                                            {
                                                showMore ? <div className="header-popup-btn">
                                                    <a href="#!" onClick={seeMoreData}>See less</a>
                                                </div> : <div className="header-popup-btn">
                                                    <a href="#!" onClick={seeMoreData}>See more</a>
                                                </div>
                                            }
                                            {/* {!showMore && vendorNotificationData && vendorNotificationData.length > 3 && (<div className="header-popup-btn">
                                                <a href="#!" onClick={seeMoreData}>See more</a>
                                            </div>
                                            )} */}
                                        </div>
                                    )}
                                    {/* <figcaption>1</figcaption> */}
                                </figure>
                            </div>

                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default VendorHeader;