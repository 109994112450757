import { categoryData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const categoryAction = () => async (dispatch) => {
    try {
        const categoryList = await commonService.withOutLogin   (`/category-list`);
        if (categoryList) {
            dispatch({ type: categoryData.GET_CATEGORY_SUCCESS, payload: { datasss: categoryList.data } });
        }
        else {
            dispatch({ type: categoryData.GET_CATEGORY_FAILURE, payload: { data: categoryList.data } });
        }
    } 
    catch (error) {
        dispatch({ type: categoryData.GET_CATEGORY_FAILURE, payload: error.message })
    }
}

export default categoryAction;