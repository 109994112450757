import  axios  from "axios";
import { clearLoginData, userConstants } from "../constants/user.constants";
import { Navigate, useNavigate } from "react-router";
import { commonService } from "../services/common.service";
import { toast } from "react-toastify";

const authenticationLogin = (datass) => async (dispatch) => {
  try {
    const userdata = await commonService.withOutToken(`/login`, datass);
    if (userdata) {
      // Check if the "config" property exists and remove it if it does
      if (userdata.hasOwnProperty('config')) {
        delete userdata.config;
      }
      if (userdata.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(userdata));
      }
      dispatch({
        type: userConstants.GET_LOGIN_SUCCESS,
        payload: { datasss: userdata.data },
      });
    } else {
      dispatch({
        type: userConstants.GET_LOGIN_FAILURE,
        payload: { datasss: userdata.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({ type: userConstants.GET_LOGIN_FAILURE, payload: error.message });
  }
};

export const clearLoginMsg = () => async (dispatch)=>{
  try{
    dispatch({type : clearLoginData.CLEAR_LOGIN_MESSAGE , payload : []})
  } catch(err){
    console.log(`Error while calling login api ${err}`);
  }
}

export default authenticationLogin;
