import { DeleteEventConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const deleteEventAction = (id) => async (dispatch) => {
    try {
        const deleteEvent = await commonService.withLogin(`/delete-event/${id}`);
        if (deleteEvent) {
            dispatch({ type: DeleteEventConst.GET_DELETEEVENT_SUCCESS, payload: { datasss: deleteEvent.data } });
        }
        else {
            dispatch({ type: DeleteEventConst.GET_DELETEEVENT_FAILURE, payload: { data: deleteEvent.data } });
        }
    }
    catch(error) {
        dispatch({ type: DeleteEventConst.GET_DELETEEVENT_FAILURE, payload: error.message })
    }
}

export default deleteEventAction;