import React, { useEffect, useState } from "react";
// import Loader from 'react-js-loader';
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';

import ConsumerSidebar from "../includes/sidebar";
import ConsumerHeader from "../includes/header";
import ConsumerFooter from "../../includes/footer";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import viewEventAction from "../../../actions/viewEventAction";
import moment from "moment";

const Marker = ({ text }) => (
    <div style={{ position: "relative", top: "-50%", left: "-50%", width: "30px", height: "30px" }}>
      <img src="../img/location-pin.png" alt="marker" style={{ width: "100%", height: "auto" }} />
    </div>
);

const ViewEvent = (props) => {

    const google = window.google;
    const [eventDtl, seteventDtl] = useState([]);
    const [category, setcategory] = useState([]);
    const [totalBudget, setTotalBudget] = useState(0)

    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [eventDecription,setEventDecription] = useState([])

    const dispatch = useDispatch();

    const { id } = useParams();


    function initializeMap() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setLatitude(latitude)
                    setLongitude(longitude)
                    const options = {
                        center: { lat: latitude, lng: longitude },
                        zoom: 17,
                        disableDefaultUI: true,
                    };
                    const map = new google.maps.Map(document.getElementById("map"), options);
                    const marker = new google.maps.Marker({
                        position: { lat: latitude, lng: longitude },
                        map: map,
                        title: "My Location",
                    });
                },
                () => {
                    const options = {
                        center: { lat: Number(latitude), lng: Number(longitude) },
                        zoom: 17,
                        disableDefaultUI: true,
                    };
                    const map = new google.maps.Map(document.getElementById("map"), options);
                    const marker = new google.maps.Marker({
                        position: { lat: Number(latitude), lng: Number(longitude) },
                        map: map,
                        title: "My Location",
                    });
                }
            );
        } else {
            alert("Location is not supported by this browser.");
        }
    }

    useEffect(() => {
        window.addEventListener('load', () => initializeMap(latitude, longitude));
    }, [latitude, longitude]);


    useEffect(() => {
        dispatch(viewEventAction(id));
    }, []);

    const event = useSelector((state) => state.viewEvent.viewEvent);
    useEffect(() => {
        if (event && event.datasss && event.datasss.status === true) {
            seteventDtl(event.datasss.data)
            const eventDescription = event.datasss.data.event_description;
            const descriptionLines = eventDescription ? eventDescription.split('\n') : [];
            setEventDecription(descriptionLines)
            setcategory(event.datasss.data.event_category)
            setLatitude(event.datasss.data.latitude)
            setLongitude(event.datasss.data.longitude)
        }
    }, [event, category]);

    useEffect(()=> {
        const sum = eventDtl?.event_category?.reduce((total , element) => total + element.budget, 0)
        setTotalBudget(sum)
    },[event,eventDtl])
    
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleLocationClick = (loc) => {
        if (loc) {
          const location = encodeURIComponent(loc);
          const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;
          window.open(googleMapsUrl, "_blank");
        }
      };
    return (
        <>
            <div className="wrapper">
                <ConsumerHeader />
                <section className="sidebar-content-wr consumer-block event-listing-details-wrapper event-details">
                    <div className="container">
                        {/* <ConsumerSidebar /> */}
                        <div className="right-content-block">
                            <div className="joshn-wedding">
                                {/* <div className="banner-inner-block">
                                    <div className="right-top-text">
                                        <h3>{eventDtl.event_name}</h3>
                                    </div>
                                    <div className="event-details-block">
                                        <ul>
                                            <li><span><img src="/img/Frame.svg" alt="" /></span>Event Budget:<p>{(eventDtl.event_budget) ? '$ ' + numberWithCommas(eventDtl.event_budget) : '-'}</p>
                                            </li>
                                            <li><span><img src="/img/ep_location.png" placeholder="location.." alt="" /></span>Location: {eventDtl?.location ? <a href="#" onClick={()=>handleLocationClick(eventDtl?.location)}>{eventDtl?.location}</a> : "NA"} 
                                            </li>
                                            <li><span> <img src="/img/carbon_user-multiple.svg" alt=""/></span>Number of Guests:<p>{(eventDtl.num_of_guest) ?? '-'}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="event-service">
                                        <li>Event date: <span>{moment(eventDtl.event_from_date).format('DD-MM-YYYY') + ' / ' + moment(eventDtl.event_to_date).format('DD-MM-YYYY')}</span></li>
                                        <li>Service Required:
                                            <span>
                                                {(eventDtl?.event_category?.length > 0) ? (
                                                    <>
                                                        {eventDtl?.event_category?.map((data) => (
                                                            <>
                                                                {data?.category?.category_name ?? ''}  |
                                                            </>
                                                        ))}
                                                    </>
                                                ) : (<h6>--</h6>)}
                                            </span>
                                        </li>
                                    </ul>
                                   
                                    <div className="event-brief-block common-description">
                                        <div className="event-brief">
                                            <h4 className="common-label">event brief</h4>
                                            <p className="paragraph event-description">{eventDecription.map((line, index) => (
                                                <span key={index}>{line}<br/></span>
                                            ))}</p>
                                            
                                        </div>
                                        <div className="event-document">
                                            <h4 className="common-label">event documents</h4>
                                            <div className="event-documents-downloads">
                                                <a href={eventDtl.event_file} download={eventDtl.event_file} target="_blank" id="download-document"><img src="/img/Frame_.svg" alt="" />
                                                    <p>Download Document</p>
                                                    <div className="upload-doc">
                                                        <iframe className="upload-doc" src={eventDtl.event_file} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-categories  fadeInUp wow ">
                                        <h4 className="common-label">EVENT categories</h4>
                                        <div className="right-box">
                                            <div className="categories-accordion auction-accordion-block">
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        {(eventDtl?.event_category?.length > 0) ? (
                                                            <>
                                                                {eventDtl.event_category.map((data) => (
                                                                    <>
                                                                        <h2 className="accordion-header" id="headingTwo">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + data?.id} aria-expanded="false" aria-controls={"collapse_" + data?.id}>
                                                                                <label className="checkbox-label">{data?.category?.category_name}</label>
                                                                            </button>
                                                                        </h2>
                                                                        {data.event_sub_category.map((subData) => {
                                                                            return (
                                                                                <>
                                                                                    <div id={"collapse_" + data?.id} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                                        <div className="accordion-body">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <label htmlFor="INdoor" className="checkbox-label">{subData?.subcategory?.subcategory_name}</label>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <h6> Oops !! There are no event category ! </h6>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div> */}
                                <div className="banner-inner-block">
                                    <div className="event-listing-type-date">
                                        <div className="event-listing-type">
                                            <ul>
                                                <li>{eventDtl.event_name}</li>
                                                <li>Event Type : {eventDtl.event_type ?? '-'} </li>
                                            </ul>
                                        </div>
                                        <div className="event-listing-date">
                                            <div className="event-listing-date-inner">
                                                <span><img src="./../img/event_calender_icon.svg"/></span>
                                                <p>Event Date:</p>
                                                <p>{moment(eventDtl.event_from_date).format('DD-MM-YYYY')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-listing-budget-block">
                                        <div className="event-listing-budget-inner">
                                            <span><img src="./../img/Frame.svg"/></span>
                                            <h5>Event Budget Total:</h5>
                                            {/* <p>{(eventDtl.event_budget) ? '$ ' + numberWithCommas(eventDtl.event_budget) : 'N/A' } </p> */}
                                            <p>{totalBudget ? '$ ' + numberWithCommas(totalBudget) : 'N/A' } </p>
                                            
                                        </div>
                                        <div className="event-listing-budget-inner">
                                            <span><img src="./../img/location_icon.svg"/></span>
                                            <h5>Address:</h5>
                                            <a href='#' onClick={()=>handleLocationClick(eventDtl?.location)}>{eventDtl?.location ? eventDtl?.location : 'N/A'}</a>
                                        </div>
                                        <div className="event-listing-budget-inner">
                                            <span><img src="./../img/carbon_user-multiple.svg"/></span>
                                            <h5>Number of Guests:</h5>
                                            <p>{(eventDtl?.num_of_guest ? eventDtl?.num_of_guest : 'N/A')}</p>
                                        </div>
                                    </div>
                                    {eventDtl?.event_category?.length > 0 ? <>
                                        <div className="event-listing-service-block">
                                            <div className="event-listing-service-head">
                                                <span><img src="./../img/Frame.svg"/></span>
                                                <h5>Vendor Required:</h5>
                                            </div>
                                            <div className="event-listing-service-list">
                                                <ul>
                                                {eventDtl.event_category.map((x , index) => {
                                                    return <ul key={index}>
                                                        <li>{x.category.category_name} {(x.budget) ? '- $' + numberWithCommas(x.budget) +' budget' : '- Message for budget' }</li>
                                                    </ul>
                                                })}
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </> : null}
                                    <div className="event-brief-description-block">
                                        <div className="event-brief-description-head">
                                            <h4 className="common-label">Event description</h4>
                                        </div>
                                        <div className="event-brief-description-box">
                                        {eventDecription.map((line, index) => (
                                        <span key={index}>{line}<br/></span>
                                        ))}
                                            {/* <p>{eventDtl.event_description}</p> */}
                                            <a  href={eventDtl.event_file} download={eventDtl.event_file} target="_blank">View Document</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConsumerFooter />
            </div>
        </>
    )
}
// export default ViewEvent;
export default ViewEvent;
