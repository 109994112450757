import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link} from "react-router-dom";
import Loader from 'react-js-loader';
import Select, { components } from 'react-select';
import regionCategoryListAction from "../../actions/regionCategoryListAction";
import VendorHeader from "./includes/header";
import VendorFooter from "../includes/footer";
import "react-toastify/dist/ReactToastify.css";
import vendorHomeAction from "../../actions/vendorHomeAction";
import moment from "moment";

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isChecked,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        fontSize:'16px',
        letterSpacing:'1px'
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isChecked={isChecked}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isChecked} style={{width:'20px',height:'20px',marginRight:'20px'}} />
            {children}
        </components.Option>
    );
};
const VendorHome = () => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchEvent, setSearchEvent] = useState("");
    const [loading, setLoading] = useState(true);
    const [vendorData, setVendorData] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        // setLoading(true);
        dispatch(vendorHomeAction({ category_id: selectedCategory, search_event: searchEvent, region_id: selectedOptions }))
    }, [selectedCategory, searchEvent, selectedOptions, dispatch]);

    // Get vendor all information
    const vendorProfile = useSelector((state) => state.vendorProfile?.vendorProfile);
    useEffect(() => {
        if (vendorProfile && vendorProfile.datasss && vendorProfile.datasss.status === true) {
            setVendorData(vendorProfile.datasss.data);
        }
    }, [vendorProfile]);

    // get all event's matched vendor category 
    const eventList = useSelector((state) => state.vendorHome.vendorHome);
    useEffect(() => {
        if(eventList && eventList.datasss){
            setLoading(false);
        }
        if (eventList && eventList.datasss && eventList.datasss.status === true) {
            setEventData(eventList.datasss.data);
        }
        else {
            // setLoading(false);
            setEventData([]);
        }
    }, [eventList]);

    // Category list start 
    useEffect(() => { dispatch(regionCategoryListAction()); }, []);

    const category = useSelector((state) => state.regionsCategoryList);

    useEffect(() => {
        if (category && category.regionsCategoryList && category.regionsCategoryList.datasss && category.regionsCategoryList.datasss.status === true) {
            setCategoryList(category.regionsCategoryList.datasss.data.categoryList);
            setRegionList(category.regionsCategoryList.datasss.data.regionList);
        }
    }, [category]);

    const preSelectedOptions = regionList
        .filter((option) => selectedOptions.includes(option.id))
        .map((option) => ({ label: option.name, value: option.id }));

    const preSelectedCategory = categoryList
        .filter((option) => selectedCategory.includes(option.id))
        .map((option) => ({ label: option.category_name, value: option.id }));

    const handleCategoryChange = (options) => {
        if (Array.isArray(options)) {
            setSelectedCategory(options.map((opt) => opt.value));
        }
    }
    // for 
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleLocationClick = (loc) => {
        if (loc) {
          const location = encodeURIComponent(loc);
          const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;
          window.open(googleMapsUrl, "_blank");
        }
      };
    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className=" middle-section">
                            <div className="banner-sec">
                                <div className="container">
                                    {/* <div className="banner-img common-inner-container fadeInUp wow">
                                        <img src="./img/biglogo.png" className="big-logo" alt="" />
                                        <img src="./img/bannerimg.png" className="logo-name" alt="" />
                                    </div> */}

                                    <div className="filter-section">
                                        <div className="scroll-section common-inner-container fadeInUp wow">
                                            <Link className="home-logo" to="/home">
                                                <img src="./img/logo.svg" alt="ozgigs" />
                                            </Link>
                                        </div>
                                        <div className="filter-section-inner fadeInUp wow" style={{visibility:'visible'}}>
                                            <div className="filter-section-row desktop-view">
                                                {/* <div className="category-filter"> */}
                                                    <div className="category-filter common-select-sec category-sec">
                                                        <img src="./img/More_Grid_Small.svg" alt="" />
                                                        <Select className="custom-select"
                                                            options={
                                                                categoryList.map((data, index) => {
                                                                    return {
                                                                        label: data.category_name,
                                                                        value: data.id,
                                                                    }
                                                                })
                                                            }
                                                            placeholder='Category'
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            onChange={handleCategoryChange}
                                                            // value={preSelectedCategory}
                                                            // onChange={handleChangeDropdown}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#101727',
                                                                    borderRadius: '10px',
                                                                    border: 'none'
                                                                }),
                                                            }}
                                                            isMulti
                                                        />
                                                    </div>
                                                {/* </div> */}
                                                <div className="search-filter">
                                                    <div className="form-group ">
                                                        <div className="search-section">
                                                            <img src="/img/Search.svg" alt="" />
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="search_box"
                                                                name="suburb"
                                                                placeholder="Search"
                                                                onChange={(e) => { setFlag(true); setSearchEvent(e.target.value); }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="location-filter"> */}
                                                    <div className="location-filter common-select-sec category-sec">
                                                        <img src="./img/Compass.svg" alt="" />
                                                        <Select className="custom-select"
                                                            options={
                                                                regionList.map((data, index) => {
                                                                    return {
                                                                        label: data.name,
                                                                        value: data.id,
                                                                    }
                                                                })
                                                            }
                                                            placeholder='Location'
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            onChange={(options) => {
                                                                if (Array.isArray(options)) {
                                                                    setSelectedOptions(options.map((opt) => opt.value));
                                                                }
                                                            }}
                                                            // value={preSelectedOptions}
                                                            // onChange={handleChangeDropdown}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#101727',
                                                                    borderRadius: '10px',
                                                                    border: 'none'
                                                                }),
                                                            }}
                                                            isMulti
                                                        />
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className="filter-section-row tablet-view">
                                                <div className="search-filter">
                                                    <div className="form-group ">
                                                        <div className="search-section">
                                                            <img src="/img/Search.svg" alt="" />
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="search_box"
                                                                name="suburb"
                                                                placeholder="Search"
                                                                onChange={(e) => { setFlag(true); setSearchEvent(e.target.value); }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="searchandlocation">
                                                <div className="category-filter">
                                                    <div className="common-select-sec category-sec">
                                                        <img src="./img/More_Grid_Small.svg" alt="" />
                                                        <Select className="custom-select"
                                                            options={
                                                                categoryList.map((data, index) => {
                                                                    return {
                                                                        label: data.category_name,
                                                                        value: data.id,
                                                                    }
                                                                })
                                                            }
                                                            placeholder='Category'
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            onChange={handleCategoryChange}
                                                            // value={preSelectedCategory}
                                                            // onChange={handleChangeDropdown}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#101727',
                                                                    borderRadius: '10px',
                                                                    border: 'none'
                                                                }),
                                                            }}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                <div className="location-filter">
                                                    <div className="common-select-sec category-sec">
                                                        <img src="./img/Compass.svg" alt="" />
                                                        <Select className="custom-select"
                                                            options={
                                                                regionList.map((data, index) => {
                                                                    return {
                                                                        label: data.name,
                                                                        value: data.id,
                                                                    }
                                                                })
                                                            }
                                                            placeholder='Location'
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            onChange={(options) => {
                                                                if (Array.isArray(options)) {
                                                                    setSelectedOptions(options.map((opt) => opt.value));
                                                                }
                                                            }}
                                                            // value={preSelectedOptions}
                                                            // onChange={handleChangeDropdown}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#101727',
                                                                    borderRadius: '10px',
                                                                    border: 'none'
                                                                }),
                                                            }}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="scroll-section common-inner-container fadeInUp wow">
                                            {(vendorData.step === 1 ? <h2>YOU NEED TO SETUP YOUR PROFILE</h2> : <h2>EVENT MARKETPLACE</h2>)}
                                            <span className="scroll-btn">
                                                <a href="#pro-sec">
                                                    <span>
                                                        <i className="fa fa-chevron-down"></i>{" "}
                                                        <i className="fa fa-chevron-down"></i>{" "}
                                                        <i className="fa fa-chevron-down"></i>{" "}
                                                    </span>
                                                </a>
                                            </span>
                                        </div> */}
                                    </div>
                                    {/* <div className="banner-event-list">
                                        <div className="container">
                                            <div className="filter-section">
                                                <div className="filter-section-inner common-inner-container fadeInUp wow"
                                                    // style="visibility: visible; animation-name: fadeInUp;"
                                                    >
                                                    <form action="">
                                                        <div className="filter-section-row desktop-view">
                                                            <div className="category-filter">
                                                                <div className="form-group common-select-sec category-sec">
                                                                    <img src="./img/category_icon.png" alt=""/>
                                                                    <select className="form-control" name="" id="u-state">
                                                                        <option value="1">categories</option>
                                                                        <option value="1">1</option>
                                                                        <option value="1">1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="search-filter">
                                                                <div className="form-group ">
                                                                    <div className="search-section">
                                                                        <input className="form-control" type="text" id="u-suburb" name="suburb"
                                                                            placeholder="Search Events"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="location-filter">
                                                                <div className="form-group common-select-sec category-sec">
                                                                    <img src="./img/ep_location.png" alt=""/>
                                                                    <select className="form-control" name="" id="u-state">
                                                                        <option value="1">Location</option>
                                                                        <option value="1">1</option>
                                                                        <option value="1">1</option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            <div className="submit-btn">
                                                                <input type="submit" name="submit" value=""/>
                                                            </div>
                                                        </div>
                                                        <div className="filter-section-row tablet-view">
                                                            <div className="search-filter">
                                                                <div className="form-group ">
                                                                    <div className="search-section">
                                                                        <input className="form-control" type="text" id="u-suburb" name="suburb"
                                                                            placeholder="Search"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="searchandlocation">
                                                                <div className="category-filter">
                                                                    <div className="form-group common-select-sec category-sec">
                                                                        <img src="./img/category_icon.png" alt=""/>
                                                                        <select className="form-control" name="" id="u-state">
                                                                            <option value="1">categories</option>
                                                                            <option value="1">1</option>
                                                                            <option value="1">1</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="location-filter">
                                                                    <div className="form-group common-select-sec category-sec">
                                                                        <img src="./img/ep_location.png" alt=""/>
                                                                        <select className="form-control" name="" id="u-state">
                                                                            <option value="1">Location</option>
                                                                            <option value="1">1</option>
                                                                            <option value="1">1</option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <div className="submit-btn">
                                                                    <input type="submit" name="submit" value=""/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                    <ul className="event-service">
                                                        <li>Recent Searches: <span>Photography</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="container">
                                <div className="right-top-text">
                                    <h3>EVENT MARKETPLACE</h3>
                                    <span className="small color">This is your event marketplace where you view enquiry details, reply to customers and negotiate directly.</span>
                                </div>
                            </div>
                            <div className="container">
                                {(eventData.length > 0) ? (
                                    <div>
                                        {eventData.map((data) => {
                                            let totalBudget = 0; // Initialize totalBudget for each event item
                                            if (data.category) {
                                              data.category.forEach(x => {
                                                totalBudget += x.budget;
                                              });
                                            }
                                            
                                            return(
                                            <div key={data.id} className="fadeIn wow">
                                                <div className="event-listing-type-date">
                                                    <div className="event-listing-type">
                                                        <ul>
                                                            <li>{data.event_name}</li>
                                                            <li>Event Type : {data.event_type ?? '-'} </li>
                                                        </ul>
                                                    </div>
                                                    <div className="event-listing-date">
                                                        <span><img src="./img/ic_baseline-date-range.svg"/></span>
                                                        <p>Event Date:</p>
                                                        <p>{moment(data.event_from_date).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                </div>
                                                <div className="event-listing-budget-block vendor-event">
                                                    <div className="event-listing-budget-inner">
                                                        <span><img src="img/Frame.svg"/></span>
                                                        <h5>Event Budget Total:</h5>
                                                        {/* <p>{(data.event_budget) ? '$ ' + numberWithCommas(data.event_budget) : 0 } </p> */}
                                                        <p>{totalBudget ? '$ ' + numberWithCommas(totalBudget) : 'N/A' } </p>
                                                        
                                                    </div>
                                                    <div className="event-listing-budget-inner">
                                                        <span><img src="img/location_icon.svg"/></span>
                                                        <h5>Location:</h5>
                                                        <a href="#" onClick={()=>handleLocationClick(data?.location)}>{data?.location ? data?.location  : 'NA'}</a>
                                                    </div>
                                                    <div className="event-listing-budget-inner">
                                                        <span><img src="./img/carbon_user-multiple.svg"/></span>
                                                        <h5>Number of Guests:</h5>
                                                        <p>{(data.num_of_guest) ? data.num_of_guest : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                {data.category.length > 0 ? <>
                                                    <div className="event-listing-service-block">
                                                        <div className="event-listing-service-head">
                                                            <span><img src="img/Frame.svg"/></span>
                                                            <h5>Vendor Required:</h5>
                                                        </div>
                                                        <div className="event-listing-service-list">
                                                            <ul>
                                                            {data.category.map((x , index) => {
                                                                return <ul key={index}>
                                                                    <li>{x.category.category_name} {(x.budget) ? '- $' + numberWithCommas(x.budget) +' budget' : '- Message for budget' } </li>
                                                                </ul>
                                                            })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </> : null}
                                                <div className="event-brief-description-block">
                                                    <div className="event-brief-description-head">
                                                        <h4 className="common-label">event brief</h4>
                                                    </div>
                                                    <div className="event-brief-description-box">
                                                        {/* <p>{data.event_description}</p> */}
                                                        {

                                                                data && data.event_description.split('\n').map((line,index)=>{
                                                                    return (
                                                                        <span key={index}>{line}<br/></span>
                                                                    )
                                                                })
                                                        }

                                                        <Link to={`/event-details/${data.id}`} className="btn">View details</Link>
                                                    </div>
                                                </div>
                                                <hr style={{marginTop : "80px" , marginBottom:'80px', borderTop: '1px solid', opacity:'unset'}}/>
                                                {/* <div className="joshn-wedding">
                                                    <div className="banner-inner-block nancy-pool">
                                                        <Link to={`/event-details/${data.id}`}>
                                                            <div className="joshn-weds-inner">
                                                                <h4>{data.event_name}</h4>
                                                                <div className="videography-block">
                                                                    <p>Event Type : ( {data.event_type ?? '-'} )</p>
                                                                </div>
                                                                <div className="event-date-sec">
                                                                    <div className="event-block">
                                                                        <img src="./img/ic_baseline-date-range.svg" alt="" />
                                                                        <span>Event Date:</span>
                                                                    </div>
                                                                    <p>{data.event_from_date + ' / ' + data.event_to_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="event-budget-block">
                                                                <ul>
                                                                    <li><span><img src="./img/Frame.svg" alt="" /></span>Event Budget:<p>{(data.event_budget) ? '$ ' + numberWithCommas(data.event_budget) : '-'} </p>
                                                                    </li>
                                                                    <li><span><img src="./img/ep_location.png" alt="" /></span>Location:<p>{data.region_name},<strong className="countryColor"> Australia</strong></p>
                                                                    </li>
                                                                    <li><span> <img src="./img/carbon_user-multiple.svg" alt="" /></span>Number of Guests:<p>0</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Link>
                                                        <div className="event-brief-block common-description">
                                                            <div className="event-brief">
                                                                <h4 className="common-label">event brief</h4>
                                                                <p className="paragraph event-description">{data.event_description}</p>
                                                            </div>
                                                            <div className="event-document">
                                                                <h4 className="common-label">event documents</h4>
                                                             
                                                                <div className="documents-downloads">
                                                                    {(data.event_file.length > 0) ?
                                                                        <a href={data.event_file} download={data.event_file} target="_blank" id="download-document"><img src="/img/Frame_.svg" alt="" />
                                                                            <p>Download Document</p>
                                                                            <div className="upload-doc">
                                                                                <iframe className="upload-doc" src={data.event_file} alt="" />
                                                                            </div>
                                                                        </a>
                                                                        :
                                                                        <a href="" id="download-document">
                                                                            <p>No Document</p>
                                                                        </a>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <div className="common-inner-container">
                                        <div className="text-center fadeIn wow">
                                            <img className="w-25" src="./img/OZGIGS_Logo_Mark_PNG.png" />
                                            <h3 className="warning-text">There are currently no events that match your services. Please check back later.</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                    <VendorFooter />
                </>
            )}
        </>
    );
};

export default VendorHome;