import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { clearLogoutMsg } from "../../../actions/logoutAction";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { openNav } from "../../../assets/js/customNav";
import { closeNav } from "../../../assets/js/customNav";
import consumerProfileAction from '../../../actions/consumerProfileAction';
import notificationAction from "../../../actions/notificationListAction";
import axios from "axios";
import { getDatabase, ref, remove} from "firebase/database";

const ConsumerHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [consumerNotificationData, setConsumerNotificationData] = useState([]);
    const [notificatioCount, setnotificatioCount] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const popupRef = useRef(null);
    const isMobile = require("is-mobile");
    
    useEffect(() => {
        dispatch(consumerProfileAction());
    }, []);
    
    const handleLogout = () => {

        const db = getDatabase();
        const localStorageChatData = JSON.parse(
            localStorage.getItem("current_chat_id")
        );
        // alert(JSON.stringify(localStorageChatData))
        
        const userInfo = JSON.parse(localStorage.getItem("userData"));
        const userData = userInfo?.data?.data?.user;
        if(localStorageChatData){
            const previousActiveUserRef =
                userData.role === 2
                        ? `active_users/${localStorageChatData.event_id}_${localStorageChatData.vendor_id}_${localStorageChatData.user_id}`
                        : `active_users/${localStorageChatData.event_id}_${localStorageChatData.user_id}_${localStorageChatData.vendor_id}`;
            let removeActiveUser = ref(db, previousActiveUserRef);
            remove(removeActiveUser)
            .then((data) => {
                localStorage.removeItem("current_chat_id");
            })
            .catch((error) => {
                console.log(error, "node error");
            });
        }

        let tokenObj = JSON.parse(localStorage.getItem("token"));
        let fcm_token = localStorage.getItem("FCM_TOKEN");
        if (userInfo) {
            const headers = {
                "content-type": "application/json",
                Authorization: `Bearer ${tokenObj.api_token}`,
            };
            let obj = {
                user_id: userData.id,
                device_token: fcm_token,
                device_type : isMobile() ? 'mobile' : 'desktop'
            };
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`, obj, {
                    headers,
                })
                .then((response) => {
                    localStorage.removeItem("current_chat_id");
                    localStorage.removeItem("token");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("FCM_TOKEN");
                    localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
                    window.location.href = "/home";
                });
        }
        dispatch(clearLogoutMsg());
    };
    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("userData"));
        if (!userInfo) {
            navigate("/login");
            toast("Login required.");
        }
        dispatch(notificationAction());
    }, []);
    const consumerNotifications = useSelector(
        (state) => state.notificationList.notification
    );
    useEffect(() => {
        if (
            consumerNotifications &&
            consumerNotifications.datasss &&
            consumerNotifications.datasss.status == true
        ) {
            setConsumerNotificationData(consumerNotifications.datasss.data);
            let count = consumerNotifications.datasss.data.filter(
                (count) => count.status === "0"
            );
            setnotificatioCount(count.length);
        }
    }, [consumerNotifications]);

    const handleClick = () => {
    };
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    const seeMoreData = () => {
        setShowMore(!showMore);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(false);
          }
        };
    
        const handleEscKey = (event) => {
          if (event.keyCode === 27) {
            setShowPopup(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleEscKey);
        };
      }, []);
    const handleReadNotification = (id, postId, type) => {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {
            id: id,
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/notification-seen/`, obj, { headers }).then(response => {
            if (response && response.data) {
                dispatch(notificationAction())
            }
        })
        if(type === "Event" ){
            navigate(`/view-event/${postId}`)
        }else if(type === "Review"){
            navigate(`/consumer-reviews?review_id=${postId}`)
        }else if(type === "Reported"){

        }else if(type === "Change Password"){

        }else if(type === "Subscription"){

        }
    }

    return (
        <>
            <header className="header fadeInDown">
                <div className="container">
                    <nav className="navbar">
                        <div className="container">
                            <Link to="/consumer-home" className="navbar-brand desktop-brand">
                                <img src="/img/logo.svg" alt="ozgigs" />
                            </Link>
                            <div id="mySidenav" className="sidenav">
                                <span className="closebtn" onClick={closeNav}>
                                    &times;
                                </span>
                                <Link to="/consumer-home" className="navbar-brand ">
                                    <img src="/img/logo.svg" alt="ozgigs" />
                                </Link>
                                <ul>
                                    <li>
                                        <Link target="_blank" to="/faq#AboutUs" className="nav-link">
                                            <img src="/img/Search.svg" alt="" />
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" to="/faq#Support" className="nav-link">
                                            <img src="/img/Search.svg" alt="" />
                                            Contact
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" to="/faq" className="nav-link">
                                            <img src="/img/Circle_Warning.svg" alt="" />
                                            Help
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <span className="menu-btn" onClick={openNav}>
                                &#9776;
                            </span>
                            <div className="signup-signin-sec">
                                <Link to={"/consumer-home"} className="btn btn_normal">
                                    {" "}
                                    Home
                                </Link>
                                <Link to={"/consumer-profile"} className="btn btn_normal">
                                    {" "}
                                    My Profile
                                </Link>
                                <a
                                    onClick={() => {
                                        handleLogout();
                                    }}
                                    className="btn"
                                >
                                    Logout
                                </a>
                                <figure onClick={handleClick}>
                                    {/* <button>
                                        <img src="/img/Header_Notification.svg" title="Under Process" alt="" />
                                    </button> */}
                                    <button onClick={togglePopup} data-toggle="tooltip" data-placement="right" title="Notification">
                                        <img src="/img/Header_Notification.svg" alt="" />
                                        {/* {notificatioCount !== 0 && ( */}
                                            <figcaption>{notificatioCount}</figcaption>
                                            {/* )} */}
                                    </button>
                                    {showPopup && (
                                        <div className="notification-popup-wr comn-para-white" ref={popupRef}>
                                            <div className="notification-popup-text">
                                                <div className="header-notification-text">
                                                    <p>Notification</p>
                                                </div>
                                                <div className="header-popup-setting">
                                                
                                                        <figure>
                                                            <Link to="/consumer-profile">
                                                                <img src="/img/header-setting-icon.svg" alt="Header Setting Icon" />
                                                            </Link>
                                                        </figure>
                                                 
                                                </div>
                                            </div>
                                            <div className="header-popup-names-block">
                                                {consumerNotificationData.slice(0, 3).map((notification, index) => (
                                                    <div key={index} className="header-popup-names-inner" >
                                                        {Number(notification.status) ? <></> : <div className="show-read-unread show-unread"></div> }
                                                        <div className="header-popup-img-text" onClick={() => handleReadNotification(notification.id, notification.post_id, notification.notification_type)}>
                                                            <figure>
                                                                <img className="noti-img" src='/img/favicon.png' alt="Joe Rogan" />
                                                            </figure>
                                                            <div className="header-popup-text">
                                                                <p>{notification.notification_type}</p>
                                                                <p>{notification.text}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {showMore && consumerNotificationData.slice(3).map((notification, index) => (
                                                    <div key={index} className="header-popup-names-inner" >
                                                        {Number(notification.status) ?  <></> : <div className="show-read-unread show-unread"></div>}
                                                        <div className="header-popup-img-text" onClick={() => handleReadNotification(notification.id, notification.post_id, notification.notification_type)}>
                                                            <figure>
                                                                <img className="noti-img" src='/img/favicon.png' alt="Joe Rogan" />
                                                            </figure>
                                                            <div className="header-popup-text">
                                                                <p>{notification.notification_type}</p>
                                                                <p>{notification.text}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            {
                                                showMore ? <div className="header-popup-btn">
                                                    <a  onClick={seeMoreData}>See less</a>
                                                </div> : <div className="header-popup-btn">
                                                    <a onClick={seeMoreData}>See more</a>
                                                </div>
                                            }
                                        </div>
                                    )}

                                    {/* <figcaption>1</figcaption> */}
                                </figure>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default ConsumerHeader;