
import { DeleteEventConst, RecentEventsConst, UpdateEventConst } from '../constants/user.constants';
import { commonService } from '../services/common.service';

const recentEventsAction = () => async (dispatch) =>  {
    try {
        const recentEvents = await commonService.withLogin(`/upcoming-events`);
        if (recentEvents) {
            dispatch({ type: RecentEventsConst.GET_RECENTEVENTS_SUCCESS, payload: { datasss: recentEvents.data } });
            dispatch({ type: UpdateEventConst.UPDATE_EVENT_CLEAR, payload: { } });
            dispatch({ type: DeleteEventConst.DELETEEVENT_CLEAR, payload: { } });
        }
        else {
            dispatch({ type: RecentEventsConst.GET_RECENTEVENTS_FAILURE, payload: { data: recentEvents.data } });
        }
    }
    catch(error) {
        dispatch({ type: RecentEventsConst.GET_RECENTEVENTS_FAILURE, payload: error.message })
    }
}

export default recentEventsAction;