import { VendorProfileConstant, clearPasswordData} from "../constants/user.constants";
import { commonService } from "../services/common.service";

const VendorProfileAction = () => async (dispatch) => {
    try {
        const vendorProfile = await commonService.withToken('/vendor-profile');
        if(vendorProfile) {
            dispatch({type: VendorProfileConstant.GET_VENDORPROFILE_SUCCESS, payload: {datasss: vendorProfile.data} });
        }
        else {
            dispatch({ type: VendorProfileConstant.GET_VENDORPROFILE_FAILURE, payload: { data: vendorProfile.data } });
        }
    }
    catch (error) {
        dispatch({ type: VendorProfileConstant.GET_VENDORPROFILE_FAILURE, payload: error.message });
    }
}

export const clearPasswordMsg = () => async (dispatch)=>{
    try{
        dispatch({type : clearPasswordData.CLEAR_PASSWORD_MESSAGE , payload : []})
    } catch(err){
        console.log(`Error while calling login api ${err}`);
    }
}

export default VendorProfileAction;