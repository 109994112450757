import { DeleteProfileConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const deleteProfileAction = () => async (dispatch) => {
    try {
        const deleteProfile = await commonService.withToken(`/delete-profile`);
        if (deleteProfile) {
            dispatch({ type: DeleteProfileConst.GET_DELETEPROFILE_SUCCESS, payload: { datasss: deleteProfile.data } });
        }
        else {
            dispatch({ type: DeleteProfileConst.GET_DELETEPROFILE_FAILURE, payload: { data: deleteProfile.data } });
        }
    }
    catch (error) {
        dispatch({ type: DeleteProfileConst.GET_DELETEPROFILE_FAILURE, payload: error.message })
    }
}

export const clearDeleteProfileMsg = () => async (dispatch)=>{
    try{
        dispatch({type : DeleteProfileConst.CLEAR_DELETEPROFILE_MESSAGE , payload : []})
    } catch(err){
        console.log(`Error while calling login api ${err}`);
    }
}

export default deleteProfileAction;