import { ChatListConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const usersChatAction = () => async (dispatch) =>{
    try{
      const  chatList  = await commonService.withLogin(`/chat-list`)
      if(chatList){
        
        dispatch({type: ChatListConst.GET_CHATLIST_SUCCESS , payload: {datasss: chatList.data}})
      }
      else{
        dispatch({type: ChatListConst.GET_CHATLIST_FAILURE , payload: {data: chatList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: ChatListConst.GET_CONSUMER_FAILURE , payload: error.message})
  }
}

export default usersChatAction;