import { vendorDetailsData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const vendorDetailAction = (id) => async (dispatch) => {
    let vendorInfo = null;
    try {
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        {userInfo && userInfo?.data?.data && userInfo?.data?.data?.user?.role == 2 ? 
            vendorInfo = await commonService.withToken(`/vendor-preview`)
        :   vendorInfo = await commonService.withOutLogin(`/vendor-details/${id}`)
        }
        if (vendorInfo) {
            dispatch({ type: vendorDetailsData.GET_VENDORDETAIL_SUCCESS, payload: { datasss: vendorInfo.data } });
        }
        else {
            dispatch({ type: vendorDetailsData.GET_VENDORDETAIL_FAILURE, payload: { data: vendorInfo.data } });
        }
    }
    catch (error) {
        dispatch({ type: vendorDetailsData.GET_VENDORDETAIL_FAILURE, payload: error.message })
    }
}

export default vendorDetailAction;