
// const myFunction = () => {
//     var dots = document.getElementById("dots");
//     var moreText = document.getElementById("more");
//     var btnText = document.getElementById("myBtn");
  
//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more"; 
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less"; 
//       moreText.style.display = "inline";
//     }
// }

export const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("mySidenav").style.visibility = "visible";
    document.getElementById("mySidenav").classList.add("displaynone");
}

export const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.visibility = "hidden";
    document.getElementById("mySidenav").classList.remove("displaynone");
}